import React from "react";
import { useEffect , useState} from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

function Success(props) {
  const {s} = props.match.params;
  const [show, setShow] = useState(false);


  const location = useLocation();
  useEffect(() =>{
   // console.log(s);
   // console.log(location);

  }, [s]);

 if (s=='ok' && location.state && location.state .from == 'add')
 return (
      <div className="container">
        <main className="l-main lmain2">
          <div className="l-section l-section--archive">
            <div className="loading_logo successIcon">
              <img
                src={"assets/icons/success.png"}
                alt="tunisia living loading icon"
              />
              <p style={{ color: "#1c9070" }}>

              Votre annonce a été soumise avec succès, elle sera publiée une fois examinée et approuvée de notre côté, pour l'instant vous pouvez toujours la consulter et la modifier dans votre page de profil     

              </p>
    <br/>
            </div>
            
            <div className="callTOActionSuccess" >
           <Link className="btn btn-primary " to='/' >Page d'accueil</Link> 
           <Link className="btn btn-primary " to='/members/profile' >Visitez votre page de profil</Link>
             <br/><br/>
              <button className="btn btn-primary " onClick={()=>setShow(!show)}>Ajouter un autre annonce</button>
              <br/>
           {show && <> <br/>
            <Link className="btn btn-primary " to='/bon-plans/add' >Ajouter Bon Plan Annonce </Link>
            <Link className="btn btn-primary " to='/real-estates/add' >Ajouter Immobilier Annonce </Link>
            <br/> <br/>
            <Link className="btn btn-primary " to='/vehicles/add' >Ajouter Véhicule Annonce </Link> 
            <Link className="btn btn-primary " to='/articles/add' >Ajouter Article Annonce </Link>
            <br/> <br/>
            <Link className="btn btn-primary " to='/animals/add' >Ajouter Animal Annonce </Link>
            <Link className="btn btn-primary " to='/services/add' >Ajouter Service Annonce </Link>
            </>}
            </div>
          </div>
        </main>
      </div>
    );
  else  if (s=='ok' )
    return (
      <div className="container">
        <main className="l-main lmain2">
          <div className="l-section l-section--archive">
            <div className="loading_logo">
              <br />
  
            </div>
          </div>
        </main>
      </div>
    );
   else  return (
    <div className="container">
      <main className="l-main lmain2">
        <div className="l-section l-section--archive">
          <div className="loading_logo">
            <br />
            <img
              src={"assets/icons/fail.png"}
              alt="tunisia living loading icon"
            />
            <p style={{ color: "#db382d" }}>
         error 
            </p>
          </div>
        </div>
      </main>
    </div>
  ); 

}
export default Success;
