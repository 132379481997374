import React, { useEffect, useState } from "react";
import { IMAGES_BASE_URL , API_BASE_URL , keyHeader , minmileconst , minpriceconst} from "../../../../config";
import axios from "axios";
import Loading from "src/components/myWidgets/loadingWidget";
import {
  CCol,
  CDataTable,
  CLabel,
  CFormGroup,
  CSelect,
  CInputRadio,
  CInput
  /* CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,*/
} from "@coreui/react";
import { useLocation , useHistory } from "react-router-dom";
import Breadcrumb from "../../Breadcrumb";
import ArticleItem from "./articleItem";
import { articleSection } from "../../../../config";

import { FilterArticle } from "./filterArticle";

export default function ResultArticle() {
  const location = useLocation();
  const history = useHistory();

  const [isLoaded, setIsLoaded] = useState(false);
  const [all, setAll] = useState([]);
  const [errorAll,setErrorALL]= useState(false);
  const [results , setresults] = useState([]);
  const [isLoadedALL , setisLoadedALL] = useState(false);
  const [status, setStatus] = useState(null);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [types, setTypes] = useState([]);
  const [type, setType] = useState("");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [typesAll, setTypesAll] = useState([]);
  const [section, setSection] = useState(null);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [delegate, setDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [filtersCount, setFiltersCount] = useState(0);
  const [sortBy, setSortBy] = useState('dateD');

  const toggleVisibility = () => {
    //console.log('rdyjgkhlkmgdeefglmdesghg');
    setIsVisible(prev => !prev);
  };

     const searchParams = new URLSearchParams(location.search);

  const fields = [{ key: "title", _style: { width: "100%" } }];

  useEffect(() => {

    //console.log('paramMMM:', location.search );
    //console.log('paramMMMxxx:', searchParams );

  if (location.search)
    {       
    setStatus(searchParams.get('status'));
    setType(searchParams.get('type'));
    setCategory(searchParams.get('category'));
    setSection(searchParams.get('section'));
    setMaxPrice(searchParams.get('maxPrice'));
    setMinPrice(searchParams.get('minPrice'));
    setCountry(searchParams.get('country'));
    setCity(searchParams.get('city'));
    setDelegate(searchParams.get('delegate'));
    
    setFiltersCount(searchParams.size)
  }
  

  }, [location.search]);

  useEffect(() => {

    if (!localStorage.getItem("typeArticles")) 
    {axios(`${API_BASE_URL}/articles/types`)
        .then((res) => res.data)
        .then(
          (result) => {
            //console.log("result555 all", result);
            setTypesAll(result);  
          localStorage.setItem("typeArticles", JSON.stringify(result));
          if (result && searchParams.get('section')) {
            var typesF=[]
            typesF = result.filter(obj => { return obj.sectionId == searchParams.get('section') })
             setTypes(typesF);
            //console.log('countriesF',countriesF);
          }
         
          if (result && searchParams.get('type')) {
           var typesF=[]
           typesF = result.find((el) => el.id.toString() == searchParams.get('type'));
           setCategories(typesF.categoryArticles);
           //console.log('countriesF',countriesF);
         }
          },
          (error) => {
            //console.log(error);
          }
        ); } else {
          setTypesAll(JSON.parse(localStorage.getItem("typeArticles"))); 

          if (JSON.parse(localStorage.getItem("typeArticles")) && searchParams.get('section')) {
            var typesF=[]
            typesF = JSON.parse(localStorage.getItem("typeArticles")).filter(obj => { return obj.sectionId == searchParams.get('section') })
             setTypes(typesF);
            //console.log('countriesF',countriesF);
          }

          if (JSON.parse(localStorage.getItem("typeArticles")) && searchParams.get('type')) {
            var typesF=[]
            typesF = JSON.parse(localStorage.getItem("typeArticles")).find((el) => el.id.toString() == searchParams.get('type'));
            setCategories(typesF.categoryArticles);
            //console.log('countriesF',countriesF);
          }
        }

        if (!localStorage.getItem("countries")) {
          axios(`${API_BASE_URL}/countries/all`)
            .then((res) => res.data)
            .then(
              (result) => {
                // //console.log("countries", result);
                setCountries(result);
               // setIsLoaded2(true);
               if(location.state && location.state.all.cities) setCities(location.state.all.cities);
               else if (result && searchParams.get('country')) {
                var countriesF=[]
                countriesF = result.find((el) => el.id.toString() == searchParams.get('country'));
                setCities(countriesF.cities);
                //console.log('countriesF',countriesF);
              }
                localStorage.setItem("countries", JSON.stringify(result));
              },
              (error) => {}
            );
        } else {
          setCountries(JSON.parse(localStorage.getItem("countries")));
          if(location.state && location.state.all.cities) setCities(location.state.all.cities);
          else if (JSON.parse(localStorage.getItem("countries")) && searchParams.get('country')) {
           var countriesF=[]
           countriesF = JSON.parse(localStorage.getItem("countries")).find((el) => el.id.toString() == searchParams.get('country'));
          setCities(countriesF.cities);
           //console.log('countriesFfffffff',countriesF);
         }
        }
        
        if (location.state) {   
          //console.log('rtrtrttkkkkkkk : ',location.state.result);
           //console.log('all : ',location.state);
          setresults(location.state.result);
          setAll(location.state.all.all);
         
          
          setisLoadedALL(true)
          setIsLoaded(true);
        } else {
          //console.log('no state : ',location.state);
          
          axios
          (API_BASE_URL + "/article/all",keyHeader)
          .then((res) => res.data)
          .then(
            (result) =>{ 
            //console.log('all result' , result);
            setAll(result);
            setresults(result)    
            setisLoadedALL(true)
            setIsLoaded(true);
            //setdisable('');
          },
          (error) => {
            //console.log(error);
            setErrorALL(true)
          }
          );
        }   
        

    }, []);

    useEffect(() => {

      if(isLoadedALL)   {
        var results = all.filter(function (el) {
    
          const statusc = status === null || el.state == status;
          const sectionc = !section || (section  && el.typeArticle && el.typeArticle.sectionId && el.typeArticle.sectionId == section);
          const typec = !type || (type  && el.typeArticle && el.typeArticle.id && el.typeArticle.id == type);
          const categoryc = !category || (category  && el.categoryArticle && el.categoryArticle.id && el.categoryArticle.id == category);
   
   
    const minPricec = !minPrice || el.price >= minPrice;  
    const maxPricec = !maxPrice || el.price <= maxPrice; 
     const countryc = !country || el.country.id == country;  
          const cityc = !city || el.city.id == city;  
      const delegatec = !delegate || el.delegate.id == delegate;  
        
          return statusc && typec && sectionc  && categoryc && countryc && cityc && delegatec && minPricec && maxPricec;
        });
    
        //console.log('rsddsdxxx ',results)
        setresults(results);
      }  
       
      }, [ isLoadedALL, status, type, section, category , country , city , delegate , minPrice, maxPrice ]);
    
      const clearAll = () => {
        handleClearSelection('status');handleClearSelection('section');
        handleClearSelection('type');handleClearSelection('category');
        handleClearSelection('maxPrice');handleClearSelection('minPrice');
        handleClearSelection('country');handleClearSelection('city');handleClearSelection('delegate')
            }  
        
            const handleSortBy = (e) => {
              //console.log('sortBy:',e.target.value);
              setSortBy(e.target.value);
              const sortedData = [...results].sort((a, b) => {
           
                const titleA = a.nom !== undefined && a.nom !== null ? a.nom : ''; // Treat null or undefined as empty string
                const titleB = b.nom !== undefined && b.nom !== null ? b.nom : ''; // Treat null or undefined as empty string

                const convertToNumber = (value) => {
                  // Convert value to number if it's not null or undefined
                  return value !== null && value !== undefined ? parseFloat(value) || 0 : 0;
                };
            
                const priceA = convertToNumber(a.price);
                const priceB = convertToNumber(b.price);
            
                if ((priceA === null || priceA === 0) && (priceB === null || priceB === 0)) {
                  return 0; // Maintain original order if both prices are null or zero
                }

                if (e.target.value === 'nomC') {
                  if (!titleA && !titleB) return 0; // Both are null or empty, maintain current order
                  if (!titleA) return 1; // 'a' comes after 'b'
                  if (!titleB) return -1; // 'a' comes before 'b'
                  return titleA.localeCompare(titleB); // Compare non-null titles
                } else if (e.target.value === 'nomD') {
                  if (!titleA && !titleB) return 0; // Both are null or empty, maintain current order
                  if (!titleA) return 1; // 'a' comes after 'b'
                  if (!titleB) return -1; // 'a' comes before 'b'
                  return titleB.localeCompare(titleA); // Compare non-null titles
                }
                
                else    if (e.target.value === 'dateC') {
                  return a['createdAt'] > b['createdAt'] ? 1 : -1;
                }
                else    if (e.target.value === 'dateD') {
                  return a['createdAt'] < b['createdAt'] ? 1 : -1;
                } else     if (e.target.value === 'price_asc') {
                  return priceA - priceB; // Ascending order by price
                } else if (e.target.value === 'price_desc') {
                  return priceB - priceA; // Descending order by price
                }
                
              });
              setresults(sortedData);
             } 

    const handleSectionChange = (e) => {
        setSection(e.target.value);
        let i = e.target.value;
        var typesF = [];
  var typesF = [];
    typesF = typesAll.filter(obj => { return obj.sectionId == i })
    setTypes(typesF);
    setType('');
    setCategory('');
    //console.log(typesF);     
      };

    const handleTypeChange = (e) => {
        setType(e.target.value);
        
        let id = e.target.value;
        var typesF = [];
        if (id !== "0") {
          typesF = types.find((category) => category.id.toString() === id);
          setCategories(typesF.categoryArticles);
          setCategory("");
        } else {
          setCategories([]);
          setCategory("");
        }
      };
      const handleCategoryChange = (e) => {
        setCategory(e.target.value);
     
      };

    
      const updateUrlParams = (e) => {
        // //console.log('nnnnnvvvvthfgjukhjk',e.target.name)
         // Set the new value for 'mark' parameter
         searchParams.set(e.target.name, e.target.value);
     
         // Create a new URL with the updated search parameters
         const updatedURL = `${location.pathname}?${searchParams.toString()}`;
     
         // Replace the current URL with the updated one
         history.replace(updatedURL);
       };
   
       const deleteUrlParams = (p) => {
         // //console.log('nnnnnvvvvthfgjukhjk',e.target.name)
          // Set the new value for 'mark' parameter
          searchParams.delete(p);
      
          // Create a new URL with the updated search parameters
          const updatedURL = `${location.pathname}?${searchParams.toString()}`;
          setFiltersCount(searchParams.size)
          // Replace the current URL with the updated one
          history.replace(updatedURL);
        };
   
     const handleCountryChange = (e) => {
       setCountry(e.target.value);
       let id = e.target.value;
       var countriesF = [];
       // //console.log("iddd", id);
       if (id !== "0") {
         countriesF = countries.find((city) => city.id.toString() === id);
         setCities(countriesF.cities);
         setCity('');
         // //console.log("countriesF", countriesF);
       } else {
         setCity('');
         //  setModel("");
       }
     };
   
     const handleCityChange = (e) => {
       setCity(e.target.value);
       let id = e.target.value;
       var citiesF = [];
       // //console.log("iddd", id);
       if (id !== "0") {
         citiesF = cities.find((delegate) => delegate.id.toString() === id);
         setDelegates(citiesF.delegations);
         setDelegate('');
         //  //console.log("citiesF", citiesF);
       } else {
         setDelegate('');
         //  setModel("");
       }
     };
   
     const handleClearSelection = (type) => {
       if(searchParams.get(type))  deleteUrlParams(type) 
       if (type=='status') setStatus(null)
     else { const setStateFunction = `set${type.charAt(0).toUpperCase()}${type.slice(1)}`;
       eval(`${setStateFunction}('')`);}
       if(type=='country') { deleteUrlParams('city'); deleteUrlParams('delegate') ; setCity('');setDelegate('') }
       if(type=='city') { deleteUrlParams('delegate') ; setDelegate('') }
     };
   
     const handleSubmit = (e) => {
      setLoading(true)

      e.preventDefault();
      const formData = new FormData();
      formData.append("type", type);
      formData.append("category", category);
      formData.append("minPrice", minPrice);
      formData.append("maxPrice", maxPrice);

      axios
          .post(API_BASE_URL + "/articles/search", formData)
          .then((res) => {
              setLoading(false)

              //console.log("res ff", res)
              history.push({
                  pathname: "/articles/search-result",
                  state: { result: res.data, keywords: { type, category } },
              });
          })
          .catch((error) => {
              setLoading(false)

              //console.log(" errors : ", error);
          });
  };

  if (!isLoaded) {
    return (
   <Loading/>
    );
  } else {
    return (
      <div className="container">
      <Breadcrumb/>
        <main className="l-main">
          <div className="l-main__article l-main__content l-main__section l-article ">
            <div className="row">
              <CCol md="3">
              <span className="filtersMobButton" onClick={toggleVisibility} >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" id="filter"><path fill="#000" fill-rule="evenodd" d="M20 5h-1.17a3.001 3.001 0 0 0-5.66 0H4a1 1 0 0 0 0 2h9.17a3.001 3.001 0 0 0 5.66 0H20a1 1 0 1 0 0-2zm-4 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM3 12a1 1 0 0 1 1-1h1.17a3.001 3.001 0 0 1 5.66 0H20a1 1 0 1 1 0 2h-9.17a3.001 3.001 0 0 1-5.66 0H4a1 1 0 0 1-1-1zm5 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-4 4a1 1 0 1 0 0 2h9.17a3.001 3.001 0 0 0 5.66 0H20a1 1 0 1 0 0-2h-1.17a3.001 3.001 0 0 0-5.66 0H4zm13 1a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" clip-rule="evenodd"></path></svg> Filters
            </span>
            {filtersCount > 0 && <span className="filtersCountMob filtersCount">{ filtersCount+' appliqués'} <button
             onClick={clearAll} className="btn btn-primary ">
             Supprimer tout <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
<path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"></path>
</svg>
             </button>
              </span>}
              <div className={`bordered filter filtersSectionMob ${isVisible ? 'visibleFiltersmob' : ''}`} >
              <span onClick={toggleVisibility}
              className={`filtersMobButton filtersMobButtonx `} >&times;</span>          
              <CLabel className='filtersS'> 
            Filters
            </CLabel>
            {filtersCount > 0 && <span className="filtersCount">{ filtersCount+' appliqués'} <button
             onClick={clearAll} className="btn btn-primary ">
             Supprimer tout <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
<path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"></path>
</svg>
             </button>
              </span>}
            <div>
    <br />
                <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="1"
                      name="status"
                      value="1"
                      onChange={(e) => {setStatus(1);updateUrlParams(e)}}
                      checked = {status==1 || status=='1' || status==true  ? true : false}
                    />
                    <CLabel variant="checkbox" htmlFor="neuf">
                      Neuf
                    </CLabel>
                  </CFormGroup>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="0"
                      name="status"
                      value="0"
                      onChange={(e) => {setStatus(0);updateUrlParams(e)}}
                      checked = {status==0 || status=='0' || status==false ? true : false}
                    />
                    <CLabel variant="checkbox" htmlFor="occasion">
                      Occasion
                    </CLabel>
                  </CFormGroup>


                {
                  status!=null?  <button
         className="clearFilterOptionButton"
          onClick={()=> handleClearSelection('status')}
        >
          <span style={{verticalAlign:'text-top'}} > × </span>clear
        </button> : null
                }
      </div>

            <div>
                <span className="filter-title-field">Section </span>
            </div>
      <div  className='filterOptions'>
                  <CSelect
                    name="section"
                    value={section}
                    onChange={(e) => {handleSectionChange(e);
          updateUrlParams(e)
        }}
        style={{
          appearance: section == '0' || section == '' || !section ? 'auto' : 'none',
        }}
                  >
                    <option value="0"> Choisir le section</option>
                    {articleSection.map((type) => {
                      return (
                        <option key={type.id} value={type.id}>
                          {type.label}
                        </option>
                      );
                    })}
                  </CSelect>
                  {section == '0' || section == '' || !section ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> {handleClearSelection('section');handleClearSelection('type');handleClearSelection('category'); setTypes([]);setCategories([])}}
        >
          ×
        </button>
      )}
       </div>
                 

            <div>
                <span className="filter-title-field">Type </span>
            </div>
      <div  className='filterOptions'>
                  <CSelect
                    name="type"
                    value={type}
                    onChange={(e) => {handleTypeChange(e);
          updateUrlParams(e)
        }}
        style={{
          appearance: type == '0' || type == '' || !type ? 'auto' : 'none',
        }}
                  >
                    <option value="0"> Choisir le type</option>
                    {types.map((type) => {
                      return (
                        <option key={type.id} value={type.id}>
                          {type.label}
                        </option>
                      );
                    })}
                  </CSelect>
                  {type == '0' || type == '' || !type ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> {handleClearSelection('type');handleClearSelection('category'); setCategories([])}}
        >
          ×
        </button>
      )}
        </div>
                 

           {categories && categories.length>0 && <>
            <div>
                <span className="filter-title-field">Categorie</span>
            </div>
      <div  className='filterOptions'>
            <CSelect
                            name="category"
                            value={category}
                            onChange={(e) => {handleCategoryChange(e);
          updateUrlParams(e)
        }}
        style={{
          appearance: category == '0' || category == '' || !category ? 'auto' : 'none',
        }}
                        >
                            <option value="0"> Choisir la categorie </option>
                            {categories.map((category) => {
                                return (
                                    <option key={category.id} value={category.id}>
                                        {category.label}
                                    </option>
                                );
                            })}
                        </CSelect>
                        {category == '0' || category == '' || !category ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('category')}
        >
          ×
        </button>
      )}
        </div>
        </> }
      
        <div>
      <span className="filter-title-field">Prix de</span> 
      <div className="inputWithSuffix">
                <CInput
                disabled = {isLoadedALL? false : true} 
                  type="number"
                  name="minPrice"
                  min={1}
                 // max={maxpriceconst}
                  value={minPrice}
                 // placeholder={"Prix de "}
                  onChange={(e) => {setMinPrice(parseInt(e.target.value));updateUrlParams(e)}}
                ></CInput>
               {minPrice && minPrice != "" && <button
         className="clearFilterInput"
          onClick={()=> handleClearSelection('minPrice')}
        >
          ×
        </button> }  <span className="suf">TND</span> </div>
      </div>

      <div>
      <span className="filter-title-field">Prix à </span> 
      <div className="inputWithSuffix">
                <CInput
                disabled = {isLoadedALL? false : true} 
                  min={1}
                 // max={maxpriceconst}
                  type="number"
                  name="maxPrice"
                  //placeholder={"Prix à "}
                  value={maxPrice}
                  onChange={(e) => {setMaxPrice(parseInt(e.target.value));updateUrlParams(e)}}
                ></CInput>
                {maxPrice && maxPrice != "" && <button
         className="clearFilterInput"
          onClick={()=> handleClearSelection('maxPrice')}
        >
          ×
        </button> } <span className="suf">TND</span> </div>
      </div>
      
      
       <div>
      <span className="filter-title-field">Pays</span> 
      <div  className='filterOptions'>
      <CSelect
       style={{ appearance: country == '0' || country == '' || !country ? 'auto' : 'none',}}              
                  name="country"
                  value={country}
                  onChange={(e) => {handleCountryChange(e);updateUrlParams(e)}}
                >
                  <option value=""></option>
                  {countries.map((country) => {
                    return (
                      <option key={country.id} value={country.id}>
                        {country.label}
                      </option>
                    );
                  })}
                </CSelect> 
          {country == '0' || country == '' || !country ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('country')}
        >
          ×
        </button>
      )}</div>
      </div>

      <div>
      <span className="filter-title-field">Gouvernorat</span> 
      <div  className='filterOptions'>
      <CSelect
       style={{ appearance: city == '0' || city == '' || !city ? 'auto' : 'none',}}              
                  name="city"
                  value={city}
                  onChange={(e) => {handleCityChange(e);updateUrlParams(e)}}
                >
                  <option value=""></option>
                  {cities.map((city) => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.label}
                      </option>
                    );
                  })}
                </CSelect>
                {city == '0' || city == '' || !city ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('city')}
        >
          ×
        </button>
      )}</div>
      </div>

      <div>
      <span className="filter-title-field">Délégation</span> 
      <div  className='filterOptions'>
      <CSelect
       style={{ appearance: delegate == '0' || delegate == '' || !delegate ? 'auto' : 'none',}}              
                 name="delegate"
                  value={delegate}
                  onChange={(e) => {setDelegate(e.target.value);updateUrlParams(e)}}
                >
                  <option value=""></option>
                  {delegates.map((delegate) => {
                    return (
                      <option key={delegate.id} value={delegate.id}>
                        {delegate.label}
                      </option>
                    );
                  })}
                </CSelect>
                {delegate == '0' || delegate == '' || !delegate ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('delegate')}
        >
          ×
        </button>
      )}</div>
      </div>
         
      <span className="filtersMobButton filtersMobButtonG " onClick={toggleVisibility} >
              Chercher
            </span>
      
        </div>
              </CCol>

              <CCol>
                <div className="occasions-results layout-list">
                  <div className="articles">
                    Il y a <strong>{results.length} </strong> résultat
                    {results.length > 1 && "s"}
                    {results.length > 0 && 
                    <span className="filtersOrder">
                    <CSelect
      
                 name="sortBy"
                  value={sortBy}
                  onChange={(e) => {handleSortBy(e)}}
                >
                  <option value="dateD">Annonces les plus récentes d'abord</option>
                  <option value="dateC">Annonces les plus anciennes d'abord</option>
                  <option value="nomC">Titre ordre croissant</option>
                  <option value="nomD">Titre ordre décroissant</option>
                  <option value="price_asc">Prix ordre croissant</option>
                  <option value="price_desc">Prix ordre décroissant</option>                   
                </CSelect>
                  </span>}
                    <CDataTable
                      className="vehicle-data-table"
                      items={results}
                      fields={fields}
                      header={false}
                      itemsPerPage={10}
                      pagination
                      scopedSlots={{
                        title: (item) => (
                          <td>
                            <ArticleItem
                             item={item}
                              id={item.id}
                              at={item.createdAt}
                              type={
                                item.typeArticle &&
                                item.typeArticle.label
                              }
                              category={
                                item.categoryArticle &&
                                item.categoryArticle.label
                              }
                              nom = {item.nom && item.nom}
                              table = 'Articles'
                              price={item.price}
                              url={`/articles/details/${item.id}`}
                              image={
                                item.mainPicture && item.typeArticle
                                  ? `${IMAGES_BASE_URL}/public/uploads/images/${item.typeArticle.section}/${item.mainPicture.name}`
                                  : ""
                              }
                              city={
                                item.city && item.city.label
                              }
                              delegate={
                                item.delegate &&
                                item.delegate.label
                              }
                              locality={
                                item.locality &&
                                item.locality.label
                              }
                              itemType={
                                item.typeArticle &&
                                item.typeArticle.label
                              }
                            />
                          </td>
                        ),
                      }}
                    />
                  </div>
                </div>
              </CCol>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
