import React, { useEffect, useState } from "react";
import { API_BASE_URL, IMAGES_BASE_URL } from "../../../config";
import axios from "axios";

import Moment from "react-moment";

function Candidature(props) {
  const { id } = props.match.params;
  const [candidature, setCandidature] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const [emailBody , setEmailBody] = useState('')
  const emailSubject = "Réception de votre candidature et proposition d'entretien";

  useEffect(() => {
    if(props.location.state){
      setIsLoaded(true);
      setCandidature(props.location.state);
      setJobTitle(props.location.state.jobOffer.title);
      let bod = `Cher/Chère ${props.location.state.candidate.prenom}, \n
      Nous vous remercions vivement d'avoir soumis votre candidature pour le poste de ${props.location.state.jobOffer.title} chez ${props.location.company}.\n
      Nous avons bien reçu votre dossier et nous tenons à vous informer que nous l'avons examiné avec attention.\n
      Après avoir pris en considération votre expérience, vos compétences et vos qualifications, nous sommes intéressés par votre profil et aimerions vous rencontrer pour discuter plus en détail de votre candidature ainsi que des opportunités au sein de notre entreprise.\n
      Nous sommes donc ravis de vous proposer un entretien en personne dans nos locaux situés à ${props.location.companyAdres}. \n
      Cet entretien nous permettra de mieux vous connaître, de discuter de vos motivations et de répondre à toutes vos questions concernant le poste et notre entreprise.\n
      Nous vous prions de bien vouloir nous faire part de vos disponibilités pour la semaine à venir afin que nous puissions convenir d'une date et d'une heure qui vous conviennent. \n
      Si toutefois vous rencontrez des contraintes particulières, n'hésitez pas à nous en informer afin que nous puissions trouver une solution adaptée.\n
      Dans l'attente de votre réponse et de notre rencontre, nous vous prions d'agréer, cher/Chère ${props.location.state.candidate.prenom}, l'expression de nos salutations distinguées.\n
      Cordialement.`
      setEmailBody(bod);
      //console.log(props.location.state);
    }

   else { axios(`${API_BASE_URL}/jobs/get-candidature/${id}`)
      .then((res) => res.data)
      .then(
        (result) => {
          setIsLoaded(true);
          setCandidature(result);
          setJobTitle(result.jobOffer.title);
          //console.log(result);
          let bod = `Cher/Chère ${result.candidate.prenom}, \n
          Nous vous remercions vivement d'avoir soumis votre candidature pour le poste de ${result.jobOffer.title} chez ${result.jobOffer.company}.\n
          Nous avons bien reçu votre dossier et nous tenons à vous informer que nous l'avons examiné avec attention.\n
          Après avoir pris en considération votre expérience, vos compétences et vos qualifications, nous sommes intéressés par votre profil et aimerions vous rencontrer pour discuter plus en détail de votre candidature ainsi que des opportunités au sein de notre entreprise.\n
          Nous sommes donc ravis de vous proposer un entretien en personne dans nos locaux situés à ${result.jobOffer.companyAdres}. \n
          Cet entretien nous permettra de mieux vous connaître, de discuter de vos motivations et de répondre à toutes vos questions concernant le poste et notre entreprise.\n
          Nous vous prions de bien vouloir nous faire part de vos disponibilités pour la semaine à venir afin que nous puissions convenir d'une date et d'une heure qui vous conviennent. \n
          Si toutefois vous rencontrez des contraintes particulières, n'hésitez pas à nous en informer afin que nous puissions trouver une solution adaptée.\n
          Dans l'attente de votre réponse et de notre rencontre, nous vous prions d'agréer, cher/Chère ${result.candidate.prenom}, l'expression de nos salutations distinguées.\n
          Cordialement.`
          setEmailBody(bod);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );}
  }, [id]);
  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Chargement...</div>
          </div>
        </main>
      </div>
    );
  } else {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div className="information-title candidatureInfoPage">
              <div className="vehicle-thumbnail" style={{width:'100px',height:'100px',marginBottom:'12px'}}>
                      <img
                        src={
                          candidature.candidate.path?
                          `${IMAGES_BASE_URL}/public/uploads/members/${candidature.candidate.path}`
                          : `${IMAGES_BASE_URL}/public/uploads/members/default_avatar.jpg`
                        }
                        alt=""
                      />
                    </div> <br/>
                <strong>
            { candidature.candidate ? candidature.candidate.prenom : ''} {candidature.candidate ? candidature.candidate.nom : ''}{" "}
                </strong>
              
              <div className="small text-muted">
                Envoyé le :{" "}
                <Moment format="DD/MM/YYYY">{candidature.createdAt}</Moment>
              </div>
              <div className="candidatureInfoSection">
             <p><strong>Message</strong></p>  
                {candidature.message}
              </div>  
               <div className="candidatureInfoSection">
        <p><strong>Contact candidat</strong></p>
            <a
                        href={`mailto:${candidature.candidate.email}?subject=${emailSubject}&body=${encodeURIComponent(emailBody)}`}
                        target="_blank" rel="noreferrer"
                      >
               <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <g> <path fill="none" d="M0 0h24v24H0z"/> <path d="M22 13h-2V7.238l-7.928 7.1L4 7.216V19h10v2H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v9zM4.511 5l7.55 6.662L19.502 5H4.511zM21 18h3v2h-3v3h-2v-3h-3v-2h3v-3h2v3z"/> </g> </svg> 
               &nbsp; &nbsp; {candidature.candidate.email}
               </a> <br/><br/>
               <a 
                //href="tel:+5656565"
                href={`tel:${candidature.candidate.phoneNumber}`}
                      >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className="bi bi-telephone-plus" viewBox="0 0 16 16"> <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/> <path fill-rule="evenodd" d="M12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z"/> </svg>
                &nbsp; &nbsp;  {candidature.candidate.phoneNumber}
               </a>
       </div>
              <div className="candidatureInfoSection">
               <p><strong>CV</strong></p> 
               <div className="displayed-file">
              {/* <span className="file-name">{candidature.path? candidature.path : candidature.candidate.cv}</span>  */}
           <span>
            {candidature.path ? 

<a
    href={`${IMAGES_BASE_URL}/public/uploads/candidatures/${candidature.path}`}
    target="_blank" rel="noreferrer"
  >
    <img
                          src={"assets/icons/pdf_icon.svg"}
                          className="icon-brand"
                          alt=""
                        />
  {candidature.path}
  </a> 
: <a
    href={`${IMAGES_BASE_URL}/public/uploads/members/${candidature.candidate.cv}`}
    target="_blank" rel="noreferrer"
  >
    <img
                          src={"assets/icons/pdf_icon.svg"}
                          className="icon-brand"
                          alt=""
                        />
{candidature.candidate.cv}
</a>}</span>  

              {/* <span>
                      {candidature.path  ? 
                      <a
                        href={`${IMAGES_BASE_URL}/public/uploads/candidatures/${candidature.path}`}
                        target="_blank" rel="noreferrer"
                      >
                        <img
                          src={"assets/icons/pdf_icon.svg"}
                          className="icon-brand"
                          alt=""
                        />
                      </a> : ''}
              </span> */}
       </div>
    
            
             
              
              </div>
              { candidature.candidate.others && <div className="candidatureInfoSection">
               <p><strong>Complément du dossier</strong></p> 
               <div className="displayed-file">
              {/* <span className="file-name">{candidature.path? candidature.path : candidature.candidate.cv}</span>  */}
           <span> <a
    href={`${IMAGES_BASE_URL}/public/uploads/members/${candidature.candidate.others}`}
    target="_blank" rel="noreferrer"
  >
    <img
                          src={"assets/icons/pdf_icon.svg"}
                          className="icon-brand"
                          alt=""
                        />
{candidature.candidate.others}
</a></span>  
       </div>
    
            
             
              
              </div> }
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default Candidature;
