import React, { Component } from "react";
import { API_BASE_URL, IMAGES_BASE_URL } from "../../../../config";
import axios from "axios";
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CLabel,
  CCol,
  CContainer,
  CForm,
  CInput,
  CRow,
  CFormGroup,
  CSelect,
  CInputCheckbox,
  CSpinner,
} from "@coreui/react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export class AddEquipementRealEstate extends Component {
  constructor(props) {
    super(props);

    this.state = {
        label: "",
        type: "",
        type_annonce: [],
        type_annonce_label: "",
        type_annonce_value:"",
        loading:"",
        errors: {
            label: "",
            type: "",
            type_annonce: "",
            type_annonce_label: "",
            type_annonce_value:"",
           
          },
          success: "",

    }

  }
  componentDidMount() {
    axios(`${API_BASE_URL}/realEstate/equipement/type/all`)
    .then((res) => res.data)
    .then((result) => {
        console.log('result', result)

      this.setState({
        typeEquipementSelect: result,
        type_annonce: [{ id:1,label:"annonce de location et de vente"},{id:2,label:"annonce de location et de vacances" },{id:3,label:"annonce de location,de vente et de vacances" } ],
        loading :false

      });

    }); 
  }

  handleChange = (event) => {
    console.log('eventd', event)
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.handleValidation()) {
      const formData = new FormData();
      this.state.loading=true;
      console.log("this.state.type.id",this.state.type_annonce,this.state.type_annonce_value)
      formData.append("type",this.state.type);
      formData.append("label", this.state.label);
      formData.append("typeAnnonceValue", this.state.type_annonce_value);
      formData.append("typeAnnonce", this.state.type_annonce[this.state.type_annonce_value-1].label);
      
      
      const { id } = this.props.match.params;
      axios
        .post(`${API_BASE_URL}/realEstate/equipement/add`, formData)
        .then((res) => {
          this.state.loading=false;
          // if (res.data.status.toString() === "ok") {
          
            this.setState({
              success: "L'équipement a été ajouté avec succès",
              
            });
            window.scrollTo(0, 0)
         //  }
        })
        .catch((error) => {
          console.log(" errors : ", error);
          this.state.loading=true;
        });
    }
  };

  handleValidation = () => {
    let fieldLabel = this.state.label;

    let errors = {};
    let formIsValid = true;
    if (!fieldLabel) {
      formIsValid = false;
      errors.label = "Label de l'équipement ne peut pas être vide";
    } else {
      errors.title = "";
    }
   

    if (this.state.type_annonce === "") {
      formIsValid = false;
      errors.type_annoce = "Veuillez choisir le type de l'annonce";
    } else {
      errors.type_annonce = "";
    }
    /* if (this.state.image === null) {
      formIsValid = false;
      errors.image = "Veuillez ajouer une image";
    } else {
      errors.image = "";
    }*/
    this.setState({ errors: errors });
    return formIsValid;
  };


  render() {
    let errors = this.state.errors;
    return (
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="12" lg="12" xl="12">
            <CCard className="mx-4">
              <CCardBody className="p-4">
                {this.state.success !== "" ? (
                  <CAlert color="success" closeButton>
                    {this.state.success}
                  </CAlert>
                ) : (
                  ""
                )}
                <CForm onSubmit={this.handleSubmit} id="form-add">
                  <h1>Ajouter un équipement</h1>
                  <CCol>
                    <p></p>
                  </CCol>

                  <CRow>
                    <CCol xs="6">
                      <CFormGroup>
                        <CLabel htmlFor="label">Label</CLabel>
                        <CInput
                          id="label"
                          placeholder="Entrer le label d'équipement"
                          name="label"
                          value={this.state.label}
                          onChange={this.handleChange}
                        />
                      </CFormGroup>
                    </CCol>
                  </CRow>
                  <CCol>
                    {errors.label && errors.label.length > 0 && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.label}
                      </p>
                    )}
                  </CCol>
                <CRow>
                    <CCol xs="8">
                      <CFormGroup>
                        <CLabel htmlFor="content">
                         Type équipement
                        </CLabel>
                        <CSelect
                          name="type"
                          value={this.state.type.id}
                          onChange={this.handleChange}
                        >
                          <option value="">--------------------</option>
                          {this.state.typeEquipementSelect && this.state.typeEquipementSelect.map((equipement) => {
                            return (
                              <option
                                key={equipement.id}
                                id={equipement.id}
                                value={equipement.id}
                              >
                                {equipement.label}
                              </option>
                            );
                          })}
                        </CSelect>
                      </CFormGroup>
                    </CCol>
                  </CRow> 
                   <CCol>
                    {errors.typeEquipementSelect && errors.typeEquipementSelect.length > 0 && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.typeEquipementSelect}
                      </p>
                    )}
                  </CCol>
                 <CRow>
                    <CCol xs="8">
                      <CFormGroup>
                        <CLabel htmlFor="content">
                         Type annonce
                        </CLabel>
                        <CSelect
                          name="type_annonce_value"
                          value={this.state.type_annonce_value}
                          onChange={this.handleChange}
                        >
                          <option value="">--------------------</option>
                          {this.state.type_annonce && this.state.type_annonce.map((annonce) => {
                            return (
                              <option
                                key={annonce.id}
                                id={annonce.id}
                                value={annonce.id}
                              >
                                {annonce.label}
                              </option>
                            );
                          })}
                        </CSelect>
                      </CFormGroup>
                    </CCol>
                  </CRow> 
                   <CCol>
                    {errors.type_annonce && errors.type_annonce.length > 0 && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.type_annonce}
                      </p>
                    )}
                  </CCol>
                
               <CRow>
                <CCol md="3" className="offset-9">
                {!this.state.loading && (  <CButton color="primary" block type="submit">
                    Valider
                  </CButton>)}

                  {this.state.loading && (<CButton color="primary" block disabled>
                <span> <CSpinner color="default" size="sm" /></span>

                <span> Valider</span>

            </CButton>)}

                </CCol>
              </CRow> 
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    );
  }
}

export default AddEquipementRealEstate;
