import React, { Component } from "react";
import { API_BASE_URL , IMAGES_BASE_URL } from "../../../config";
import axios from "axios";
import { Link } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { keyHeader , tokenCookie  } from "../../../config";
import { isLogin } from "src/components/middleware/auth";
import LoginMember from "./LoginMember";
import apiClient from "../apiClient";
import {
  CCol,
  CRow,
  CButton,
  CAlert,
  CCard,
  CCardBody,
  CLabel,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CFormGroup,
  CSpinner,
} from "@coreui/react";

const validEmailRegex = RegExp(
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

export class MyProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      typeAccount: "",
      nom: "",
      prenom: "",
      email: "",
      cv: null,
      other: null,
      cvname: "",
      othername: "",
      success: "",
      existcv: false,
      isloaded: false,
      fileslimit: "",
      cvchanged: false,
      otherchanged: false,
      loading: false,
      phoneNumber: "",
      matricule: "",
      person: "",
      adresse: "",
      website: "",
      desc: "",
      emailErrors: "",
      errors: {
        nom: "",
        prenom: "",
        email: "",
        phoneNumber: "",
        files: "",
        matricule: "",
        person: "",
        adresse: "",
        website: "",
      },
      success: "",
      path : null ,
      image: null,
      changedProfile: false,
      croppedImage: null,
      src: null,
      crop: {
        x: 10,
        y: 10,
        width: 120,
        height: 120,
        aspect: 1 / 1,
      },
    };
  
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleKeyUp = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;
    if (this.state.typeAccount == 1)
    switch (name) {
      case "nom":
        errors.nom = value.length < 1 ? "Le champs nom est requis" : "";
        break;
      case "prenom":
        errors.prenom = value.length < 1 ? "Le champs prénom est requis" : "";
        break;
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Veuillez entrer une adresse mail valide";
        break;
      case "phoneNumber":
          errors.phoneNumber = !isValidPhoneNumber(value) ? "" : "Veuillez entrer un numéro de téléphone valide";
          break;
      default:
        break;
    }
    if (this.state.typeAccount == 2)
    switch (name) {
      case "nom":
        errors.nom = value.length < 1 ? "Le champs nom est requis" : "";
        break;
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Veuillez entrer une adresse mail valide";
        break;
      case "phoneNumber":
          errors.phoneNumber = !isValidPhoneNumber(value) ? "" : "Veuillez entrer un numéro de téléphone valide";
          break;
          case "person":
            errors.person = value.length < 1 ? "Le champs person est requis" : "";
            break;
          case "nom":
            errors.adresse = value.length < 1 ? "Le champs adresse est requis" : "";
              break;
          case "nom":
            errors.matricule = value.length < 1 ? "Le champs matricule est requis" : "";
                break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };

  componentDidMount() {

  //const formData = new FormData();
  //formData.append("id",tokenCookie );

  apiClient.get(`/get_member_profile` //, keyHeader   
  )
      .then(response => {
          // Handle the response
          console.log('response prof --- ', response);     
          let result = response.data.member;
          if (result) {
            this.setState({
              typeAccount:result.accountType.id,
              cvname: result.cv,
              othername: result.others,
              nom: result.nom,
              prenom: result.prenom,
              email: result.email,
              path: result.path,
              isloaded: true,
              phoneNumber:result.phoneNumber,
              matricule: result.matricule,
              person: result.person,
              adresse: result.adresse,
              website: result.website,
              desc: result.description,
            });
          }
      })
      .catch(error => {
          // Handle errors
          //console.log('error --- ', error)

      });
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.setState({
        changedProfile: true,
      });
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () =>
          this.setState({
            src: reader.result,
          }),
        false
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  onImageLoaded = (image) => {
    this.imageRef = image;
  };
  onCropComplete = (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImg(this.imageRef, crop);
      this.setState({ croppedImageUrl });
    }
  };
  getCroppedImg(image, crop) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.dataURLtoFile(reader.result, "cropped.jpg");
      };
    });
  }
  onCropChange = (crop) => {
    this.setState({ crop });
  };
  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    this.setState({ croppedImage: croppedImage });
  }

  handleFilecv = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];
    //console.log(file);
    reader.onloadend = () => {
      this.setState({
        cv: file,
        cvname: file.name,
        cvchanged: true,
      });
    };
    reader.readAsDataURL(file);
    setTimeout(() => {
      //console.log(this.state);
    }, 200);
  };

  handleFileother = (event) => {
    if (event.target.files.length > 4) {
      this.setState({
        fileslimit: "Veuillez ne pas ajouter plus que 4 fichiers!",
      });
    } else {
      let reader = new FileReader();
      let files = event.target.files[0];
      //console.log(files);
      reader.onloadend = () => {
        this.setState({
          other: files,
          othername: files.name,
          otherchanged: true,
        });
      };
      reader.readAsDataURL(files);
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    if (this.state.fileslimit.length > 0) {
      //console.log("not valid");
    } else {
      const formData = new FormData();
      formData.append("cv", this.state.cv);
      formData.append("other", this.state.other);
      formData.append("nom", this.state.nom);
      formData.append("prenom", this.state.prenom);
      formData.append("email", this.state.email);
      formData.append("avatar", this.state.croppedImage);
     // formData.append("id", tokenCookie);
      formData.append("phoneNumber", this.state.phoneNumber);
      formData.append("matricule", this.state.matricule);
      formData.append("person", this.state.person);
      formData.append("adresse", this.state.adresse);
      formData.append("website", this.state.website);
      formData.append("desc", this.state.desc);
      //console.log(formData.get('avatar'));
      //console.log(formData.get('cv'));
      //console.log(formData.get('other'));
      
       apiClient.post( "update_member", formData )
        .then((res) => {
          console.log("iuiuiuiuiui");
          console.log(res);
          this.setState({ loading: false });
          if (res.data.status.toString() === "ok") {
            this.setState({
              success: "Votre compte a été modifeé avec succès",
            });
            localStorage.setItem("avatar", res.data.path);
            localStorage.setItem("name", res.data.name);
           if(res.data.prenom) localStorage.setItem("prenom", res.data.prenom);
            setTimeout(() => {
              this.props.history.push("/members/profile");
            }, 1500);
          }
        })
        .catch((error) => {
          //console.log("errors : ", error);
          this.setState({ loading: false });
        });
    }
  };

  render() {
    const { errors } = this.state;
    const mainlink = "/uploads/candidatures/";
    return localStorage.getItem("roles") === "ROLE_MEMBER" && isLogin() ?  (
      <div>
        <CContainer className='MyProfileForm'>
          <CRow className="justify-content-center">
            <CCol md="12" lg="12" xl="12">
              <CCard className="mx-4">
                <CCardBody className="p-4">
                  <>
                    {this.state.isloaded && (
                      <CForm onSubmit={this.handleSubmit} id="form-add">

                      {this.state.typeAccount === 2 && (
                          <>
                            <div className="mb-3">
                              <label htmlFor="nom">Nom Société*</label>
                              <input
                                type="text"
                                name="nom"
                                value={this.state.nom}
                                onChange={this.handleChange}
                              />{" "}
                              {errors.nom && errors.nom.length > 0 && (
                                <CCol>
                                  {" "}
                                  <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {errors.nom}
                                  </p>
                                </CCol>
                              )}
                            </div>
                            <div className="mb-3">
                              <label htmlFor="matricule">Matricule</label>
                              <input
                                type="text"
                                name="matricule"
                                value={this.state.matricule}
                                onChange={this.handleChange}
                              />

                              {errors.matricule &&
                                errors.matricule.length > 0 && (
                                  <CCol>
                                    {" "}
                                    <p
                                      className="invalid-feedback"
                                      style={{ display: "block" }}
                                    >
                                      {errors.matricule}
                                    </p>
                                  </CCol>
                                )}
                            </div>
                            <div className="mb-3">
                              <label htmlFor="person">Person en charge</label>
                              <input
                                type="text"
                                name="person"
                                value={this.state.person}
                                onChange={this.handleChange}
                              />{" "}
                              {errors.person && errors.person.length > 0 && (
                                <CCol>
                                  {" "}
                                  <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {errors.person}
                                  </p>
                                </CCol>
                              )}
                            </div>
                            <div className="mb-3">
                              <label htmlFor="adresse">Adresse*</label>
                              <input
                                type="text"
                                name="adresse"
                                value={this.state.adresse}
                                onChange={this.handleChange}
                              />{" "}
                              {errors.adresse && errors.adresse.length > 0 && (
                                <CCol>
                                  {" "}
                                  <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {errors.adresse}
                                  </p>
                                </CCol>
                              )}
                            </div>
                          </>
                        )}

                        {this.state.typeAccount === 1 && (
                          <>
                            <div className="mb-3">
                              <label htmlFor="nom">Nom *</label>
                              <input
                                type="text"
                                name="nom"
                                value={this.state.nom}
                                onChange={this.handleChange}
                              />{" "}
                              {errors.nom && errors.nom.length > 0 && (
                                <CCol>
                                  {" "}
                                  <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {errors.nom}
                                  </p>
                                </CCol>
                              )}
                            </div>
                            <div className="mb-3">
                              <label htmlFor="prenom">Prénom *</label>
                              <input
                                type="text"
                                name="prenom"
                                value={this.state.prenom}
                                onChange={this.handleChange}
                              />

                              {errors.prenom && errors.prenom.length > 0 && (
                                <CCol>
                                  {" "}
                                  <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {errors.prenom}
                                  </p>{" "}
                                </CCol>
                              )}
                            </div>
                          </>
                        )}

                        <div className="mb-3">
                          <label htmlFor="email">E-mail *</label>
                          <input
                            type="text"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                          />

                          {errors.email.length > 0 && (
                            <CCol>
                              <p
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {errors.email}
                              </p>
                            </CCol>
                          )}
                          {this.state.emailErrors.length > 0 && (
                            <CCol>
                              <p
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {this.state.emailErrors}
                              </p>
                            </CCol>
                          )}
                        </div>
                        <div className="mb-3">
                          <label htmlFor="phoneNumber">
                            Numéro téléphone *
                          </label>
                          <PhoneInput
                            type="text"
                            onChange={(phoneNumber) =>
                              this.setState({ phoneNumber })
                            }
                            placeholder="Enter phone number"
                            defaultCountry="TN"
                            international
                            name="phoneNumber"
                            id="phoneNumber"
                            value={this.state.phoneNumber}
                          />

                          {errors.phoneNumber.length > 0 && (
                            <CCol>
                              <p
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {errors.phoneNumber}
                              </p>
                            </CCol>
                          )}
                        </div>

                        {this.state.typeAccount === 2 && (
                          <div className="mb-3">
                            <label htmlFor="website">Site web</label>
                            <input
                              type="text"
                              name="website"
                              value={this.state.website}
                              onChange={this.handleChange}
                            />
                          </div>
                        )}

                        {this.state.typeAccount === 2 && (
                          <>
                            <br />
                            <CRow>
                              <CCol className="mb-3">
                                <textarea
                                  name="desc"
                                  value={this.state.desc}
                                  onChange={this.handleChange}
                                  placeholder="Description"
                                  style={{ height: "102px" }}
                                />
                              </CCol>
                            </CRow>
                            <br />
                          </>
                        )}

                        <br />
                        <CRow>
                          <CCol className="mb-3">
                            <label htmlFor="profile">{this.state.typeAccount === 1 ? 'Modifier votre profile' : 'Modifier Logo de la société'}</label>
                            <br />
                            <input
                                  type="file"
                                  name="image"
                                  onChange={this.onSelectFile}
                                  accept="image/*"
                                />
                          </CCol>
                          {!this.state.changedProfile && this.state.path && <CCol className="mb-3">
                            <img src={IMAGES_BASE_URL+'/public/uploads/members/'+this.state.path }  alt="preview"/>
                          </CCol>}
                          {this.state.src && (
                                <ReactCrop
                                  src={this.state.src}
                                  crop={this.state.crop}
                                  onImageLoaded={this.onImageLoaded}
                                  onComplete={this.onCropComplete}
                                  onChange={this.onCropChange}
                                />
                              )}
                        </CRow>
                       {this.state.typeAccount === 1 && <><br /> <br /> <br />
                        <CRow>
                          <CCol className="mb-3">
                            <label htmlFor="cv">Modifier votre cv </label>
                            <br />
                            <input
                              type="file"
                              name="cv"
                              onChange={this.handleFilecv}
                              accept=".pdf"
                            />
                          </CCol>
                          {!this.state.cvchanged && ( <CCol className="mb-3">
                            <a href={mainlink + this.state.cvname}>
                              {this.state.cvname}
                            </a></CCol>
                          )}
                        </CRow>
                   </>  }
                        <br /> <br /> <br />
                        <CRow>
                          <CCol className="mb-3">
                            <label htmlFor="autres">
                            {this.state.typeAccount === 1 ? "Modifier d'autres documents" :'Modifier les documents de la société'}
                            </label>
                            <br />
                            <input
                              multiple
                              type="file"
                              name="autres"
                              onChange={this.handleFileother}
                              accept=".pdf,.jpg,.png,jpeg"
                            />
                          </CCol>
                          {!this.state.otherchanged && (
                            <CCol className="mb-3">
                            <a
                              href={mainlink + this.state.othername}
                            >
                              {this.state.othername}
                            </a>
                            </CCol>
                          )}
                        </CRow>
                        {this.state.fileslimit.length > 0 && (
                          <CCol>
                            <p
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {this.state.fileslimit}
                            </p>
                          </CCol>
                        )}
                        <br />
                        <CRow>
                          <CCol xs="12" sm="6">
                            {/* <CButton color="success" block type="submit">
                              Valider
                            </CButton> */}
                            {!this.state.loading && (
                              <CButton color="primary" block type="submit">
                                Valider
                              </CButton>
                            )}
                            {this.state.loading && (
                              <CButton color="primary" block disabled>
                                <span>
                                  <CSpinner color="default" size="sm" />
                                </span>

                                <span> Valider</span>
                              </CButton>
                            )}
                          </CCol>
                        </CRow>
                      </CForm>
                    )}
                    {this.state.success !== "" ? (
                      <CAlert color="success" closeButton>
                        {this.state.success}
                      </CAlert>
                    ) : (
                      ""
                    )}
                    {!this.state.isloaded && (
                      <p className="loadingnow">Chargement...</p>
                    )}
                  </>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </div>

      // <>
      //   {this.state.isloaded && (
      //     <form
      //       onSubmit={this.handleSubmit}
      //       id="form-add"
      //       style={{ marginTop: "60px" }}
      //     >
      //       <CRow>
      //         <CCol className="mb-3">
      //           <label htmlFor="content">Modifier votre cv </label>
      //           <br />
      //           <input
      //             type="file"
      //             name="image"
      //             onChange={this.handleFilecv}
      //             accept=".pdf"
      //           />
      //         </CCol>
      //         {!this.state.cvchanged && (
      //           <a href={this.state.mainlink + this.state.cvname}>
      //             {this.state.cvname}
      //           </a>
      //         )}
      //         <CCol></CCol>
      //       </CRow>
      //       <br /> <br /> <br />
      //       <CRow>
      //         <CCol className="mb-3">
      //           <label htmlFor="content">Modifier d'autres documents </label>
      //           <br />
      //           <input
      //             multiple
      //             type="file"
      //             name="image"
      //             onChange={this.handleFileother}
      //             accept=".pdf,.jpg,.png,jpeg"
      //           />
      //         </CCol>
      //         {!this.state.otherchanged && (
      //           <a href={this.state.mainlink + this.state.othername}>
      //             {this.state.othername}
      //           </a>
      //         )}
      //         <CCol></CCol>
      //       </CRow>
      //       {this.state.fileslimit.length > 0 && (
      //         <CCol>
      //           <p className="invalid-feedback" style={{ display: "block" }}>
      //             {this.state.fileslimit}
      //           </p>
      //         </CCol>
      //       )}
      //       <br />
      //       <CRow>
      //         <CCol xs="12" sm="6">
      //           <CButton color="primary" block type="submit">
      //             Soumettre
      //           </CButton>
      //         </CCol>
      //       </CRow>
      //     </form>
      //   )}
      //   {!this.state.isloaded && <p className="loadingnow">Chargement...</p>}
      // </>
    )
    :
    (<LoginMember/>)
    ;
  }
}

export default MyProfile;
