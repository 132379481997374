import React, { useEffect, useState } from "react";
import Loading from "src/components/myWidgets/loadingWidget";
import { useLocation , useHistory } from "react-router-dom";
import { IMAGES_BASE_URL , API_BASE_URL , keyHeader } from "../../../../config";
import axios from "axios";

import {
  CCol,
  CDataTable,
  CLabel,
  CFormGroup,
  CSelect,
  CInputRadio,
  CButton,
  CInput,
  CSpinner,

} from "@coreui/react";
import VehicleItem from "../VehicleItem";
//import FilterMoto from "./FilterMoto";

export default function ResultMoto() {
  const location = useLocation();
  const history = useHistory();
 
  const [isLoaded, setIsLoaded] = useState(false);
  const [motos, setMotos] = useState([]);
  const fields = [{ key: "title", _style: { width: "100%" } }];
  const itemType = "Vehicle";

  const [all, setAll] = useState([]);
  const [errorAll,setErrorALL]= useState(false);
  const [results , setresults] = useState([]);

  const [makes, setMakes] = useState([]);
  const [make, setMake] = useState("");
  const [models, setModels] = useState([]);
  const [model, setModel] = useState("");
  const [status, setStatus] = useState(null);
  const [bodyStyle, setBodyStyle] = useState("");
  const [year, setYear] = useState("");
  const [fuel, setFuel] = useState("");
  const [warranty, setWarranty] = useState('');
  const [loading, setLoading] = useState(false);
  const [gearingType, setGearingType] = useState('');
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [color, setColor] = useState("");
  const [isLoadedALL , setisLoadedALL] = useState(false);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [delegate, setDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [filtersCount, setFiltersCount] = useState(0);
  const [sortBy, setSortBy] = useState('dateD');

  const toggleVisibility = () => {
    //console.log('rdyjgkhlkmgdeefglmdesghg');
    setIsVisible(prev => !prev);
  };
  const searchParams = new URLSearchParams(location.search);

  const handleMakeChange = (e) => {
    setMake(e.target.value);
    //console.log("make hadle change : ", make);
    let id = e.target.value;
    //console.log("make hadle change : ", id);
    var makesF = [];
    if (id.toString !== '0') {
      makesF = makes.find((model) => model.id.toString() === id);
      setModels(makesF.models);
      setModel("");
    } else {
      setModels([]);
      setModel("");
    }
  }; 
  
  const getYear = () => {
    const years = [];
    for (let i = new Date().getFullYear(); i > 1950; i--) {
      years.push(
        <option value={i} key={i}>
          {" "}
          {i}
        </option>
      );
    }
    return years;
  };

  useEffect(() => {
    if (!localStorage.getItem("motoBrands")) 
   { axios(`${API_BASE_URL}/vehicles/moto-brands`)
        .then((res) => res.data)
        .then(
          (result) => {
            setMakes(result);
            //  console.log(result);
    localStorage.setItem("motoBrands", JSON.stringify(result));

          },
          (error) => {
         
          }
        );} else { 
    setMakes(JSON.parse(localStorage.getItem("motoBrands")));

        }

   if (!localStorage.getItem("countries")) {
          axios(`${API_BASE_URL}/countries/all`)
            .then((res) => res.data)
            .then(
              (result) => {
                setCountries(result);
               if(location.state && location.state.all.cities) setCities(location.state.all.cities);
               else if (result && searchParams.get('country')) {
                var countriesF=[]
                countriesF = result.find((el) => el.id.toString() == searchParams.get('country'));
                setCities(countriesF.cities);
                //console.log('countriesF',countriesF);
              }
                localStorage.setItem("countries", JSON.stringify(result));
              },
              (error) => {}
            );
        } else {
          setCountries(JSON.parse(localStorage.getItem("countries")));
          if(location.state && location.state.all.cities) setCities(location.state.all.cities);
          else if (JSON.parse(localStorage.getItem("countries")) && searchParams.get('country')) {
           var countriesF=[]
           countriesF = JSON.parse(localStorage.getItem("countries")).find((el) => el.id.toString() == searchParams.get('country'));
          setCities(countriesF.cities);
           //console.log('countriesFfffffff',countriesF);
         }
        }
        
    if (location.state) {   
      //console.log('rtrtrttkkkkkkk : ',location.state.result);
       //console.log('all : ',location.state);
      setresults(location.state.result);
      setAll(location.state.all.all);
     
      
      setisLoadedALL(true)
      setIsLoaded(true);
    } else {
      //console.log('no state : ',location.state);
      
      axios
      (API_BASE_URL + "/annonce_moto/all",keyHeader)
      .then((res) => res.data)
      .then(
        (result) =>{ 
        //console.log('all result' , result);
        setAll(result);
        setresults(result)    
        setisLoadedALL(true)
        setIsLoaded(true);
        //setdisable('');
      },
      (error) => {
        //console.log(error);
        setErrorALL(true)
      }
      );
    }

  }, []);  


  useEffect(() => {

  if (location.search)
    {       
    setStatus(searchParams.get('status'));
    setMake(searchParams.get('make'));
    setModel(searchParams.get('model'));
    setYear(searchParams.get('year'));
    setBodyStyle(searchParams.get('bodyStyle'));
    setFuel(searchParams.get('fuel'));
    setGearingType(searchParams.get('gearingType')); 
    setWarranty(searchParams.get('warranty'));
    setMaxPrice(searchParams.get('maxPrice'));
    setMinPrice(searchParams.get('minPrice'));
    setCountry(searchParams.get('country'));
    setCity(searchParams.get('city'));
    //setColor(location.state.all.color)

    setFiltersCount(searchParams.size)
  }

  }, [location.search]);

  useEffect(() => {
    //  handleFilter(e);
    //console.log(make,model)
   // var results ;
   if(isLoadedALL) 
   {var results = all.filter(function (el) {
      const statusc = status === null || el.state == status;
      //!status || el.state == status; 
      //status!=null ?  el.state == status : true;

      const makec =
        !make || el.brand.id == make;  
      const modelc = model > 0 ?  el.model.id == model : true;
      const yearc =  year > 0 ?  el.year == year : true;
      const bodyStylec = !bodyStyle || el.bodyStyle == bodyStyle;  
      const fuelc = !fuel || el.fuel == fuel;  
      const gearingTypec = !gearingType || el.gearingType == gearingType;  
      const colorc = !color || el.color == color;  
      const warrantyc = !warranty || el.warranty == warranty;  
      const countryc = !country || el.country.id == country;  
      const cityc = !city || el.city.id == city;  
      const minPricec = !minPrice || el.price >= minPrice;  
      const maxPricec = !maxPrice || el.price <= maxPrice;  
    
      return statusc && makec && modelc && bodyStylec && yearc && fuelc && gearingTypec && warrantyc && countryc && cityc && minPricec && maxPricec && colorc;
    });
    //console.log('rsddsdxxx ',results)
    setresults(results);
  }
     }, [isLoadedALL , status ,make, model , bodyStyle , year , fuel , gearingType , color , warranty , country , city , minPrice , maxPrice ]);
  
     const clearAll = () => {
      handleClearSelection('status'); handleClearSelection('year');
      handleClearSelection('make');handleClearSelection('model'); 
      handleClearSelection('minPrice');handleClearSelection('maxPrice');
      handleClearSelection('fuel'); handleClearSelection('gearingType');
      handleClearSelection('bodyStyle');handleClearSelection('warranty');
      handleClearSelection('color');
      handleClearSelection('country');handleClearSelection('city');handleClearSelection('delegate')
          }  
      
          const handleSortBy = (e) => {
            //console.log('sortBy:',e.target.value);
            setSortBy(e.target.value);
            const sortedData = [...results].sort((a, b) => {
         
              if (e.target.value === 'dateC') {
                return a['createdAt'] > b['createdAt'] ? 1 : -1;
              }
              else    if (e.target.value === 'dateD') {
                return a['createdAt'] < b['createdAt'] ? 1 : -1;
              } 
               else    if (e.target.value === 'year_asc') {
                return a['year'] > b['year'] ? 1 : -1;
              }
              else    if (e.target.value === 'year_desc') {
                return a['year'] < b['year'] ? 1 : -1;
              } 
               else    if (e.target.value === 'price_asc') {
                return a['price'] > b['price'] ? 1 : -1;
              }
              else    if (e.target.value === 'price_desc') {
                return a['price'] < b['price'] ? 1 : -1;
              } 
            });
            setresults(sortedData);
           } 

     const handleCountryChange = (e) => {
      setCountry(e.target.value);
      let id = e.target.value;
      var countriesF = [];
      // console.log("iddd", id);
      if (id !== "0") {
        countriesF = countries.find((city) => city.id.toString() === id);
        setCities(countriesF.cities);
        // console.log("countriesF", countriesF);
      } else {
        setCity([]);
        //  setModel("");
      }
    };

  const updateUrlParams = (e) => {
    searchParams.set(e.target.name, e.target.value);
    const updatedURL = `${location.pathname}?${searchParams.toString()}`;
    history.replace(updatedURL);
  };

  const deleteUrlParams = (p) => {
     searchParams.delete(p);
     const updatedURL = `${location.pathname}?${searchParams.toString()}`;
     setFiltersCount(searchParams.size);
     history.replace(updatedURL);
   };

   const handleClearSelection = (type) => {
    if(searchParams.get(type))  deleteUrlParams(type) 
    if (type=='status') setStatus(null)
     else { const setStateFunction = `set${type.charAt(0).toUpperCase()}${type.slice(1)}`;
    eval(`${setStateFunction}('')`);}
    if(type=='country') { deleteUrlParams('city'); deleteUrlParams('delegate') ; setCity('');setDelegate('') }
    if(type=='city') { deleteUrlParams('delegate') ; setDelegate('') }
  };

  if (!isLoaded) {
    return (
 <Loading/>
    );
  } else {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-main__article l-main__content l-main__section l-article ">
            <div className="row">
              <CCol md="3">
              <span className="filtersMobButton" onClick={toggleVisibility} >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" id="filter"><path fill="#000" fill-rule="evenodd" d="M20 5h-1.17a3.001 3.001 0 0 0-5.66 0H4a1 1 0 0 0 0 2h9.17a3.001 3.001 0 0 0 5.66 0H20a1 1 0 1 0 0-2zm-4 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM3 12a1 1 0 0 1 1-1h1.17a3.001 3.001 0 0 1 5.66 0H20a1 1 0 1 1 0 2h-9.17a3.001 3.001 0 0 1-5.66 0H4a1 1 0 0 1-1-1zm5 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-4 4a1 1 0 1 0 0 2h9.17a3.001 3.001 0 0 0 5.66 0H20a1 1 0 1 0 0-2h-1.17a3.001 3.001 0 0 0-5.66 0H4zm13 1a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" clip-rule="evenodd"></path></svg> Filters
            </span>
            {filtersCount > 0 && <span className="filtersCountMob filtersCount">{ filtersCount+' appliqués'} <button
             onClick={clearAll} className="btn btn-primary ">
             Supprimer tout <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
<path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"></path>
</svg>
             </button>
              </span>}
              <div className={`bordered filter filtersSectionMob ${isVisible ? 'visibleFiltersmob' : ''}`} >
              <span onClick={toggleVisibility}
              className={`filtersMobButton filtersMobButtonx `} >&times;</span>
       <CLabel  className='filtersS'> 
            Filters
            </CLabel>
            {filtersCount > 0 && <span className="filtersCount">{ filtersCount+' appliqués'} <button
             onClick={clearAll} className="btn btn-primary ">
             Supprimer tout <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
<path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"></path>
</svg>
             </button>
              </span>}
      <div>
    <br />
              <CFormGroup variant="checkbox" inline>
                <CInputRadio
                  className="form-check-input"
                  id="1"
                  name="status"
                  value="1"
                  onChange={(e) => {setStatus(e.target.value);updateUrlParams(e)}}
                checked = {status==1 || status=='1' ? true : false}
                />
                <CLabel variant="checkbox" htmlFor="neuf">
                  Neuf
                </CLabel>
              </CFormGroup>
              <CFormGroup variant="checkbox" inline>
                <CInputRadio
                  className="form-check-input"
                  id="0"
                  name="status"
                  value="0"
                  onChange={(e) => {setStatus(e.target.value);updateUrlParams(e)}}
                      checked = {status==0 || status=='0' ? true : false}
                />
                <CLabel variant="checkbox" htmlFor="occasion">
                  Occasion
                </CLabel>
              </CFormGroup>
              {
                  status!=null?  <button
         className="clearFilterOptionButton"
          onClick={()=> handleClearSelection('status')}
        >
          <span style={{verticalAlign:'text-top'}} > × </span>clear
        </button> : null
                }
      </div>
      <div>
        <span className="filter-title-field">Marques</span>
      </div>
      <div  className='filterOptions'>

      <CSelect
                    name="make"
                    value={make}
                    onChange={(e) => {handleMakeChange(e);
          updateUrlParams(e)
        }}
        style={{
          appearance: make == '0' || make == '' || !make ? 'auto' : 'none',
        }}
                  >
                    <option value="0"> Choisir la marque</option>
                    {makes.map((make) => {
                      return (
                        <option key={make.id} value={make.id}>
                          {make.label}
                        </option>
                      );
                    })}
       </CSelect>
       {make && make != '0' && (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('make')}
        >
          ×
        </button>
      )}
      </div>

      <div>
        <span className="filter-title-field">Modèles</span>
      </div>
      <div  className='filterOptions'>
      <CSelect
                    name="model"
                    value={model}
                    onChange={(e) => {setModel(e.target.value);
          updateUrlParams(e)
        }}
        style={{
          appearance: model == '0' || model == '' || !model ? 'auto' : 'none',
        }}
                  >
                    <option value="0"> Modèle</option>
                    {models.map((model) => {
                      return (
                        <option key={model.id} value={model.id}>
                          {model.label}
                        </option>
                      );
                    })}
                  </CSelect>
                  {model && model != '0' && (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('model')}
        >
          ×
        </button>
      )}
      </div>     
                
      <div>
        <span className="filter-title-field">Année</span>
      </div>
      <div  className='filterOptions'>
      <CSelect
        name="year"
        value={year}
        onChange={(e) => {setYear(e.target.value);
          updateUrlParams(e)
        }}
        style={{
          appearance: year == '0' || year == '' || !year ? 'auto' : 'none',
        }}
      >
        <option value=""> 1ère immatriculation</option>
        {getYear()}
      </CSelect>
      {year == '0' || year == '' || !year ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('year')}
        >
          ×
        </button>
      )}
     </div>
      <div>
      <span className="filter-title-field">Prix de</span> 
      <div className="inputWithSuffix">
                <CInput
                disabled = {isLoadedALL? false : true} 
                  type="number"
                  name="minPrice"
                  min={1}
                 // max={maxpriceconst}
                  value={minPrice}
                 // placeholder={"Prix de "}
                  onChange={(e) => {setMinPrice(parseInt(e.target.value));updateUrlParams(e)}}
                ></CInput>
               {minPrice && minPrice != "" && <button
         className="clearFilterInput"
          onClick={()=> handleClearSelection('minPrice')}
        >
          ×
        </button> }  <span className="suf">TND</span> </div>
      </div>

      <div>
      <span className="filter-title-field">Prix à </span> 
      <div className="inputWithSuffix">
                <CInput
                disabled = {isLoadedALL? false : true} 
                  min={1}
                 // max={maxpriceconst}
                  type="number"
                  name="maxPrice"
                  //placeholder={"Prix à "}
                  value={maxPrice}
                  onChange={(e) => {setMaxPrice(parseInt(e.target.value));updateUrlParams(e)}}
                ></CInput>
                {maxPrice && maxPrice != "" && <button
         className="clearFilterInput"
          onClick={()=> handleClearSelection('maxPrice')}
        >
          ×
        </button> } <span className="suf">TND</span> </div>
      </div>


      <div>
      <span className="filter-title-field">Carburant</span>
      <div  className='filterOptions'>
      <CSelect
                    name="fuel"
                    value={fuel}
                    onChange={(e) => {setFuel(e.target.value);
          updateUrlParams(e)
        }}
        style={{
          appearance: fuel == '0' || fuel == '' || !fuel ? 'auto' : 'none',
        }}
                  >
                    <option value=""></option>
                    <option value="Essence">Essence</option>
                    <option value="Diesel">Diesel</option>
                    <option value="Electrique">Electrique</option>
                    <option value="GPL">GPL</option>
                    <option value="Electrique/Essence">Electrique/Essence</option>
                    <option value="Essence 2 temps">Essence 2 temps</option>
                   
                    <option value="Autres">Autres</option>
                  </CSelect>
                  {fuel == '0' || fuel == '' || !fuel ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('fuel')}
        >
          ×
        </button>
      )}
          </div>
      </div>
      <div>
      <span className="filter-title-field">Transmission</span>
      <div  className='filterOptions'>
      <CSelect
                    name="gearingType"
                    value={gearingType}
                    onChange={(e) => {setGearingType(e.target.value);
          updateUrlParams(e)
        }}
        style={{
          appearance: gearingType == '0' || gearingType == '' || !gearingType ? 'auto' : 'none',
        }}
                  >
                    <option value=""></option>
                    <option value="Semi-automatique">Semi-automatique</option>
                    <option value="Cylindrée">Cylindrée</option>
                    <option value="Boîte manuelle">Boîte manuelle</option>
                    
                    <option value="autres">Autres</option>
                  </CSelect>
   {gearingType == '0' || gearingType == '' || !gearingType ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('gearingType')}
        >
          ×
        </button>
      )}
      </div></div>
  
    
      <div>
      <span className="filter-title-field">Type de châssis</span>
      <div  className='filterOptions'>
      <CSelect
                    name="bodyStyle"
                    value={bodyStyle}
                    onChange={(e) => {setBodyStyle(e.target.value);
          updateUrlParams(e)
        }}
        style={{
          appearance: bodyStyle == '0' || bodyStyle == '' || !bodyStyle ? 'auto' : 'none',
        }}
                  >
                    <option value=""></option>
                    <option value="Scooter">Scooter</option>
                    <option value="Roadster">Roadster</option>
                    <option value="Sportive">Sportive</option>
                    <option value="Custom">Custom</option>
                    <option value="Routière GT">Routière GT</option>
                    <option value="Quad">Quad</option>
                    <option value="Routière">Routière</option>
                    <option value="Trail">Trail</option>
                    <option value="Enduro">Enduro</option>
                    <option value="Sidecar">Sidecar</option>
                    <option value="Pocket Bike">Pocket Bike</option>
                    <option value="Vélomoteur">Vélomoteur</option>
                    <option value="Cross">Cross</option>
                    <option value="Collection">Collection</option>
                    <option value="Rallye">Rallye</option>
                    <option value="Compétition">Compétition</option>
                    <option value="Tuning">Tuning</option>
                    <option value="Supermotard">Supermotard</option>
                    <option value="Trial">Trial</option>
                    <option value="Trois Roues">Trois Roues</option>
                    <option value="autres">Autres</option>
                  </CSelect>
     {bodyStyle == '0' || bodyStyle == '' || !bodyStyle ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('bodyStyle')}
        >
          ×
        </button>
      )}         
     </div> </div>

      <div>
      <span className="filter-title-field">Garrantie</span>
      <div  className='filterOptions'>
      <CSelect
                    name="warranty"
                    value={warranty}
                    onChange={(e) => {setWarranty(e.target.value);
          updateUrlParams(e)
        }}
        style={{
          appearance: warranty == '0' || warranty == '' || !warranty ? 'auto' : 'none',
        }}
                  >
                    <option value=""></option>
                    <option value="0">0</option>
                    <option value="moins 1"> Moins d'une année</option>
                    <option value="">1 an</option>
                    <option value="Automatique">2 ans</option>
       </CSelect>
              {warranty == '0' || warranty == '' || !warranty ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('warranty')}
        >
          ×
        </button>
      )} 
     </div> </div>

      <div>
      <span className="filter-title-field">Couleur</span>
      <div  className='filterOptions'>
      <CSelect
                  name="color"
                  value={color}
                  onChange={(e) => {setColor(e.target.value);
          updateUrlParams(e)
        }}
        style={{
          appearance: color == '0' || color == '' || !color ? 'auto' : 'none',
        }}
                >
                  <option value=""></option>
                  <option value="Noir">Noir</option>
                  <option value="Gris argent">Gris argent</option>
                  <option value="Gris anthracite">Gris anthracite</option>
                  <option value="Gris Shark">Gris Shark</option>
                  <option value="Blanc">Blanc</option>
                  <option value="Rouge">Rouge</option>
                  <option value="Bleu">Bleu</option>
                  <option value="Beige">Beige</option>
                  <option value="Jaune">Jaune</option>
                  <option value="Orange">Orange</option>
                  <option value="Vert">Vert</option>
                  <option value="Aubergine">Aubergine</option>
                  <option value="Marron">Marron</option>
                  <option value="Autre">Autre</option>
       </CSelect>
       {color == '0' || color == '' || !color ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('color')}
        >
          ×
        </button>
      )}
     </div>     
     </div>
     <div>
      <span className="filter-title-field">Pays</span> 
      <div  className='filterOptions'>
      <CSelect
       style={{ appearance: country == '0' || country == '' || !country ? 'auto' : 'none',}}              
                  name="country"
                  value={country}
                  onChange={(e) => {handleCountryChange(e);updateUrlParams(e)}}
                >
                  <option value=""></option>
                  {countries.map((country) => {
                    return (
                      <option key={country.id} value={country.id}>
                        {country.label}
                      </option>
                    );
                  })}
                </CSelect> 
          {country == '0' || country == '' || !country ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('country')}
        >
          ×
        </button>
      )}</div>
      </div>

      <div>
      <span className="filter-title-field">Gouvernorat</span> 
      <div  className='filterOptions'>
      <CSelect
       style={{ appearance: city == '0' || city == '' || !city ? 'auto' : 'none',}}              
                  name="city"
                  value={city}
                  onChange={(e) => {setCity(e.target.value);updateUrlParams(e)}}
                >
                  <option value=""></option>
                  {cities.map((city) => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.label}
                      </option>
                    );
                  })}
                </CSelect>
                {city == '0' || city == '' || !city ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('city')}
        >
          ×
        </button>
      )}</div>
      </div>
      <span className="filtersMobButton filtersMobButtonG " onClick={toggleVisibility} >
              Chercher
            </span>
    </div>
              </CCol>

              <CCol>
                <div className="occasions-results layout-list">
                  <div className="articles">
                    Il y a <strong>{results.length} </strong> résultat
                    {results.length > 1 && "s"}
                    {results.length > 0 && 
                    <span className="filtersOrder">
                    <CSelect
      
                 name="sortBy"
                  value={sortBy}
                  onChange={(e) => {handleSortBy(e)}}
                >
                  <option value="dateD">Annonces les plus récentes d'abord</option>
                  <option value="dateC">Annonces les plus anciennes d'abord</option>
                  <option value="year_asc">Année ordre croissant</option>
                  <option value="year_desc">Année ordre décroissant</option>  
                  <option value="price_asc">Prix ordre croissant</option>
                  <option value="price_desc">Prix ordre décroissant</option>                 
                </CSelect>
                  </span>}
                    <CDataTable
                      className="vehicle-data-table"
                      items={results}
                      fields={fields}
                      header={false}
                      itemsPerPage={10}
                      pagination
                      scopedSlots={{
                        title: (moto) => (
                          <td>
                            <VehicleItem
                             table='Vehicle'
                               item={moto}
                               at={moto.createdAt}
                               id={moto.id}
                              make={moto.brand && moto.brand.label}
                              model={moto.model && moto.model.label}
                              price={moto.price}
                              url={`/vehicles/motos/details/${moto.id}`}
                              image={
                                moto.mainPicture
                                  ? `${IMAGES_BASE_URL}/public/uploads/images/motos/${moto.mainPicture.name}`
                                  : ""
                              }
                              mileage={moto.mileage && moto.mileage}
                              city={moto.city && moto.city.label}
                              boite={moto.boite && moto.boite}
                              fuel={moto.fuel && moto.fuel}
                              year={moto.year && moto.year}
                              itemType={itemType}
                            />
                          </td>
                        ),
                      }}
                    />
                  </div>
                </div>
              </CCol>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
