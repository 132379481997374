
import React, { useEffect, useState } from "react";
import { API_BASE_URL , keyHeader , houseTypes , apartmentTypes , new_real_estateTypes ,localTypes , groundTypes} from "../../../config";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  minsurfaceconst,
  maxsurfaceconst,
  minpriceconst,
  maxpriceconst,
} from "../../../config";

import {
  CRow,
  CCol,
  CSelect,
  CFormGroup,
  CLabel,
  CButton,
  CSpinner,
  CInput,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
} from "@coreui/react";
import Breadcrumb from "../Breadcrumb";

function SearchRealEstate() {
  const [section, setSection] = useState('house');
  const [types, setTypes] = useState(houseTypes);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [typeAnnonce, setTypeAnnonce] = useState("");
  const [type, setType] = useState("");
  const [minSurface, setMinSurface] = useState("");
  const [maxSurface, setMaxSurface] = useState("");
  const [delegate, setDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  const [locality, setLocality] = useState("");
  const [localities, setLocalities] = useState([]);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [isLoadedALL , setisLoadedALL] = useState(false);
  const [all, setAll] = useState([]);
  const [errorAll,setErrorALL]= useState(false);
  const [results , setresults] = useState([]);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    if (!localStorage.getItem("countries")) {
      axios(`${API_BASE_URL}/countries/all`)
        .then((res) => res.data)
        .then(
          (result) => {
            //  console.log("countries", result);
            setCountries(result);
            localStorage.setItem("countries", JSON.stringify(result));
          },
          (error) => {}
        );
    } else {
      setCountries(JSON.parse(localStorage.getItem("countries")));
    } 
  }, []);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         console.log('section ', section)
//         if(section=='house') 
//        { 
//         if (!house || house.length<1)
//         {const result = await axios.get(`${API_BASE_URL}/annonce_camion/all`, keyHeader);
//         setAll(result.data);
//         setresults(result.data);
//         setHouse(result.data);
//         setisLoadedALL(true);
//         console.log('res all house', retryCount ,result)
//       } else { 
//         setAll(house);
//         setresults(house);
//         setisLoadedALL(true);
//         console.log('set from prev house')
//       }
//     } else if(section=='apartment') 
//     { 
//      if (!apartment || apartment.length<1)
//      {const result = await axios.get(`${API_BASE_URL}/annonce_bike/all`, keyHeader);
//      setAll(result.data);
//      setresults(result.data);
//      setApartment(result.data);
//      setisLoadedALL(true);
//      console.log('res all apartment', retryCount ,result)
//    } else { 
//      setAll(apartment);
//      setresults(apartment);
//      setisLoadedALL(true);
//      console.log('set from prev apartment')
//    }
//  }
      
//       } catch (error) {
//         console.log('errrrrr' ,  retryCount)
//         // If there is a network error and retries are less than 3, retry the request
//         if ((error.message === 'Network Error' || error.code === 'ECONNABORTED')  && retryCount < 2) {
//           setRetryCount((prevCount) => prevCount + 1);
//         } else {
//           // After 3 retries, log a message to check the internet connection
//           setErrorALL(true);
//           setRetryCount(9)
//           console.error('Failed to fetch data. Please check your internet connection.');
//         }
//       }
//     };

//     // Call the fetchData function
//     fetchData();

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [retryCount , section]); 


useEffect(() => {
  const fetchData = async () => {
    try {
      const result = await axios.get(`${API_BASE_URL}/real_estate/all`, keyHeader);
      setAll(result.data);
      setresults(result.data)
      setisLoadedALL(true);
      //console.log('res all ', retryCount ,result)
    } catch (error) {
      //console.log('errrrrr' ,  retryCount)
      if ((error.message === 'Network Error' || error.code === 'ECONNABORTED')  && retryCount < 2) {
        setRetryCount((prevCount) => prevCount + 1);
      } else {
        setErrorALL(true);
        setRetryCount(9)
        console.error('Failed to fetch data. Please check your internet connection.');
      }
    }
  };

  fetchData();

}, [retryCount]); 


  useEffect(() => {
//typeApartment , typeHouse , typeNewRealEstate , typeGround , typeCommercialSpace
    if(isLoadedALL)   {
      var results = all.filter(function (el) {

      const sectionc = !section || (section  && el.type  && el.type == section);
   // const typec = !type || (type  && el.typeArticle && el.typeArticle.id && el.typeArticle.id == type);
   const typeC = !type ||
   (el.typeHouse && el.typeHouse == type) ||
   (el.typeApartment && el.typeApartment == type) ||
   (el.typeNewRealEstate && el.typeNewRealEstate == type) ||
   (el.typeGround && el.typeGround == type) ||
   (el.typeCommercialSpace && el.typeCommercialSpace == type);

  const typeAnnoncec = !typeAnnonce || (typeAnnonce  && el.typeAnnonce &&  el.typeAnnonce== typeAnnonce);
const minSurfacec = !minSurface || el.surface >= minSurface;  
const maxSurfacec = !maxSurface || el.surface <= maxSurface;   
  const minPricec = !minPrice || el.price >= minPrice;  
  const maxPricec = !maxPrice || el.price <= maxPrice; 
   //const countryc = !country || el.country.id == country;  
        const cityc = !city || el.city.id == city;  
      
        return sectionc && typeC && typeAnnoncec  && cityc && minPricec && maxPricec && minSurfacec && maxSurfacec;
      });
  
      //console.log('rsddsdxxx ',results)
      setresults(results);
    }  
     
    }, [ isLoadedALL, section ,type, typeAnnonce  , city , minPrice, maxPrice , minSurface , maxSurface]);

    let history = useHistory(); 
      const handleSubmit = (e) =>  {
        e.preventDefault();
        let path =``;

     if(section) path+=`section=${section}&`
     if(type) path+=`type=${type}&`  
    if(typeAnnonce) path+=`typeAnnonce=${typeAnnonce}&`
    if(minPrice) path+=`minPrice=${minPrice}&`
    if(maxPrice) path+=`maxPrice=${maxPrice}&`
    if(minSurface) path+=`minSurface=${minSurface}&`
    if(maxSurface) path+=`maxSurface=${maxSurface}&`
    if(country) path+=`country=${country}&`
    if(city) path+=`city=${city}&`
    
    //console.log(path.charAt(path.length - 1))
    if(path.charAt(path.length - 1)=='&') path = path.substring(0, path.length - 1)
    //console.log(path)
    history.push({
      pathname: "/real-estates/search-result",
      search:`?${path}`,
      state: { result: results , all:all  },
    });
      }

  const handleSubmitOld = (e) => {
    setSubmitted(true);
    e.preventDefault();
    if (typeAnnonce) {
      setLoading(true);
      const formData = new FormData();
      formData.append("typeAnnoncce", typeAnnonce);
      formData.append("type", type);
      formData.append("minSurface", minSurface);
      formData.append("maxSurface", maxSurface);
      formData.append("minPrice", minPrice);
      formData.append("maxPrice", maxPrice);
      formData.append("country", country);
      formData.append("city", city);
      formData.append("delegate", delegate);
      formData.append("locality", locality);
      axios
        .post(API_BASE_URL + "/annonce_house/search", formData)
        .then((res) => {
          //console.log('res.data',res);
          history.push({
            pathname: "/real-estates/search-result",
            state: { result: res.data },
            from : 'house'
          });
          setLoading(false);

          // console.log(props);
        })
        .catch((error) => {
          // console.log(" errors : ", error);
          setLoading(false);
        });
    }
  };
const handleSectionChange = (s) =>  {
//console.log(s);
setSection(s);
switch (s) {
case 'house':
    setTypes(houseTypes);
    break;
 case 'apartment':
    setTypes(apartmentTypes);
    break;
 case 'new_real_estate':
      setTypes(new_real_estateTypes);
   break;
case 'ground':
      setTypes(groundTypes);
    break;
case 'commercial_space':
      setTypes(localTypes);
    break;    
default:
     setTypes([]); break;
  }
  setType(''); setTypeAnnonce('') ; setMinPrice('') ; setMaxPrice('');
  setMinSurface(''); setMaxSurface(''); setCities([]) ; setCity(''); setCountry('');
}

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    let id = e.target.value;
    var countriesF = [];
    ////console.log("iddd", id)
    if (id !== "0") {
      countriesF = countries.find((city) => city.id.toString() === id);
      setCities(countriesF.cities);
      // //console.log('countriesF', countriesF);
    } else {
      setCity([]);
      //  setModel("");
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    let id = e.target.value;
    var citiesF = [];
    // console.log("iddd", id)
    if (id !== "0") {
      citiesF = cities.find((delegate) => delegate.id.toString() === id);
      setDelegates(citiesF.delegations);
      // console.log('citiesF', citiesF);
    } else {
      setDelegate([]);
      //  setModel("");
    }
  };
  const handleDelegateChange = (e) => {
    setDelegate(e.target.value);
    let id = e.target.value;
    var delegatesF = [];
    //console.log("iddd", id)
    if (id !== "0") {
      delegatesF = delegates.find((local) => local.id.toString() === id);
      setLocalities(delegatesF.localities);
      //console.log('delegatesF', delegatesF);
    } else {
      setLocality([]);
      //  setModel("");
    }
  };

 

  return (
    <>
      <div className="vehicle-search">
        <div className="container">
        <Breadcrumb/>
          <div className="row">
            <CCol
              md="6"
              className="justify-content-md-center container-vehicle"
            >
              <CTabs>
                <div className="row ">
                  <CCol xs="12" md="12">
                    <CNav variant="tabs" className="vehicule-tabs">
                      <CNavItem
                        className="vehicule-navItem immobilierIcon"
                        onClick={()=> handleSectionChange('house')}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/house.svg"}
                            className="icon-vehicle"
                            alt="house"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem immobilierIcon"
                       onClick={()=> handleSectionChange('apartment')}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/apartment.svg"}
                            className="icon-vehicle"
                            alt="apartment"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem immobilierIcon"
                        onClick={()=> handleSectionChange('new_real_estate')}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/new_real_estate.svg"}
                            className="icon-vehicle"
                            alt="new_real_estate"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem immobilierIcon"
                        onClick={()=> handleSectionChange('garage')}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/garage.svg"}
                            className="icon-vehicle"
                            alt="garage"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem immobilierIcon"
                        onClick={()=> handleSectionChange('office')}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/office.svg"}
                            className="icon-vehicle"
                            alt="office"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem immobilierIcon"
                        onClick={()=> handleSectionChange('commercial_space')}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/local.svg"}
                            className="icon-vehicle"
                            alt="commercial_space"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem immobilierIcon"
                        onClick={()=> handleSectionChange('ground')}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/ground.svg"}
                            className="icon-vehicle"
                            alt="ground"
                          />
                        </CNavLink>
                      </CNavItem>
                    </CNav>
                  </CCol>
                </div>


                <CTabContent  className="vehicule-tabContent filteringPage">
                <CTabPane className="tab-pane active show searchFormWrapper">
          
                <form onSubmit={handleSubmit}>
                <CRow>
            {" "}
            <CLabel htmlFor="type" className="typeLabel">
            {section == 'house'? 'Maison'
                    :section == 'apartment'? 'appartement'
                    :section == "new_real_estate"? 'immoblier'
                    :section == "commercial_space"? 'local'
                    :section == "ground"? 'Terrain'
                    :section.replaceAll("_", " ")} 
            </CLabel>
          </CRow>
        <CFormGroup row>
          {" "}
        {(section !='garage' && section !='office') ? 
        <CCol xs="6" md="6">
            {/* <CLabel htmlFor="type" className={"mainlabel"}>
              Type de maison
            </CLabel> */}
            <CSelect
              name="type"
              value={type}
              onChange={(e) => setType(e.target.value)}
              disabled = {isLoadedALL? false : true} 
            >
             <option value="">Choisissez le type de l'immobilier</option>
              {types.map((el,index) => {
                    return (
                      <option key={index} value={el.value}>
                        {el.label}
                      </option>
                    );
                  })}
            </CSelect>
          </CCol> : ''}
          <CCol xs="6" md="6"> 
         
            {/* <CLabel htmlFor="nbrSeat">Type d'annonce</CLabel> */}
            <CSelect
              name="typeAnnonce"
              value={typeAnnonce}
              onChange={(e) => setTypeAnnonce(e.target.value)}
              disabled = {isLoadedALL? false : true} 
            >
              <option value="">Choisissez le type d'annonce</option>
              <option value="annonce_location">Annonce de location</option>
              <option value="annonce_vente">Annonce de vente </option>
              {(section =='house' || section =='apartment') &&<option value="annonce_vacance">Annonce de vacances</option>}
            
            </CSelect>
            {submitted && !typeAnnonce && (
              <span className="invalid-feedback error">obligatoire</span>
            )}
          </CCol>
        </CFormGroup>

        <CFormGroup row>
          <CCol xs="6" md="6" className="min-max2">
            <CFormGroup>
              {/* <span>Min Surface</span> */}
              <div className="inputWithSuffix">
            <CInput
                type="number"
                name="minSurface"
                min={minsurfaceconst}
                max={maxsurfaceconst}
                value={minSurface}
                placeholder={minsurfaceconst + " m²"}
                onChange={(e) => {
                  setMinSurface(parseInt(e.target.value));
                }}
                disabled = {isLoadedALL? false : true} 
              ></CInput>
              <span className="suf">m²</span> </div>
            </CFormGroup>
          </CCol>
          <CCol xs="6" md="6" className="min-max2">
            <CFormGroup>
              {/* <span>Max Surface</span> */}
              <div className="inputWithSuffix">
            <CInput
                min={minsurfaceconst}
                max={maxsurfaceconst}
                type="number"
                name="maxSurface"
                placeholder={maxsurfaceconst}
                value={maxSurface}
                onChange={(e) => {
                  setMaxSurface(parseInt(e.target.value));
                }}
                disabled = {isLoadedALL? false : true} 
              ></CInput>
              <span className="suf">m²</span> </div>
            </CFormGroup>
          </CCol>
        </CFormGroup>

        <CFormGroup row>
            <CCol xs="6" md="6" className="min-max2">
              <CFormGroup>
                {/* <span>Prix a</span> */}
                <div className="inputWithSuffix">
                <CInput
                disabled = {isLoadedALL? false : true} 
                  type="number"
                  name="minPrice"
                  min={1}
                 // max={maxpriceconst}
                  value={minPrice}
                  placeholder={"Prix de "}
                  onChange={(e) => setMinPrice(parseInt(e.target.value))}
                ></CInput>
                <span className="suf">TND</span> </div>
              </CFormGroup>
            </CCol>
            <CCol xs="6" md="6" className="min-max2">
              <CFormGroup>
                {/* <span>de</span> */}
                <div className="inputWithSuffix">
                <CInput
                disabled = {isLoadedALL? false : true} 
                  min={1}
                 // max={maxpriceconst}
                  type="number"
                  name="maxPrice"
                  placeholder={"Prix à "}
                  value={maxPrice}
                  onChange={(e) => setMaxPrice(parseInt(e.target.value))}
                ></CInput>
                <span className="suf">TND</span> </div>
              </CFormGroup>
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol xs="6" md="6">
              <CFormGroup>
                {/* Pays */}
                <CSelect
                disabled = {isLoadedALL? false : true} 
                  name="country"
                  value={country}
                  onChange={(e) => handleCountryChange(e)}
                >
                  <option value="0"> Choisissez un pays</option>
                  {countries.map((country) => {
                    return (
                      <option key={country.id} value={country.id}>
                        {country.label}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
            <CCol xs="6" md="6">
              <CFormGroup>
                {/* Gouvernorat */}
                <CSelect
                disabled = {isLoadedALL? false : true} 
                  name="city"
                  value={city}
                  onChange={(e) => handleCityChange(e)}
                >
                  <option value="0"> Gouvernorat</option>
                  {cities.map((city) => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.label}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
          </CFormGroup>

        <CFormGroup row>
          <CCol xs="6" md="6"></CCol>
          <CCol xs="6" md="6">
          { !isLoadedALL && retryCount>0  && retryCount<4 ?
                <CButton className=" btn-search" disabled>
                <span>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span> <span> Erreur réseau ! 😟 réessaye </span>
                </CButton>
                :
                errorAll?
                <CButton className=" btn-search" disabled>
                  <span> Error Chercher ! 😟 <br/>Vérifiez votre connection internet</span>
                </CButton>
                : !loading && isLoadedALL ? (
                <CButton className=" btn-search" type="submit">
                  <span> {results.length} résultats</span>
                </CButton>
              )
              : !loading && isLoadedALL ? (
                <CButton className=" btn-search" type="submit">
                  <span> {results.length} résultats</span>
                </CButton>
              )
              :  <CButton className=" btn-search" disabled>
                  <span>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>

                  <span> Chercher </span>
                </CButton>
              }
          </CCol>
        </CFormGroup>
      </form>    
        </CTabPane> 
        </CTabContent> 

               {/* <CTabContent fade={false} className="vehicule-tabContent filteringPage">
                  <CTabPane className="searchFormWrapper">
                    <SearchHouse />
                  </CTabPane>
                  <CTabPane className="searchFormWrapper">
                    <SearchApartment />
                  </CTabPane>
                  <CTabPane className="searchFormWrapper">
                    <SearchNewRealEstate />
                  </CTabPane>
                  <CTabPane className="searchFormWrapper">
                    <SearchGarage />
                  </CTabPane>
                  <CTabPane className="searchFormWrapper">
                    <SearchOffice />
                  </CTabPane>
                  <CTabPane className="searchFormWrapper">
                    <SearchCommercialSpace />
                  </CTabPane>
                  <CTabPane className="searchFormWrapper">
                    <SearchGaround />
                  </CTabPane>
                </CTabContent>  */}

              </CTabs>
            </CCol>

            <CCol md="6">
              <div className="sideImgWrapper">
                <img
                  src={"assets/images/real estate banner.jpg"}
                  alt="tunisia living real estate banner"
                />
              </div>
            </CCol>
            {/* {showHideBike && (
              <CCol  md="6">
                <SizeBike/>
              </CCol>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchRealEstate;
