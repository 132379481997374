import {
  CCol,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabs,
  CTabPane,
  CSelect,
  CFormGroup,
  CLabel,
  CButton,
  CSpinner,
  CRow,
  CInput,
  CInputRadio
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { API_BASE_URL , keyHeader } from "../../../../config";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Loading from "src/components/myWidgets/loadingWidget";
import Breadcrumb from "../../Breadcrumb";

function SearchArticles() {

    const [section, setSection] = useState(1);
    const [sectionLabel, setSectionLabel] = useState("Animalerie");

    const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [delegate, setDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  const [locality, setLocality] = useState("");
  const [localities, setLocalities] = useState([]);
  const [types, setTypes] = useState([]);
  const [type, setType] = useState("");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [isLoaded1, setIsLoaded1] = useState(false);
  const [isLoaded2, setIsLoaded2] = useState(false);
  const [typesAll, setTypesAll] = useState([]);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);
  const [isLoadedALL , setisLoadedALL] = useState(false);
  const [all, setAll] = useState([]);
  const [errorAll,setErrorALL]= useState(false);
  const [results , setresults] = useState([]);
  const [retryCount, setRetryCount] = useState(0);

      let history = useHistory();


      useEffect(() => {
        const fetchData = async () => {
          try {
            const result = await axios.get(`${API_BASE_URL}/article/all`, keyHeader);
            setAll(result.data);
            setresults(result.data)
            setisLoadedALL(true);
            //console.log('res all ', retryCount ,result)
          } catch (error) {
            //console.log('errrrrr' ,  retryCount)
            if ((error.message === 'Network Error' || error.code === 'ECONNABORTED')  && retryCount < 2) {
              setRetryCount((prevCount) => prevCount + 1);
            } else {
              setErrorALL(true);
              setRetryCount(9)
              console.error('Failed to fetch data. Please check your internet connection.');
            }
          }
        };
    
        fetchData();
    
      }, [retryCount]); 
    

    useEffect(() => {
   
    if (!localStorage.getItem("typeArticles")) 
    {axios(`${API_BASE_URL}/articles/types`)
    .then((res) => res.data)
    .then(
      (result) => {
        //console.log("result555 all", result);
        setTypesAll(result);
    let typesF = [];
    typesF = result.filter(obj => { return obj.sectionId == 1 })
    setTypes(typesF);
    //console.log(typesF); 

        setIsLoaded1(true);
        localStorage.setItem("typeArticles", JSON.stringify(result));
      },
      (error) => {
        setError(error);
        setIsLoaded1(true);
      }
    );} else {
      setTypesAll(JSON.parse(localStorage.getItem("typeArticles"))); 
      let typesF = [];
      typesF = JSON.parse(localStorage.getItem("typeArticles")).filter(obj => { return obj.sectionId == 1 })
      setTypes(typesF);
      //console.log(typesF); 
  
          setIsLoaded1(true);
    }

  if (!localStorage.getItem("countries")) {
      axios(`${API_BASE_URL}/countries/all`)
        .then((res) => res.data)
        .then(
          (result) => {
            //  //console.log("countries", result);
            setCountries(result);
            setIsLoaded2(true);
            localStorage.setItem("countries", JSON.stringify(result));
          },
          (error) => {}
        );
    } else {
      ////console.log("countris2222222");
      ////console.log(localStorage.getItem("countries"));
      setIsLoaded2(true);
      setCountries(JSON.parse(localStorage.getItem("countries")));
    } 
  
  }, []);   
  
  useEffect(() => {

    if(isLoadedALL)   {
      var results = all.filter(function (el) {
  
        const statusc = status === null || el.state == status;
        const sectionc = !section || (section  && el.typeArticle && el.typeArticle.sectionId && el.typeArticle.sectionId == section);
       // !section || el.typeArticle.sectionId == section;  
        const typec = !type || (type  && el.typeArticle && el.typeArticle.id && el.typeArticle.id == type);
       // !type || el.typeArticle.id == type;  
        const categoryc = !category || (category  && el.categoryArticle && el.categoryArticle.id && el.categoryArticle.id == category);
        //!category || el.categoryArticle.id == category;  
//const breedc = !breed || (breed  && el.breedsAnimal && el.breedsAnimal.id && el.breedsAnimal.id == breed); 
 
 
  const minPricec = !minPrice || el.price >= minPrice;  
  const maxPricec = !maxPrice || el.price <= maxPrice; 
   const countryc = !country || el.country.id == country;  
        const cityc = !city || el.city.id == city;  
      
        return statusc && typec && sectionc  && categoryc && countryc && cityc && minPricec && maxPricec;
      });
  
      //console.log('rsddsdxxx ',results)
      setresults(results);
    }  
     
    }, [ isLoadedALL, status, type, section, category , country , city , minPrice, maxPrice ]);
    

  const handleSectionChange = (i, label) => {
        setSection(i);
        setSectionLabel(label);
        var typesF = [];
        //console.log(label);
  var typesF = [];
    typesF = typesAll.filter(obj => { return obj.sectionId == i })
    setTypes(typesF);
    setType(''); setCategory('');
    setCountry(''); setCity('');
    setStatus(null);
    setMinPrice('');
    setMaxPrice('');
    //console.log(typesF);     
      };


      const handleSubmit = (e) =>  {
        e.preventDefault();
        let path =``;
    if(status) path+=`status=${status}&`
    if(type) path+=`type=${type}&`
    if(section) path+=`section=${section}&`
    if(category) path+=`category=${category}&`
    if(minPrice) path+=`minPrice=${minPrice}&`
    if(maxPrice) path+=`maxPrice=${maxPrice}&`
    if(country) path+=`country=${country}&`
    if(city) path+=`city=${city}&`
    
    //console.log(path.charAt(path.length - 1))
    if(path.charAt(path.length - 1)=='&') path = path.substring(0, path.length - 1)
    //console.log(path)
    
     history.push({
          pathname:  `/articles/search-result`,
          search:`?${path}`,
          state: { result: results  ,
            all:{all:all ,cities:cities}     
                 },
        });
      }

      const handleSubmitOld = (e) => {
        setSubmitted(true);
        e.preventDefault();
        if (type) {
          setLoading(true);
          const formData = new FormData();
          formData.append("type", type);
          if(status) formData.append("state", status);
          formData.append("category", category);
          formData.append("locality", locality);
          formData.append("minPrice", minPrice);
          formData.append("maxPrice", maxPrice);
          formData.append("country", country);
          formData.append("city", city);
          formData.append("delegate", delegate);
          formData.append("locality", locality);
          //console.log('sttttttate');
          //console.log(formData.get('state'));
          axios
            .post(
              API_BASE_URL + "/articles/search",
              formData
            )
            .then((res) => {
               //console.log("res.data", res);
              history.push({
                pathname: "/articles/search-result",
                state: { result: res.data },
              });
              setLoading(false);
    
              // //console.log(props);
            })
            .catch((error) => {
              // //console.log(" errors : ", error);
              setLoading(false);
            });
        }
      };
    
      const handleCountryChange = (e) => {
        setCountry(e.target.value);
        let id = e.target.value;
        var countriesF = [];
        ////console.log("iddd", id);
        if (id !== "0") {
          countriesF = countries.find((city) => city.id.toString() === id);
          setCities(countriesF.cities);
          // //console.log("countriesF", countriesF);
        } else {
          setCity([]);
          //  setModel("");
        }
      };
    
      const handleCityChange = (e) => {
        setCity(e.target.value);
        let id = e.target.value;
        var citiesF = [];
        // //console.log("iddd", id);
        if (id !== "0") {
          citiesF = cities.find((delegate) => delegate.id.toString() === id);
          setDelegates(citiesF.delegations);
          // //console.log("citiesF", citiesF);
        } else {
          setDelegate([]);
          //  setModel("");
        }
      };
      const handleDelegateChange = (e) => {
        setDelegate(e.target.value);
        let id = e.target.value;
        var delegatesF = [];
        ////console.log("iddd", id);
        if (id !== "0") {
          delegatesF = delegates.find((local) => local.id.toString() === id);
          setLocalities(delegatesF.localities);
          // //console.log("delegatesF", delegatesF);
        } else {
          setLocality([]);
          //  setModel("");
        }
      };
      const handleTypeChange = (e) => {
        setType(e.target.value);
    
        let id = e.target.value;
        var typesF = [];
        if (id !== "0") {
          typesF = types.find((category) => category.id.toString() === id);
          setCategories(typesF.categoryArticles);
          // //console.log(typesF);
        } else {
          setCategories([]);
          setCategory("");
        }
      };
      const handleCategoryChange = (e) => {
        setCategory(e.target.value);
      };

      if (isLoaded1 && isLoaded2) {
  return (
    <>
      <div className="vehicle-search">
        <div className="container">
        <Breadcrumb/>
          <div className="row">
            <CCol
              md="6"
              className="justify-content-md-center container-vehicle"
            >
              <CTabs>
                <div className="row ">
                  <CCol xs="12" md="12">
                    <CNav variant="tabs" className="vehicule-tabs">
                      <CNavItem
                        className="vehicule-navItem articleIcon"
                        onClick={() => handleSectionChange("1", "Animalerie")}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/petShop.svg"}
                            className="icon-vehicle"
                            alt="pet Shop"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem articleIcon"
                        onClick={() => handleSectionChange("2", "Informatique")}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/computerScience.svg"}
                            className="icon-vehicle"
                            alt="computer accessory"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem articleIcon"
                        onClick={() => handleSectionChange("3", "Smart Home")}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/smartHome.svg"}
                            className="icon-vehicle"
                            alt="smart Home"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem articleIcon"
                        onClick={() => handleSectionChange("4", "Maison et déco")}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/homeDeco.svg"}
                            className="icon-vehicle"
                            alt="electromenager"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem articleIcon"
                        onClick={() => handleSectionChange("5", "Sport et Fitness")}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/sportFitness.svg"}
                            className="icon-vehicle"
                            alt="sportFitness"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem articleIcon"
                        onClick={() => handleSectionChange("6", "Auto et Accessoires")}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/autoAccessory.svg"}
                            className="icon-vehicle"
                            alt="autoAccessory"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem articleIcon"
                        onClick={() => handleSectionChange("7", "Shopping")}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/clothes.svg"}
                            className="icon-vehicle"
                            alt="clothes"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem articleIcon"
                        onClick={() => handleSectionChange("8", "AudioVisuel")}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/audiovisuel.svg"}
                            className="icon-vehicle"
                            alt="audioVisuel"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem articleIcon"
                        onClick={() => handleSectionChange("9", "Divers")}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/divers.svg"}
                            className="icon-vehicle"
                            alt="divers tunisia living"
                          />
                        </CNavLink>
                      </CNavItem>
                    </CNav>
                  </CCol>
                </div>

                <CTabContent  className="vehicule-tabContent filteringPage">
       

                <CTabPane className="tab-pane active show searchFormWrapper">
                <form onSubmit={handleSubmit}>
          <CRow>
            {" "}
            <CLabel htmlFor="type" className="typeLabel">
            {sectionLabel}
            </CLabel>
          </CRow>
          <CFormGroup row>
            <CCol xs="6" md="6">
              <CFormGroup variant="custom-checkbox" inline>
                <CFormGroup variant="checkbox" inline>
                  <CInputRadio
                    className="form-check-input"
                    id="1"
                    name="status"
                    value="1"
                    onChange={() => setStatus(1)}
                    disabled = {isLoadedALL? false : true} 
                    checked = {status==1 || status=='1' || status==true ? true : false}
                  />
                  <CLabel variant="checkbox" htmlFor="neuf">
                    Neuf
                  </CLabel>
                </CFormGroup>
                <CFormGroup variant="checkbox" inline>
                  <CInputRadio
                    className="form-check-input"
                    id="0"
                    name="status"
                    value="0"
                    onChange={() => setStatus(0)}
                    disabled = {isLoadedALL? false : true} 
                    checked = {status==0 || status=='0' || status==false? true : false}
                  />
                  <CLabel variant="checkbox" htmlFor="occasion">
                    Occasion
                  </CLabel>
                </CFormGroup>
              </CFormGroup>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs="6" md="6">
              <CSelect
                name="type"
                value={type}
                onChange={(e) => handleTypeChange(e)}
                disabled = {isLoadedALL? false : true} 
              >
                <option value="0"> Choisir le type</option>
                {types.map((type) => {
                  return (
                    <option key={type.id} value={type.id}>
                      {type.label}
                    </option>
                  );
                })}
              </CSelect>

              {submitted && !type && (
                <span className="invalid-feedback error">
                  Veuillez choisir le type
                </span>
              )}
            </CCol>
            <CCol xs="6" md="6">
              <CSelect
                name="category"
                value={category}
                onChange={(e) => handleCategoryChange(e)}
                disabled = {isLoadedALL? false : true} 
              >
                <option value="0"> Choisir la categorie </option>
                {categories.map((category) => {
                  return (
                    <option key={category.id} value={category.id}>
                      {category.label}
                    </option>
                  );
                })}
              </CSelect>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs="6" md="6" className="min-max2">
              <CFormGroup>
                {/* <span>Prix a</span> */}
                <div className="inputWithSuffix">
                <CInput
                disabled = {isLoadedALL? false : true} 
                  type="number"
                  name="minPrice"
                  min={1}
                 // max={maxpriceconst}
                  value={minPrice}
                  placeholder={"Prix de "}
                  onChange={(e) => setMinPrice(parseInt(e.target.value))}
                ></CInput>
                <span className="suf">TND</span> </div>
              </CFormGroup>
            </CCol>
            <CCol xs="6" md="6" className="min-max2">
              <CFormGroup>
                {/* <span>de</span> */}
                <div className="inputWithSuffix">
                <CInput
                disabled = {isLoadedALL? false : true} 
                  min={1}
                 // max={maxpriceconst}
                  type="number"
                  name="maxPrice"
                  placeholder={"Prix à "}
                  value={maxPrice}
                  onChange={(e) => setMaxPrice(parseInt(e.target.value))}
                ></CInput>
                <span className="suf">TND</span> </div>
              </CFormGroup>
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol xs="6" md="6">
              <CFormGroup>
                {/* Pays */}
                <CSelect
                disabled = {isLoadedALL? false : true} 
                  name="country"
                  value={country}
                  onChange={(e) => handleCountryChange(e)}
                >
                  <option value="0"> Choisissez un pays</option>
                  {countries.map((country) => {
                    return (
                      <option key={country.id} value={country.id}>
                        {country.label}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
            <CCol xs="6" md="6">
              <CFormGroup>
                {/* Gouvernorat */}
                <CSelect
                disabled = {isLoadedALL? false : true} 
                  name="city"
                  value={city}
                  onChange={(e) => handleCityChange(e)}
                >
                  <option value="0"> Gouvernorat</option>
                  {cities.map((city) => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.label}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
          </CFormGroup>

          {/* <CFormGroup row>
            <CCol xs="6" md="6">
              <CFormGroup>
                Délégation
                <CSelect
                  name="delegate"
                  value={delegate}
                  onChange={(e) => handleDelegateChange(e)}
                >
                  <option value="0"> Choisissez une délégation</option>
                  {delegates.map((delegate) => {
                    return (
                      <option key={delegate.id} value={delegate.id}>
                        {delegate.label}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
            <CCol xs="6" md="6">
              <CFormGroup>
                Localité
                <CSelect
                  name="locality"
                  value={locality}
                  onChange={(e) => setLocality(e.target.value)}
                >
                  <option value="0"> Localité</option>
                  {localities.map((local) => {
                    return (
                      <option key={local.id} value={local.id}>
                        {local.label}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
          </CFormGroup> */}

          <CFormGroup row>
            <CCol xs="6" md="6"></CCol>
            <CCol xs="6" md="6">
            { !isLoadedALL && retryCount>0  && retryCount<4 ?
                <CButton className=" btn-search" disabled>
                <span>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span> <span> Erreur réseau ! 😟 réessaye </span>
                </CButton>
                :
                errorAll?
                <CButton className=" btn-search" disabled>
                  <span> Error Chercher ! 😟 <br/>Vérifiez votre connection internet</span>
                </CButton>
                : !loading && isLoadedALL ? (
                <CButton className=" btn-search" type="submit">
                  <span> {results.length} résultats</span>
                </CButton>
              )
              : !loading && isLoadedALL ? (
                <CButton className=" btn-search" type="submit">
                  <span> {results.length} résultats</span>
                </CButton>
              )
              :  <CButton className=" btn-search" disabled>
                  <span>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>

                  <span> Chercher </span>
                </CButton>
              }
            </CCol>
          </CFormGroup>
        </form>
 </CTabPane>

          
                
                </CTabContent>
              </CTabs>
            </CCol>
   
            <CCol md="6">
              <div className="sideImgWrapper">
                <img
                  src={"assets/images/article-banner.jpg"}
                  alt="tunisia living articles banner"
                />
              </div>
            </CCol>
            
          </div>
        </div>
      </div>
    </>
  );
      } else
      return (
    <Loading/>
      );
}

export default SearchArticles;
