import React, { useState , useEffect} from 'react';
import MemberContext from './MemberContext';
//import { API_BASE_URL , tokenCookie , keyHeader} from 'src/config';
import axios from 'axios';
import { logout } from 'src/components/middleware/auth';
import apiClient from "../apiClient";

const MemberProvider = ({ children }) => {
    const [loadedAnimals, setloadedAnimals] = useState(false);
    const [animals, setAnimals] = useState([]);
    const [loadedArticles, setloadedArticles] = useState(false);
    const [articles, setArticles] = useState([]);
    const [loadedPlans, setloadedPlans] = useState(false);
    const [plans, setPlans] = useState([]);
    const [loadedVehicles, setloadedVehicles] = useState(false);
    const [vehicles, setVehicles] = useState([]); 
    const [loadedEstates, setloadedEstates] = useState(false);
    const [estates, setEstates] = useState([]); 
    const [loadedServices, setloadedServices] = useState(false);
    const [services, setServices] = useState([]);
    const [loadedJobs, setloadedJobs] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [testm, setTestm] = useState('rererererererettttttt');
    const [failed, setFailed] = useState(false);

  
    useEffect(() => {
      if(localStorage.getItem("typeCompte") != 2 ) 
         setloadedJobs(true);
    }, []);
    useEffect(() => {
        const source = axios.CancelToken.source();
        //console.log('in member provvv --- ')

        if(localStorage.getItem("typeCompte") == 2 ) 
          { 
            apiClient.get(`/jobs/my-jobs`, {
               cancelToken: source.token,
               //...keyHeader,
             })
             .then(response => {
               //console.log('response jobss --- ', response);
               setloadedJobs(true);
               setJobs(response.data.items);
              
             })
             .catch(error => {
         if (axios.isCancel(error)) {
           //console.log("Request cancelled serrr:", error.message);
         } else {
           ////console.log('error  --- ',error.response.status , '>>>>',  error)
           if (error.response && error.response.status === 401){
             setFailed(true)
           }
         }
             });
           }
           
        apiClient.get(`/animals/By_member`, {
          cancelToken: source.token,
         // ...keyHeader,
        })
            .then(response => {  
             console.log('response anim --- ', response)
              setloadedAnimals(true);
              setAnimals(response.data.items);
          
    
             
            })
            .catch(error => {
              //setloadedAnimals(true);
              //setError(error);
             
                //console.log('error animals --- ',error.response.status , '>>>>',  error)
                if (error.response && error.response.status === 401){
                  setFailed(true)
                }
      
            });
    
        apiClient.get(`/articles/By_member`, {
        cancelToken: source.token,
        //...keyHeader,
      })
                .then(response => {
                console.log('response arttt --- ', response);
                  setloadedArticles(true);
                  setArticles(response.data.items);
                 
                })
                .catch(error => {
                  //console.log('error  --- ',error.response.status , '>>>>',  error)
                  if (error.response && error.response.status === 401){
                    setFailed(true)
                  }
          
                });    
      
       apiClient.get(`/goodPlans/By_member`, {
        cancelToken: source.token,
        //...keyHeader,
      })
                .then(response => {
                  //console.log('response plans --- ', response);
                  setloadedPlans(true);
                  setPlans(response.data.items);
                 
                })
                .catch(error => {
                  //console.log('error  --- ',error.response.status , '>>>>',  error)
                  if (error.response && error.response.status === 401){
                    setFailed(true)
                  }
          
                });
    
             apiClient.get(`/vehicle/By_member`, {
                  cancelToken: source.token,
                  //...keyHeader,
                })
                .then(response => {
                  //console.log('response vvvvv --- ', response);
                  setloadedVehicles(true);
                  setVehicles(response.data.items);
                 
                })
                .catch(error => {
                  if (axios.isCancel(error)) {
                    //console.log("Request cancelled vvvvvv:", error.message);
                  } else {
                   // console.log('error  --- ',error.response.status , '>>>>',  error)
                    if (error.response && error.response.status === 401){
                      setFailed(true)
                    }
                  }
                });        
    
               apiClient.get(`/real_estate/By_member`, {
                  cancelToken: source.token,
                  //...keyHeader,
                })
                .then(response => {
                  //console.log('response estates --- ', response);
                  setloadedEstates(true);
                  setEstates(response.data.items);
                 
                })
                .catch(error => {
                  if (axios.isCancel(error)) {
                    //console.log("Request cancelled estttt:", error.message);
                  } else {
                   // //console.log('error  --- ',error.response.status , '>>>>',  error)
                    if (error.response && error.response.status === 401){
                      setFailed(true)
                    }
                  }
                });
    
                apiClient.get(`/services/By_member`, {
                  cancelToken: source.token,
                 // ...keyHeader,
                })
                .then(response => {
                  //console.log('response serrr --- ', response);
                  setloadedServices(true);
                  setServices(response.data.items);
                 
                })
                .catch(error => {
            if (axios.isCancel(error)) {
              //console.log("Request cancelled serrr:", error.message);
            } else {
             // //console.log('error  --- ',error.response.status , '>>>>',  error)
              if (error.response && error.response.status === 401){
                setFailed(true)
              }
            }
                });
          
       // Clean up function to cancel the request on component unmount
       return () => {
        source.cancel("Request cancelled on component unmount.");
      };
    
        }, []);

        useEffect(() =>   {

        if(failed)    {
          logout();
          }
    }  , [failed]);

  const value = {
        animals,
        articles,
        vehicles,
        estates,
        services,
        plans,
        jobs,
        loadedAnimals,
        loadedArticles,
        loadedEstates,
        loadedPlans,
        loadedServices,
        loadedVehicles,
        loadedJobs,
        setAnimals,
        setArticles,
        setEstates,
        setPlans,
        setServices,
        setVehicles,
        setJobs,
        testm,
        failed
  };

  return (
   
    <MemberContext.Provider value={value}>
      {children}
    </MemberContext.Provider>
  );
};

export default MemberProvider;
