import React from 'react';


const Toaster = React.lazy(() => import('./views/notifications/toaster/Toaster'));





const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));
const CoreUIIcons = React.lazy(() => import('./views/icons/coreui-icons/CoreUIIcons'));
const Flags = React.lazy(() => import('./views/icons/flags/Flags'));
const Brands = React.lazy(() => import('./views/icons/brands/Brands'));
const Alerts = React.lazy(() => import('./views/notifications/alerts/Alerts'));
const Badges = React.lazy(() => import('./views/notifications/badges/Badges'));
const Modals = React.lazy(() => import('./views/notifications/modals/Modals'));
const Colors = React.lazy(() => import('./views/theme/colors/Colors'));
const Typography = React.lazy(() => import('./views/theme/typography/Typography'));
const Widgets = React.lazy(() => import('./views/widgets/Widgets'));
const infobar = React.lazy(() => import('./components/BackOffice/infobar'));


const routes = [
  { path: '/5467854', exact: true, name: 'Home' },
  { path: '/5467854/register', exact: true, name: 'Ajouter utilisateur' },
  { path: '/5467854/users/edit/:id', exact: true, name: 'Modifier utilisateur' },
  { path: '/5467854/type-news/', exact: true, name: 'Type actualité' },
  { path: '/5467854/type-news/info-bar', exact: true, name: 'Info Bar' },
 // { path: '/5467854/type-news/infobar', name: 'Widgets', component: infobar },
  { path: '/5467854/type-news/add', exact: true, name: 'Ajouter type actualité' },
  { path: '/5467854/type-news/edit/:id', exact: true, name: 'Modifier type actualité' },
  { path: '/5467854/category-news', exact: true, name: 'Catégories des news' },
  { path: '/5467854/category-news/add', exact: true, name: "Ajouter catégorie d'actualité "},
  { path: '/5467854/category-news/edit/:id', exact: true, name: "Modifier catégorie d'actualité "},
  { path: '/5467854/news', exact: true, name: 'News' },
  { path: '/5467854/news/add', exact: true, name: 'Ajouter une actualité' },
  { path: '/5467854/news/detail/:id', exact: true, name: "Détail d'actualité" },
  { path: '/5467854/news/edit/:id', exact: true, name: "Modifier actualité" },
  { path: '/5467854/setting/equipement', exact: true, name: 'paramétrage equipement ' },
  { path: '/5467854/setting/add-equipement', exact: true, name: 'Ajouter un équipement' },
  { path: '/5467854/setting/detail-equipement/:id', exact: true, name: "Détail d'un équipement" },
  { path: '/5467854/setting/edit-equipement/:id', exact: true, name: "Modifier équipement" },
  { path: '/5467854/setting/equipement/immobiliers', exact: true, name: 'paramétrage equipement immobiliers' },
  { path: '/5467854/setting/add-equipement/immobilier', exact: true, name: 'Ajouter un équipement immobilier' },
  { path: '/5467854/immobilier/setting/equipement/edit/:id', exact: true, name: "Modifier équipement immobilier" },

  { path: '/5467854/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/theme', name: 'Theme', component: Colors, exact: true },
  { path: '/theme/colors', name: 'Colors', component: Colors },
  { path: '/theme/typography', name: 'Typography', component: Typography },
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', component: Flags },
  { path: '/icons/brands', name: 'Brands', component: Brands },
  { path: '/notifications', name: 'Notifications', component: Alerts, exact: true },
  { path: '/notifications/alerts', name: 'Alerts', component: Alerts },
  { path: '/notifications/badges', name: 'Badges', component: Badges },
  { path: '/notifications/modals', name: 'Modals', component: Modals },
  { path: '/notifications/toaster', name: 'Toaster', component: Toaster },
  { path: '/widgets', name: 'Widgets', component: Widgets },
  //{ path: '/5467854/users',  name: 'Utilisateurs', component: Users },
 
];

export default routes;
