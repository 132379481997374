  import {
    CBadge,
      CAlert,
      CRow,
      CCol,
      CFormGroup,
      CLabel,
      CInput,
      CSelect,
      CButton,
      CSpinner,
      CModal,
      CModalHeader,
      CModalBody,
      CModalFooter,
      CInputCheckbox,
      CInputRadio
  } from "@coreui/react";
  import React, { useEffect, useState , useContext} from "react";
  import axios from "axios";
  import Dropzone from "react-dropzone-uploader";
  import "react-dropzone-uploader/dist/styles.css";
  import imageCompression from "browser-image-compression";
  import { useHistory } from "react-router-dom";
  import { CKEditor } from "@ckeditor/ckeditor5-react";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import CIcon from "@coreui/icons-react";
  import { IMAGES_BASE_URL, API_BASE_URL , tokenCookie , keyHeader , sizeAlert} from "src/config";
  import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
  import "react-phone-number-input/style.css";
  import Loading from "src/components/myWidgets/loadingWidget";
  import { Basic , Scroll , Categori , Adresse , Contact , Extra , Imgs, Eventt} from "src/components/myWidgets/formTitles";
  import { getBadge , textBadge } from "src/actions";
  import { addWatermark , addWatermarkToAlbum } from "src/actions";
  import MemberContext from "../../members/MemberContext";
  import { logoutLocal } from "src/components/middleware/auth";
import apiClient from "../../apiClient";

  export default function EditArticle(props) {
      const [error, setError] = useState(null);
      const [isLoaded, setIsLoaded] = useState(false);
      const [submitted, setSubmitted] = useState(false);
      const [price, setPrice] = useState("");
      const [album, setAlbum] = useState([]);
      const [imgPrincipal, setImgPrincipal] = useState(null);
      const [success, setSuccess] = useState(false);
      const [description, setDescription] = useState("");
      const [loading, setLoading] = useState(""); 
      const [country, setCountry] = useState("");
      const [countries, setCountries] = useState([]);
      const [city, setCity] = useState("");
      const [cities, setCities] = useState([]);
      const [delegate, setDelegate] = useState("");
      const [delegates, setDelegates] = useState([]);
      const [locality, setLocality] = useState("");
      const [localities, setLocalities] = useState([]);
      const [types, setTypes] = useState([]);
      const [type, setType] = useState(null);
      const [categories, setCategories] = useState([]);
      const [category, setCategory] = useState([]);
    
      const [annonce, setAnnonce] = useState(null);
      const [otherImages, setOtherImages] = useState([]);
      const [typeImage, setTypeImage] = useState(false);
      const [index, setIndex] = useState([]);
      const [item, setItem] = useState(null);
      const [modal, setModal] = useState(false);
      const [setErrorDel] = useState("");
      const [doneuploadingfile, setDoneuploadingfile] = useState(true);
      const [newPhone, setNewPhone] = useState(true);
      const [phoneNumber, setPhoneNumber] = useState("");
      const [pImageThumb, setpImageThumb] = useState(null);
      const [oldImgP, setoldImgp] = useState(false);
      const [deleting, setDeleting] = useState(false);
      const [uploadingImage, setUploadingImage] = useState(false);
      const [status, setStatus] = useState("");
      const [nom, setNom] = useState("");
      const [subCategories, setSubCategories] = useState([]);
      const [subCategory, setSubCategory] = useState([]);
      const [subSubCategories, setSubSubCategories] = useState([]);
      const [subSubCategory, setSubSubCategory] = useState([]);
      
      const [typesAll, setTypesAll] = useState([]);
      const [section, setSection] = useState(null);
      const [title, setTitle] = useState(null);
      const [id, setId] = useState(null);

      const [imgCount , setImgCount] = useState(0);
      const [imgCountA , setImgCountA] = useState(0);
      const [abortControllers, setAbortControllers] = useState({});
      const [removedfiles, setremovedfiles] = useState([]);
      const [hasChanged , sethasChanged] = useState(false);
      const {articles , setArticles} = useContext(MemberContext);


      useEffect(() => {

        if(annonce && !hasChanged &&
          ((annonce.nom && nom != annonce.nom ) || status != annonce.state 
          || (annonce.price && price != annonce.price)
          || (annonce.subCategoryPetShop && subCategory!=annonce.subCategoryPetShop.id)
          || (annonce.subSubCategoryPetShops && subSubCategory!=annonce.subSubCategoryPetShops.id)
          || (annonce.categoryArticle && category!=annonce.categoryArticle.id )
          || (annonce.typeArticle && type!=annonce.typeArticle.id )  
          || (!annonce.typeArticle && type)  
          || country != annonce.country.id || city != annonce.city.id 
        || delegate != annonce.delegate.id || locality != annonce.locality.id
        || description != annonce.description || (!newPhone && phoneNumber=='' ) || phoneNumber != annonce.phoneNumber
        || pImageThumb || imgCountA > 0  
        )
          )
        {
          sethasChanged(true);
          //console.log(' in use changed : ')
        }
  
      },
     [nom , status , price , subCategory , subSubCategory ,category , type , imgCountA , imgPrincipal , description  ,phoneNumber , newPhone , locality , delegate , city , country]
     );
     

      const history = useHistory();
    
        const handleSubmit = async (e) => {
            e.preventDefault();
            setSubmitted(true);
            if (!doneuploadingfile) {
              alert("not done uploading files yet");
              setLoading(false);
        
              return false;
            } else {
          if (
            type && price  && 
            country && city && delegate && locality &&
            imgPrincipal &&
            (phoneNumber.length==0 || isValidPhoneNumber(phoneNumber))
          ) {
              setLoading(true)

              const watermarkedAlbum = await addWatermarkToAlbum(album);
          

              const formData = new FormData();
              if(category) formData.append("category", category);
              formData.append("type", type);
              formData.append("locality", locality);
              formData.append("description", description);
              formData.append("price", price);
              //formData.append("createdBy", tokenCookie);
              formData.append("state", status);
              formData.append("nom", nom);
              formData.append("section", section);
              if(subCategory) formData.append("subCategory", subCategory);
              if(subSubCategory) formData.append("subSubCategory", subSubCategory);

              if(newPhone)  formData.append("phone", phoneNumber);
              if (pImageThumb) {
                const watermarkedP = await addWatermark(imgPrincipal);
                formData.append("imgPrincipal", watermarkedP);
                formData.append("newimgPrincipal", 'yes');
            }
            else{
              formData.append("newimgPrincipal", 'no');
            }
            for (let index = 0; index < watermarkedAlbum.length; index++) {
              formData.append("album[]", watermarkedAlbum[index]);       
              }         
              //console.log(formData.get("imgPrincipal"));
              apiClient
                  .post("/article/edit/"+id, formData)
                  .then((res) => {
                      //console.log(res);
                      if (res.data.status.toString() === "ok") {
                          setSuccess(true);
                          if(res.data.annonce) { 
                            const updatedAnnonces = [...articles];
                            const elIndex = updatedAnnonces.findIndex(el => el.id === id);
                            if (elIndex !== -1) {
                              updatedAnnonces[elIndex] = res.data.annonce;
                              setArticles(updatedAnnonces);
                            }
                          }
                          setTimeout(() => {
                            history.push("/members/profile/articles");
                          }, 1500);
                      }
                      setLoading(false)
                  })
                  .catch((error) => {
                      //console.log(" errors : ", error);
                      setLoading(false)
                      if (error.response && error.response.status === 401){
                        logoutLocal();
                        window.location.replace("/members/login");
                      }
                  });
          } else {
              window.scrollTo(0, 200);
          }



      }};


      useEffect(() => {

        if (!localStorage.getItem("countries")) {
          axios(`${API_BASE_URL}/countries/all`)
            .then((res) => res.data)
            .then(
              (result) => {
                setCountries(result);
                localStorage.setItem("countries", JSON.stringify(result));
              },
              (error) => {}
            );
        } else {
          setCountries(JSON.parse(localStorage.getItem("countries")));
          if ( props.location.state.country.id !== "0") {
            var countriesF = [];
            countriesF = JSON.parse(localStorage.getItem("countries")).find((city) => city.id.toString() ==  props.location.state.country.id);
            setCities(countriesF.cities);
            if (props.location.state.city.id !== "0") {
              var citiesF = [];
              citiesF = countriesF.cities.find((delegate) => delegate.id.toString() == props.location.state.city.id);
              setDelegates(citiesF.delegations);
              //console.log('citiesF', citiesF);
            }
            if (props.location.state.delegate.id !== "0") {
              var delegatesF = [];
              delegatesF = citiesF.delegations.find((local) => local.id.toString() == props.location.state.delegate.id);
              setLocalities(delegatesF.localities);
              //console.log('delegatesF', delegatesF);
            }
          }
        }

      }, [countries.id]);

      const handleCountryChange = (e) => {
          setCountry(e.target.value);
          let id = e.target.value;
          var countriesF = [];
          //console.log("iddd", id)
          if (id !== "0") {
            countriesF = countries.find((city) => city.id.toString() === id);
            setCities(countriesF.cities);
            //console.log('countriesF', countriesF);
          } else {
            setCity([]);
            //  setModel("");
          }
        };
      
        const handleCityChange = (e) => {
          setCity(e.target.value);
          let id = e.target.value;
          var citiesF = [];
          //console.log("iddd", id)
          if (id !== "0") {
            citiesF = cities.find((delegate) => delegate.id.toString() === id);
            setDelegates(citiesF.delegations);
            //console.log('citiesF', citiesF);
          } else {
            setDelegate([]);
            //  setModel("");
          }
        };
        const handleDelegateChange = (e) => {
          setDelegate(e.target.value);
          let id = e.target.value;
          var delegatesF = [];
          //console.log("iddd", id)
          if (id !== "0") {
            delegatesF = delegates.find((local) => local.id.toString() === id);
            setLocalities(delegatesF.localities);
            //console.log('delegatesF', delegatesF);
          } else {
            setLocality([]);
            //  setModel("");
          }
        };
      


      const getUploadParams = ({ meta }) => {
          return { url: "https://httpbin.org/post" };
      };

      // called every time a file's `status` changes
      const handleChangeStatus = async ({ meta, file }, status) => {

        if (status === 'preparing' && meta.size > 5 * 1024 * 1024) {
          alert(sizeAlert);
          return;
      //file = null;
        }
        setDoneuploadingfile(false);
        let removedfilesx = removedfiles
    
        if (status === 'preparing' && !(meta.size > 5 * 1024 * 1024)) { setImgCountA(imgCountA+1)}     
        if (status.toString() === "done" && !(meta.size > 5 * 1024 * 1024)) {  
          let newalb = album;
          let options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
          };
         const abortController = new AbortController();
          const fileId = meta.id;
          const updatedAbortControllers = { ...abortControllers, [fileId]: abortController };
          setAbortControllers(updatedAbortControllers);
          options.signal = abortController.signal;
    
          try {
            file = await imageCompression(file, options);
          } catch (error) {
            //console.log('erro compress : ',error);
           // return;
          }
             if ((!removedfiles.includes(meta.id) && !removedfilesx.includes(meta.id)) && !(meta.size > 5 * 1024 * 1024)) 
             {
              newalb.push(file);
             // setAlbum(prevAlbum => [...prevAlbum, file]);
             setAlbum(newalb)
              setImgCount(prevCount => prevCount + 1);
        } else {
           //console.log("new album in done else");
        //console.log(newalb.length ,newalb);
       }
          setDoneuploadingfile(true);
        }
       if (status.toString() === "removed" && !(meta.size > 5 * 1024 * 1024)) {
        removedfilesx.push(meta.id);
        setremovedfiles(removedfiles)
      // setremovedfiles(prevA => [...prevA, meta.id]);
          const fileId = meta.id;
          if (abortControllers[fileId]) {
            abortControllers[fileId].abort();
            const updatedAbortControllers = { ...abortControllers };
            delete updatedAbortControllers[fileId];
            setAbortControllers(updatedAbortControllers);
          
          }
    
          let index = album.findIndex((obj) => obj.name === file.name);
          let newAlbum = album;
         { newAlbum.splice(index, 1);
         if(index>-1) {
         if(imgCount>0) setImgCount(newAlbum.length);}
          if(imgCountA>0) setImgCountA(imgCountA-1);
          setAlbum(newAlbum);
         }
          setDoneuploadingfile(true);
    
        } else  setDoneuploadingfile(true);
      };
          
      const handleFileSection = async ( event) => {
        setUploadingImage(true);
        
        let file = event.target.files[0];
        if (file.size > 5 * 1024 * 1024) {
          alert(sizeAlert);
          setUploadingImage(false);    
          event.target.value = '';
          return;
        }
  
        let options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
        };
        try {
          file = await imageCompression(file, options);
        } catch (error) {
          //console.log(error);
        }  
        setoldImgp(false) 
        setpImageThumb(URL.createObjectURL(file));
        setImgPrincipal(file);
        setUploadingImage(false);
  
      };
        
    //  const { id } = props.match.params;

      useEffect(() => {

    if (!localStorage.getItem("typeArticles"))       
        {axios(`${API_BASE_URL}/articles/types`)
        .then((res) => res.data)
        .then(
          (result2) => {
            //console.log("result555 all", result2);
            setTypesAll(result2);

  localStorage.setItem("typeArticles", JSON.stringify(result2));

            if(props.location.state) {
      
             setAnnonce(result)
                 let result = props.location.state
              //console.log("good Plans--", result);
              
        setId(result.id);
        
          //console.log('article ', result)
    
    if (result2 && result2.length>0 && result.typeArticle){ let typesF = [];
    typesF = result2.filter(obj => { return obj.sectionId == result.typeArticle.sectionId })
    setTypes(typesF);
    setCategories(result.typeArticle.categoryArticles);
    } 
    if(!result.typeArticle) setTypes(result2)
    if(result.phoneNumber)  setPhoneNumber(result.phoneNumber)
    if (result.typeArticle) {
    setSection(result.typeArticle.sectionId);
    
      switch (result.typeArticle.sectionId) {
          case 1:
              setTitle('Animalerie')
              break;
              case 2:
                  setTitle('Informatique')
                  break;
                  case 3:
                      setTitle('Smart Home')
                      break;
                  case 4:
              setTitle('Maison et déco')
              break;
              case 5:
              setTitle('Sport et Fitness')
              break;
              case 6:
              setTitle('Auto et Accessoires')
              break;
              case 7:
              setTitle('Shopping')
              break;
              case 8:
              setTitle('AudioVisuel')
              break;
              case 9:
              setTitle('Divers')
              break;
          default:
           break;
      }
    }
          setPrice(result.price);
        if(result.nom)setNom(result.nom);
        if(result.country){
          setCountry(result.country.id)
          //setCities(result.country.cities)
          setCity(result.city.id)
          //setDelegates(result.city.delegations)
          setDelegate(result.delegate.id)
         // setLocalities(result.delegate.localities)
          setLocality(result.locality.id)
        }
        setDescription(result.description)
        if(result.typeArticle){
            setType(result.typeArticle.id)
        }
        if(result.categoryArticle){
            setCategory(result.categoryArticle.id);
          //if(result.categoryArticle.subCategoryPetShops)  setSubCategories(result.categoryArticle.subCategoryPetShops)
        }
    
        if(result.subCategoryPetShop)
        {
          setSubCategory(result.subCategoryPetShop.id)
         // setSubSubCategories(result.subCategoryPetShop.subSubCategoryPetShops)
        }
        if(result.subSubCategoryPetShops){
            setSubSubCategory(result.subSubCategory.id)
        }
    setStatus(result.state);
      
          if (result.mainPicture) {
              setImgPrincipal(`${IMAGES_BASE_URL}/public/uploads/images/${result.typeArticle.section}/${result.mainPicture.name}`)
    setoldImgp(true);        
            
          }
    
    if (result.imageArticles) {
                  setOtherImages(result.imageArticles)
              }
              setIsLoaded(true);
      
    
            }    

          },
          (error) => {
            setError(error);
            setIsLoaded(true);
          }
        );} else {
          setTypesAll(JSON.parse(localStorage.getItem("typeArticles")));

          if(props.location.state) {
    
            let result = props.location.state
          //console.log("good Plans--", result);
            
      setId(result.id);
      
        //console.log('article ', result)
  
  if (JSON.parse(localStorage.getItem("typeArticles"))
   && JSON.parse(localStorage.getItem("typeArticles")).length>0
   && result.typeArticle)
  { 
    let typesF = [];
  typesF = JSON.parse(localStorage.getItem("typeArticles")).filter(obj => { return obj.sectionId == result.typeArticle.sectionId })
  setTypes(typesF);


  var typesF2 = [];
  if (result.typeArticle && result.typeArticle.id != "0") {
    typesF2 = JSON.parse(localStorage.getItem("typeArticles")).find((category) => category.id.toString() == result.typeArticle.id);
    setCategories(typesF2.categoryArticles);
    //console.log('typesF2:',typesF2,typesF2.categoryArticles);
  }

  var typesF3 = [];
  if (result.subCategoryPetShop && result.subCategoryPetShop.id != "0") {
    typesF3 = typesF2.categoryArticles.find((category) => category.id == result.categoryArticle.id);
    //console.log('typesF3',typesF3.subCategoryPetShops);
    setSubCategories(typesF3.subCategoryPetShops);
  }

  var typesF4 = [];
  if (result.subSubCategoryPetShop && result.subSubCategoryPetShop.id != "0") {
    typesF4 = typesF3.subCategoryPetShops.find((category) => category.id.toString() == result.subCategoryPetShop.id);
    setSubSubCategories(typesF4.subSubCategoryPetShops);
    //console.log('typesF4',typesF4);
  }

  }
        setAnnonce(result) 
        if(result.phoneNumber)  setPhoneNumber(result.phoneNumber)
    if(result.typeArticle)   { setSection(result.typeArticle.sectionId);  
    switch (result.typeArticle.sectionId) {
        case 1:
            setTitle('Animalerie')
            break;
            case 2:
                setTitle('Informatique')
                break;
                case 3:
                    setTitle('Smart Home')
                    break;
                case 4:
            setTitle('Maison et déco')
            break;
            case 5:
            setTitle('Sport et Fitness')
            break;
            case 6:
            setTitle('Auto et Accessoires')
            break;
            case 7:
            setTitle('Shopping')
            break;
            case 8:
            setTitle('AudioVisuel')
            break;
            case 9:
            setTitle('Divers')
            break;
        default:
         break;
    }}
    if(!result.typeArticle) setTypes(JSON.parse(localStorage.getItem("typeArticles")))
        setPrice(result.price);
      if(result.nom)setNom(result.nom);
      if(result.country){
        setCountry(result.country.id)
       // setCities(result.country.cities)
        setCity(result.city.id)
       // setDelegates(result.city.delegations)
        setDelegate(result.delegate.id)
       // setLocalities(result.delegate.localities)
        setLocality(result.locality.id)
      }
      setDescription(result.description)
      if(result.typeArticle){
          setType(result.typeArticle.id)
      }
      if(result.categoryArticle){
          setCategory(result.categoryArticle.id);
      }
  
      if(result.subCategoryPetShop)
      {
        setSubCategory(result.subCategoryPetShop.id)
       
      }
      if(result.subSubCategoryPetShop){
          setSubSubCategory(result.subSubCategoryPetShop.id)
      }
  setStatus(result.state);
    
        if (result.mainPicture) {
            setImgPrincipal(`${IMAGES_BASE_URL}/public/uploads/images/${result.typeArticle ? result.typeArticle.section :'audiovisuel'}/${result.mainPicture.name}`)
  setoldImgp(true);        
          
        }
  
  if (result.imageArticles) {
                setOtherImages(result.imageArticles)
            }
            setIsLoaded(true);
    
  
          }    
  
        }      

      }, []
      //[annonce.id]
      );
      const toggle = (id, type, i) => {
          setModal(!modal);
          setItem(id)
          setTypeImage(type)
          setIndex(i)
      };
      function deleteImageArticle(idIm, index) {
    setDeleting(true);
          //console.log("id", idIm, index)

          apiClient
              .delete(`/article/delete_image/` + idIm )
              .then((res) => {
                  toggle();
                  if (typeImage) {
                      setImgPrincipal(false);
          setoldImgp(false);

                  }
                  otherImages.splice(index, 1);
                  setOtherImages(otherImages);
                  if (res.data.status.toString() === "ok") {
                      setOtherImages((items) => items.filter((x) => x.idIm !== idIm));
                      //console.log('okkd', otherImages)
                  }
                  if (
                      res.data.status.toString() ===
                      "ForeignKeyConstraintViolationException"
                  ) {
                      setErrorDel(
                          "Vous ne pouvez pas supprimer cette image"
                      );
                  }
                  setDeleting(false);
                })
                .catch((err) => {
                  //console.log(err);
                  setDeleting(false);})
      }

          const handleTypeChange = (e) => {
        setType(e.target.value);
        setSubCategories([]);
        setSubCategory("");
        setSubSubCategories([]);
        setSubSubCategory("");
        
        let id = e.target.value;
        var typesF = [];
        if (id !== "0") {
          typesF = types.find((category) => category.id.toString() === id);
          setCategories(typesF.categoryArticles);
          //console.log(typesF);
        } else {
          setCategories([]);
          setCategory("");
        }
      };
      const handleCategoryChange = (e) => {
        setCategory(e.target.value);
        setSubSubCategories([]);
        setSubSubCategory("");
        let id = e.target.value;
        var categoryF = [];
        if (id !== "0") {
          categoryF = categories.find((subCategory) => subCategory.id.toString() === id);
          setSubCategories(categoryF.subCategoryPetShops);
          //console.log(categoryF);
        } else {
          setSubCategories([]);
          setSubCategory("");
        }
    
      };
    
      const handleSubCategoryChange = (e) => {
        setSubCategory(e.target.value);
        let id = e.target.value;
        var subCategoryF = [];
        if (id !== "0") {
          subCategoryF = subCategories.find((subCategory) => subCategory.id.toString() === id);
          setSubSubCategories(subCategoryF.subSubCategoryPetShops);
          //console.log(subCategoryF);
        } else {
          setSubSubCategories([]);
          setSubSubCategory("");
        }
      };
      const handleSubSubCategoryChange = (e) => {
        setSubSubCategory(e.target.value);
        let id = e.target.value;
     
      };

      const changePhoneNumber = (event) => {
        //console.log(event.target.checked);
        if(event.target.checked) {setNewPhone(false);setPhoneNumber("");}
        else {setNewPhone(true);
        }
      }
    

      if (error) {
          return (
              <div className="container">
                  <main className="l-main">
                      <div className="l-section l-section--archive">
                          <div>Erreur : {error.message}</div>
                      </div>
                  </main>
              </div>
          );
      } else if (!isLoaded) {
          return (
           <Loading/>
          );
      } else {
          return (
            <div className="container addingPage editPage">
            <div className="information-title">
                  <h2>modifier {title}</h2> <CBadge color={getBadge(props.location.state.status)}>
                  {textBadge(props.location.state.status)} 
                          </CBadge>
              <span className="snSpan snSpanE ">SN: {props.location.state.sn} </span> 
                </div>
                  <form onSubmit={handleSubmit} id="form-add">
                     
                      <div className="advInfoSzctionCard" >
       <Categori name={title} />
                      <CRow>
            <CCol>
              {" "}
              <CLabel>Etat </CLabel> 
              <>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                     checked={status}
                      name="status"                     
                      onChange={(e) => setStatus(1)}
                      
                    />
                    <CLabel variant="checkbox" htmlFor="neuf">
                      Neuf
                    </CLabel>
                  </CFormGroup>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      checked={!status}
                      name="status"                     
                      onChange={(e) => setStatus(0)}
                    />
                    <CLabel variant="checkbox" htmlFor="occasion">
                      Occasion
                    </CLabel>
                  </CFormGroup>
                </>
            </CCol>
          </CRow><br />

                      <CRow xs="12">   
                      <CCol xs="6">
                  <CFormGroup>
                    <CLabel htmlFor="type">Type </CLabel>
                    <CSelect
                      name="type"
                      value={type}
                      onChange={(e) => handleTypeChange(e)}
                    >
                      <option value="0"> Choisir le type</option>
                      {types && types.map((type) => {
                        return (
                          <option key={type.id} value={type.id}>
                            {type.label}
                          </option>
                        );
                      })}
                    </CSelect>
                    {submitted && !type && (
                      <span className="invalid-feedback error">
                        Veuillez choisir le type 
                      </span>
                    )}
                  </CFormGroup>
                </CCol>
                {categories && categories.length >0 && <CCol xs="6">
                  <CFormGroup>
                    <CLabel htmlFor="category">Categorie</CLabel>
                    <CSelect
                      name="category"
                      value={category}
                      onChange={(e) => handleCategoryChange(e)}
                    >
                      <option value="0"> Choisir la categorie </option>
                      {categories && categories.map((category) => {
                        return (
                          <option key={category.id} value={category.id}>
                            {category.label}
                          </option>
                        );
                      })}
                    </CSelect>
                    {submitted && !category && (
                      <span className="invalid-feedback error">
                        Veuillez choisir le categorie 
                      </span>
                    )}
                  </CFormGroup>
                </CCol>}
          
               
  {subCategories && subCategories.length > 0 ?
     <CCol xs="6"><CFormGroup>
      <CLabel htmlFor="subCategory">Sous Categorie</CLabel>
      <CSelect
        name="subCategory"
        value={subCategory}
        onChange={(e) => handleSubCategoryChange(e)}
      >
        <option value="0"> Choisir la sous categorie </option>
        {subCategories.map((subCategory) => {
          return (
            <option key={subCategory.id} value={subCategory.id}>
              {subCategory.label}
            </option>
          );
        })}
      </CSelect>
      {submitted && !subCategory && (
        <span className="invalid-feedback error">
          Veuillez choisir le sous categorie
        </span>
      )}
    </CFormGroup></CCol>
    :
    null
  }

{subSubCategories && subSubCategories.length > 0 ?
  <CCol xs="6">

    <CFormGroup>
      <CLabel htmlFor="subSubCategory">Sous sous Categorie</CLabel>
      <CSelect
        name="subSubCategory"
        value={subSubCategory}
        onChange={(e) => handleSubSubCategoryChange(e)}
      >
        <option value="0"> Choisir la sous categorie </option>
        {subSubCategories.map((subSubCategory) => {
          return (
            <option key={subSubCategory.id} value={subSubCategory.id}>
              {subSubCategory.label}
            </option>
          );
        })}
      </CSelect>
      {submitted && !subSubCategory && (
        <span className="invalid-feedback error">
          Veuillez choisir le sous categorie
        </span>
      )}
    </CFormGroup>


  </CCol>
  : null}
  

  </CRow>
  <Scroll/>
         </div>
         <div className="advInfoSzctionCard" >
        <Basic/>
                      <CRow xs="12">
                      <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="nom"> Nom du produit</CLabel>
                <CInput
                  type="text"
                  name="nom"
                  value={nom}
                  onChange={(e) => setNom(e.target.value)}
                ></CInput>
              </CFormGroup>
                     </CCol>
                          <CCol xs="6">
                              <CFormGroup>
                                  <CLabel htmlFor="price">Prix en TND</CLabel>
                                  <CInput
                                      type="number"
                                      name="price"
                                      value={price}
                                      onChange={(e) => setPrice(e.target.value)}
                                  ></CInput>
                                  {submitted && !price  && (
                                      <span className="invalid-feedback error">
                                          Obligatoire
                                      </span>
                                  )}
                              </CFormGroup>
                          </CCol>

                      
                    
                      </CRow>
                      <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >
          <Adresse/>                   
                <CRow xs="12">
                  <CCol xs="6">
                    <CFormGroup>
                      <CLabel htmlFor="country">Pays</CLabel>
                      <CSelect
                        name="country"
                        value={country}
                        onChange={(e) => handleCountryChange(e)}
                      >
                        <option value="0"> Choisissez un pays</option>
                        {countries && countries.map((country) => {
                          return (
                            <option key={country.id} value={country.id}>
                              {country.label}
                            </option>
                          );
                        })}
                      </CSelect>
                      {submitted && !country && (
                        <span className="invalid-feedback error">
                          Obligatoire
                        </span>
                      )}
                    </CFormGroup>
                  </CCol>
                  <CCol xs="6">
                    <CFormGroup>
                      <CLabel htmlFor="Région">Gouvernorat</CLabel>
                      <CSelect
                        name="city"
                        value={city}
                        onChange={(e) => handleCityChange(e)}
                      >
                        <option value="0"> Gouvernorat</option>
                        {cities && cities.map((city) => {
                          return (
                            <option key={city.id} value={city.id}>
                              {city.label}
                            </option>
                          );
                        })}
                      </CSelect>
                      {submitted && !city && (
                        <span className="invalid-feedback error">
                          Obligatoire
                        </span>
                      )}
                    </CFormGroup>
                  </CCol>
                </CRow>
                <CRow xs="12">
                  <CCol xs="6">
                    <CFormGroup>
                      <CLabel htmlFor="delegate">Délégation</CLabel>
                      <CSelect
                        name="delegate"
                        value={delegate}
                        onChange={(e) => handleDelegateChange(e)}
                      >
                        <option value="0"> Choisissez une délégation</option>
                        {delegates && delegates.map((delegate) => {
                          return (
                            <option key={delegate.id} value={delegate.id}>
                              {delegate.label}
                            </option>
                          );
                        })}
                      </CSelect>
                      {submitted && !delegate && (
                        <span className="invalid-feedback error">
                          Obligatoire
                        </span>
                      )}
                    </CFormGroup>
                  </CCol>
                  <CCol xs="6">
                    <CFormGroup>
                      <CLabel htmlFor="loclité">Localité</CLabel>
                      <CSelect
                        name="locality"
                        value={locality}
                        onChange={(e) => setLocality(e.target.value)}
                      >
                        <option value="0"> Localité</option>
                        {localities && localities.map((local) => {
                          return (
                            <option key={local.id} value={local.id}>
                              {local.label}
                            </option>
                          );
                        })}
                      </CSelect>
                      {submitted && !locality && (
                        <span className="invalid-feedback error">
                          Obligatoire
                        </span>
                      )}
                    </CFormGroup>
                  </CCol>
                </CRow>
                <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >
       <Contact/>
                <CRow xs="12">
            <CCol xs="6">
              {newPhone && <CFormGroup>
                <CLabel htmlFor="phoneNumber">Numéro téléphone</CLabel>
                <PhoneInput
                  type="text"
                  onChange={(value) => setPhoneNumber(value)}
                  placeholder="Entrez un numéro de téléphone"
                  defaultCountry="TN"
                  international
                  name="phoneNumber"
                  id="phoneNumber"
                  value={phoneNumber}
                />
                  {submitted && newPhone && phoneNumber.length>0 && !isValidPhoneNumber(phoneNumber) && (
                  <span className="invalid-feedback error">Ce numéro de téléphone n'est pas valide</span>
                )}
              </CFormGroup>}
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  onChange={changePhoneNumber}                 
                />
                <CLabel variant="checkbox" className="form-check-label">
                Utilisez le numéro téléphone de votre compte
                </CLabel>
              </CFormGroup> 
            </CCol>
            </CRow>
            <Scroll/>
         </div> 

         <div className="advInfoSzctionCard" >
     <Extra/>
                      <CRow>
                          <CCol>
                              <CFormGroup>
                                  <CLabel htmlFor="description">Description</CLabel>
                                  <CKEditor
                                      editor={ClassicEditor}
                                      data={annonce.description}
                                      onChange={(event, editor) => {
                                          const data = editor.getData();
                                          setDescription(data)
                                      }}
                                  />


                                  {submitted && !description  && (
                                      <span className="invalid-feedback error">Obligatoire</span>
                                  )}
                              </CFormGroup>
                          </CCol>
                      </CRow>
                      <Scroll/>
         </div> 

         <div className="advInfoSzctionCard" >

<Imgs/>

          <CRow>
            <CCol>
            <CFormGroup style={{display:"inline-block"}}>
                <CLabel htmlFor="pImage">Principal image</CLabel>
                <input 
                          type="file"
                          name="pImage"
                          onChange={handleFileSection}
                          accept="image/*"
                           ></input>
            </CFormGroup>
            {uploadingImage ?
            <span style={{height:"fit-content",marginLeft:"10px",lineHeight:"90px"}}>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>
       : !uploadingImage && oldImgP ? 
        <img style={{height: "85px", width: "auto"}}
          src={imgPrincipal}
          alt="preview"
          className="img-fluid img-thumbnail "
        />
       : !uploadingImage && pImageThumb ? 
        <img style={{height: "85px", width: "auto"}}
          src={pImageThumb}
          alt="preview"
          className="img-fluid img-thumbnail "
        /> : ''}
    
           {submitted && !imgPrincipal && (
                <span className="invalid-feedback error">
                  Veuillez uploader une image
                </span>
              )}
            </CCol>
          </CRow><br/>


          {otherImages.length > 0 && 
            <>
              <CLabel htmlFor="pImage">Autres images</CLabel>
                              {otherImages.map((other, i) => {

                                  return (

                                      <span key={i} className="img-thumbnail-others">
                                          {!other.principal ?
                                              <>

                                                  <img
                                                      src={`${IMAGES_BASE_URL}/public/uploads/images/${annonce.typeArticle ? annonce.typeArticle.section :'audiovisuel'}/${other.name}`}
                                                      width="100"
                                                      className="img-fluid img-thumbnail "
                                                      alt=""
                                                  />
                                                  <CButton onClick={(e) => toggle(other.id, false, i)}><CIcon name="cilTrash" /></CButton>

                                              </>

                                              : null}
                                      </span>

                                  );

                              })
                              }
                              </>
           }
      <br/><br/>
      {imgCountA>0 && <> <div className="imgCounter"> {imgCount} {imgCount > 1 ? ' images téléchargées sur ' :' image téléchargée sur ' }  {imgCountA} </div>
       <div className="loading-bar">
          <div className="loading-bar-fill" style={{ width: `${imgCountA==0?0 :(imgCount / imgCountA) * 100}%` }}></div>
        </div><br/></>}
          <CLabel >ajouter des images</CLabel> 
                      <CRow>
                          <CCol>
                              <Dropzone
                                 // getUploadParams={getUploadParams}
                                  onChangeStatus={handleChangeStatus}
                                  // onSubmit={handleSubmitF}
                                  accept="image/*"
                                  maxFiles={10 - otherImages.length}
                                  maxSizeBytes={5 * 1024 * 1024}
                              >
                                  <input />
                              </Dropzone>
                           </CCol>
                      </CRow>
                      <br />
                      </div>
                      {success && (
                          <CAlert
                              show={true}
                              // autohide="5000"
                              // autohide={false}
                              placement="top-end"
                              color="success"
                          >
                              {`L'article a été modifiée avec succès !`}
                          </CAlert>
                      )}

                      <CRow>
                          <CCol md="3" className="offset-9">
                          {
       ((!(doneuploadingfile && imgCountA == album.length)) || uploadingImage)?
               (
              <CButton color="primary" block disabled>
                <span>
                  {" "}
                  <CSpinner color="default" size="sm" />
                </span>

                <span> chargement des images</span>
              </CButton>
            )

       :  loading ? (
              <CButton color="primary" block disabled>
                <span>
                  {" "}
                  <CSpinner color="default" size="sm" />
                </span>

                <span> Valider</span>
              </CButton>
            )  
            :
              <CButton color="primary" block 
               disabled={!hasChanged}
              type="submit">
                Valider
              </CButton>
            
}

                          </CCol>
                      </CRow>
<br/>



                  </form>

                  <CModal show={modal} onClose={toggle}>
                                <CModalHeader closeButton></CModalHeader>
                                <CModalBody>
                                voulez vous vraiment supprimer cette image?
                                
                                </CModalBody>
                                <CModalFooter>

                                  {!deleting && <CButton
              color="primary"
              onClick={() => {
                deleteImageArticle(item, index);
              }}
            >
              Oui
            </CButton>}
            {deleting && <CButton
              color="primary"           
            >
             <span>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>
              Oui
            </CButton>}

                                  <CButton color="secondary" onClick={toggle}>
                                    Cancel
                                  </CButton>
                                </CModalFooter>
                              </CModal>
              </div>
          );
      }
  }
