import React from "react";

function Support (){

    return (
        <h2 style={{textAlign:'center'}}>
            coming soon 
        </h2>
    )

}
export default Support;