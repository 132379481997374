import React, { Component , useContext } from "react";
import { Link } from "react-router-dom";
import "../assets/css/style.css";
import CIcon from "@coreui/icons-react";
import {CSpinner} from "@coreui/react";
import NavItem from "./NavItem";
import { API_BASE_URL, IMAGES_BASE_URL } from "../config";
import axios from "axios";
import { withRouter } from 'react-router-dom';
//import { connect } from "react-redux";
//import * as actions from "../components/FrontOffice/reducers/vehicle/vehicleActions";
import { logout, isLogin, isGranted } from "../components/middleware/auth";
import "./Header.css";
import FavoriteContext from "src/components/FrontOffice/FavoriteContext";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSection:'',
      navLinkCss:'c-nav__link',
      favCount:null,
      activeSubMenuMob:null,
      showSubMenuMob: false,
      showProfileMenu: false,
      showSticky: false,
      isHovered: false,
      disabled: false,
      mobileMenu: false,
      categoriesReportage: [],
      categories2MConso: [],
      isLogin: false,
      term:'',
      searchResult:null,
      searchResultFirst3:[],
      searching : false,
      visible:false,
    };
    this.elementRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.bigMenuRef = React.createRef();
    this.bigMenuButtonRef = React.createRef();
    this.handleClickOutsideMenu = this.handleClickOutsideMenu.bind(this);
    this.handleRouteChange = this.handleRouteChange.bind(this);
  }
  
  handleRouteChange() {
  //   console.log("this.props.location in RouteChange" , this.props.history.location.pathname
  //   ,
  //   !this.props.history.location.pathname.includes('/login')
  //   && !this.props.history.location.pathname.includes('/register')
  // )
   if(!this.props.history.location.pathname.includes('/login')
   && !this.props.history.location.pathname.includes('/register'))    
   {    if( document.getElementsByClassName("grecaptcha-badge")[0]) document.getElementsByClassName("grecaptcha-badge")[0].style.display = "none";}
   else { if( document.getElementsByClassName("grecaptcha-badge")[0]) document.getElementsByClassName("grecaptcha-badge")[0].style.display = "block";}
    
   this.setState({
      mobileMenu: false,
      visible:false,
    });
   // console.log('props route : ',typeof this.props.history.location.pathname ,this.props.history.location.pathname);
    this.setState({currentSection:this.props.history.location.pathname});
  }
  handleMobileMenu = () => {
    this.setState((prevState) => ({
      mobileMenu: !prevState.mobileMenu,
    }));
  };
  handleHoverMenu = (e) => {
    this.setState((prevState) => ({
      isHovered: !prevState.isHovered,
      // hoverReportage: !prevState.hoverReportage,
      disabled: !prevState.disabled,
    }));
  };
  handleSubMenu = (idMenu,idButton)=>{
    const list = document.getElementById('menuMain-topicsMob');
    const buttons =  [...list.getElementsByTagName('button')];
    const clickedButton = document.getElementById(idButton);
    const buttonClasses = clickedButton.classList.value;
    //console.log("Classes List:",typeof buttons, buttons);

    buttons.forEach(element => { 
    //console.log("Id List:", Array.from(element.classList));
     if(element.id==idButton) {
      if(Array.from(element.classList).includes('c-nav__link--active'))
      {
        element.classList.remove('c-nav__link--active');
        this.setState({  
          activeSubMenuMob:null,
      }
      );
      } else {
        element.classList.add('c-nav__link--active');
        this.setState({  
          activeSubMenuMob:idMenu,
      }
      );
      }
     }
      else {
     element.classList.remove('c-nav__link--active');
    }
    });
  }

  handleLogout = () => {
    logout();
    this.setState({ isLogin: false });
  };
 
  search = (type) => {
    //console.log('typppppppe');
    //console.log(type);
    this.setState({searchResult : null , searchResultFirst3:[] }); 
    if(this.state.term.trim()){
      this.setState({visible : true });
      this.setState({searching : true });
    //console.log('ssssssearch',this.state.term);
    const formData = new FormData();
    formData.append("term", this.state.term);
    axios
    .post(API_BASE_URL + "/searchAll", formData)
    .then((res) => {
        //console.log(res);
        //console.log(res.data);
        let result3 = [];
      
        for (let i = 0; i < res.data.goodPlans.length + res.data.members.length + res.data.articles.length + res.data.vehicles.length + res.data.realEstate.length
          + res.data.animals.length + res.data.services.length
          + res.data.jobs.length + res.data.news.length; i++) {
          for (const [key, value] of Object.entries(res.data)) {
            // Check if the array has an element at the current index
            //console.log('before if',i,result3);
            if (result3.length<3){ 
              if (value[i]) {
                // Add the element to the result array
                result3.push({key:key,value:value[i]});
                //console.log('inside if',key);
              } else {}
            }
            else break;
          }
        }

        //console.log('result3');
        //console.log(result3);


          this.setState({searchResult : res.data , searchResultFirst3:result3 });     
      this.setState({searching : false });
    })
    .catch((error) => {
        console.log(" errors : ", error);
      this.setState({searching : false });
    });
  }
}
  

handlePageChange = () => {
  //console.log('ccccccclicked')
  this.setState({ term: '' , searchResult : null , searchResultFirst3:[]  });
};

  componentDidMount() {

  //   console.log("this.props.location in componentDidMount" , this.props.history.location.pathname
  //   ,!this.props.history.location.pathname.includes('/login')
  //   && !this.props.history.location.pathname.includes('/register')
  // )
   if(!this.props.history.location.pathname.includes('/login')
   && !this.props.history.location.pathname.includes('/register'))    
   {
    if( document.getElementsByClassName("grecaptcha-badge")[0]) document.getElementsByClassName("grecaptcha-badge")[0].style.display = "none";}
   else { if( document.getElementsByClassName("grecaptcha-badge")[0]) document.getElementsByClassName("grecaptcha-badge")[0].style.display = "block";}
    
    this.unlisten = this.props.history.listen(this.handleRouteChange);
    document.addEventListener('click', this.handleClickOutside);
    document.addEventListener('click', this.handleClickOutsideMenu);
    
    if (isGranted(["ROLE_MEMBER"]) & isLogin()) {
      this.setState({ isLogin: true });
    }

    const handleScroll = () => {
      const header = document.querySelector(".mysticky");
      const headerMob = document.querySelector(".onTopMob");
      const menuMob = document.querySelector(".l-panel--more");
      if (header) {
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop === 0) {
          header.classList.add("hidden");
          this.setState({ showSticky:false });
        } else {
          header.classList.remove("hidden");
          this.setState({ showSticky: true });
        }
      }
     
      if (headerMob) {
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop < 30) { 
          headerMob.classList.remove("stickyMob");        
          menuMob.classList.remove("scrolledMob");        
        } else {
          headerMob.classList.add("stickyMob");
          menuMob.classList.add("scrolledMob");
        }
      }

    };


    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

  }

  componentWillUnmount() {
    // Remove the event listener when the component unmounts
    document.removeEventListener('click', this.handleClickOutside);
    document.removeEventListener('click', this.handleClickOutsideMenu);
    this.unlisten();
  }

  handleClickOutside(event) {
    // Check if the clicked element is outside the component
    if (
      this.elementRef.current &&
      !this.elementRef.current.contains(event.target)
    ) {
      // Clicked outside the component, so hide it
      this.setState({visible:false,term:''})};
    }
  
    handleClickOutsideMenu(event) {
      // Check if the clicked element is outside the component
      if (
        this.bigMenuRef.current &&
        !this.bigMenuRef.current.contains(event.target) &&
        this.bigMenuButtonRef.current &&
        !this.bigMenuButtonRef.current.contains(event.target)
      )  {
        // Clicked outside the component, so hide it
        this.setState({mobileMenu:false})};
      }

  render() {
    const mobileMenuCss = !this.state.mobileMenu
      ? "l-panel l-panel--more l-panel--aboveNav is-slid-and-faded-out"
      : "l-panel l-panel--more l-panel--aboveNav l-panel--init is-slid-and-faded-in";
    const btnToggleMobile = !this.state.mobileMenu
      ? "c-nav__item "
      : "c-nav__item is-toggled c-nav__item--active";
    const loginLogout = this.state.isLogin ? (
      <>
        <div className="c-avatar" style={{justifyContent:'flex-end',width:'max-content',display:'inline-block'}}>
        
        <div  onMouseEnter={() => this.setState({showProfileMenu:true})}
          onMouseLeave={() => this.setState({showProfileMenu:false})}>
        {this.state.showProfileMenu  && <div 
         className="profileDropDown" >
        <ul className="dropdown searchResultList ">
                    <li className="searchResultItem" >
                         <Link to="/members/profile" 
                         > 
                         Mon Profile
                                     </Link> 
                                  </li>
                                  <li className="searchResultItem" >
                                  <Link
          onClick={() => this.handleLogout()}
          to="/"
        >
          Déconnexion
        </Link>
                                  </li>      
                  </ul>
        </div>}
      
        <span className="userNameSpan"> Hi {localStorage.getItem("prenom") ? ','+localStorage.getItem("prenom")+' ' :
        localStorage.getItem("name") ? ','+localStorage.getItem("name")+' ' 
        :''}</span>
          <Link to="/members/profile" className="avatarLink"
          >
            <img
              className="c-avatar-img"
              src={
                localStorage.getItem("avatar") !== "undefined"
                  ? `${IMAGES_BASE_URL}/public/uploads/members/${localStorage.getItem(
                      "avatar"
                    )}`
                  : `${IMAGES_BASE_URL}/public/uploads/members/default_avatar.jpg`
              }
              alt="avatar"
            />
          </Link>     
         </div>  </div>
      </>
    ) : (
      <>
        <div className="c-avatar " style={{height:'auto',justifyContent:'flex-end',width:'max-content',display:'inline-block'}}>
        <Link
         className="text-white mesFavoris"
          to="/members/login"
        >
          Connexion <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-in"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" fill="white"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line></svg>
        </Link>
        </div>
      </>
    );
    const loginLogoutMobFooter = this.state.isLogin ? (
      <>
         
           <Link
           className="text-white mesFavoris loginLogoutMobMenu"
          onClick={() => this.handleLogout()}
          to="/"
        >
         <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" fill="white"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
        </Link>
       
      </>
    ) : (
      <>
      
        <Link
        className="text-white mesFavoris loginLogoutMobMenu"
          to="/members/login"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-in"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" fill="white"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line></svg>
        </Link>
   
      </>
    );

    return (
      <FavoriteContext.Consumer>
        {(context) => {
          const { favorites } = context;

    return (
      <>
        <div className="sticky mysticky">
          <section className="l-navbar" aria-label="Main website navigation">
            <div className="l-navbar__top container">
              <div className="l-navbar__inner">
                <div
                  id="menuMain"
                  className="l-navbar__standard is-slid-and-faded-out"
                >
                 <Link to="/" className="l-navbar__logo sticky_logo">
                    <img
                      className="l-navbar__morty"
                      src={
                        "assets/images/logos/retina white.svg"
                      }
                      alt=""
                    />
                  </Link> 

                  <nav className="l-navbar__topics">
                    <div className="l-navbar__track">
                      <ul
                        id="menuMain-topics"
                        className="c-nav c-nav--main l-navbar__list"
                      >

                  {/* <li className="c-nav__item">
                   
                          <Link to="/" className="l-navbar__logo sticky_logo">
                    <img
                      className="l-navbar__morty"
                      src={
                        "assets/images/logos/retina white.svg"
                      }
                      alt=""
                    />
                  </Link>
                        </li> */}

                        <li className="c-nav__item">
                          <NavItem name="Highlight" />
                          {/* <ul className="dropdown">
                            {this.state.categoriesReportage.length > 1 &&
                              this.state.categoriesReportage.map((category) => {
                                return (
                                  <li key={category.id}>
                                    <Link
                                      to={`/news/${category.id}/${category.slug}`}
                                    >
                                      {category.label}
                                    </Link>
                                  </li>
                                );
                              })}                 
                          </ul> */}
                          <ul className="dropdown">
                            <li>
                              <Link to={`/news/1/economie`}>Économie </Link>
                            </li>
                            <li>
                              <Link to={`/news/2/sport`}>Sport </Link>
                            </li>
                            <li>
                              <Link to={`/news/3/sante`}>Santé </Link>
                            </li>
                            <li>
                              <Link to={`/news/4/social`}>Social </Link>
                            </li>
                            <li>
                              <Link to={`/news/12/space`}>Space </Link>
                            </li>
                            <li>
                              <Link to={`/news/13/technolgie`}>Technolgie </Link>
                            </li>
                          </ul>
                        </li>

                        <li className="c-nav__item">
                          <NavItem name="Emploi" />
                          <ul className="dropdown">
                            <li>
                              <Link to="/jobs/sectors"> Trouver un emploi</Link>
                            </li>
                            <li>
                              <Link to="/jobs/publish-job-offer">
                                Publier une offre d'emploi
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="c-nav__item">
                          <NavItem name="Bon plans" />
                          <ul className="dropdown">
                            <li>
                              <Link to="/bon-plans">
                                {" "}
                                Trouver un bon plans{" "}
                              </Link>
                            </li>
                            <li>
                              <Link to="/bon-plans/add">
                                Publier Une Annonce
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="c-nav__item">
                          <NavItem name="Immobilier" />
                          <ul className="dropdown">
                            <li>
                              <Link to="/real-estates">
                                {" "}
                                Trouver un Immoblier{" "}
                              </Link>
                            </li>
                            <li>
                              <Link to="/real-estates/add">
                                Publier Une Annonce
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="c-nav__item">
                          <NavItem name="Véhicules" />
                          <ul className="dropdown">
                            <li>
                              <Link to="/vehicles"> Trouver un véhicule</Link>
                            </li>
                            <li>
                              <Link to="/vehicles/add">
                                Publier une annonce
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="c-nav__item">
                          <NavItem name="Petite annonce" />
                          <ul className="dropdown" id="dd">
                            <li>
                              <Link to="#">Animaux</Link>
                              <ul>
                                <li>
                                  <Link to="/animals">Trouver un animal</Link>
                                </li>
                                <li>
                                  <Link to="/animals/add">
                                    Publier Une Annonce
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <Link to="#">Articles</Link>
                              <ul>
                                <li>
                                  <Link to="/articles">Trouver un article</Link>
                                </li>
                                <li>
                                  <Link to="/articles/add">
                                    Publier Une Annonce
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li className="lastChild">
                              <Link to="#">Services</Link>
                              <ul>
                                <li>
                                  <Link to="/services">Trouver un service</Link>
                                </li>
                                <li>
                                  <Link to="/services/add">
                                    Publier Une Annonce
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li className="c-nav__item">
                          <NavItem name="12 M conso" />
                          <ul className="dropdown">
                            <li>
                              <Link to={`/news/5/agroalimentaire`}>
                                Agroalimentaire
                              </Link>
                            </li>
                            <li>
                              <Link to={`/news/6/consommation`}>
                                Consommation
                              </Link>
                            </li>
                            <li>
                              <Link to={`/news/7/environment`}>
                                Environment
                              </Link>
                            </li>
                            <li>
                              <Link to={`/news/8/culture`}>Culture</Link>
                            </li>
                            <li>
                              <Link to={`/news/9/logement-et-transport`}>
                                Logement et transport
                              </Link>
                            </li>
                            <li>
                              <Link to={`/news/10/fake-news`}>Fake news</Link>
                            </li>
                            <li>
                                  <Link to={`/news/14/education`}>
                                  Education
                                  </Link>
                                </li>
                          </ul>
                        </li>
                        {/* <li className="stickyFloatRight">{loginLogout}</li> */}
                      </ul>
                    </div>
                  </nav>
                  <div className="stickyFloatRight"> 
                  {this.state.showSticky && loginLogout}
                  </div>

                </div>
              </div>
            </div>{" "}
          </section>
          {/* <div className="l-navbar__submenus">
        <section id="menuMore" className="mm">
          <div className="l-panel__inner">
            <div className="row">nnnnnnnnn</div>
          </div>
        </section>
      </div> */}
        </div>

        <div className="l-container ">
          <header className="l-header">
            <div className="l-header__inner container">
              <div className="mesFavorisWrapper">
                <Link to="/members/profile/mes-favoris" className="text-white mesFavoris">
                  {" "}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="var(--ci-primary-color, currentColor)" d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"></path></svg> {favorites && favorites.length}
                  {/* {this.props.favorites.length > 0 &&
                    this.props.favorites.length} */}
                </Link>
              </div>

              <div className="searchWrapperOutter">
              <div className="searchWrapper" ref={this.elementRef}>

<div >
{this.state.visible && <div className="searchResultList" style={this.state.visible?{display:'block',textAlign:'center'}:{display:'none',textAlign:'center'}}>
<button style={{width:'30px',height:'30px'}} className="close" aria-label="Close" onClick={() => this.setState({visible:false,term:''})}>×</button>
{this.state.searching ? <CSpinner color="default" size="sm" /> 
:this.state.searchResult && this.state.searchResultFirst3.length>0?
<>
<ul className="dropdown searchResultItemUL">
 { this.state.searchResultFirst3.map((el) => {
                  return (
                    <li className="searchResultItem" key={el.value.id}>
                         <Link 
                         to = {{ pathname:
                          el.key=='goodPlans'?`/bon-plans/details/${el.value.id}`
                         : el.key=='members'?`/memberAnnonces/${el.value.prenom?el.value.prenom+'-'+el.value.nom+'-'+el.value.id :el.value.nom+'-'+el.value.id}`
                         : el.key == 'articles' ? `/articles/details/${el.value.id}`
                         : el.key == 'vehicles' ? `/vehicles/${el.value.type}/details/${el.value.id}`
                         : el.key == 'realEstate' ? `/real-estates/details/${el.value.id}`
                         : el.key == 'animals' ? `/animals/details/${el.value.id}`
                         : el.key == 'services' ? `/services/details/${el.value.id}`
                         : el.key == 'jobs' ? `/jobs/job-details/${el.value.id}/${el.value.sector.slug}/${el.value.slug}`
                         : el.key == 'news' && el.value.category ? `/news/${el.value.id}-${el.value.category.id}/${el.value.category.slug}/${el.value.slug}`                            
                         :'',
                              state:el.value,}}
                        //  to={el.key=='goodPlans'?`/bon-plans/details/${el.value.id}`
                        //  : el.key == 'articles' ? `/articles/details/${el.value.id}`
                        //  : el.key == 'vehicles' ? `/vehicles/${el.value.type}/details/${el.value.id}`
                        //  : el.key == 'realEstate' ? `/real-estates/details/${el.value.id}`
                        //  : el.key == 'animals' ? `/animals/details/${el.value.id}`
                        //  : el.key == 'services' ? `/services/details/${el.value.id}`
                        //  : el.key == 'jobs' ? `/jobs/job-details/${el.value.id}/${el.value.sector.slug}/${el.value.slug}`
                        //  : el.key == 'news' && el.value.category ? `/news/${el.value.id}-${el.value.category.id}/${el.value.category.slug}/${el.value.slug}`                            
                        //  :''} 
                         > 
                    {
                      el.value.prenom && el.value.prenom.length>0 &&
                             el.value.nom && el.value.nom.length>0 ? el.value.prenom + ' ' + el.value.nom 
                    :el.value.nom && el.value.nom.length>0 ? el.value.nom 
                    :el.value.title && el.value.title.length>0 ? el.value.title
                    :el.value.type == "camions" && el.value.brand && el.value.model ?  el.value.brand.label+' '+el.value.model
                    :el.value.type == "camions" && el.value.brand ?  el.value.brand.label
                    :el.value.brand && el.value.model ? el.value.brand.label+' '+el.value.model.label 
                    :el.value.brand && el.value.category ? el.value.brand.label+' '+el.value.category.label 
                    :el.value.categoryGoodPlans ? el.value.categoryGoodPlans.label
                    :el.value.typeArticle ? el.value.typeArticle.label      
                    :'' }
                                     </Link> 
                                  </li>
                  );
                })} </ul>
                <Link 
                 to={{
                    pathname:"/allSearchResult",
                    state:this.state.searchResult,
                    term:this.state.term,
                }}
                className="voirResultButton" onClick={() => this.setState({visible:false})}> Voir tous les résultats</Link>        
         
</>
: <>aucun résultat trouvé !</>
}

</div>}</div>




  <input className="input" 
  onKeyDown={e => e.key === 'Enter' ? this.search('desk') : ''}
  onChange={(e) => this.setState({ term : e.target.value })}
  placeholder='Effectuez une recherche sur Tunisia Living..' value={this.state.term}/>
  <div className="searchIcon" onClick={() => this.search('desk')} >
  <svg  xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16"> <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" fill="white"></path> </svg> Chercher</div>
</div> 
              </div>

              {loginLogout}
            </div>
          </header>
          <section className="l-navbar onTopMob" aria-label="Main website navigation">
            <div className="l-navbar__background"></div>
            <div className="l-navbar__top container">
              <div className="l-navbar__inner">
                <div
                  id="menuMain"
                  className="l-navbar__standard is-slid-and-faded-out"
                >
                <div className="slogan_logo">
                  <div className="slogan">
                    <p className="sloganP1">Social , Culturel</p>
                    <p className="sloganP2"> &amp; Economique</p>
                  </div>
                  <Link to="/" className="l-navbar__logo">
                    <img
                      className="l-navbar__morty"
                      src={"assets/images/logos/logo-tunisia-living-version-Delta.png"}
                      alt=""
                    />
                  </Link>
                 </div>
                  <nav className="l-navbar__topics">
                    <div className="l-navbar__track">
                      <ul
                        id="menuMain-topics"
                        className="c-nav c-nav--main l-navbar__list"
                      >
                        <li className={
                        this.state.currentSection.includes('economie') || this.state.currentSection.includes('sport')
                        || this.state.currentSection.includes('sante') || this.state.currentSection.includes('social')
                        || this.state.currentSection.includes('space') || this.state.currentSection.includes('technolgie')
                        ? "currentSection c-nav__item":"c-nav__item"}>
                          <NavItem name="Highlight" />
                          {/* <ul className="dropdown">
                            {this.state.categoriesReportage.length > 1 &&
                              this.state.categoriesReportage.map((category) => {
                                return (
                                  <li key={category.id}>
                                    <Link
                                      to={`/news/${category.id}/${category.slug}`}
                                    >
                                      {category.label}
                                    </Link>
                                  </li>
                                );
                              })}
                              </ul> */}
                              
                              <ul className="dropdown">
                            <li>
                              <Link to={`/news/1/economie`}>Économie </Link>
                            </li>
                            <li>
                              <Link to={`/news/2/sport`}>Sport </Link>
                            </li>
                            <li>
                              <Link to={`/news/3/sante`}>Santé </Link>
                            </li>
                            <li>
                              <Link to={`/news/4/social`}>Social </Link>
                            </li>
                            <li>
                              <Link to={`/news/12/space`}>Space </Link>
                            </li>
                            <li>
                              <Link to={`/news/13/technolgie`}>Technolgie </Link>
                            </li>
                          </ul>

                          
                        </li>

                        <li className={this.state.currentSection.includes('job')? "currentSection c-nav__item":"c-nav__item"}>
                          <NavItem name="Emploi" />
                          <ul className="dropdown">
                            <li>
                              <Link to="/jobs/sectors"> Trouver un emploi</Link>
                            </li>
                            <li>
                              <Link to="/jobs/publish-job-offer">
                                Publier une offre d'emploi
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className=
                        {this.state.currentSection.includes('bon-plan')? "currentSection c-nav__item":"c-nav__item"}>
                          <NavItem name="Bon plans" />
                          <ul className="dropdown">
                            <li>
                              <Link to="/bon-plans">
                                {" "}
                                Trouver un bon plans{" "}
                              </Link>
                            </li>
                            <li>
                              <Link to="/bon-plans/add">
                                Publier Une Annonce
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className= {this.state.currentSection.includes('real-estate')? "currentSection c-nav__item":"c-nav__item"}>
                          <NavItem name="Immobilier" />
                          <ul className="dropdown">
                            <li>
                              <Link to="/real-estates">
                                {" "}
                                Trouver un Immoblier{" "}
                              </Link>
                            </li>
                            <li>
                              <Link to="/real-estates/add">
                                Publier Une Annonce
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className= {this.state.currentSection.includes('vehicle')? "currentSection c-nav__item":"c-nav__item"}>
                          <NavItem name="Véhicules" />
                          <ul className="dropdown">
                            <li>
                              <Link to="/vehicles"> Trouver un véhicule</Link>
                            </li>
                            <li>
                              <Link to="/vehicles/add">
                                Publier une annonce
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className={this.state.currentSection.includes('animal') 
                        || this.state.currentSection.includes('article') || this.state.currentSection.includes('service')? "currentSection c-nav__item":"c-nav__item"}>
                          <NavItem name="Petite annonce" />
                          <ul className="dropdown" id="dd">
                            <li>
                              <Link to="#">Animaux</Link>
                              <ul>
                                <li>
                                  <Link to="/animals">Trouver un animal</Link>
                                </li>
                                <li>
                                  <Link to="/animals/add">
                                    Publier Une Annonce
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <Link to="#">Articles</Link>
                              <ul>
                                <li>
                                  <Link to="/articles">Trouver un article</Link>
                                </li>
                                <li>
                                  <Link to="/articles/add">
                                    Publier Une Annonce
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <Link to="#">Services</Link>
                              <ul>
                                <li>
                                  <Link to="/services">Trouver un service</Link>
                                </li>
                                <li>
                                  <Link to="/services/add">
                                    Publier Une Annonce
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li className={
                        this.state.currentSection.includes('agroalimentaire') || this.state.currentSection.includes('consommation')
                        || this.state.currentSection.includes('environment') || this.state.currentSection.includes('logement-et-transport')
                        || this.state.currentSection.includes('fake-news') || this.state.currentSection.includes('education') || this.state.currentSection.includes('culture')
                        ? "currentSection c-nav__item":"c-nav__item"}>
                          <NavItem name="12 M conso" />
                          <ul className="dropdown">
                          <li>
                                  <Link to={`/news/5/agroalimentaire`}>
                                    Agroalimentaire
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/news/6/consommation`}>
                                    Consommation
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/news/7/environment`}>
                                    Environment
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/news/8/culture`}>Culture</Link>
                                </li>
                                <li>
                                  <Link to={`/news/9/logement-et-transport`}>
                                    Logement et transport
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/news/10/fake-news`}>
                                    Fake news
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/news/14/education`}>
                                  Education
                                  </Link>
                                </li>
                            {/* {this.state.categories2MConso.length > 1 &&
                              this.state.categories2MConso.map((category) => {
                                return (
                                  <li key={category.id}>
                                    <Link
                                      to={`/news/${category.id}/${category.slug}`}
                                    >
                                      {category.label}
                                    </Link>
                                  </li>
                                );
                              })} */}
                            {/* {this.state.categories2MConso.length < 1 && (
                              <>
                                
                              </>
                            )} */}
                          </ul>
                        </li>
                      </ul>
                      <div id="menuScrolling" className="l-navbar__scrolling">
                        <div className="l-navbar__scrollingLeft"></div>
                        <div className="l-navbar__scrollingRight"></div>
                      </div>
                    </div>
                  </nav>
                  <section
                    className="l-navbar__toggles bigMenuButton"
                    aria-label="Website menu and search buttons"
                  >
                    <div className="c-nav c-nav--buttons">
                      <button
                        className={btnToggleMobile}
                        onClick={this.handleMobileMenu}
                        data-toggle-menu="#menuMore"
                      >
                        <span className="sr-only">Full Menu</span>
                        <span className="toggle-off">
                          <CIcon name="cilHamburgerMenu" size="2xl" />
                        </span>
                        <span className="toggle-on">
                          <CIcon name="cilX" size="2xl" />
                        </span>
                      </button>
                    </div>
                  </section>
                </div>
                <nav
                  id="menuMain-quickLinks"
                  className="l-navbar__buttons c-nav c-nav--buttons mobNav"
                  aria-label="Quick Links"
                >
                  <div className="homeLogoMob" name="home" style={{width:'60px',display:'contents'}}>
                    <Link  to="/">
                      <div >
                        <img
                          className="c-logo__image"
                          src={"assets/images/logos/tunis logo retina.png"}
                          alt=""
                        />
                      </div>
                    </Link>
                  </div>

                  {/* <div className="c-nav__item searchWrapper" name="local">
                  <div className="searchIcon" onClick={() => this.search('mob')} >
  <svg  xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16"> <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" fill="white"></path> </svg> Chercher
  </div>
                  </div> */}
                  <div className="c-nav__item" name="local">
                  <div className="searchWrapperOutterMob">
              <div className="searchWrapper" ref={this.elementRef}>

<div className="searchResultListOut" >
{this.state.visible && <div className="searchResultList" style={this.state.visible?{display:'block',textAlign:'center'}:{display:'none',textAlign:'center'}}>
<button style={{width:'30px',height:'30px'}} className="close" aria-label="Close" onClick={() => this.setState({visible:false,term:''})}>×</button>
{this.state.searching ? <CSpinner color="default" size="sm" /> 
:this.state.searchResult && this.state.searchResultFirst3.length>0?
<>
<ul className=" searchResultItemUL searchResultItemULMob">
 { this.state.searchResultFirst3.map((el) => {
                  return (
                    <li className="searchResultItem" key={el.value.id}>
                         <Link to={el.key=='goodPlans'?`/bon-plans/details/${el.value.id}`
                         : el.key=='members'?`/memberAnnonces/${el.value.prenom?el.value.prenom+'-'+el.value.nom+'-'+el.value.id :el.value.nom+'-'+el.value.id}`
                         : el.key == 'articles' ? `/articles/details/${el.value.id}`
                         : el.key == 'vehicles' ? `/vehicles/${el.value.type}/details/${el.value.id}`
                         : el.key == 'realEstate' ? `/real-estats/details/${el.value.id}`
                         : el.key == 'animals' ? `/animals/details/${el.value.id}`
                         : el.key == 'services' ? `/services/details/${el.value.id}`
                         : el.key == 'jobs' ? `/jobs/job-details/${el.value.id}/${el.value.sector.slug}/${el.value.slug}`
                         : el.key == 'news' && el.value.category ? `/news/${el.value.id}-${el.value.category.id}/${el.value.category.slug}/${el.value.slug}`                            
                         :''}  > 
                    { 
                       el.value.prenom && el.value.prenom.length>0 &&
                             el.value.nom && el.value.nom.length>0 ? el.value.prenom + ' ' + el.value.nom    
                    :el.value.nom && el.value.nom.length>0 ? el.value.nom 
                    :el.value.title && el.value.title.length>0 ? el.value.title
                    :el.value.type == "camions" && el.value.brand && el.value.model ?  el.value.brand.label+' '+el.value.model
                    :el.value.type == "camions" && el.value.brand ?  el.value.brand.label
                    :el.value.brand && el.value.model ? el.value.brand.label+' '+el.value.model.label 
                    :el.value.brand && el.value.category ? el.value.brand.label+' '+el.value.category.label 
                    :el.value.categoryGoodPlans ? el.value.categoryGoodPlans.label
                    :el.value.typeArticle ? el.value.typeArticle.label      
                    :'' }
                                     </Link> 
                                  </li>
                  );
                })} </ul>
               <br/>  <Link 
                 to={{
                    pathname:"/allSearchResult",
                    state:this.state.searchResult,
                    term:this.state.term,
                }}
                className="voirResultButton" onClick={() => this.setState({visible:false})}> Voir tous les résultats</Link>        
         
</>
: <>aucun résultat trouvé !</>
}

</div>}</div>




  <input className="input" 
  onKeyDown={e => e.key === 'Enter' ? this.search('desk') : ''}
  onChange={(e) => this.setState({ term : e.target.value })}
  placeholder='Chercher..' value={this.state.term}/>
  <div className="searchIcon" onClick={() => this.search('desk')} >
  <svg  xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16"> <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" fill="white"></path> </svg></div>
</div> 
              </div>
 </div>

                  <button
                    name="megamenu"
                    onClick={this.handleMobileMenu}
                    className="black "
                    ref={this.bigMenuButtonRef}
                  >
                    <CIcon name="cilHamburgerMenu" size="xl" />
                    <div className="c-nav__label">Menu</div>
                  </button>
                </nav>
              </div>
            </div>
          </section>
          <div className="l-navbar__submenus"  ref={this.bigMenuRef}>
            <section id="menuMore" className={mobileMenuCss}>
              <div className="l-panel__inner">
<button style={{width:'30px',height:'30px',color:'white'}} className="close" aria-label="Close"  onClick={this.handleMobileMenu}>×</button>
<div className="slogan_logo">
                  <div className="slogan">
                    <p className="sloganP1">Social , Culturel</p>
                    <p className="sloganP2"> &amp; Economique</p>
                  </div>
                  <Link to="/" className="l-navbar__logo">
                    <img
                      className="l-navbar__morty"
                      src={"assets/images/logos/logo-tunisia-living-version-Delta.png"}
                      alt=""
                    />
                  </Link>
                 </div>
                 <div className="mobMenuFooter">
              <Link to="/members/profile/mes-favoris" className="text-white mesFavoris">
                  {" "}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="var(--ci-primary-color, currentColor)" d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"></path></svg>
                  {/* {this.props.favorites.length > 0 &&
                    this.props.favorites.length} */}
                </Link>
                <Link to="/members/profile" className="avatarLink"
          >
            <img
              className="c-avatar-img"
              src={
                localStorage.getItem("avatar") !== "undefined"
                  ? `${IMAGES_BASE_URL}/public/uploads/members/${localStorage.getItem(
                      "avatar"
                    )}`
                  : `${IMAGES_BASE_URL}/public/uploads/members/default_avatar.jpg`
              }
              alt="avatar"
            />
          </Link>  
                {loginLogoutMobFooter}
              </div> 
   <nav className="l-navbar__topics mobMenu">
                    <div className="l-navbar__track">
                      <ul
                        id="menuMain-topicsMob"
                        className="c-nav c-nav--main l-navbar__list"
                      >

                  {/* <li className="c-nav__item">
                   
                          <Link to="/" className="l-navbar__logo sticky_logo">
                    <img
                      className="l-navbar__morty"
                      src={
                        "assets/images/logos/retina white.svg"
                      }
                      alt=""
                    />
                  </Link>
                        </li> */}
                        

                        <li className="c-nav__item">
                        <button
                          onClick={()=>{this.handleSubMenu(1,'highlight');}}
                          id='highlight'
                          className='c-nav__link'                        
                        >
                          <div className="c-nav__local" 
                          >
                            Highlight
                            <span
                              className="c-nav__icon c-nav__icon--chevron"
                              focusable="false"
                            ></span>
                          </div>
                        </button>
                          {/* { this.state.activeSubMenuMob==1 && 
                          <ul className="dropdown">
                            {this.state.categoriesReportage.length > 1 &&
                              this.state.categoriesReportage.map((category) => {
                                return (
                                  <li key={category.id}>
                                    <Link
                                      to={`/news/${category.id}/${category.slug}`}
                                    >
                                      {category.label}
                                    </Link>
                                  </li>
                                );
                              })}                 
                          </ul>} */}
                          {this.state.activeSubMenuMob==1 && <ul className="dropdown">
                            <li>
                              <Link to={`/news/1/economie`}>Économie </Link>
                            </li>
                            <li>
                              <Link to={`/news/2/sport`}>Sport </Link>
                            </li>
                            <li>
                              <Link to={`/news/3/sante`}>Santé </Link>
                            </li>
                            <li>
                              <Link to={`/news/4/social`}>Social </Link>
                            </li>
                            <li>
                              <Link to={`/news/12/space`}>Space </Link>
                            </li>
                            <li>
                              <Link to={`/news/13/technolgie`}>Technolgie </Link>
                            </li>
                          </ul>}

                        </li>

                        <li className="c-nav__item">
                          <button
                          onClick={()=>{this.handleSubMenu(2,'emploi');}}
                          id='emploi'
                          className='c-nav__link'                         
                        >
                          <div className="c-nav__local" 
                          >
                            Emploi
                            <span
                              className="c-nav__icon c-nav__icon--chevron"
                              focusable="false"
                            ></span>
                          </div>
                        </button>
                          { this.state.activeSubMenuMob == 2 &&                         
                          <ul className="dropdown">
                            <li>
                              <Link to="/jobs/sectors"> Trouver un emploi</Link>
                            </li>
                            <li>
                              <Link to="/jobs/publish-job-offer">
                                Publier une offre d'emploi
                              </Link>
                            </li>
                          </ul>}
                        </li>
                        <li className="c-nav__item">
                           <button
                          onClick={()=>{this.handleSubMenu(3,'bonplans');}}
                          id='bonplans'
                          className='c-nav__link'                        
                        >
                          <div className="c-nav__local" 
                          >
                          Bon plans
                            <span
                              className="c-nav__icon c-nav__icon--chevron"
                              focusable="false"
                            ></span>
                          </div>
                        </button>
                          { this.state.activeSubMenuMob==3 && 
                          <ul className="dropdown">
                            <li>
                              <Link to="/bon-plans">
                                {" "}
                                Trouver un bon plans{" "}
                              </Link>
                            </li>
                            <li>
                              <Link to="/bon-plans/add">
                                Publier Une Annonce
                              </Link>
                            </li>
                          </ul>
                          }
                        </li>
                        <li className="c-nav__item">                          
                         <button
                          onClick={()=>{this.handleSubMenu(4,'Immobilier');}}
                          id='Immobilier'
                          className='c-nav__link'                        
                        >
                          <div className="c-nav__local" 
                          >
                         Immobilier
                            <span
                              className="c-nav__icon c-nav__icon--chevron"
                              focusable="false"
                            ></span>
                          </div>
                        </button>
                          { this.state.activeSubMenuMob==4 && 
                          <ul className="dropdown">
                            <li>
                              <Link to="/real-estates">
                                {" "}
                                Trouver un Immoblier{" "}
                              </Link>
                            </li>
                            <li>
                              <Link to="/real-estates/add">
                                Publier Une Annonce
                              </Link>
                            </li>
                          </ul>}
                        </li>
                        <li className="c-nav__item">
                          <button
                          onClick={()=>{this.handleSubMenu(5,'Véhicules');}}
                          id='Véhicules'
                          className='c-nav__link'                        
                        >
                          <div className="c-nav__local" 
                          >
                         Véhicules
                            <span
                              className="c-nav__icon c-nav__icon--chevron"
                              focusable="false"
                            ></span>
                          </div>
                        </button>
                          { this.state.activeSubMenuMob==5 && 
                          <ul className="dropdown">
                            <li>
                              <Link to="/vehicles"> Trouver un véhicule</Link>
                            </li>
                            <li>
                              <Link to="/vehicles/add">
                                Publier une annonce
                              </Link>
                            </li>
                          </ul>}
                        </li>
                        <li className="c-nav__item c-nav__local22 ">
                          <button
                          className='c-nav__link'                        
                        >
                          <div className="c-nav__local " 
                          >
                            Petite annonce
                            <span
                              className="c-nav__icon c-nav__icon--chevron"
                              focusable="false"
                            ></span>
                          </div>
                        </button>
                          <ul className="dropdown" id="dd">
                            <li>
                          <button
                          onClick={()=>{this.handleSubMenu(6,'Animaux');}}
                          id='Animaux'
                          className='c-nav__link'                        
                        >
                          <div className="c-nav__local" 
                          >
                         Animaux
                            <span
                              className="c-nav__icon c-nav__icon--chevron"
                              focusable="false"
                            ></span>
                          </div>
                        </button>
                          { this.state.activeSubMenuMob==6 && 
                              <ul>
                                <li>
                                  <Link to="/animals">Trouver un animal</Link>
                                </li>
                                <li>
                                  <Link to="/animals/add">
                                    Publier Une Annonce
                                  </Link>
                                </li>
                              </ul>}
                            </li>
                            <li>
                          <button
                          onClick={()=>{this.handleSubMenu(7,'Articles');}}
                          id='Articles'
                          className='c-nav__link'                        
                        >
                          <div className="c-nav__local" 
                          >
                         Articles
                            <span
                              className="c-nav__icon c-nav__icon--chevron"
                              focusable="false"
                            ></span>
                          </div>
                        </button>
                          { this.state.activeSubMenuMob==7 &&                               
                              <ul>
                                <li>
                                  <Link to="/articles">Trouver un article</Link>
                                </li>
                                <li>
                                  <Link to="/articles/add">
                                    Publier Une Annonce
                                  </Link>
                                </li>
                              </ul>}
                            </li>
                            <li className="lastChild">
                               <button
                          onClick={()=>{this.handleSubMenu(8,'Services');}}
                          id='Services'
                          className='c-nav__link'                        
                        >
                          <div className="c-nav__local" 
                          >
                         Services
                            <span
                              className="c-nav__icon c-nav__icon--chevron"
                              focusable="false"
                            ></span>
                          </div>
                        </button>
                          { this.state.activeSubMenuMob==8 &&                                                             
                              <ul>
                                <li>
                                  <Link to="/services">Trouver un service</Link>
                                </li>
                                <li>
                                  <Link to="services/add/">
                                    Publier Une Annonce
                                  </Link>
                                </li>
                              </ul>}
                            </li>
                          </ul>
                        </li>
                        <li className="c-nav__item">
                           <button
                          onClick={()=>{this.handleSubMenu(9,'12Mconso');}}
                          id='12Mconso'
                          className='c-nav__link'                        
                        >
                          <div className="c-nav__local" 
                          >
                         12 M conso
                            <span
                              className="c-nav__icon c-nav__icon--chevron"
                              focusable="false"
                            ></span>
                          </div>
                        </button>
                          { this.state.activeSubMenuMob==9 &&     
                          <ul className="dropdown">
                          <li>
                                  <Link to={`/news/5/agroalimentaire`}>
                                    Agroalimentaire
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/news/6/consommation`}>
                                    Consommation
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/news/7/environment`}>
                                    Environment
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/news/8/culture`}>Culture</Link>
                                </li>
                                <li>
                                  <Link to={`/news/9/logement-et-transport`}>
                                    Logement et transport
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/news/10/fake-news`}>
                                    Fake news
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/news/14/education`}>
                                  Education
                                  </Link>
                                </li>
                          
                            {/* {this.state.categories2MConso.length > 1 &&
                              this.state.categories2MConso.map((category) => {
                                return (
                                  <li key={category.id}>
                                    <Link
                                      to={`/news/${category.id}/${category.slug}`}
                                    >
                                      {category.label}
                                    </Link>
                                  </li>
                                );
                              })} */}
                          </ul>}
                        </li>
                      </ul>
                    </div>
                   
                  </nav> 
                   
 {/* <CAccordion activeItemKey={1}>
  <CAccordionItem itemKey={1}>
    <CAccordionHeader>Highlightxx</CAccordionHeader>
    <CAccordionBody>
    </CAccordionBody>
  </CAccordionItem>
  <CAccordionItem itemKey={2}>
    <CAccordionHeader>Accordion Item #2</CAccordionHeader>
    <CAccordionBody>
    </CAccordionBody>
  </CAccordionItem>
  <CAccordionItem itemKey={3}>
    <CAccordionHeader>Accordion Item #3</CAccordionHeader>
    <CAccordionBody>
    </CAccordionBody>
  </CAccordionItem>
</CAccordion> */}

                {/* <div className="row">
                  <section
                    className="col-md-3"
                    role="group"
                    aria-labelledby="programsMore"
                  >
                    <h3
                      id="programsMore"
                      className="c-heading c-heading--secondary"
                    >
                      Highlightxx
                    </h3>
                    <ul
                      id="megaMenu-programs"
                      className="c-nav c-nav--dropdown c-nav--dropdown--generic"
                    >
                      {this.state.categoriesReportage.map((category) => {
                        return (
                          <li
                            onClick={this.handleMobileMenu}
                            className="c-nav__item"
                            key={category.id}
                          >
                            <Link
                              to={`/news/${category.id}/${category.slug}`}
                              className="c-nav__link"
                            >
                              {category.label}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </section>

                  <section
                    className="col-md-3"
                    role="group"
                    aria-labelledby="programsMore"
                  >
                    <h3
                      id="programsMore"
                      className="c-heading c-heading--secondary"
                    >
                      Emploi
                    </h3>
                    <ul
                      id="megaMenu-programs"
                      className="c-nav c-nav--dropdown c-nav--dropdown--generic"
                    >
                      <li className="c-nav__item">
                        <Link to="/jobs/sectors" className="c-nav__link">
                          Trouver un emploi
                        </Link>
                      </li>
                      <li className="c-nav__item">
                        <Link to="/publish-job-offer" className="c-nav__link">
                          Publier une offre d'emploi
                        </Link>
                      </li>
                    </ul>
                  </section>
                  <section className="col-md-3">
                    <h3
                      id="connectMore"
                      className="c-heading c-heading--secondary"
                    >
                      Bon plans
                    </h3>
                    <ul
                      id="megaMenu-connect"
                      className="c-nav c-nav--dropdown c-nav--dropdown--generic"
                    >
                      <li className="c-nav__item">
                        <Link to="/bon-plans" className="c-nav__link">
                          Trouver un bon plans
                        </Link>
                      </li>
                      <li className="c-nav__item">
                        <Link to="/add-bon-plans" className="c-nav__link">
                          Publier une annonce
                        </Link>
                      </li>
                    </ul>
                  </section>
                  <section className="col-md-3">
                    <h3
                      id="connectMore"
                      className="c-heading c-heading--secondary"
                    >
                      Immobilier
                    </h3>
                    <ul
                      id="megaMenu-connect"
                      className="c-nav c-nav--dropdown c-nav--dropdown--generic"
                    >
                      <li className="c-nav__item">
                        <a href="/" className="c-nav__link">
                          Vente
                        </a>
                      </li>
                      <li className="c-nav__item">
                        <a href="/" className="c-nav__link">
                          Achat
                        </a>
                      </li>
                    </ul>
                  </section>
                  <section className="col-md-3">
                    <h3
                      id="connectMore"
                      className="c-heading c-heading--secondary"
                    >
                      Véhicules
                    </h3>
                    <ul
                      id="megaMenu-connect"
                      className="c-nav c-nav--dropdown c-nav--dropdown--generic"
                    >
                      <li className="c-nav__item">
                        <Link to="/vehicles" className="c-nav__link">
                          Trouver un véhicule
                        </Link>
                      </li>
                      <li className="c-nav__item">
                        <Link to="/add-vehicle-advert" className="c-nav__link">
                          Publier une annonce
                        </Link>
                      </li>
                    </ul>
                  </section>
                  <section className="col-md-3">
                    <h3
                      id="connectMore"
                      className="c-heading c-heading--secondary"
                    >
                      Articles & Services
                    </h3>
                    <ul
                      id="megaMenu-connect"
                      className="c-nav c-nav--dropdown c-nav--dropdown--generic"
                    >
                      <li className="c-nav__item">
                        <a href="/" className="c-nav__link">
                          Animaux
                        </a>
                      </li>
                      <li className="c-nav__item">
                        <a href="/" className="c-nav__link">
                          Articles
                        </a>
                      </li>
                      <li className="c-nav__item">
                        <a href="/" className="c-nav__link">
                          Services
                        </a>
                      </li>
                    </ul>
                  </section>
                  <section className="col-md-3">
                    <h3
                      id="connectMore"
                      className="c-heading c-heading--secondary"
                    >
                      12 M Conso
                    </h3>
                    <ul
                      id="megaMenu-connect"
                      className="c-nav c-nav--dropdown c-nav--dropdown--generic"
                    >
                      <li className="c-nav__item">
                        <a href="/" className="c-nav__link">
                          Titre
                        </a>
                      </li>
                    </ul>
                  </section>
                </div> */}
              </div>
             
            </section>
            
          </div>
        </div>
      </>
    );

  }}
      </FavoriteContext.Consumer>
    );

  }
}
// function mapStateToProps(state) {
//   //console.log("navbar : ", state.vehicles.favorites);
//   return {
//     favorites: state.vehicles.favorites,
//   };
// }
// export default connect(mapStateToProps, actions)(Navbar); 
export default withRouter(Navbar);
