import React, { Component } from "react";
import { API_BASE_URL } from "../../../config";
import axios from "axios";
import validator from "validator";
import {
  CountryDropdown,
  RegionDropdown,
} from "react-country-region-selector";
import {
  CButton,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CSpinner
} from "@coreui/react";
import JobForm from "./JobForm";
export class AddCompany extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading:false,
      companyId: null,
      name: "",
      website: "",
      email: "",
      registrationNbr: "",
      country: "",
      city: "",
      address: "",
      image: null,
      imagePreviewUrl: "",
      idMember:localStorage.getItem('id'),
      success:false,
      errors: {
        name: "",
        website: "",
        email: "",
        registrationNbr: "",
        country: "",
        city: "",
        address: "",
      },
    };
    
  }
  handleChange = (event) =>
    this.setState({
      [event.target.name]: event.target.value,
    });
  selectCountry(val) {
    this.setState({ country: val });
  }

  selectCity(val) {
    this.setState({ city: val });
  }
  handleFile = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        image: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  
  };
  handleValidation = () => {
    let fieldName = this.state.name;
    let errors = {};
    let formIsValid = true;

    if (!fieldName) {
      formIsValid = false;
      errors.name = "Veuillez entrer le nom de l'entreprise";
    } else {
      errors.name = "";
    }
    if (!validator.isURL(this.state.website)) {
      formIsValid = false;
      errors.website = "Veuillez entrer un url valide";
    } else {
      errors.website = "";
    }
    if (!validator.isEmail(this.state.email)) {
      formIsValid = false;
      errors.email = "Veuillez entrer un e-mail valide";
    } else {
      errors.email = "";
    }
    if (!this.state.registrationNbr) {
      formIsValid = false;
      errors.registrationNbr = "Veuillez entrer le matricule fiscale";
    } else {
      errors.registrationNbr = "";
    }
    if (!this.state.country) {
      formIsValid = false;
      errors.country = "Veuillez Choisir un pays";
    } else {
      errors.country = "";
    }
    if (!this.state.city) {
      formIsValid = false;
      errors.city = "Veuillez Choisir la région";
    } else {
      errors.city = "";
    }
    if (!this.state.address) {
      formIsValid = false;
      errors.address = "Veuillez entrer l'adresse de l'entreprise";
    } else {
      errors.address = "";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.setState({loading:true});
      const formData = new FormData();
     
      formData.append("logo", this.state.image);
      formData.append("name", this.state.name);
      formData.append("website", this.state.website);
      formData.append("email", this.state.email);
      formData.append("registrationNumber", this.state.registrationNbr);
      formData.append("country", this.state.country);
      formData.append("city", this.state.city);
      formData.append("address", this.state.address);
      formData.append("idMember", this.state.idMember);
    
      axios
        .post(API_BASE_URL + "/add-company", formData)
        .then((res) => {
          //console.log('company : ',res)
          if (res.data.status.toString() === "ok") {
            this.setState({
              success: true,
             companyId: res.data.company.id
            });
            
          }
          this.setState({loading:false});
          // this.props.history.push("/5467854/index");
        })
        .catch((error) => {
          //console.log(" errors : ", error);
          this.setState({loading:false});
        });
    }
  };
  render() {
    let errors = this.state.errors;
    const { country, city } = this.state;
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = (
        <img
          src={imagePreviewUrl} alt="preview"
          width="150"
          className="img-fluid img-thumbnail "
        />
      );
    }
    const success = this.state.success;
    if(success){
      return(
        <>
          <JobForm companyId={this.state.companyId}></JobForm>
        </>
      )
    }else 
    return (
      <>
        <div className="information-title" >
          <h2>Informations de l'entreprise</h2>
        </div>
        <span></span>
        <p></p>
        <form
          onSubmit={this.handleSubmit}
          id="form-add"
          className="form-horizontal jobFormClass"
        >
          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Nom de l'entreprise</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CInput
                type="text"
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
              />
              {errors.name.length > 0 && (
                <span className="invalid-feedback error">{errors.name}</span>
              )}
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Site web</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CInput
                type="text"
                name="website"
                value={this.state.website}
                onChange={this.handleChange}
              />
              {errors.website.length > 0 && (
                <span className="invalid-feedback error">{errors.website}</span>
              )}
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">E-mail</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CInput
                type="text"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
              {errors.email.length > 0 && (
                <span className="invalid-feedback error">{errors.email}</span>
              )}
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Matricule fiscale</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CInput
                type="text"
                name="registrationNbr"
                value={this.state.registrationNbr}
                onChange={this.handleChange}
              />
              {errors.registrationNbr.length > 0 && (
                <span className="invalid-feedback error">
                  {errors.registrationNbr}
                </span>
              )}
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3"> <CLabel htmlFor="country">Pays</CLabel></CCol>
            <CCol xs="12" md="7">
              <CountryDropdown
                value={country}
                className="form-control"
                defaultOptionLabel="Choisissez un pays"
                onChange={(val) => this.selectCountry(val)}
                priorityOptions={["TN"]}
              />
              {errors.country.length > 0 && (
                <span className="invalid-feedback error">
                  {errors.country}
                </span>
              )}
            </CCol>
          </CFormGroup>
          <CFormGroup row>
          <CCol md="3"> <CLabel htmlFor="country">Région</CLabel></CCol>
            <CCol xs="12" md="7">
          <RegionDropdown
                country={country}
                value={city}
                className="form-control"
                onChange={(val) => this.selectCity(val)}
              />
               {errors.city.length > 0 && (
                <span className="invalid-feedback error">
                  {errors.city}
                </span>
              )}
              </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Adresse</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CInput
                type="text"
                name="address"
                value={this.state.address}
                onChange={this.handleChange}
              />
              {errors.address.length > 0 && (
                <span className="invalid-feedback error">
                  {errors.address}
                </span>
              )}
            </CCol>
          </CFormGroup>
          <CFormGroup row>
         
                    <CCol xs="3">
                     
                        <CLabel htmlFor="content">Image</CLabel>
                       
                    </CCol>
                    <CCol xs="7">
                    <CInput
                          type="file"
                          name="image"
                          onChange={this.handleFile}
                          accept="image/*"
                        />
                    {$imagePreview}
                    </CCol>
                   
                 
          </CFormGroup>
          <CRow>
          <CCol md="3"></CCol>
            <CCol xs="12" sm="3">
              {!this.state.loading && (
                <CButton color="primary" block type="submit">
                  Valider
                </CButton>
              )}

              {this.state.loading && (
                <CButton color="primary" block disabled>
                  <span>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>

                  <span> Valider</span>
                </CButton>
              )}

            </CCol>
          </CRow>
        </form>
      </>
    );
  }
}

export default AddCompany;
