import React, { useEffect, useState  } from "react";
import { IMAGES_BASE_URL , API_BASE_URL , tokenU} from "src/config";
import { CCol, CRow , CButton , CSpinner ,  CModal, CBadge ,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CAlert,
    CLabel,
} from "@coreui/react";
import ImageGallery from "react-image-gallery";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import { getBadge ,  textBadge} from "src/actions";
import Moment from "react-moment";
import apiUser from "../apiUser";

export default function JobDetails(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [item, setItem] = useState();
  const [createdByCompany, setCreatedByCompany] = useState(false);
  const [pictures, setPictures] = useState([]);
  const { id } = props.match.params;
  const [iconSrc, setIconSrc] = useState("");
  const [mainImg, setMainImg] = useState("");
  const [itemType, setItemType] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [modal, setModal] = useState(false);
  const [msg, setMsg] = useState("");
  const [erroru, setErroru] = useState(null);
  const [success, setSuccess] = useState(false);

  const  table  = "JobOffer";
  
  const toggle = () => {
    setModal(!modal);
    setSuccess(false);
    setErroru(false)
  };

  function setApproved() {

    //console.log(msg);
    const formData = new FormData();
    formData.append("itemId", id);

    formData.append("table", table);
setLoading(true)
setLoading1(true)

    apiUser
      .post(`/setStatusApproved`,formData)
      .then((res) => {
        //console.log("dddddddd");
        //console.log(res);
        if (res.data.status.toString() === "ok") {
          let temp = item;
          temp.status = 'approved';
          setItem(temp);
         setLoading(false)
         setLoading1(false)      
         setSuccess(true);
          setTimeout(() => {
            setSuccess(false); 
            setModal(false);
          }, 2000);
        } else {  setErroru(true)
            setLoading(false)
         setLoading1(false)
 } 
      })
      .catch((err) =>
      { //console.log(err);
        setLoading(false);
        setLoading1(false);
      }
        );
  }
  
  function setPending() {

    //console.log(msg);
    const formData = new FormData();
    formData.append("itemId", id);

    formData.append("table", table);
setLoading(true)
setLoading4(true)

    apiUser
      .post(`/setStatusPending`,formData)
      .then((res) => {
        //console.log("dddddddd");
        //console.log(res);
        if (res.data.status.toString() === "ok") {
          let temp = item;
          temp.status = 'pending';
          setItem(temp);
         setLoading(false)
         setLoading4(false)       
         setSuccess(true);
          setTimeout(() => {
            setSuccess(false); 
            setModal(false);
          }, 2000);
        } else {  setErroru(true)
            setLoading(false)
         setLoading4(false)  } 
      })
      .catch((err) =>
      { //console.log(err);
        setLoading(false);
        setLoading4(false)
      }
        );
  }

  function setEditing() {

    //console.log(msg);
    const formData = new FormData();
    formData.append("itemId", id);
 
    formData.append("table", table);
    formData.append("msg", msg)
setLoading(true)
setLoading2(true)

//console.log("msg get" , formData.get("msg"));

    apiUser
      .post(`/setStatusEditing`,formData)
      .then((res) => {
        //console.log("dddddddd");
        //console.log(res);
        if (res.data.status.toString() === "ok") {
          let temp = item;
          temp.status = 'editing';
          setItem(temp);
         setLoading2(false)       
         setSuccess(true);
          setTimeout(() => {
            setSuccess(false); 
            setModal(false);
            setLoading(false)
          }, 2000);
        } else {  setErroru(true)
            setLoading(false)
         setLoading2(false)  } 
      })
      .catch((err) =>
      { //console.log(err);
        setLoading(false);
        setLoading2(false)
      }
        );
  }

  function setRejected() {
    //console.log(msg , table);
    const formData = new FormData();
    formData.append("itemId", id);

    formData.append("table", table);
setLoading(true)
setLoading3(true)

    apiUser
      .post(`/setStatusRejected`,formData)
      .then((res) => {
        //console.log("dddddddd");
        //console.log(res);
        if (res.data.status.toString() === "ok") {
          let temp = item;
          temp.status = 'rejected';
          setItem(temp);
         setLoading(false)
         setLoading3(false)        
         setSuccess(true);
          setTimeout(() => {
            setSuccess(false); 
            setModal(false);
          }, 2000);
        } else {  setErroru(true)
            setLoading(false)
         setLoading3(false)  } 
      })
      .catch((err) =>
      { //console.log(err);
        setLoading(false);
        setLoading3(false)
      }
        );
  }

  useEffect(() => {

    //console.log('propsdsrdhf------',id);
    //console.log(props.location.state);

    setIsLoaded(false); 

      setPictures([]); 
    if(props.location.state) {
      if(props.location.state.createdBy.accountType.id==2) setCreatedByCompany(true);
      setItem(props.location.state);

      //console.log("resultt props", props.location.state);
      setIsLoaded(true);
    }

 
  }, [id]);

  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div className="loading_logo">
              <img
                src={"assets/icons/tunisia-living-loading-icon.gif"}
                alt="tunisia living loading icon"
              />
              <p>Chargement...</p>
            </div>
          </div>
        </main>
      </div>
    );
  } else {
  
  return (
      <div className="container dashBoardDetails">
        <main className="l-main">
          <div className="l-main__grid o-container">
            <div className="l-main__article2 l-main__article l-main__content l-main__section l-article ">
        
              <CRow className='dashBoardDetailsTop'>
              <CCol md="9">
             
              <div className="features-vehicle features2">
              <div className="sectionHeadingB">  <CLabel> Info de l'Annonce</CLabel> </div><br/>
              <CRow>              
                <CCol md="6">
                <h3>{item.title && item.title}</h3>
                </CCol>  
                <CCol md="6" >
                <h5>Numéro de série : {item.sn && item.sn} </h5>
                 </CCol>
               
              </CRow> <br/>

                <CRow>

                <CCol md="6" xs="6" >
                <svg  className="icon-option-vehicle" viewBox="0 0 20 20">
							<path d="M12.075,10.812c1.358-0.853,2.242-2.507,2.242-4.037c0-2.181-1.795-4.618-4.198-4.618S5.921,4.594,5.921,6.775c0,1.53,0.884,3.185,2.242,4.037c-3.222,0.865-5.6,3.807-5.6,7.298c0,0.23,0.189,0.42,0.42,0.42h14.273c0.23,0,0.42-0.189,0.42-0.42C17.676,14.619,15.297,11.677,12.075,10.812 M6.761,6.775c0-2.162,1.773-3.778,3.358-3.778s3.359,1.616,3.359,3.778c0,2.162-1.774,3.778-3.359,3.778S6.761,8.937,6.761,6.775 M3.415,17.69c0.218-3.51,3.142-6.297,6.704-6.297c3.562,0,6.486,2.787,6.705,6.297H3.415z"></path>
						</svg>
                    <p className="title-feature">Auteur</p>
                    <p className="title-feature1">
                    {item.seller && item.seller.length > 0 ? item.seller 
                  : item.createdBy && item.createdBy.accountType && item.createdBy.accountType.id==2? item.createdBy.nom
                  : item.createdBy&& item.createdBy.prenom ? 
                    item.createdBy.prenom+' '+ item.createdBy.nom.charAt(0): ''} 
                    </p>
                    <p></p>
                  </CCol>
                  <CCol md="6" xs="6" >                
                  <svg className="icon-option-vehicle" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24"><path d="M12,19a1,1,0,1,0-1-1A1,1,0,0,0,12,19Zm5,0a1,1,0,1,0-1-1A1,1,0,0,0,17,19Zm0-4a1,1,0,1,0-1-1A1,1,0,0,0,17,15Zm-5,0a1,1,0,1,0-1-1A1,1,0,0,0,12,15ZM19,3H18V2a1,1,0,0,0-2,0V3H8V2A1,1,0,0,0,6,2V3H5A3,3,0,0,0,2,6V20a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V6A3,3,0,0,0,19,3Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V11H20ZM20,9H4V6A1,1,0,0,1,5,5H6V6A1,1,0,0,0,8,6V5h8V6a1,1,0,0,0,2,0V5h1a1,1,0,0,1,1,1ZM7,15a1,1,0,1,0-1-1A1,1,0,0,0,7,15Zm0,4a1,1,0,1,0-1-1A1,1,0,0,0,7,19Z"/></svg>      
                    <p className="title-feature">Date d'ajout</p>
                    <p className="title-feature1">
                    <Moment format="DD/MM/YYYY">
                        {item.createdAt}
                      </Moment> 
                    </p>
                    <p></p>
                  </CCol>
                  </CRow>
                    <CRow> 
                    <br/> <div className="actionButCon actionButConA">
              <CBadge className="bigBadge" color={getBadge(item.status,item.edits)}>
               Statut de l'annonce : {textBadge(item.status, item.edits)}
                          </CBadge> 
              </div> <br/>
                    </CRow>
              </div>
           
              </CCol>
              <CCol md="3">
              <div className="features-vehicle features-vehicle2 features2">
          <div className="sectionHeadingB">  <CLabel>Actions</CLabel> </div> <br/>
              <div className="actionButCon">
                   <CButton
                    size="sm"
                    className="ml-1 actionButDet btn-primary"
                    disabled={loading || item.status=='approved'}
                    onClick={() => {
                        setApproved();
                    }}
                  >
                  {loading1 && <CSpinner size="sm"/>} Validé
                  </CButton>     
  
                <CButton
                  size="sm"
                  className="ml-1 actionButDet btn-primary"
                  disabled={loading || item.status=='editing' }
                  onClick={() => 
                  toggle()
                  }
                >
               Mettre à niveau
                </CButton>
                
                <CButton
                    size="sm"
                    className="ml-1 actionButDet btn-primary"
                    disabled={loading || item.status!='approved'}
                    onClick={() => {
                        setPending();
                    }}
                  >
                  {loading4 && <CSpinner size="sm"/>}  Annuler la diffusion
                  </CButton>

              <CButton
                size="sm"
                color="danger"
                className="ml-1 actionButDet"
                disabled={loading || item.status=='rejected'}
                onClick={() => setRejected()}
              >
              {loading3 && <CSpinner size="sm"/>}  Suppression
              </CButton>
       </div> </div>
              </CCol>
              </CRow>
             
           <br/><br/>
<div className="features-vehicle" style={{paddingTop:'0px',paddingLeft:'0px',paddingRight:'0px'}}>
 <div className="sectionHeadingB">  <CLabel> Annonce Preview</CLabel> </div> 
<br/><br/>
      <div className="annoncePreview ">
      <div className="l-main__article l-main__content l-main__section l-article ">
              <div className="row jobAndCompanyRow">
                <CCol xs="4" md="4">
                  <div className="bordered">
                    <img
                      src={
                        createdByCompany? `${IMAGES_BASE_URL}/public/uploads/members/${item.createdBy.path}`
                        : item.company? `${IMAGES_BASE_URL}/public/uploads/companies/${item.company.path}`
                          : item.createdBy.company && item.createdBy.company.path
                          ? `${IMAGES_BASE_URL}/public/uploads/companies/${item.createdBy.company.path}`
                          : "https://www.keeitem.com/static/recruiter/images/default-logo.jpg"
                      }
                      alt=""
                      className="campany-logo"
                    />
                    <div className="companyDetails">
                    <h5 className="text-blue-primary text-bold">
                      { createdByCompany? item.createdBy.nom
                        : item.company ? item.company.name
                        : item.createdBy.company ? item.createdBy.company.name 
                        : ''}
                    </h5>
                    <span>
                    {  createdByCompany? item.createdBy.adresse
                        : item.company ? item.company.address
                        : item.createdBy.company ? item.createdBy.company.address 
                        : ''}
                    </span>{" "}
                    <br />
                    <span>
                      { item.company ? item.company.city
                        : item.createdBy.company ? item.createdBy.company.city 
                        : ''}
                      { item.company ?  " , "+ item.company.country
                        : item.createdBy.company ?  " , "+ item.createdBy.company.country 
                        : ''} 
                    </span>
                    </div>
                  </div>{" "}
                </CCol>
                <CCol xs="8" md="8">
                  <div className="bordered">
             
                    <h5 className="job-title">{item.title}
       
                    </h5>
                    {item.jobType !== "" && (
                      <>
                        <strong>
                          <span>Type de poste</span>
                        </strong>{" "}
                        <br />
                        <span>{item.jobType}</span> <br />{" "}
                      </>
                    )}
                    {item.vacant  && ( <>
                        <strong>
                          <span>Poste disponible</span>
                        </strong>{" "}
                        <br />
                        <span>{item.vacant}</span> <br />{" "}
                      </>)}
                    {item.levelStudies !== "" && (
                      <>
                        <strong>
                          <span>Niveau d'études</span>
                        </strong>{" "}
                        <br />
                        <span>{item.levelStudies}</span> <br />{" "}
                      </>
                    )}
                    {item.levelExp !== "" && (
                      <>
                        {" "}
                        <strong>
                          <span>Niveau d'éxpériences</span>
                        </strong>{" "}
                        <br />
                        <span>{item.levelExp}</span> <br />
                      </>
                    )}

                    <p></p>
                    {/* <Link to={`/send-job-application/${item.id}`}></Link>*/}
                    <div className="applyOrShareButton">  { 
                      <button
                        className=" c-button btn-job"                      
                      >
                        Postuler maintenant
                      </button>
                 }
              </div> <br/>
              <div className="shareJobButton" >
              <button
                        className=" c-button btn-job"
                        
                      >
                      <a style={{color:'white'}} rel="noopener" 
                                            role="img" aria-label="Facebook Link" 
                                            href={`https://www.facebook.com/sharer/sharer.php?u=https://tunisia-living.com/20222508/jobs/job-details/${item.id}/${item.sector.slug}/${item.slug}`}  
                                            target="_blank" class="lb_socialicon cc-fb w-inline-block">
                   <svg style={{width:'14px' , filter:'invert(1)' , verticalAlign:'top'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"/></svg> Share via facebook 
                   </a> </button> 
              </div>
                  </div>{" "}
                </CCol>
              </div>

              <div className="row">
                <CCol xs="12" md="12">
                  <div className="bordered">
                    <h5 className="text-blue-primary text-bold">
                      Descriptif du poste
                    </h5>
                    {ReactHtmlParser(item.description)}

                    <p></p>
                    <div className="applyOrShareButton">
                    {  <button
                        className=" c-button btn-job" 
                      >
                        Postuler maintenant
                      </button>}
                     </div>
                  </div>
                </CCol>
              </div>
            </div>
       </div></div>
             
              <br/> <br/>
         
            </div>
          </div>
          <CModal show={modal} onClose={toggle}>
                                <CModalHeader closeButton>
                                Demander Ces modifications via Email
                                </CModalHeader>
                                <CModalBody>
                                <p>
                                Saisissez les modifications souhaitées séparées par ','                                   
                                <br/> (par exemple : changer l'image, fixer un prix raisonnable...)
                                </p>
                                 <textarea
                                    value={msg}
                                    onChange={(e)=>setMsg(e.target.value)}
                                 />
                                </CModalBody>
                                <CModalFooter>
                                  <CButton
                                    color="primary"
                                    onClick={() => {
                                       setEditing()
                                    }}
                                    disabled={loading}
                                  >
                                    {loading2 && <CSpinner size="sm"/>} submit
                                  </CButton>

                                  <CButton 
                                    disabled={loading}
                                  color="secondary" onClick={toggle}>
                                    Cancel
                                  </CButton>
                                  {success ? (
                    <CAlert
                      style={{
                        textAlign: "center",
                        width: "100%",
                        marginTop: "10px",
                      }}
                      color="success"
                      closeButton
                    >
                   Votre message a été modifié avec succès
                    </CAlert>
                  ) 
                  : erroru ? (<CAlert
                      style={{
                        textAlign: "center",
                        width: "100%",
                        marginTop: "10px",
                      }}
                      color="danger"
                      closeButton
                    >
                   Error !
                    </CAlert>)
                  :(
                    ""
                  )}
                                </CModalFooter>
                              </CModal>
        </main>
      </div>
    );
  }
}
