import React, { Component } from 'react'
import { API_BASE_URL } from "../../config";
import axios from 'axios';
import { Link } from 'react-router-dom';

export class Terms extends Component {
    constructor(props) {
        super(props)
       
        this.state = {
            accept:false,
            uid:'',
            ukey:''
        }
    }
    
    handleChange= (event) =>{
      //console.log('ccccccccc');
      //console.log(event.target.checked);
      const queryParameters = new URLSearchParams(window.location.search);
      const uid = queryParameters.get("uid");
      const ukey = queryParameters.get("ukey");
      //console.log(uid+'-'+ukey);
      if(uid && ukey){
      this.setState({accept:event.target.checked,uid:uid,ukey:ukey});
      setTimeout(() => { 
    //console.log(this.state);
    axios.post(API_BASE_URL+'/users/accept_privacy',this.state)
    .then(res => {
        //console.log("res");
        //console.log(res);
        //console.log("res.data");
      //console.log(res.data);
      if(res.data=='1' || res.data==1 ){
          alert('Thank you , You will receive your login details via email soon')
      }
       
    })
    .catch(error =>{
        //console.log("login errors : ", error)
    })
    } , 200);
    }
    }
    render() {
        return(
    <div className="container">
        <div className='termsText'>
        <h2>Conditions d'utilisation</h2>
        <strong>Nos coordonnées</strong>
         <p>Tunisia Living est une marque de la société DS SARL</p> 
         <p>Sise a Monplaisir Tunis-Tunisie</p><br/>
         <strong>Article A</strong><br/>
         <strong>Responsabilité Tunisie living la partie annonce:</strong>
         <p>Tunisia Living offre une gamme de services et de produits présentés par divers annonceurs tels que des vendeurs de véhicules, d’immobiliers, les offreurs d’emplois et d'autres prestataires de services. En aucun cas, Tunisia Living ne peut être tenue responsable de l'exactitude ou de l'exhaustivité des informations publiées sur son site. </p>
         <p>En particulier, Tunisia Living décline toute responsabilité concernant les éventuels défauts constatés sur les biens, articles et les services en vente ou déjà vendus par le site, ainsi que toute inexactitude concernant les caractéristiques de ces biens, articles et services. Étant donné l'impossibilité pratique de vérifier toutes les informations publiées sur le site, les utilisateurs sont invités à exercer leur propre diligence raisonnable avant de prendre des décisions basées sur les informations disponibles.</p>
        <p>De manière plus générale, Tunisia Living ne peut être tenue responsable des problèmes éventuels liés à la vente, à la garantie ou à la qualité des biens, articles et les services à la vente sur le site. Les utilisateurs sont invités à prendre toutes les précautions nécessaires et à se renseigner auprès des vendeurs et des professionnels compétents avant de finaliser tout achat.</p>
        <strong>Article A.1:</strong><br/>
        <strong>Règles spécifiques pour l'insertion d'une annonce par un particulier:</strong>          
        <p>En tant que particulier, vous avez la possibilité de publier gratuitement une annonce pour un véhicule de tout type et des autres produits disponible sur les modules d’ajout d’annonces sur le site, incluant jusqu'à 10 photos. Aucun frais ni commission sur la vente ne vous sera demandé. Votre annonce sera visible sur le site pendant une période de 90 jours, après elle sera automatiquement désactivée. Toutefois, vous avez la possibilité de la prolonger pour une durée supplémentaire de 30 jours via votre compte personnel «Mon Profil»</p>
        <p>Nous vous recommandons vivement d'accompagner votre annonce de plusieurs photos, sachant que celles-ci sont quatre fois plus consultées que les annonces sans photo.</p>
        <p>Les professionnels de la vente de tout type de produits existant sur le site doivent souscrire à un abonnement pro gratuit, mais ils bénéficient de conditions avantageuses pour accéder aux services professionnels de Tunisia Living. Les professionnels peuvent s'inscrire sur notre site via la page dédiée.</p>
        <p>Nous effectuons une vérification quotidienne des annonces publiées sur notre site et nous nous réservons le droit de supprimer toute annonce ne respectant pas les lois en vigueur en Tunisie ou en violation de nos règles d'insertion. Ces règles comprennent notamment :</p>
        <ul>
            <li>
            Une seule annonce de bien ou d'un produit est autorisée une seule fois.
            </li>
            <li>
            L'annonceur doit effectivement être le propriétaire du produit proposé à la vente.
            </li>
            <li>
            Les annonces sous forme de demande ne sont pas autorisées.
            </li>
            <li>
            Toutes les informations relatives au produit doivent être exactes.
            </li>
            <li>
            Le produit et le vendeur doivent être situés ou disponible lors de l'achat en Tunisie.
            </li>
            <li>
            Le prix indiqué doit être réel, les annonces affichant un prix symbolique tel que "1 dt" seront supprimées.
            </li>
            <li>
            Les produits vendus ne doivent pas figurer sur le site.
            </li>
            <li>
            Les photos doivent représenter le produit réel, les photos de catalogue ou provenant d'une autre annonce ne sont pas autorisées.
            </li>
            <li>
            Le prix de vente doit inclure la TVA si le vendeur est un professionnel ou une société.
            </li>
            <li>
            L'annonceur est entièrement responsable du contenu de son annonce, Tunisia Living déclinant toute responsabilité quant au contenu de l'annonce ou à la transaction de vente du produit.
            </li>
        </ul>
        <strong>Article A.2:</strong><br/>
        <strong>Lutte contre les fausses annonces:</strong>
        <p>
        Dans le but de prévenir les fausses annonces et les éventuelles escroqueries qui pourraient en découler, Tunisia Living se réserve le droit de vérifier et de supprimer les annonces qu'elle estime suspectes. En cas de suppression, Tunisia Living peut demander des documents prouvant la propriété du bien ou du produit. Toutefois, Tunisia Living décline toute responsabilité quant aux transactions effectuées suite à des annonces publiées sur son site. 
        <span className='underLined'>Tunisia Living recommande vivement la lecture de nos conseils de prudence. </span>     
         </p> 
         <strong>Article A.3:</strong> <br/>
         <strong>Sécurité:</strong>
         <p>Les utilisateurs, notamment les vendeurs de véhicules et des services et de tout type de produits sur le site web Tunisia living, doivent faire preuve de vigilance lorsqu'ils interagissent avec des acheteurs ou des vendeurs potentiels, surtout si les transactions impliquent l'utilisation de chèques ou l'engagement avec des sociétés ou des individus étrangers, pour lesquels les recours peuvent être plus difficiles. Il est impératif de s'assurer que le paiement a été reçu avant de remettre le produit et les documents à l'acheteur.</p>    
         <p>Nous tenons à souligner que Tunisia Living ne peut en aucun cas être tenu responsable en cas de fraude survenue après des mises en contact effectuées via notre plateforme en ligne. Toutefois, nous vous encourageons vivement à nous signaler rapidement tout comportement suspect ou frauduleux afin que nous puissions informer rapidement nos utilisateurs et prendre les mesures appropriées.</p>
         <strong>Article A.4:</strong><br/>
         <strong>Propriété des informations publiées sur le site Tunisia Living :</strong>
         <p>Les informations publiées sur le portail web Tunisia Living sont la propriété exclusive de la société DS propriétaire du site. Toute collecte systématique de données à des fins commerciales à partir du site est strictement interdite et sera poursuivie immédiatement. En particulier, l'utilisation de robots pour extraire des informations de nos bases de données est strictement prohibée par la loi.</p>
         <strong>Article B</strong><br/>
        <strong>Responsabilité de Tunisie living sur la presse en ligne </strong>
        <p>Tunisia Living Reportage est un service de presse en ligne.</p>
        <p>Certains éléments de ce service permettent le transfert ou la publication d'informations utilisateur ou d'autres données sur le service (désignés ci-après comme "contenu utilisateur"), comprenant des informations personnelles, des photographies, des vidéos, du contenu audio, des commentaires, des recommandations, des images de profil, des détails de CV, des jeux, des énigmes, des tests, des journaux d'affichage/utilisation, des statistiques, des textes écrits, des articles, des opinions ou tout autre forme de contenu. En téléchargeant tout contenu utilisateur, vous acceptez qu'il soit soumis aux conditions suivantes :</p>
        <p>B.1 En fournissant tout contenu utilisateur à ce service, vous accordez à Tunisia Living une licence mondiale, non révocable, non exclusive, libre de droits et transférable pour utiliser, reproduire, distribuer, modifier, changer, créer des œuvres dérivées, afficher, exécuter, supprimer en totalité ou en partie, et ce, selon son appréciation exclusive et absolue, sur toute plateforme ou moyen de communication, quelle qu'il soit. Par conséquent, vous renoncez à tous les droits moraux, droits équivalents ou similaires, qui peuvent exister ou s'appliquer concernant tout contenu utilisateur. Cette licence et renonciation ne sont pas limitées dans le temps ou l'espace.</p>
        <p>B.2 Tunisia Living peut utiliser le contenu utilisateur pour tout usage, y compris, mais sans s'y limiter, la copie, la diffusion, la transmission, l'édition, la modification, la coupure, la reformulation, l'adaptation, la citation, la publication ou tout autre usage, selon son appréciation exclusive et absolue.</p>
        <p>B.3 Vous devez toujours respecter toutes les directives ou politiques émises par Tunisia Living de temps à autre, qui régissent la soumission, l'utilisation ou l'interaction avec le contenu utilisateur. Vous êtes seul responsable de votre contenu utilisateur et de toute réclamation, plainte, revendication de violation, dommage, droit de tiers ou action légale découlant de ou liée à votre utilisation ou soumission de votre contenu utilisateur.</p>
        <p>B.4 En soumettant tout contenu utilisateur, vous confirmez, garantissez que vous avez le droit de fournir ce contenu utilisateur, et que vous avez obtenu toutes les autorisations, licences, consentements et droits nécessaires pour utiliser votre contenu utilisateur et déléguer à Tunisia Living l'autorisation de l'utiliser.</p>
        <p>B.5 Vous vous engagez à ne pas soumettre, divulguer ou révéler à ce service ou par son intermédiaire tout contenu utilisateur, ou tout autre matériel ou information, protégé par ou soumis à des droits d'auteur, secrets commerciaux ou droits de propriété de tiers, y compris les droits de confidentialité et de publicité, sauf si vous avez obtenu tous les droits, permissions, licences et autorisations nécessaires de tous les détenteurs de droits concernés pour fournir votre contenu utilisateur et le matériel et/ou les informations et accorder la licence décrite ici à Tunisia Living.</p>
        <p>B.6 Vous vous engagez également à ne pas soumettre de contenu utilisateur ou de matériel ou d'informations illégaux, obscènes, diffamatoires, calomnieux, injurieux, vulgaires, politiquement provocateurs, menaçants, pornographiques, incitatifs, haineux, racialement offensants, ou qui constituent une infraction criminelle, ou qui engagent une responsabilité civile, ou qui encouragent à commettre des actes criminels, ou qui enfreignent la loi, la culture, les coutumes et les normes thiques internationalement acceptées.</p>
        <p>B.7 Tunisia Living détient le pouvoir discrétionnaire et absolu en ce qui concerne l'utilisation, la publication et l'affichage du contenu utilisateur, et ne s'engage pas, sous aucune circonstance, à publier tout contenu utilisateur, en totalité ou en partie, ou à l'afficher ou à l'inclure dans ce service ou dans toute autre plateforme ou service.</p>
        <br/> <strong className='termsStrong termsPink' >Conditions Générales:</strong>
        <strong>1-Champ d'application, modifications des Conditions Générales</strong>
        <p>Tunisia Living se réserve le droit de modifier les présentes Conditions Générales pour l'avenir. Toute modification rétroactive ne sera envisagée que si elle est exigée par la loi ou par les autorités compétentes.</p>
        <p>Tunisia Living s'engage à informer l'utilisateur par courrier électronique de toute modification des Conditions Générales et à lui indiquer que ces modifications seront considérées comme acceptées s'il continue à utiliser les services proposés par Tunisia Living et s'il ne s'est pas opposé à ces modifications dans un délai d'un mois.</p>
        <strong>2-Objet des services, modifications</strong><br/>
        <p>DS gère une plate-forme Internet (Tunisia Living) sur laquelle des annonces peuvent être placées et gérées par l'Utilisateur en contrepartie d'un paiement (ci-après « Les Services »). L'objet des services fournis est de permettre le placement d'annonces pour des biens, des produits et des services dans une base de données et de rendre ces annonces accessibles à des tiers via Internet. L'étendue des services fournis pourra être élargie en accord avec l'Utilisateur en fonction des bouquets de services spécifiques que celui-ci aura choisis.</p>
        <p>2.2 L'utilisation des services et de la base de données par l'Utilisateur n'est autorisée que dans les limites et conditions définies par les présentes Conditions Générales (en particulier l'article suivant ) </p>
        <p>2.3 Tunisia Living se réserve le droit de modifier, à tout moment, les services payants, en tout ou en partie, de les arrêter ou de les remplacer par d'autres services aussi longtemps que cela sera raisonnable pour l'Utilisateur et moyennant l'exposé des motifs de cette décision si cela concerne une restriction des Services. L'Utilisateur ne saurait exiger le maintien de services spécifiques, en tout ou en partie (en particulier des fonctionnalités individuelles). Cela s'appliquera en particulier aux services qui ne sont pas explicitement décrits dans le bouquet de services choisi ou qui sont offerts sans contrepartie supplémentaire après la conclusion du Contrat par Tunisia Living.</p>
        <p>2.4 Si Tunisia Living offre des services dans le domaine des statistiques ou de la gestion des appels ainsi que du suivi des appels, dans lesquels un numéro de téléphone virtuel est mis à la disposition de l'Utilisateur, Tunisia Living ne fournit pas lui-même de services de télécommunication. L'Utilisateur se voit attribuer un numéro de téléphone virtuel par un fournisseur de services de télécommunication par l'intermédiaire d'Tunisia Living, et ce numéro est visible par la publication sur les pages exploitées par l'Utilisateur sur Tunisia Living. Ce numéro permet la création de statistiques concernant les contacts téléphoniques établis. L'utilisation de ces services nécessite que l'Utilisateur soit déjà titulaire d'un contrat valide avec un fournisseur de services de télécommunication.</p>
        <p>2.5 Dans la mesure où l'Utilisateur permet aux Clients d'évaluer les services fournis sur ou en dehors du site internet www.tunisialiving.tn via l'option d'évaluation fournie par Tunisia Living, ce contact « Utilisateur/client » est réputé avoir été établi lors du premier contact par l'Utilisateur. L'Utilisateur peut prouver qu'aucun contact de ce type n'a effectivement eu lieu. Par ailleurs, Tunisia Living supprimera les évaluations manifestement illégales (par exemple, les critiques abusives, les contenus insultants) après avoir obtenu des informations quant à ces évaluations. Sur la base de ces informations et compte tenu d'autres droits, tels que ceux de l'Utilisateur, Tunisia Living se réserve également le droit de supprimer les évaluations incorrectes sur le plan des faits. En aucun cas, Tunisia Living n'est obligé d'enquêter sur les faits, il peut s'en référer aux informations fournies par l'Utilisateur.</p>
        <p>2.6 Pendant la durée du Contrat, l'Utilisateur a un accès illimité aux données qu'il a fournies et aux données générées par l'accès à ses annonces. Plus d'informations concernant le traitement de données personnelles peuvent être trouvées dans la politique vie privée et de gestion des cookies disponible sur (<Link to='/politiquedeConfidentialite' className="c-nav__link"> https://www.tunisialiving.tn/politiquedeconfidentialite</Link> )                    
       </p>
        <p>2.7 Les annonces publiées sur le site d'Tunisia Living doivent correspondre aux exigences reprises dans l'Article A.1. Il appartient à l'Utilisateur de vérifier que ces exigences sont respectées lorsqu'ils publient une annonce. L'Utilisateur sait et reconnait qu'il reste le seul responsable relativement au respect de ces exigences. En aucun cas, Tunisia Living ne pourra être tenu responsable d'une erreur ou du non-respect de ces conditions .</p>
         <strong>3-Droits d'utilisation</strong>
         <p>En soumettant ses annonces, l'Utilisateur accorde à Tunisia Living et à ses sociétés affiliées les droits suivants, non exclusifs et cessibles, pour la durée légale des droits concernés et pour le monde entier, relatifs aux contenus soumis (y compris le droit d'accorder des sous-licences) :</p>
        <p><strong>Droit d'archivage et de base de données :</strong> cela inclut le droit d'archiver les contenus sous différentes formes, notamment de les enregistrer, de les numériser, de les intégrer dans des bases de données et de les stocker sur tous les supports de stockage connus, ainsi que de les associer à d'autres travaux ou parties de travaux ;
        </p>
        <p>
            <strong>Droits de reproduction :</strong> cela comprend le droit de stocker les contenus de manière aléatoire, de les copier et de les rendre accessibles ou de les diffuser sur des supports électroniques ou autres médias (tels qu'Internet, les journaux et les magazines, TV) en tout ou en partie ;
        </p>
        <p>
            <strong>Droits de diffusion :</strong> cela englobe le droit de communiquer les contenus au public sous forme incorporelle, notamment en rendant les contenus accessibles au public sur tous les supports (en particulier les sites web et les applications), indépendamment de la technologie utilisée et du mode de transmission ;
        </p>
        <p>
            <strong>Droits d'utilisation des contenus</strong> à des fins d'analyse, de développement de la plate-forme et de développement et de commercialisation de produits, conformément aux règles de protection des données ;
        </p>
        <p><strong>Droit d'édition :</strong> cela inclut le droit d'éditer les contenus de diverses manières, notamment de les modifier, de les abréger, de les compléter avec des images et de les associer à d'autres contenus.
        </p>
        <p>Tunisia Living peut autoriser des tiers à effectuer les activités mentionnées ci-dessus.</p>
        <p>Malgré ce qui précède, des droits d'utilisation étendus peuvent être convenus dans le cadre des services proposés par Tunisia Living. En l'absence d'une disposition expresse, Tunisia Living ne revendique aucun droit de propriété sur le contenu de l'Utilisateur. Ce dernier garantit que les droits sont transférés ou accordés dans la mesure nécessaire à la fourniture des services dans le cadre du Contrat.</p>
        <p>3.1 Tous les contenus (tels que les textes, les annonces) ou les signes distinctifs (marques déposées, noms de sociétés, logos) accessibles sur les sites Internet de Tunisia Living peuvent être protégés par le droit d'auteur, le droit des marques et, éventuellement, les lois sur la concurrence. Dans le cadre du Contrat, Tunisia Living accorde à l'Utilisateur un droit non-exclusif, non cessible, valable pendant la durée du Contrat, et non susceptible de sous-licence, d'utiliser les contenus accessibles conformément aux usages normaux prévus par le Contrat et les services proposés. Toute utilisation abusive de ce droit sera considérée comme une infraction et pourra être poursuivie par Tunisia Living conformément aux lois civiles et, le cas échéant, pénales. </p>
        <strong>4-Conclusion du contrat en ligne et enregistrement</strong><br/>
        <p> 4.1 Les utilisateurs s'engagent à s'inscrire pour accéder aux services.les vendeurs professionnels (personnes morales ou personnes physiques majeures et capables) sont autorisés à s'inscrire afin d'accéder aux services pro du site. L'individu qui effectue l'inscription au nom d'une personne morale doit être dûment autorisé à conclure un tel contrat. </p>
        <p>4.2 Les utilisateurs doivent fournir avec exactitude les informations demandées lors de l'inscription. Pour l'inscription et le contrat actuel, Tunisia Living est habilitée à demander la présentation d'un extrait du registre des entreprises et des sociétés ainsi que tout autre document ou information jugé obligatoire ou opportun pour la conclusion du contrat ou le maintien de la relation contractuelle.</p>
        <p>4.3 L'envoi par l'utilisateur de ses données d'inscription à Tunisia Living équivaut à une offre de conclure un contrat avec Tunisia Living.</p>
        <p>4.4 Tunisia Living décidera d'accepter ou de refuser l'offre de contrat. En l'absence de confirmation de l'inscription par Tunisia Living dans un délai raisonnable par courrier électronique à l'adresse fournie par l'utilisateur, ce dernier ne sera plus lié par son offre. Dès confirmation de l'offre par Tunisia Living, un contrat sera effectivement conclu entre l'utilisateur et Tunisia Living.</p>
        <p>4.5 Chaque utilisateur est autorisé à procéder à une seule inscription par plateforme. Si un utilisateur possède plusieurs succursales, chaque succursale doit être inscrite en tant qu'utilisateur distinct. L'inscription auprès de Tunisia Living n'est pas transférable. Chaque utilisateur reçoit un seul mot de passe qui ne peut être partagé.</p>
        <p>4.6 Les utilisateurs doivent informer immédiatement Tunisia Living de toute modification de leurs données.</p>
        <p>4.7 Les utilisateurs ne doivent en aucun cas divulguer leur mot de passe, même si cela leur est demandé. Tunisia Living informe les utilisateurs que ses collaborateurs ne sont pas autorisés à leur demander leur mot de passe. En cas d'oubli du mot de passe, les utilisateurs peuvent demander, via la fonction "mot de passe oublié", qu'un nouveau mot de passe leur soit envoyé à l'adresse électronique fournie.</p>
        <p>4.8 Les utilisateurs sont responsables des dommages pouvant résulter de la divulgation de leur mot de passe à des tiers en raison de leur négligence ou intentionnellement. Les utilisateurs doivent informer immédiatement Tunisia Living, par courrier électronique via la zone de retour d'informations, en cas de vol de leur mot de passe ou s'ils ont connaissance d'une utilisation frauduleuse de celui-ci.</p>
        <strong>5-Blocage des contenus ou de l'accès ; autres sanctions</strong>
        <p>5.1 La décision d'accepter les contenus proposés par l'utilisateur relève de la discrétion de Tunisia Living. Tunisia Living se réserve le droit de refuser l'insertion des contenus proposés par l'utilisateur ou de retirer ces contenus après insertion, notamment dans les cas suivants :</p>
        <p className='termsMargined' >Si ces contenus ne respectent pas les Exigences relatives à la Présentation des Annonces de Tunisia Living (voir Article A.1) ;
        <br /> Si Tunisia Living estime que ces contenus contreviennent à la loi et/ou aux principes éthiques.</p>
        <p>5.2 En cas de plainte d'un tiers concernant un contenu inséré par l'utilisateur, Tunisia Living est autorisée, afin de prévenir tout risque de poursuite, à retirer immédiatement ces contenus, sans être tenue de vérifier la validité de la plainte. Dans ce cas, Tunisia Living informera l'utilisateur.</p>
        <p>5.3 Tunisia Living est en droit de bloquer immédiatement l'accès de l'utilisateur si :</p>
        <p className='termsMargined'>L'utilisateur a enfreint de manière répétée les Exigences relatives à la Présentation des Annonces de Tunisia Living (voir Article A.1), ou a publié des contenus illicites ;
<br/>L'utilisateur ne règle pas ses factures,
<br/>Il existe un soupçon fondé selon lequel un tiers utilise également l'accès de l'utilisateur ;
<br/>En cas d'utilisation abusive de la base de données ou des droits d'utilisation prévus à l'Article 3 ci-dessus.</p>
        <p>5.4 Dans tous les cas, l'utilisateur sera informé par écrit dans un délai raisonnable avant que le blocage n'intervienne. Tunisia Living pourra demander le paiement de frais d'un montant de 100DT pour procéder au déblocage.</p> 
        <p>5.5 Si malgré l'avertissement écrit, l'utilisateur enfreint de manière préjudiciable les dispositions de cet Article 5, Tunisia Living prendra les mesures nécessaires pour exclure définitivement l'utilisateur. Tunisia Living pourra également exiger une pénalité de 50 DT par semaine entamée pendant laquelle l'infraction ou l'abus se poursuivra. De plus, en cas de non-respect concernant la désignation d'une annonce (par exemple, une annonce d'un vendeur professionnel présentée comme provenant d'un particulier), Tunisia Living pourra réclamer une pénalité contractuelle d'un montant de 100 DT par annonce ainsi présenté.</p>
        <p>5.6 Le système interne de gestion des plaintes de Tunisia Living est accessible via les contacts enregistrés dans l'espace professionnel. Tunisia Living accepte de coopérer avec les médiateurs dont les coordonnées sont indiquées dans l'espace utilisateur.</p>
        <strong>Modification de nos conditions d'utilisations</strong>
        <p>Nous pouvons être amenés à modifier occasionnellement la présente politique, afin notamment de se conformer à toutes évolutions réglementaires, jurisprudentielles, éditoriales ou techniques. </p>
        <p>Le cas échéant, nous changerons la date de « dernière mise à jour » et indiquerons la date à laquelle les modifications ont été apportées. Vous devez vérifier cette page régulièrement pour vous assurer de prendre connaissance de tout changement effectué à cette politique. Nous pouvons vous informer des changements effectués à cette politique par courrier électronique ou par le biais du service de messagerie privée de notre site web.</p>
        <br/>
        <p>Version mise à jour le 31/05/2023</p>
        <br/>
        </div>
        
        <input style={{cursor:"pointer",marginRight:"6px"}} type="checkbox"  name="changepasstype" id="changepasstype" onChange={this.handleChange} />
        <label htmlFor='changepasstype' style={{display:"inline-block",cursor:"pointer"}}>
            Accept terms
            </label><br/><br/>
</div>
   )
 }
}

    export default Terms