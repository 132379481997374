import React, { useEffect, useState } from "react";
import { API_BASE_URL, IMAGES_BASE_URL } from "../../../config";
import axios from "axios";

import Moment from "react-moment";
import { CCol, CDataTable, CTabs, CTabContent, CTabPane } from "@coreui/react";
import { Link } from "react-router-dom";

function Jobs(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [location, setLocation] = useState("");

  const [nbrJobOffer, setNbrJobOffer] = useState("");
  const fields = [
    { key: "createdBy", label: "entreprise", _style: { width: "10%" } },
    { key: "title", _style: { width: "60%" } },
    { key: "createdAt", label: "Date", _style: { width: "30%" } },
  ];
  const { id } = props.match.params;
  useEffect(() => {
    axios(`${API_BASE_URL}/jobs-by-sector/${id}`)
      .then((res) => res.data)
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
          //console.log("job by sector", result, id);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
    axios(`${API_BASE_URL}/jobs/get-count-job-offer`)
      .then((res) => res.data)
      .then(
        (result) => {
          //console.log(result);
          setNbrJobOffer(result);
        },
        (error) => {
          //console.log(error);
        }
      );
  }, [id]);
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();

    formData.append("keyword", keyword);
    formData.append("location", location);
    //console.log("submit", formData);
    axios
      .post(`${API_BASE_URL}/jobs/search`, formData)
      .then((response) => {
        //console.log(response.data);
        setItems(response.data);
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div className="loading_logo">
              <img
                src={"assets/icons/tunisia-living-loading-icon.gif"}
                alt="tunisia living loading icon"
              />
              <p>Chargement...</p>
            </div>
          </div>
        </main>
      </div>
    );
  } else {
    return (
      /*  <div className="container">
        <main className="l-main">
          <div className="l-main__grid o-container">
            <div className="l-main__article l-main__content l-main__section l-article">
              {items.map((jobOffer, index) => {
                return (
               
                
                  
                  <JobItem
                    key={index}
                    title={jobOffer.title}
                    company={jobOffer.createdBy.company && jobOffer.createdBy.company.name}
                  
                    urlCompany={`/jobs-by-company/${jobOffer.createdBy.company.id}`}
                    url={`/jobs-detail/${jobOffer.id}/${jobOffer.sector.slug}/${jobOffer.slug}`}
                    logo ={jobOffer.createdBy.company.path ?`${IMAGES_BASE_URL}/public/uploads/companies/${jobOffer.createdBy.company.path}`:"https://www.keejob.com/static/recruiter/images/default-logo.jpg"}
                  ></JobItem>
                  
                
                );
              })}
              
            </div>
           
          </div>
        </main>
      </div>*/
      <>
        <img
          src={"assets/images/job banner.jpg"}
          alt="tunisia living job banner"
        />
        <div className="row ">
          <CCol xs="2" md="2" className="title-section mt-4">
            <img src={"assets/images/icon3.png"} alt="job" className="a-icon" />{" "}
            <br />
            <span className="mt-4"> Jobs</span>
          </CCol>
          <CCol xs="10" md="10">
            <h3 className="mt-3 text-blue-primary">
              Commencez votre carrière ici
            </h3>
            <span className="text-blue-primary">
              Trouvez ici plus que {nbrJobOffer} offres d'emploi
            </span>
            <div className="mt-4">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <CCol xs="5" md="5">
                    <input
                      type="text"
                      placeholder="Mots clés"
                      onChange={(e) => setKeyword(e.target.value)}
                      value={keyword}
                    />
                  </CCol>
                  <CCol xs="5" md="5">
                    <input
                      type="text"
                      placeholder="Emplacements"
                      onChange={(e) => setLocation(e.target.value)}
                      value={location}
                    />{" "}
                  </CCol>
                  <CCol xs="2" md="2">
                    <button className=" c-button" type="submit">
                      ok
                    </button>
                  </CCol>
                </div>
              </form>
            </div>
          </CCol>
        </div>
        {items.length !== 0 ? (
          <>
            <div className="col-md-9 offset-md-2">
              <CTabs>
                <CTabContent fade={false} className= 'jobsTableAll'>
                  <CTabPane>
                    <CDataTable                    
                      items={items}
                      fields={fields}
                      /*columnFilter
              tableFilter*/
                      itemsPerPage={20}
                      hover
                      sorter
                      pagination
                      scopedSlots={{
                        createdAt: (item) => (
                          <td className="lastColJobsTable">
                            {" "}
                            <Moment format="DD/MM/YYYY">
                              {item.createdAt}
                            </Moment>
                            <br />
                            <Link
                              to = {{ pathname:`/jobs/job-details/${item.id}/${item.sector.slug}/${item.slug}`,
                             state:item,}}
                             // to={`/jobs/job-details/${item.id}/${item.sector.slug}/${item.slug}`}
                            >
                              <button className=" c-button ">Voir plus</button>
                            </Link>
                          </td>
                        ),
                        createdBy: (item) => (
                          <>
                            {" "}
                            <td className="campany-logoTd">
                              <img
                                className="campany-logo"
                                src={
                                  item.createdBy.accountType.id==2 ? `${IMAGES_BASE_URL}/public/uploads/members/${item.createdBy.path}`
                                  : item.company? `${IMAGES_BASE_URL}/public/uploads/companies/${item.company.path}`
                                    : item.createdBy.company.path
                                    ? `${IMAGES_BASE_URL}/public/uploads/companies/${item.createdBy.company.path}`
                                    : "https://www.keejob.com/static/recruiter/images/default-logo.jpg"
                                }
                                alt=""
                              />

                              {/*item.createdBy.company && item.createdBy.company.name*/}
                            </td>
                          </>
                        ),
                        title: (item) => (
                          <td>
                            <Link
                              to={`/jobs/job-details/${item.id}/${item.sector.slug}/${item.slug}`}
                              className=" link-job-title"
                            >
                              {item.title}
                            </Link>
                            <div className=" text-muted">
                              { item.company ? item.company.city
                        : item.createdBy.company ? item.createdBy.company.city 
                        : ''}
                      { item.company ? " , "+item.company.country
                        : item.createdBy.company ? " , "+ item.createdBy.company.country 
                        : ''} 
                            </div>
                            <span>{item.jobType}</span>
                          </td>
                        ),
                      }}
                    />
                  </CTabPane>
                </CTabContent>
              </CTabs>
            </div>
          </>
        ) : (
          <div className="col-md-9 offset-md-2">
            <CTabs>
              <CTabContent fade={false}>
                <CTabPane>
                  Il n'ya pas d'offres d'emploi pour ce secteur
                </CTabPane>
              </CTabContent>
            </CTabs>
          </div>
        )}
      </>
    );
  }
}

export default Jobs;
