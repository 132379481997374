import React, { Component } from "react";
import { isLogin } from "src/components/middleware/auth";
import LoginMember from "./LoginMember";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CContainer,

} from "@coreui/react";

export class MyProfileMain extends Component {
  constructor(props) {
    super(props);
  
  }



  render() {

    return localStorage.getItem("roles") === "ROLE_MEMBER" && isLogin() ?  (
      <div>
        <CContainer className='MyPrfileMain'>
          <CRow className="justify-content-center">
            <CCol md="12" lg="12" xl="12">
              <CCard className="mx-4">
                <CCardBody className="p-4" style={{color:"black"}}>
                <br /> <br />
                    <h5>
                    Bienvenue sur Tunisia Living ! <br />

Vous êtes maintenant membre de notre communauté. Consultez nos articles et annonces pour tout savoir sur l'actualité en Tunisie.

<br /> Profitez de votre visite !
</h5> <br /> <br />

<h5 className="arabicFontClass" style={{fontWeight: 600,lineHeight: "27px"}}> 

مرحبًا بك في Tunisia Living! <br />

لقد أصبحت الآن جزءًا من مجتمعنا. تصفح مقالاتنا والإعلانات لتبقى على اطلاع على كل ما يحدث في تونس.
<br />
استمتع بزيارتك!


</h5> <br /> <br />
<h5>
Welcome to Tunisia Living! <br />

You are now part of our community. Browse our articles and announcements to stay informed about what's happening in Tunisia.
<br />
Enjoy your visit!


                    </h5>

                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </div>

    )
    :
    (<LoginMember/>)
    ;
  }
}

export default MyProfileMain;
