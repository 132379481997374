import React, { useEffect, useState } from "react";
import Vehicle from "../vehicles/Vehicle";
import AnimalDetails from "../OtherAnnonce/animals/animalDetails";
import BonPlansDetails from "../bonPlans/bonPlansDetails";
import ServiceDetails from "../OtherAnnonce/services/serviceDetails";
import ArticleDetails from "../OtherAnnonce/articles/articleDetails";
import RealEstateDetails from "../realEstate/RealEstatedetails";
import JobDetail from "../jobs/JobDetail";
import { API_BASE_URL } from "src/config";
import axios from "axios";
import { CBadge } from "@coreui/react";
import { getBadge , textBadge} from "src/actions";
import {
    Route,
  } from "react-router-dom";
import { CSpinner } from "@coreui/react";


function MyItemDetails(props) {
    //console.log('param')
    //console.log(props.match.params);
    //console.log(props.location.state);
    //console.log(props.match.params.type);
    //const { pathname, params } = props;
    const { history, location, match } = props;
    const [favNumber, setFavNumber] = useState(null);
    const [seeNumber, setseeNumber] = useState(null);
    const  id  =  props.location.state.id;
    const { type } =  props.match.params;

//   const handleDataFetched = (data) => {
//     setFavNumber(data);
//   };
useEffect(() => {
    //console.log('id , type');
    //console.log(id);
    //console.log(type);

let typ = '';
let path = '';
switch (type) {

    case 'vehicle':
    typ = 'Vehicle';
    path = 'vehicle/show/'+id;
    break;
    case 'animal':
      typ = 'Animal';
      path = 'animal/show/'+id;
    break; 
    case 'goodPlans': 
      typ = 'goodPlans';
      path = 'goodPlans/show/'+id; 
    break;
    case 'article': 
      typ = 'Articles';
      path = 'article/show/'+id; 
    break;
    case 'job': 
    typ = 'JobOffer';
    path = 'jobs/show'+id; 
    break;
    case 'real_estate': 
    typ = 'RealEstate';
    path = 'real_estate/show/'+id; 
   break;
    case 'service' :
      typ = 'Service';
      path = 'service/show/'+id;
    break;  
  default:
    break;
}
//console.log(`${API_BASE_URL}/checkFavoriteCount/${id}/${typ}`);
    axios(`${API_BASE_URL}/checkFavoriteCount/${id}/${typ}`)
    .then((res) => res.data)
    .then(
      (result) => {
        //console.log('check count');
        //console.log(result);
        setFavNumber(result.count);
      },
      (error) => {
        //console.log(error);
      }
    );


const formData = new FormData();
formData.append("path",  path);
//console.log('pathf',formData.get("path"));
    axios
    .post(API_BASE_URL + "/checkSeeCount", formData)
    .then((res) => res.data)
    .then(
      (result) => {
        //console.log('check see count');
        //console.log(result);
        setseeNumber(result.count);
      },
      (error) => {
        //console.log(error);
      }
    );

},  [id]);

    return (
        <div className="itemDetailsProfilePageMain">
      <div className="itemDetailsProfilePage">  
        {/* Other content in MyItemDetails component */}
      
      <Route path="/members/profile/myItemDetails/:type" 
 render={(props) => {
    switch (props.match.params.type) {
      case 'vehicle':
        return <Vehicle {...props}  />;
      case 'animal':
        return <AnimalDetails {...props}  />;
      case 'goodPlans':
        return <BonPlansDetails {...props}  />;
      case 'article':
        return <ArticleDetails {...props}  />;
      case 'real_estate':
        return <RealEstateDetails {...props} />;
      case 'service':
        return <ServiceDetails {...props}  />;
      case 'job':
        return <JobDetail {...props} />;
      default:
        return null;
    }
  }}       
            /> 
            
                 {/* <Route
        path="/members/profile/myItemDetails/:type/:id"
        render={(routeProps) => (
            props.match.params.type=='annonce_car'?<Vehicle {...routeProps} onDataFetched={handleDataFetched} />
            :props.match.params.type=='annonce_camion'?<Truck {...routeProps} onDataFetched={handleDataFetched} />
            :props.match.params.type=='annonce_bike'?<Bike {...routeProps} onDataFetched={handleDataFetched} />
            :props.match.params.type=='annonce_moto'?<Moto {...routeProps} onDataFetched={handleDataFetched} />
          :<></>
        )}
      /> */}

      </div>    
      <div className="itemReportSection">
      <CBadge className="bigBadge" color={getBadge(props.location.state.status)}>
                         status :   {textBadge(props.location.state.status)}
                          </CBadge> <br/>
      <h5>Suivi des réactions de l'annonce</h5>
      <div className="annonceActionsInfo">
       <strong>{seeNumber!= null ? seeNumber :     <CSpinner color="default" size="sm" />}   <img src="assets/icons/eye.svg" /> </strong>
       <p>utilisateurs vu votre annonce</p>
      </div>
      <div className="annonceActionsInfo">
      <strong>{favNumber!= null ? favNumber :     <CSpinner color="default" size="sm" />}    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="var(--ci-primary-color, currentColor)"
                          d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"
                        />
                      </svg></strong> <p> utilisateurs aime votre annonce</p>
      </div>
            </div>
      </div>
)
}
export default MyItemDetails;