import React, { useEffect, useState  } from "react";
import { keyHeader,API_BASE_URL ,IMAGES_BASE_URL } from "../../config";
import { getBadge , textBadge } from "src/actions";
import axios from "axios";
import Moment from "react-moment";
import Loading from "src/components/myWidgets/loadingWidget";
import { Link } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import {CCol , CRow , CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs} from "@coreui/react";
import ItemCard from "./itemCard";
import { Scroll , BasicMember} from "src/components/myWidgets/formTitles";


function AnnoncerPage(props) {
    const [vehicle, setVehicle] = useState([]);
    const [goodPlans, setGoodPlans] = useState([]);
    const [realEstate, setRealEstate] = useState([]);
    const [animal, setAnimal] = useState([]);
    const [articles, setArticles] = useState([]);
    const [service, setService] = useState([]);
    const [jobOffer, setJobOffer] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [member, setMember] = useState(null);
   // const { id } =  props.match.params;
    const id = props.match.params.id.includes('-') ? props.match.params.id.split('-').pop() : props.match.params.id;

    useEffect(() => {
        const source = axios.CancelToken.source();
        //console.log('in member annonces --- ' , id)
      
        axios.post(`${API_BASE_URL}/annonces_by_member/${id}`, {
          cancelToken: source.token,
        })
            .then(result => {  
               // console.log("result annbnbnb",result); 
                //console.log("result annbnbnb result.data.GoodPlans",result.data.goodPlans); 
                setGoodPlans(result.data.goodPlans);
                setVehicle(result.data.vehicles);
                setArticles(result.data.articles);
                setAnimal(result.data.animals);
                setRealEstate(result.data.realEstate);
                setService(result.data.services);
                setJobOffer(result.data.jobs);
                setMember(result.data.member);
                setIsLoaded(true);          
            })
            .catch(error => {
                console.log("error",error);
              //  setIsLoaded(true);
                setError(true);
            });


            
        }, [id]);
        if(error)  {
          return (
            <div className="container">
              <main className="l-main annonceDetailsPage">
                <div className="l-section l-section--archive">
                  <div>Not found !</div>
                </div>
              </main>
            </div>
          );
        } 
  else if (!isLoaded) {
          return (
       <Loading/>
          );
        }
        
          else {
                if  (
            (goodPlans.length + articles.length 
                   + vehicle.length + realEstate.length
                   + animal.length + service.length + jobOffer.length == 0 )
           )
             {    
               return (
                 <div>
                        <CRow className="mainBannerText">
                        Il n'y a pas encore des annonces de {member.nom}
                      </CRow>
                     </div>
               )
             }
          else  return (
            <> 
      <div className="container annoncerPage">
        <main className="l-main">
        <div className="advInfoSzctionCard annoncerInfoSection" >
        <BasicMember/>
         <CRow xs="12">
         <CCol xs="12">
            <div className="annoncerInfoSection1">

                    <img
                      className="c-avatar-img"
                      src={
                        member.path
                          ? `${IMAGES_BASE_URL}/public/uploads/members/${member.path}`
                          : `${IMAGES_BASE_URL}/public/uploads/members/default_avatar.jpg`
                      }
                      alt={member.nom+" avatar"}
                    />


               </div>   

            <div className="annoncerInfoSection1">
    
               <h3>
               Virtine {member.nom}
                </h3> 
                {member.createdAt &&
                 <>  <span><img
                    src={"assets/icons/icon_calandar.svg"}
                    alt="date-icon"
                    className="icon-item"
                  /> Membre depuis le <Moment format="DD/MM/YYYY">
                        {member.createdAt}
                      </Moment>
                </span></>}
                {member.website &&
                 <> <span> <img
                    src={"assets/icons/website-icon.svg"}
                    alt="website-icon"
                    className="icon-item"
                  />  <a href={member.website} target="_blank">
                     {member.website}
                  </a>
                       
                </span></>}
                {member.addrese &&
                 <> <span> <img
                    src={"assets/icons/icon_map.svg"}
                    alt="website-icon"
                    className="icon-item"
                  /> 
                     {member.addrese}
                </span></>}

               </div>   
       </CCol>
          </CRow>
           {member.desc && 
           <CRow xs="12">
           <CCol xs="10">
                 <span>  
                     {member.desc}
                </span>
            </CCol>
          </CRow>}
         </div>
                   
                <CTabs>
                <div className="row ">
                <CCol xs="12" md="12">
                  <CNav variant="tabs" className="vehicule-form-tabs add_page">
                    {jobOffer.length>0 && <CNavItem className="vehicule-form-navItem immobilierIcon">
                      <CNavLink className="vehicule-form-navLink">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="c-sidebar-nav-icon" role="img"><path fill="var(--ci-primary-color, currentColor)" d="M208,16A112.127,112.127,0,0,0,96,128v79.681a80.236,80.236,0,0,0,9.768,38.308l27.455,50.333L60.4,343.656A79.725,79.725,0,0,0,24,410.732V496H312V464H56V410.732a47.836,47.836,0,0,1,21.841-40.246l97.66-63.479-41.64-76.341A48.146,48.146,0,0,1,128,207.681V128a80,80,0,0,1,160,0v79.681a48.146,48.146,0,0,1-5.861,22.985L240.5,307.007,312,353.483V315.317l-29.223-19,27.455-50.334A80.23,80.23,0,0,0,320,207.681V128A112.127,112.127,0,0,0,208,16Z" class="ci-primary"></path><polygon fill="var(--ci-primary-color, currentColor)" points="424 400 424 336 392 336 392 400 328 400 328 432 392 432 392 496 424 496 424 432 488 432 488 400 424 400" class="ci-primary"></polygon></svg>
                        {/* <img
                          src={"assets/icons/house.svg"}
                          className="icon-vehicle"
                          alt="house"
                        /> */}
                      </CNavLink>
                    </CNavItem>}

                    {vehicle.length >0 && <CNavItem className="vehicule-form-navItem immobilierIcon">
                      <CNavLink className="vehicule-form-navLink">
                        <img
                          src="assets/icons/icon_car.svg"
                          className="icon-vehicle"
                          alt="icon_car"
                        />
                      </CNavLink>
                    </CNavItem>}

                    {realEstate.length>0  && <CNavItem className="vehicule-form-navItem immobilierIcon">
                      <CNavLink className="vehicule-form-navLink">
                        <img
                          src={"assets/icons/house.svg"}
                          className="icon-vehicle"
                          alt="house"
                        />
                      </CNavLink>
                    </CNavItem>}

                    {goodPlans.length>0  &&  <CNavItem className="vehicule-form-navItem immobilierIcon">
                      <CNavLink className="vehicule-form-navLink">
                        <img
                          src="assets/icons/loisir.svg"
                          className="icon-vehicle"
                          alt="bon_Plans"
                        />
                      </CNavLink>
                    </CNavItem>}

                    {animal.length>0  && <CNavItem className="vehicule-form-navItem immobilierIcon">
                      <CNavLink className="vehicule-form-navLink">
                        <img
                          src={"assets/icons/chien-blanc.svg"}
                          className="icon-vehicle"
                          alt="animals"
                        />
                      </CNavLink>
                    </CNavItem>}

                    {articles.length>0  && <CNavItem className="vehicule-form-navItem immobilierIcon">
                      <CNavLink className="vehicule-form-navLink">
                        <img
                          src="assets/icons/divers.svg"
                          className="icon-vehicle"
                          alt="articles"
                        />
                      </CNavLink>
                    </CNavItem>}

                    {service.length>0  && <CNavItem className="vehicule-form-navItem immobilierIcon">
                      <CNavLink className="vehicule-form-navLink">
                        <img
                          src={"assets/icons/services.png"}
                          className="icon-vehicle widthAuto"
                          alt="services"
                        />
                      </CNavLink>
                    </CNavItem>}

                  </CNav>
                </CCol>
              </div>

              <CTabContent fade={false} className="vehicule-form-tabContent">
                {jobOffer.length>0 && <CTabPane className="tabpane-vehicle-form">
               
    <div className="annoncerPageSection">
            <h4> Offres D'emploi <span> ({jobOffer.length})</span></h4>
            <div className="row itemsRow">
      {jobOffer.map((element) => {
                return (
                  element && <CCol md="3" xs="6" key={element.id}>
                    <ItemCard
                  item={element}
                        id = {element.id}
                         table='JobOffer'
                          title= {element.title}
                          subtitle={
                          element.createdBy.accountType.id==2 ? element.createdBy.nom : 
                          element.company?element.company.name:
                          element.createdBy.company ?
                          element.createdBy.company.name
                          :'job company'
                        }
                          image={
                              element.createdBy.accountType.id==2 ? `${IMAGES_BASE_URL}/public/uploads/members/${element.createdBy.path}`                  
                                  : element.company? `${IMAGES_BASE_URL}/public/uploads/companies/${element.company.path}`
                                    : element.createdBy.company.path
                                    ? `${IMAGES_BASE_URL}/public/uploads/companies/${element.createdBy.company.path}`
                                    : "https://www.keejob.com/static/recruiter/images/default-logo.jpg"
                                }
                                alt={
                          element.createdBy.accountType.id==2 ?   element.createdBy.nom :     
                          element.company?element.company.name:
                          element.createdBy.company ?
                          element.createdBy.company.name
                          :'job company'
                        }     
                          url={`/jobs/job-details/${element.id}/${element.sector.slug}/${element.slug}`}
                   
                        />

                  </CCol>
                );
              })}
            </div>  </div>
                </CTabPane>}

               {vehicle.length>0 && <CTabPane className="tabpane-vehicle-form">

                
                    <div className="annoncerPageSection">
            <h4> Véhicule Annonces <span> ({vehicle.length})</span></h4>
            <div className="row itemsRow">
                  {vehicle.map((element) => {
                  return (
                    element && <CCol md="3" xs="6" key={element.id}>
                    <ItemCard
                        item={element}
                        id = {element.id}
                         table='Vehicle'
                          title={element.brand &&  element.model && element.model.label ? 
                            element.brand.label +' '+ element.model.label 
                            : element.brand &&  element.model ? 
                            element.brand.label +' '+ element.model
                            :element.brand &&  element.category ? 
                            element.brand.label +' '+ element.category.label 
                            :''
                          }
                          price={element.price}
                          city= {element.city && element.city.label}
                          image={
                            element.mainPicture
                              ? `${IMAGES_BASE_URL}/public/uploads/images/${element.type}/${element.mainPicture.name}`
                              : `assets/icons/${element.type}.svg`
                          }
                          url={`/vehicles/${element.type}/details/${element.id}`}
                          at={element.createdAt}
                        />

                    </CCol>
                  );
                })}
            </div>
               </div>
            
                </CTabPane> }

                {realEstate.length>0  && <CTabPane className="tabpane-vehicle-form">                
                    <div className="annoncerPageSection">
            <h4> Immoblier Annonces <span> ({realEstate.length})</span></h4>
            <div className="row itemsRow">
                  {realEstate.map((element) => {
                  return (
                    element && <CCol md="3" xs="6" key={element.id}> 
                    <ItemCard
                    item={element}
                       id = {element.id}
                         table='RealEstate'
                          title={element.title}
                          price={element.price}
                          city= {element.city && element.city.label}
                          image={
                            element.mainPicture
                              ? `${IMAGES_BASE_URL}/public/uploads/images/${element.type}/${element.mainPicture.name}`
                              : `assets/icons/${element.type}.svg`
                          }
                          url={`/real-estates/details/${element.id}`}
                          at={element.createdAt}
                        />
                    </CCol>
                  );
                })}
               </div> </div>
                </CTabPane>}

             {goodPlans.length>0  &&   <CTabPane className="tabpane-vehicle-form">
                
                <div className="annoncerPageSection">
            <h4> Bon Plans Annonces <span> ({goodPlans.length})</span></h4>
            <div className="row itemsRow">

                {goodPlans.map((element) => {
                  return (
                    element &&  <CCol md="3" xs="6" key={element.id}> 
                    <ItemCard
                        item={element}
                        id = {element.id}
                         table='GoodPlans'
                          title={
                            element.nom && element.nom.length > 0
                              ? element.nom
                              : element.typeGoodPlans && element.typeGoodPlans.label
                          }
                          price={element.tickets 
                             ? element.tickets
                             : element.price 
                          }
                          subtitle={element.categoryGoodPlans.label}
                          city= {element.city && element.city.label}
                          image={
                            element.mainPicture
                              ? `${IMAGES_BASE_URL}/public/uploads/images/goodPlans/${element.mainPicture.name}`
                              : "assets/icons/loisir.svg"
                          }
                          url={`/bon-plans/details/${element.id}`}
                          at={element.createdAt}
                        />
                    </CCol>
                  );
                })
                }
              </div>  </div>   
                </CTabPane>} 

              {animal.length>0  &&  <CTabPane className="tabpane-vehicle-form">
                
            <div className="annoncerPageSection">
            <h4> Animaux Annonces <span> ({animal.length})</span></h4>
            <div className="row itemsRow">
                  {animal.map((element) => {
                  return (
                    element && <CCol md="3" xs="" key={element.id}> 
                    <ItemCard
                        item={element}
                        id = {element.id}
                         table='Animal'
                          title={element.title}
                          subtitle={element.typeAnimal && element.typeAnimal.label}
                          city= {element.city && element.city.label}
                          price={element.price ? element.price : ""}
                          image={
                            element.mainPicture
                              ? `${IMAGES_BASE_URL}/public/uploads/images/animal/${element.mainPicture.name}`
                              : "assets/icons/chien-blanc.svg"
                          }
                          url={`/animals/details/${element.id}`}
                          at={element.createdAt}
                        />
                    </CCol>
                  );
                })}
               </div> </div>
                </CTabPane>}

              {articles.length>0  &&  <CTabPane className="tabpane-vehicle-form">                
                    <div className="annoncerPageSection">
            <h4> Articles Annonces <span> ({articles.length})</span></h4>
            <div className="row itemsRow">

                  {articles.map((element) => {
                  return (
                    element &&
                      <CCol md="3" xs="6" key={element.id}> 
                      {
                        <ItemCard
                        item={element}
                        id = {element.id}
                         table='Articles'
                          title={
                            element.nom && element.nom.length>0 ? element.nom
                              : element.categoryArticle ? element.categoryArticle.label
                              : ""
                          }
                          price={element.price}
                          subtitle={
                            element.typeArticle
                              ? element.typeArticle.label
                              : ""
                          }
                          city= {element.city && element.city.label}
                          image={
                            element.mainPicture && element.typeArticle
                              ? `${IMAGES_BASE_URL}/public/uploads/images/${element.typeArticle.section}/${element.mainPicture.name}`
                              : "assets/icons/icone-electromenager.svg"
                          }
                          url={`/articles/details/${element.id}`}
                          at={element.createdAt}
                        />
                      }
                    </CCol>
                  );
                })}
               </div> </div>
                </CTabPane>}

              {service.length>0  &&  <CTabPane className="tabpane-vehicle-form">                
                    <div className="annoncerPageSection">
            <h4> Service Annonces <span> ({service.length})</span></h4>
            <div className="row itemsRow">
                  {service.map((element) => {
                  return (
                    element &&  <CCol md="3" xs="6" key={element.id}> 
                      {
                       <ItemCard
                        item={element}
                        id = {element.id}
                         table='Service'
                          title={element.title}
                          price={element.price}
                          subtitle={element.typeService.label}
                          city= {element.city && element.city.label}
                          image={
                            element.mainPicture
                              ? `${IMAGES_BASE_URL}/public/uploads/images/service/${element.mainPicture.name}`
                              : "assets/icons/house.svg"
                          }
                          url={`/services/details/${element.id}`}
                          at={element.createdAt}
                        />
                      }
                    </CCol>
                  );
                })}
               </div> </div>                
                </CTabPane> }

              </CTabContent>
            </CTabs>
               
                 </main> </div>
            </>
            );
          }

}

export default AnnoncerPage;