  import React from "react";

  export const Basic = (props) => {
return ( 
   <div className="advInfoSzctionCardHead"> 
<span className="advInfoSzctionCardHeadTitle">{props.name && props.name}</span> <br/>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M0 48C0 21.5 21.5 0 48 0l0 48V441.4l130.1-92.9c8.3-6 19.6-6 27.9 0L336 441.4V48H48V0H336c26.5 0 48 21.5 48 48V488c0 9-5 17.2-13 21.3s-17.6 3.4-24.9-1.8L192 397.5 37.9 507.5c-7.3 5.2-16.9 5.9-24.9 1.8S0 497 0 488V48z"/></svg>
 Basic Info </div>
)
  }

  export const BasicMember = (props) => {
    return ( 
       <div className="advInfoSzctionCardHead"> 
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M0 48C0 21.5 21.5 0 48 0l0 48V441.4l130.1-92.9c8.3-6 19.6-6 27.9 0L336 441.4V48H48V0H336c26.5 0 48 21.5 48 48V488c0 9-5 17.2-13 21.3s-17.6 3.4-24.9-1.8L192 397.5 37.9 507.5c-7.3 5.2-16.9 5.9-24.9 1.8S0 497 0 488V48z"/></svg>
    Informations sur le vendeur </div>
    )
      }

  export const Categori = (props) => {
    return (  
      <div className="advInfoSzctionCardHead">
       <span className="advInfoSzctionCardHeadTitle">{props.name && props.name}</span> <br/>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/> <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/> <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/> </svg>     
        Catégorie Info </div>
    )
      }

      export const Equip = (props) => {
        return (  
          <div className="advInfoSzctionCardHead">
<img src="assets/icons/etat.png" alt="" className="icon-option-vehicle"></img>   
         Equipements {props.name && props.name}</div>
        )
          }
       
     export const HouseSetting = (props) => {
            return (  
              <div className="advInfoSzctionCardHead">
    <img src="assets/icons/house-settings.png" alt="" className="icon-option-vehicle"></img>   
        {props.name && props.name}</div>
            )
              }   

    export const HousePlan = (props) => {
                return (  
                  <div className="advInfoSzctionCardHead">
        <img src="assets/icons/house-plan.png" alt="" className="icon-option-vehicle"></img>   
         {props.name && props.name } </div>
                )
                  }         


     export const Options = (props) => {
            return (  
              <div className="advInfoSzctionCardHead">
    <img src="assets/icons/etat.png" alt="" className="icon-option-vehicle"></img>   
            {props.name && props.name}</div>
            )
              }    

     export const Weight = (props) => {
            return (  
              <div className="advInfoSzctionCardHead">
    <img src="assets/icons/weight.png" alt="" className="icon-option-vehicle"></img>   
    Poids & masse</div>
            )
              }

     export const Engine = (props) => {
            return (  
              <div className="advInfoSzctionCardHead">
    <img src="assets/icons/engine-eco.png" alt="" className="icon-option-vehicle"></img>   
    Moteur & environnement</div>
            )
              }

 export const Adresse = (props) => {
        return (  
          <div className="advInfoSzctionCardHead">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>
           Adresse Info </div>
        )
          }

export const Contact = (props) => {
            return (  
              <div className="advInfoSzctionCardHead">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M512 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h448c35.35 0 64-28.65 64-64V96C576 60.65 547.3 32 512 32zM176 128c35.35 0 64 28.65 64 64s-28.65 64-64 64s-64-28.65-64-64S140.7 128 176 128zM272 384h-192C71.16 384 64 376.8 64 368C64 323.8 99.82 288 144 288h64c44.18 0 80 35.82 80 80C288 376.8 280.8 384 272 384zM496 320h-128C359.2 320 352 312.8 352 304S359.2 288 368 288h128C504.8 288 512 295.2 512 304S504.8 320 496 320zM496 256h-128C359.2 256 352 248.8 352 240S359.2 224 368 224h128C504.8 224 512 231.2 512 240S504.8 256 496 256zM496 192h-128C359.2 192 352 184.8 352 176S359.2 160 368 160h128C504.8 160 512 167.2 512 176S504.8 192 496 192z"/></svg>
              
                       Contact Info </div>
            )
         }  

export const Extra = (props) => {
          return (  
            <div className="advInfoSzctionCardHead">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-card-list" viewBox="0 0 16 16"> <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/> <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/> </svg>
             Extra Info </div>
          )
       }       
       
export const Imgs = (props) => {
        return ( 
          <>
          <div className="advInfoSzctionCardHead">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>
          
          Upload Images</div>
          <div className="noteDiv">
        <img  src="assets/icons/landscape-mode.png"/>     
        <span className="highlight-container"><span className="highlight">Veuillez ajouter des images en mode paysage pour une meilleure expérience utilisateur. 
       </span></span><br/>
        <span style={{margin:"0px 0px 30px 45px"}} className="highlight-container"><span className="highlight">
        Vous avez la possibilité d'ajouter <strong>10</strong> images.</span></span>
       </div>
</> 
        )
     } 
     
export const Eventt = (props) => {
      return (  
        <div className="advInfoSzctionCardHead">
<svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24"><path d="M12,19a1,1,0,1,0-1-1A1,1,0,0,0,12,19Zm5,0a1,1,0,1,0-1-1A1,1,0,0,0,17,19Zm0-4a1,1,0,1,0-1-1A1,1,0,0,0,17,15Zm-5,0a1,1,0,1,0-1-1A1,1,0,0,0,12,15ZM19,3H18V2a1,1,0,0,0-2,0V3H8V2A1,1,0,0,0,6,2V3H5A3,3,0,0,0,2,6V20a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V6A3,3,0,0,0,19,3Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V11H20ZM20,9H4V6A1,1,0,0,1,5,5H6V6A1,1,0,0,0,8,6V5h8V6a1,1,0,0,0,2,0V5h1a1,1,0,0,1,1,1ZM7,15a1,1,0,1,0-1-1A1,1,0,0,0,7,15Zm0,4a1,1,0,1,0-1-1A1,1,0,0,0,7,19Z"/></svg>      
{props.name? props.name
 :'Événement Info' }</div>
      )
   }  

  export const  Scroll = (props) => {

    const scrollToNext = () =>
    {window.scrollBy(0,250)}
 

return ( 
  <div className="advInfoSzctionCardBottom">
  <div className="roller" onClick={scrollToNext}>
{/* <span id="rolltext">Scroll  <br/>
Down <br/></span> */}
 <span className="advInfoSzctionCardBottomA">↓</span>
<br/>
</div> 
  
  </div>)
  }