import LoginMember from "../../members/LoginMember";
import { isLogin } from "src/components/middleware/auth";
import { getBadge , textBadge} from "src/actions";
import {
  CAlert,
  CRow,
  CBadge,
  CCol,
  CFormGroup,
  CLabel,
  CInput,
  CButton,
  CSpinner,
  CSelect,
  CInputCheckbox,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import React, { useEffect, useState , useContext} from "react";
import axios from "axios";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import imageCompression from "browser-image-compression";
import { useHistory } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { API_BASE_URL , IMAGES_BASE_URL , tokenCookie ,keyHeader , sizeAlert} from "src/config";
//import Loading from "src/components/myWidgets/loadingWidget";
import { Basic , Scroll , Categori , Adresse , Contact , Extra , Imgs} from "src/components/myWidgets/formTitles";
import { addWatermark , addWatermarkToAlbum } from "src/actions";
import { logoutLocal } from "src/components/middleware/auth";
import MemberContext from "../../members/MemberContext";
import apiClient from "../../apiClient";


export default function AddAnimals(props) {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [price, setPrice] = useState(null);
  const [album, setAlbum] = useState([]);
  const [imgPrincipal, setImgPrincipal] = useState(null);
  const [success, setSuccess] = useState(false);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [delegate, setDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  const [locality, setLocality] = useState("");
  const [localities, setLocalities] = useState([]);
  const [types, setTypes] = useState([]);
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [age, setAge] = useState("");
  const [ageUnit, setAgeUnit] = useState("Année");
  const [size, setSize] = useState("");
  const [sizes, setSizes] = useState([]);
  const [criteria, setCriteria] = useState("");
  const [criteres, setCriteres] = useState([]);
  const [breed, setBreed] = useState("");
  const [breeds, setBreeds] = useState([]);
  
  const [doneuploadingfile, setDoneuploadingfile] = useState(true);
  const [newPhone, setNewPhone] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pImageThumb, setpImageThumb] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [imgCount , setImgCount] = useState(0);
  const [imgCountA , setImgCountA] = useState(0);
  const [otherImages, setOtherImages] = useState([]);
  const [oldImgP, setoldImgp] = useState(false);
  const [annonce, setAnnonce] = useState(null);
  const [id, setId] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [index, setIndex] = useState([]);
  const [item, setItem] = useState(null);
  const [modal, setModal] = useState(false);
  const [setErrorDel] = useState("");
  const [typeImage, setTypeImage] = useState(false);
  const [abortControllers, setAbortControllers] = useState({});
  const [removedfiles, setremovedfiles] = useState([]);
  const [hasChanged , sethasChanged] = useState(false);
  const {animals , setAnimals} = useContext(MemberContext);


  useEffect(() => {

    if(annonce && !hasChanged &&
      (title != annonce.title || (annonce.age && age != annonce.age)
    || (annonce.sizeAnimal && size != annonce.sizeAnimal.id)
    || (annonce.typeAnimal && type!=annonce.typeAnimal.id)
    || (annonce.criteriaAnimal && criteria!=annonce.criteriaAnimal.id)
    || (annonce.breedsAnimal && breed!=annonce.breedsAnimal.id)
    || country != annonce.country.id || city != annonce.city.id 
    || delegate != annonce.delegate.id || locality != annonce.locality.id
    || description != annonce.description 
    || (!newPhone && phoneNumber=='' ) || (annonce.phoneNumber && phoneNumber != annonce.phoneNumber)
    || pImageThumb || imgCountA > 0  
    )
      )
    {
      sethasChanged(true);
      //console.log(' in use : ' , album.length)
    }

  },
 [title  , age  , size , criteria , breed , type , imgCountA , imgPrincipal , description  ,phoneNumber , newPhone , locality , delegate , city , country]
 );


  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

      if ((!(doneuploadingfile && imgCountA == album.length)) || uploadingImage) {
        alert("not done uploading files yet");
        setLoading(false);

        return false;
      } else
     {
        setSubmitted(true);
        if (type && country && city && delegate && locality && imgPrincipal &&            
          (phoneNumber.length==0 || isValidPhoneNumber(phoneNumber))) {
          setLoading(true);

          const watermarkedAlbum = await addWatermarkToAlbum(album);      

          const formData = new FormData();
          formData.append("title", title); 
          formData.append("age", age);    
          formData.append("ageunit", ageUnit); 
          if (price) {
            formData.append("price", price);
          }
          formData.append("description", description); 
          formData.append("size", size);
          formData.append("criteria", criteria); 
          formData.append("breeds", breed); 
          formData.append("locality", locality); 
          if(newPhone)  {formData.append("phone", phoneNumber); }

          for (let index = 0; index < watermarkedAlbum.length; index++) {
            formData.append("album[]", watermarkedAlbum[index]);
          }
          //console.log(formData.get("imgPrincipal"));
       
            //console.log('sud editttttt');
            if (pImageThumb) {
              const watermarkedP = await addWatermark(imgPrincipal);
              formData.append("imgPrincipal", watermarkedP);
              formData.append("newimgPrincipal", 'yes');
          }
          else{
            formData.append("newimgPrincipal", 'no');
          }

          apiClient
          .post("/annonce_animals/edit/"+id, formData )
          .then((res) => {
              //console.log(res);
              if (res.data.status.toString() === "ok") {          
                  setSuccess(true);
                  if(res.data.annonce) { 
                    const updatedAnnonces = [...animals];
                    const elIndex = updatedAnnonces.findIndex(el => el.id === id);
                    if (elIndex !== -1) {
                      updatedAnnonces[elIndex] = res.data.annonce;
                      setAnimals(updatedAnnonces);
                    }
                  }
                  setTimeout(() => {
                          // Update the animals array
                    history.push("/members/profile/animaux");
                   // window.location.replace("/members/profile/animaux");
                  }, 1500);
              }
              setLoading(false)
          })
          .catch((error) => {
              //console.log(" errors : ", error);
              setLoading(false)
              if (error.response && error.response.status === 401){
                logoutLocal();
                window.location.replace("/members/login");
              }
          });

          
        } else {
          window.scrollTo(0, 200);
          setLoading(false);
        }
      }
    
  };

  useEffect(() => {

    if (!localStorage.getItem("countries")) {
      axios(`${API_BASE_URL}/countries/all`)
        .then((res) => res.data)
        .then(
          (result) => {
            setCountries(result);
            localStorage.setItem("countries", JSON.stringify(result));
          
              //console.log("iddd", id)
              if ( props.location.state.country.id !== "0") {
                var countriesF = [];
                countriesF = result.find((city) => city.id.toString() ===  props.location.state.country.id);
                setCities(countriesF.cities);
                if (props.location.state.city.id !== "0") {
                  var citiesF = [];
                  citiesF = countriesF.cities.find((delegate) => delegate.id.toString() === props.location.state.city.id);
                  setDelegates(citiesF.delegations);
                  //console.log('citiesF', citiesF);
                }
                if (props.location.state.delegate.id !== "0") {
                  var delegatesF = [];
                  delegatesF = citiesF.delegations.find((local) => local.id.toString() === props.location.state.delegate.id);
                  setLocalities(delegatesF.localities);
                  //console.log('delegatesF', delegatesF);
                }
              }
            
          },
          (error) => {}
        );
    } else {
      setCountries(JSON.parse(localStorage.getItem("countries")));

        if ( props.location.state.country.id != "0") {
        var countriesF = [];
        countriesF = JSON.parse(localStorage.getItem("countries")).find((city) => city.id.toString() ==  props.location.state.country.id);
        //console.log('countriesF.cities xxxx', countriesF.cities);
        setCities(countriesF.cities);
        if (props.location.state.city.id != "0") {
          var citiesF = [];
          citiesF = countriesF.cities.find((delegate) => delegate.id.toString() == props.location.state.city.id);
          setDelegates(citiesF.delegations);
          //console.log('citiesF', citiesF);
        }
        if (props.location.state.delegate.id != "0") {
          var delegatesF = [];
          delegatesF = citiesF.delegations.find((local) => local.id.toString() == props.location.state.delegate.id);
          setLocalities(delegatesF.localities);
          //console.log('delegatesF', delegatesF);
        }
      } 

    }

    if (!localStorage.getItem("typeAnimals")) 
    { axios(`${API_BASE_URL}/typeAnimals/all`)
      .then((res) => res.data)
      .then(
        (result) => {
          setTypes(result);
         
          localStorage.setItem("typeAnimals", JSON.stringify(result));

          var typesF = [];
          if (props.location.state.typeAnimal.id.toString() !== "0") {
            typesF = result.find((category) => category.id.toString() === props.location.state.typeAnimal.id.toString());
            setSizes(typesF.sizeAnimals);
            setBreeds(typesF.breedsAnimals);
            setCriteres(typesF.criteriaAnimals);
      
            //console.log(typesF);
          }
         
        setIsLoaded(true);
        },
        (error) => {
          setError(error);
        }
      );
  } else {
    setTypes(JSON.parse(localStorage.getItem("typeAnimals")));

      var typesF = [];
      if (props.location.state.typeAnimal.id.toString() !== "0") {
        typesF = JSON.parse(localStorage.getItem("typeAnimals")).find((category) => category.id.toString() === props.location.state.typeAnimal.id.toString());
        setSizes(typesF.sizeAnimals);
        setBreeds(typesF.breedsAnimals);
        setCriteres(typesF.criteriaAnimals);
  
        //console.log(' zzzzzz-',typesF.sizeAnimals,typesF.breedsAnimals,typesF.criteriaAnimals);
      }
    
    setIsLoaded(true);
  }
  
  //console.log('jdkjdk')
        //console.log('propsss', props)
        if(props.location.state) {
          let result = props.location.state
          //console.log("animal source edit--", result);
          
    setId(result.id);

      //console.log('animal ', result)

      setTitle(result.title)
      setAge(result.age)
      if(result.ageunit) setAgeUnit(result.ageunit);
      setAnnonce(result) 
  
      if(result.price){
      setPrice(result.price)
  }
    if(result.country){
      setCountry(result.country.id)
      //setCities(result.country.cities)
      setCity(result.city.id)
     // setDelegates(result.city.delegations)
      setDelegate(result.delegate.id)
     // setLocalities(result.delegate.localities)
      setLocality(result.locality.id)
    }
    setDescription(result.description)
    if(result.typeAnimal){
        setType(result.typeAnimal.id)
       // setSizes(result.typeAnimal.sizeAnimals)
       // setCriteres(result.typeAnimal.criteriaAnimals)
       // setBreeds(result.typeAnimal.breedsAnimals)
    }
    if(result.sizeAnimal){
        setSize(result.sizeAnimal.id)
    }
    if(result.criteriaAnimal)
    {
        setCriteria(result.criteriaAnimal.id)
    }
    if(result.breedsAnimal)
    {
        setBreed(result.breedsAnimal.id)
    }
    if(result.phoneNumber) setPhoneNumber(result.phoneNumber);
  
      if (result.mainPicture) {
          setImgPrincipal(`${IMAGES_BASE_URL}/public/uploads/images/animal/${result.mainPicture.name}`)
          setoldImgp(true);
      }
    if (result.imageAnimals) {
              setOtherImages(result.imageAnimals)
          }

          setIsLoaded(true);
        }



  }, []);

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    let id = e.target.value;
    var countriesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      countriesF = countries.find((city) => city.id.toString() === id);
      setCities(countriesF.cities);
      //console.log("countriesF", countriesF);
    } else {
      setCity([]);
      //  setModel("");
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    let id = e.target.value;
    var citiesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      citiesF = cities.find((delegate) => delegate.id.toString() === id);
      setDelegates(citiesF.delegations);
      //console.log("citiesF", citiesF);
    } else {
      setDelegate([]);
      //  setModel("");
    }
  };

  const handleDelegateChange = (e) => {
    setDelegate(e.target.value);
    let id = e.target.value;
    var delegatesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      delegatesF = delegates.find((local) => local.id.toString() === id);
      setLocalities(delegatesF.localities);
      //console.log("delegatesF", delegatesF);
    } else {
      setLocality([]);
      //  setModel("");
    }
  };
  
  const handleTypeChange = (e) => {
    setType(e.target.value);

    let id = e.target.value;
    var typesF = [];
    if (id !== "0") {
      typesF = types.find((category) => category.id.toString() === id);
      setSizes(typesF.sizeAnimals);
      setBreeds(typesF.breedsAnimals);
      setCriteres(typesF.criteriaAnimals);

      //console.log(typesF);
    } else {
      setSizes([]);
      setSize("");

      setBreeds([]);
      setBreed("");

      setCriteres([]);
      setCriteria("");
    }
  };
  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };
  const handleBreedsChange = (e) => {
    setBreed(e.target.value);
  };
  const handleCriteriaChange = (e) => {
    setCriteria(e.target.value);
  };

  const getUploadParams = ({ meta }) => {console.log(meta);
    return { url: "https://httpbin.org/post" };
    
  };
    
  // called every time a file's `status` changes
  const handleChangeStatus = async ({ meta, file }, status) => {

    if (status === 'preparing' && meta.size > 5 * 1024 * 1024) {
      alert(sizeAlert);
      return;
  //file = null;
    }
    setDoneuploadingfile(false);
    let removedfilesx = removedfiles

    if (status === 'preparing' && !(meta.size > 5 * 1024 * 1024)) { setImgCountA(imgCountA+1)}     
    if (status.toString() === "done" && !(meta.size > 5 * 1024 * 1024)) {  
      let newalb = album;
      let options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
      };
     const abortController = new AbortController();
      const fileId = meta.id;
      const updatedAbortControllers = { ...abortControllers, [fileId]: abortController };
      setAbortControllers(updatedAbortControllers);
      options.signal = abortController.signal;

      try {
        file = await imageCompression(file, options);
      } catch (error) {
        //console.log('erro compress : ',error);
       // return;
      }
         if ((!removedfiles.includes(meta.id) && !removedfilesx.includes(meta.id)) && !(meta.size > 5 * 1024 * 1024)) 
         {
          newalb.push(file);
         // setAlbum(prevAlbum => [...prevAlbum, file]);
         setAlbum(newalb)
          setImgCount(prevCount => prevCount + 1);
    } else {
       //console.log("new album in done else");
    //console.log(newalb.length ,newalb);
   }
      setDoneuploadingfile(true);
    }
   if (status.toString() === "removed" && !(meta.size > 5 * 1024 * 1024)) {
    removedfilesx.push(meta.id);
    setremovedfiles(removedfiles)
  // setremovedfiles(prevA => [...prevA, meta.id]);
      const fileId = meta.id;
      if (abortControllers[fileId]) {
        abortControllers[fileId].abort();
        const updatedAbortControllers = { ...abortControllers };
        delete updatedAbortControllers[fileId];
        setAbortControllers(updatedAbortControllers);
      
      }

      let index = album.findIndex((obj) => obj.name === file.name);
      let newAlbum = album;
     { newAlbum.splice(index, 1);
     if(index>-1) {
     if(imgCount>0) setImgCount(newAlbum.length);}
      if(imgCountA>0) setImgCountA(imgCountA-1);
      setAlbum(newAlbum);
     }
      setDoneuploadingfile(true);

    } else  setDoneuploadingfile(true);
  };
      
  const handleFileSection = async ( event) => {
    setUploadingImage(true);
    
    let file = event.target.files[0];
    if (file.size > 5 * 1024 * 1024) {
      alert(sizeAlert);
      setUploadingImage(false);    
      event.target.value = '';
      return;
    }

    let options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
    };
    try {
      file = await imageCompression(file, options);
    } catch (error) {
      //console.log(error);
    }  
    setoldImgp(false) 
    setpImageThumb(URL.createObjectURL(file));
    setImgPrincipal(file);
    setUploadingImage(false);

  };

  const changePhoneNumber = (event) => {
    //console.log(event.target.checked);
    if(event.target.checked) {setNewPhone(false);setPhoneNumber("");}
    else {setNewPhone(true);
    }
  }

  const toggle = (id, type, i) => {
    setModal(!modal);
    setItem(id)
    setTypeImage(type)
    if(type) {
      let x = otherImages.findIndex(item => item.principal == true);
      setIndex(x)
    }
   else setIndex(i)
};
function deleteImageAnimal(idIm, index) {
  setDeleting(true);
    //console.log("id", idIm, index)

    apiClient
        .delete(`/animals/delete_image/` + idIm )
        .then((res) => {
            toggle();
            if (typeImage) {
                setImgPrincipal(false);
                setoldImgp(false);
            }
            otherImages.splice(index, 1);
            setOtherImages(otherImages);
            if (res.data.status.toString() === "ok") {
                setOtherImages((items) => items.filter((x) => x.idIm !== idIm));
                //console.log('okkd', otherImages)
                //setImgCount(imgCount-1)
            }
            if (
                res.data.status.toString() ===
                "ForeignKeyConstraintViolationException"
            ) {
                setErrorDel(
                    "Vous ne pouvez pas supprimer cette image"
                );
            }
            setDeleting(false);
          })
          .catch((err) => {
            //console.log(err);
            setDeleting(false);})
}


  localStorage.setItem("prevUrl", props.location.pathname);
  
  return localStorage.getItem("roles") === "ROLE_MEMBER" && isLogin() ? (
      <>
        <div className="container addingPage editPage">

            <div className="information-title">
                <h2>modifier animal</h2>  <CBadge color={getBadge(props.location.state.status)}>
                {textBadge(props.location.state.status)} 
                          </CBadge>
              <span className="snSpan snSpanE ">SN: {props.location.state.sn} </span> 
              </div>
              <>
        <form onSubmit={handleSubmit} id="form-add">
        <div className="advInfoSzctionCard" >
        <Basic/>
          <CRow xs="12">
            <CCol xs="6">
              <CFormGroup> 
                <CLabel htmlFor="title">Titre</CLabel>
                <CInput
                  type="text"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                ></CInput>
                {submitted && !title && (
                  <span className="invalid-feedback error">Obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="6" className='formAgeSection'>
              <CFormGroup>
                <CLabel htmlFor="age">Age</CLabel>
                <div className='formAgeSection1'>
                <CInput
                  type="number"
                  name="age"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                ></CInput> &nbsp; &nbsp;
                <CSelect
                  name="ageUnit"
                  value={ageUnit}
                  onChange={(e) => {setAgeUnit(e.target.value); sethasChanged(true)}}
                >
                  <option value="Année">Année</option>
                  <option value="Mois">Mois</option>
                  <option value="Semaine">Semaine</option>
                  <option value="Jour">Jour</option>               
                </CSelect></div>
              </CFormGroup>
            </CCol>
          </CRow>
          <Scroll/>
         </div>

        <div className="advInfoSzctionCard" >
       <Categori/>
          <CRow xs="12">
            <CCol>
              <CFormGroup className='formTypeSelect'>
                <CLabel htmlFor="type">Type </CLabel>
                <CSelect
                  name="type"
                  value={type}
                  onChange={(e) => handleTypeChange(e)}
                >
                  <option value="0"> Choisir le type</option>
                  {types && types.map((type) => {
                    return (
                      <option key={type.id} value={type.id}>
                        {type.label}
                      </option>
                    );
                  })}
                </CSelect>

                {submitted && !type && (
                  <span className="invalid-feedback error">Obligatoire</span>
                )}
              </CFormGroup>
            </CCol>

            {sizes && sizes.length > 0 ? (
              <CCol>
                <CFormGroup>
                  <CLabel htmlFor="size">Taille</CLabel>
                  <CSelect
                    name="size"
                    value={size}
                    onChange={(e) => handleSizeChange(e)}
                  >
                    <option value="0"> Choisir la taille </option>
                    {sizes.map((size) => {
                      return (
                        <option key={size.id} value={size.id}>
                          {size.label}
                        </option>
                      );
                    })}
                  </CSelect>
                  {submitted && !size && (
                    <span className="invalid-feedback error">Obligatoire</span>
                  )}
                </CFormGroup>
              </CCol>
            ) : null}
            {criteres && criteres.length > 0 ? (
              <CCol>
                <CFormGroup>
                  <CLabel htmlFor="criteria">CRITÈRES</CLabel>
                  <CSelect
                    name="criteria"
                    value={criteria}
                    onChange={(e) => handleCriteriaChange(e)}
                  >
                    <option value="0"> Choisir la Critère </option>
                    {criteres.map((criteria) => {
                      return (
                        <option key={criteria.id} value={criteria.id}>
                          {criteria.label}
                        </option>
                      );
                    })}
                  </CSelect>
                  {submitted && !criteria && (
                    <span className="invalid-feedback error">Obligatoire</span>
                  )}
                </CFormGroup>
              </CCol>
            ) : null}

            {breeds && breeds.length > 0 ? (
              <CCol>
                <CFormGroup>
                  <CLabel htmlFor="breed">Races</CLabel>
                  <CSelect
                    name="breed"
                    value={breed}
                    onChange={(e) => handleBreedsChange(e)}
                  >
                    <option value="0"> Choisir la races </option>
                    {breeds.map((breed) => {
                      return (
                        <option key={breed.id} value={breed.id}>
                          {breed.label}
                        </option>
                      );
                    })}
                  </CSelect>
                  {submitted && !breed && (
                    <span className="invalid-feedback error">Obligatoire</span>
                  )}
                </CFormGroup>
              </CCol>
            ) : null}
          </CRow>
          <Scroll/>
          </div>
          {/**
  
            <CRow xs="12">
  
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="price">Prix en TND</CLabel>
                  <CInput
                    type="number"
                    name="price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  ></CInput>
                  {submitted && !price && (
                    <span className="invalid-feedback error">
                      Obligatoire
                    </span>
                  )}
                </CFormGroup>
              </CCol>
  
  
            </CRow> */}
            <div className="advInfoSzctionCard" >
          <Adresse/>
          <CRow xs="12">
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="country">Pays</CLabel>
                <CSelect
                  name="country"
                  value={country}
                  onChange={(e) => handleCountryChange(e)}
                >
                  <option value="0"> Choisissez un pays</option>
                  {countries && countries.map((country) => {
                    return (
                      <option key={country.id} value={country.id}>
                        {country.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !country && (
                  <span className="invalid-feedback error">Obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="Région">Gouvernorat</CLabel>
                <CSelect
                  name="city"
                  value={city}
                  onChange={(e) => handleCityChange(e)}
                >
                  <option value="0"> Gouvernorat</option>
                  {cities && cities.map((city) => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !city && (
                  <span className="invalid-feedback error">Obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow xs="12">
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="delegate">Délégation</CLabel>
                <CSelect
                  name="delegate"
                  value={delegate}
                  onChange={(e) => handleDelegateChange(e)}
                >
                  <option value="0"> Choisissez une délégation</option>
                  {delegates && delegates.map((delegate) => {
                    return (
                      <option key={delegate.id} value={delegate.id}>
                        {delegate.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !delegate && (
                  <span className="invalid-feedback error">Obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="loclité">Localité</CLabel>
                <CSelect
                  name="locality"
                  value={locality}
                  onChange={(e) => setLocality(e.target.value)}
                >
                  <option value="0"> Localité</option>
                  {localities && localities.map((local) => {
                    return (
                      <option key={local.id} value={local.id}>
                        {local.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !locality && (
                  <span className="invalid-feedback error">Obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
          </CRow>
         <Scroll/>
          </div>

          <div className="advInfoSzctionCard" >
       <Contact/>
          <CRow xs="12">
          <CCol xs="6">
              {newPhone && <CFormGroup className='formPhoneNumber'>
                <CLabel htmlFor="phoneNumber">Numéro téléphone</CLabel>
                <PhoneInput
                  type="text"
                  onChange={(value) => setPhoneNumber(value)}
                  placeholder="Entrez un numéro de téléphone"
                  defaultCountry="TN"
                  international
                  name="phoneNumber"
                  id="phoneNumber"
                  value={phoneNumber}
                />
                 {submitted && newPhone && phoneNumber.length>0 && !isValidPhoneNumber(phoneNumber) && (
                  <span className="invalid-feedback error">Ce numéro de téléphone n'est pas valide</span>
                )}
              </CFormGroup>}
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  onChange={changePhoneNumber}                 
                />
                <CLabel variant="checkbox" className="form-check-label">
                Utilisez le numéro téléphone de votre compte
                </CLabel>
              </CFormGroup> 
            </CCol>
          </CRow>
         <Scroll/>
         </div>
        <div className="advInfoSzctionCard" >
     <Extra/>
          <CRow>
            <CCol>
              <CFormGroup>
                {/* <CLabel htmlFor="description">Description</CLabel> */}
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDescription(data);
                  }}
                />

                {submitted && !description > 0 && (
                  <span className="invalid-feedback error">Obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
          </CRow>
          <Scroll/>
</div>

<div className="advInfoSzctionCard" >
          {/* <div className="information-title">
            <h2>Images</h2>
          </div> 
          <br />*/}

          <Imgs/>
    
       
          <CRow>
            <CCol>
            <CFormGroup style={{display:"inline-block"}}>
                <CLabel htmlFor="pImage">Principal image</CLabel>
                <input 
                          type="file"
                          name="pImage"
                          onChange={handleFileSection}
                          accept="image/*"
                           ></input>
            </CFormGroup>
            {uploadingImage ?
            <span style={{height:"fit-content",marginLeft:"10px",lineHeight:"90px"}}>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>
       : !uploadingImage && oldImgP ? 
        <img style={{height: "85px", width: "auto"}}
          src={imgPrincipal}
          alt="preview"
          className="img-fluid img-thumbnail "
        />
       : !uploadingImage && pImageThumb ? 
        <img style={{height: "85px", width: "auto"}}
          src={pImageThumb}
          alt="preview"
          className="img-fluid img-thumbnail "
        /> : ''} 
           {submitted && !imgPrincipal && (
                <span className="invalid-feedback error">
                  Veuillez uploader une image
                </span>
              )}
            </CCol>
          </CRow>
        

          <br/>
   {imgCountA>0 && <> <div className="imgCounter"> {imgCount} {imgCount > 1 ? ' images téléchargées sur ' :' image téléchargée sur ' }  {imgCountA} </div>
       <div className="loading-bar">
          <div className="loading-bar-fill" style={{ width: `${imgCountA==0?0 :(imgCount / imgCountA) * 100}%` }}></div>
        </div><br/></>}

          <CRow>
            <CCol>
            <CLabel >Autres images</CLabel>
            <br />
                    {otherImages.length > 1  ?
                        <>
                          
                            {otherImages.map((other, i) => {

                                return (

                                    <span key={i} className="img-thumbnail-others">
                                        {!other.principal ?
                                            <>

                                                <img
                                                    src={`${IMAGES_BASE_URL}/public/uploads/images/animal/${other.name}`}
                                                    width="100"
                                                    className="img-fluid img-thumbnail "
                                                    alt=""
                                                />
                                                <CButton onClick={(e) => toggle(other.id, false, i)}><CIcon name="cilTrash" /></CButton>

                                            </>

                                            : null}
                                    </span>

                                );

                            })
                            }
                      <br/>  </>
                        : null}
              <Dropzone
              // getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                // onSubmit={handleSubmitF}
                accept="image/*"
                maxFiles={10 - otherImages.length}
                maxSizeBytes={5 * 1024 * 1024}
              >
                <input />
              </Dropzone>
            </CCol>
          </CRow>
          <br />
      </div>
          {success && (
            <CAlert show={true} placement="top-end" color="success">
              {`L'annonce a été ajoutée avec succès !`}
            </CAlert>
          )}

          <CRow>
            <CCol md="3" className="offset-9">
            {((!(doneuploadingfile && imgCountA == album.length)) || uploadingImage)

? (
              <CButton color="primary" block disabled>
                <span>
                  {" "}
                  <CSpinner color="default" size="sm" />
                </span>

                <span> chargement des images</span>
              </CButton>
            )

       :  loading ? (
              <CButton color="primary" block disabled>
                <span>
                  {" "}
                  <CSpinner color="default" size="sm" />
                </span>

                <span> Valider</span>
              </CButton>
            )  
            :
              <CButton color="primary" block type="submit"  disabled={!hasChanged}>
                Valider
              </CButton>
            
}
            </CCol>
          </CRow>
        </form>
        <CModal show={modal} onClose={toggle}>
                              <CModalHeader closeButton></CModalHeader>
                              <CModalBody>
                              voulez vous vraiment supprimer cette image?
                              
                              </CModalBody>
                              <CModalFooter>

                                {!deleting && <CButton
              color="primary"
              onClick={() => {
                deleteImageAnimal(item, index);
              }}
            >
              Oui
            </CButton>}
            {deleting && <CButton
              color="primary"           
            >
             <span>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>
              Oui
            </CButton>}

                                <CButton color="secondary" onClick={toggle}>
                                  Cancel
                                </CButton>
                              </CModalFooter>
                            </CModal>
      </>
  
        </div>
      </>
    ):
    (<LoginMember/>)
    ;

  
}
