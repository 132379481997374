import React from "react";
import LoginMember from "../members/LoginMember";
import CarForm from "./cars/CarForm";
import {
  CCol,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
} from "@coreui/react";
import TruckForm from "./trucks/TruckForm";
import BikeForm from "./bikes/BikeForm";
import MotoForm from "./motos/motoForm";
import { isLogin } from "src/components/middleware/auth";
import "./vehiclestyle.css";
import Breadcrumb from "../Breadcrumb";
export default function AddVehicle(props) {
  localStorage.setItem("prevUrl", props.location.pathname);

  return localStorage.getItem("roles") === "ROLE_MEMBER" && isLogin() ? (
    <>
       <div className="mainBanner">
        <img
          src={"assets/images/car_brands.jpg"}
          alt="tunisia living car brands banner"
        />
      </div>
      <div className="container addingPage">
      <Breadcrumb/>
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div className="information-title">
              <h2>Créer une annonce</h2>
            </div>
            <p></p>
            <CTabs>
              <div className="row ">
                <CCol xs="12" md="12">
                  <CNav variant="tabs" className="vehicule-form-tabs add_page add_pageVichcule ">
                    <CNavItem className="vehicule-form-navItem">
                      <CNavLink className="vehicule-form-navLink">
                        <img
                          src={"assets/icons/icon_car.svg"}
                          className="icon-vehicle"
                          alt="car"
                        />
                      </CNavLink>
                    </CNavItem>
                    <CNavItem className="vehicule-form-navItem">
                      <CNavLink className="vehicule-form-navLink">
                        <img
                          src={"assets/icons/icon_truck.svg"}
                          className="icon-vehicle"
                          alt="truck"
                        />
                      </CNavLink>
                    </CNavItem>
                    <CNavItem className="vehicule-form-navItem">
                      <CNavLink className="vehicule-form-navLink">
                        <img
                          src={"assets/icons/icon_bicycle.svg"}
                          className="icon-vehicle"
                          alt="bicyle"
                        />
                      </CNavLink>
                    </CNavItem>
                    <CNavItem className="vehicule-form-navItem">
                      <CNavLink className="vehicule-form-navLink">
                        <img
                          src={"assets/icons/icon_motorbike.svg"}
                          className="icon-vehicle"
                          alt="motorbike"
                        />
                      </CNavLink>
                    </CNavItem>
                  </CNav>
                </CCol>
              </div>

              <CTabContent fade={false} className="vehicule-form-tabContent">
                <CTabPane className="tabpane-vehicle-form">
                  <CarForm />
                </CTabPane>
                <CTabPane className="tabpane-vehicle-form">
                  <TruckForm />
                </CTabPane>
                <CTabPane>
                  <BikeForm />
                </CTabPane>
                <CTabPane>
                  <MotoForm />
                </CTabPane>
              </CTabContent>
            </CTabs>
          </div>
        </main>
      </div>
    </>
  ) : (
    <LoginMember />
  );
}
