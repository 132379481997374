import {ADD_FAVORITE,REMOVE_FAVORITE, FETCH_FAVORITE} from '../types';
export const addFavoriteTerm =  (data) => {
    return {
      type: ADD_FAVORITE,
      payload: {
        name: data.name,
        description: data.description
      }
    }
};

export const removeFavoriteTerm = name => {
    return {
      type: REMOVE_FAVORITE,
      payload: {
        name
      }
    }
}

export function fetchFavorites(){
   /* return dispatch => {
     dispatch({type:FETCH_FAVORITE});
     JSON.parse(localStorage.getItem('favorites'))
    }*/
    let favorites = [];
    favorites = JSON.parse(localStorage.getItem('favorites'))
    
    return {
        type: FETCH_FAVORITE,
        payload: {
          favorites
        }
      }
}