import React, { Component } from "react";
import axios from "axios";
import { API_BASE_URL, IMAGES_BASE_URL } from "../../../../config";
import ReactHtmlParser from "react-html-parser";
import {
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CEmbed,
  CRow,
} from "@coreui/react";
export class Equipement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      label: null,
      type: null,
      type_annoce: null,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    axios(`${API_BASE_URL}/equipement/show/${id}`)
      .then((res) => res.data)
      .then((result) => {
        this.setState({
          label: result.label,
          type: result.type.label,
          type_annoce : result.type_cannonce
        });
      });
  }

  render() {
 
    
    return (
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="12" lg="12" xl="12">
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <h1>{this.state.label}</h1>
              
                <CCol>
                  <div>{ReactHtmlParser(this.state.type)}</div>
                
                </CCol>

                <CCol>
                  <div>{ReactHtmlParser(this.state.type_annoce)}</div>
                
                </CCol>
               
            
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    );
  }
}

export default Equipement;
