// src/api/apiClient.js
import axios from 'axios';
import { API_BASE_URL } from 'src/config';
import { logout, isLogin } from '../middleware/auth';

// Utility functions to detect Safari and check the domain
function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

function isTunisliveDomain() {
    return window.location.hostname === 'tunisialiving.tn';
}

// Function to get the token from the normal cookie
function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

const apiClient = axios.create({
    baseURL: API_BASE_URL + '/member-api/',
    withCredentials: !(isSafari() && isTunisliveDomain()), // Only send cookies if not on Safari for tunisialiving.tn
    headers: {
        'Content-Type': 'application/json'
    }
});

// Add a request interceptor to add the Authorization header
apiClient.interceptors.request.use(config => {
    if (isSafari() && isTunisliveDomain()) {
        const token = getCookie('token'); // Extract the token from the cookie
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// Add a response interceptor to handle token refresh and errors
apiClient.interceptors.response.use(
    (response) => {
        const newToken = response.headers['x-refresh-token'];
        if (newToken) {
            console.log('New token received:', newToken);
            // Handle token refresh logic if needed
        }
        return response;
    },
    (error) => {
        console.log('Unauthorized error---', error);

        if (error.response && error.response.status === 401) {
            console.log('Unauthorized:', error.response.status);

            // Handle unauthorized access
            if (isLogin()) logout();
        }
        if (error.response && error.response.status === 403) {
            console.log('Forbidden:', error.response.status);
        }
        return Promise.reject(error);
    }
);

export default apiClient;
