import React, { useState, useEffect } from "react";
import ReactWeather, { useOpenWeather } from "react-open-weather";
import axios from "axios";

export const Weather = () =>{
  const [lat, setLat] = useState(48.8534);
  const [lon, setLon] = useState(10.17972);

  const [query, setQuery] = useState("");
  const [city, setCity] = useState("");
  const[error, setError] =useState('');
  const api = {
    key: "12671d01aaa294b710964164a9399f3f",
    base: "https://api.openweathermap.org/data/2.5/",
  };
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLat(position.coords.latitude);
      setLon(position.coords.longitude);
      console.log('effect : ',lat, 'jk', position.coords.latitude)

    });
   
    
    
    console.log('effect : ',lat)
    console.log('effect : ',lon)
  
  },[lat,lon]);
  const { data, isLoading, errorMessage } = useOpenWeather({
    key: "12671d01aaa294b710964164a9399f3f",
    lat: lat,
    lon: lon,
    lang: "fr",
    unit: "metric", // values are (metric, standard, imperial)
    showForecast: false,
 });
  
  const customStyles = {
    fontFamily: "Helvetica, sans-serif",
    gradientStart: "#39a8cf",
    gradientMid: "#2b95bb",
    gradientEnd: "#1d8eb5",
    locationFontColor: "#FFF",
    todayTempFontColor: "#FFF",
    todayDateFontColor: "#B5DEF4",
    todayRangeFontColor: "#B5DEF4",
    todayDescFontColor: "#B5DEF4",
    todayInfoFontColor: "#B5DEF4",
    todayIconColor: "#FFF",
    forecastBackgroundColor: "#FFF",
    forecastSeparatorColor: "#DDD",
    forecastDateColor: "#777",
    forecastDescColor: "#777",
    forecastRangeColor: "#777",
    forecastIconColor: "#4BC4F7",
  };

  const search = (evt) => {
    if (evt.key === "Enter") {
     /* setLat(41.0351)
          setLon(28.9833)
          console.log(lat)
          console.log(lon)*/

      axios
        .get(
          `${api.base}weather?q=${query}&units=metric&APPID=${api.key}`
        )
        .then((response) => {
         
         
         
          console.log(response.data);

          
          setLat(response.data.coord.lat)
          setLon(response.data.coord.lon)
          setCity(response.data.name+', '+response.data.sys.country)
       
          console.log('response.data.coord.lat : ',response.data.coord.lat)
          console.log('response.data.coord.lon : ',response.data.coord.lon)
          console.log(lat)
          console.log(lon)
       
     
          setQuery(""); 
          setError(""); 
      

        })
        .catch(function (error) {
          console.log(error);
         
          setQuery("");
          setError("Sorry, the specified city was not found..")
         
        })
    }
  };
  
 

  return (
<>
{/**
<div>
 latitude: {lat}
 longitude: {lon}
</div> */}
    <div className="weather">
      <input
        type="text"
        className="search-bar-weather"
        placeholder="Enter le nom de la ville"
        onChange={(e) => setQuery(e.target.value)}
        value={query}
        onKeyPress={search}
      />
      <span className="error-weather">{error}</span>
      <ReactWeather
        theme={customStyles}
        isLoading={isLoading}
        errorMessage={errorMessage}
        data={data}
        lang="fr"
        locationLabel={city}
        unitsLabels={{ temperature: "C", windSpeed: "Km/h" }}
        showForecast={false}
      />
    </div>
    </>
  );

}

export default Weather;
