import React, { Component } from "react";
import axios from "axios";
import { API_BASE_URL, IMAGES_BASE_URL } from "../../../config";
import ReactHtmlParser from "react-html-parser";
import {
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CEmbed,
  CRow,
} from "@coreui/react";
export class News extends Component {
  constructor(props) {
    super(props);

    this.state = {
      news: [],
      imageUrl: null,
      createdBy: [],
      content:'',
      newContentMode:true,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    axios(`${API_BASE_URL}/news/show/${id}`)
      .then((res) => res.data)
      .then((result) => {
        //console.log(result);

        if(result.sections&&result.sections.length>0 && result.sections[0].position&&result.sections[0].position<0) 
        {
        this.setState({newContentMode:false});
        }else {
         const originalString = result.content;
         let index = 0;       
         let replacedString = originalString.replace(/image-reference-label.png/g, () => {
           let replacement = IMAGES_BASE_URL+'/public/uploads/actualites/'+result.sections[index].path;
           index = (index + 1); 
           return replacement;
         });
         
         this.setState({
           content:replacedString,});
         }
         

        
        let temp = "By " + result.createdBy.prenom + " " + result.createdBy.nom;
        this.setState({
          news: result,
          imageUrl: `${IMAGES_BASE_URL}/public/uploads/actualites/${result.path}`,
          createdBy: temp,
        });
      });
  }

  render() {
    const news = this.state.news;

    return (
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="12" lg="12" xl="12">
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <h1>{news.title}</h1>
                <CCol>
                  <img
                    src={this.state.imageUrl}
                    className="mb-2 rounded img-fluid"
                    alt=""
                  />
                </CCol>
                <CCol>
                  {/* <div>{ReactHtmlParser(news.content)}</div> */}
                  {this.state.newContentMode? ReactHtmlParser(this.state.content):ReactHtmlParser(news.content)}
                 
                  {!this.state.newContentMode && news.sections &&
                    news.sections.map((section, index) => {
                      return (
                        <div key={index} className="sectionClass sectionClassDashboard">
                          {section.path && (
                            <img
                              src={`${IMAGES_BASE_URL}/public/uploads/actualites/${section.path}`}
                              alt={news.title}
                              className="c-posts__thumbnail"
                            />
                          )}
                          <p>{ReactHtmlParser(section.content)}</p>
                        </div>
                      );
                    })}
                </CCol>

                {news.youtubeLink !== "" && (
                  <CEmbed ratio="16by9">
                    <iframe
                      src={news.youtubeLink}
                      title="youtube link"
                    ></iframe>
                  </CEmbed>
                )}

                <p>
                  <small>
                    {this.state.createdBy ? this.state.createdBy : ""}
                  </small>
                </p>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    );
  }
}

export default News;
