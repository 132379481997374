import React, { Component } from "react";
import { BrowserRouter, Route, Switch, HashRouter } from "react-router-dom";
//import { Router, Route, browserHistory,Switch } from 'react-router';
import "./scss/style.scss";
import IndexFront from "./components/FrontOffice/index";
import LoginBack from "./components/BackOffice/users/login";
import ForgetBack from "./components/BackOffice/users/Forget";
import Soon from "./components/FrontOffice/soon";
import VideoData from "./components/special/videoData";

import PublicRoute from "./components/hooks/PublicRoute";
import { TheLayout } from "./containers";
import { HelmetProvider } from "react-helmet-async";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
//const TheLayout = React.lazy(() => import("./containers/TheLayout"));

class App extends Component {
  render() {
    return (
      <HelmetProvider>
      <BrowserRouter >
        <React.Suspense fallback={loading}>
          <Switch>
            {/**  
             
                <Route exact path="/" render={props => <IndexFront {...this.props}/>}/>
             <Route  path="/5467854" name="Home" render={props => <TheLayout {...props}/>} /> */}
          
             <PublicRoute
              restricted={true}
              component={VideoData}
              path="/vdxdata6542941"
              exact
            />

            <PublicRoute
              restricted={true}
              component={LoginBack}
              path="/5467854/login"
              exact
            />
          {/* <PrivateRoute component={IndexAdmin} path="/5467854/index" exact roles={['ROLE_SUPER_ADMIN','ROLE_JOURNALIST','ROLE_EDITOR_IN_CHIEF']} /> */}

            <PublicRoute
              restricted={true}
              component={ForgetBack}
              path="/5467854/forget"
              exact
            />
            <Route
              path="/5467854"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
           <Route
              path="/"
              render={(props) => <IndexFront {...this.props} />}
            />   
               {/* <Route  path="/20222508" render={props => <IndexFront {...this.props}/>}/> 
              <Route exact path="/" component={Soon}/>   */}
              

          </Switch>
        </React.Suspense>
      </BrowserRouter>
      </HelmetProvider>
    );
  }
}

export default App;
