import React, { useEffect, useState } from "react";
import Loading from "src/components/myWidgets/loadingWidget";
import { useLocation , useHistory } from "react-router-dom";
import { IMAGES_BASE_URL , API_BASE_URL , keyHeader } from "src/config";
import axios from "axios";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import {
  CCol,
  CDataTable,
  CLabel,
  CFormGroup,
  CSelect,
  CInputRadio,
  CButton,
  CInput,
  CSpinner,

} from "@coreui/react";
//import { FilterBonPlans } from "./filterBonPlans";
import ArticleItem from "../OtherAnnonce/articles/articleItem";
import Breadcrumb from "../Breadcrumb";

export default function ResultBonPlans() {
  const location = useLocation();
  const history = useHistory();


  const [all, setAll] = useState([]);
  const [errorAll,setErrorALL]= useState(false);
  const [results , setresults] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [bonPlans, setBonPlans] = useState([]);
  const [isLoadedALL , setisLoadedALL] = useState(false);

  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [delegate, setDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  const [locality, setLocality] = useState("");
  const [localities, setLocalities] = useState([]);
  const [types, setTypes] = useState([]);
  const [type, setType] = useState("");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [datefrom, setDatefrom] = useState();
  const [dateto, setDateto] = useState();
  const [nom, setNom] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [filtersCount, setFiltersCount] = useState(0);
  const [sortBy, setSortBy] = useState('dateD');

  const toggleVisibility = () => {
    //console.log('rdyjgkhlkmgdeefglmdesghg');
    setIsVisible(prev => !prev);
  };
  
  const fields = [{ key: "title", _style: { width: "100%" } }];
  const itemType = "goodPlans";

  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {

    //console.log('paramMMM:', location.search );
    //console.log('paramMMMxxx:', searchParams );

  if (location.search)
    {       
    setType(searchParams.get('type'));
    setCategory(searchParams.get('category'));
    setDatefrom(searchParams.get('datefrom'));
    setDateto(searchParams.get('dateto'));
    setMaxPrice(searchParams.get('maxPrice'));
    setMinPrice(searchParams.get('minPrice'));
    setCountry(searchParams.get('country'));
    setCity(searchParams.get('city'));
    setDelegate(searchParams.get('delegate'));
    setNom(searchParams.get('nom'));

    setFiltersCount(searchParams.size)
  }

  }, [location.search]);

 
  useEffect(() => {
    if (location.state) {   
      //console.log('rtrtrttkkkkkkk : ',location.state.result);
       //console.log('all : ',location.state);
      setresults(location.state.result);
      setAll(location.state.all.all);    
      setisLoadedALL(true)
      setIsLoaded(true);
    } else {
      //console.log('no state : ',location.state);
      
      axios
      (API_BASE_URL + "/good_plans/all",keyHeader)
      .then((res) => res.data)
      .then(
        (result) =>{ 
        //console.log('all result' , result);
        setAll(result);
        setresults(result)    
        setisLoadedALL(true)
        setIsLoaded(true);
        //setdisable('');
      },
      (error) => {
        //console.log(error);
        setErrorALL(true)
      }
      );
    }

    if (!localStorage.getItem("typeGoodPlans")) 
    {
        axios(`${API_BASE_URL}/goodPlans/type-goodPlans/all`)
          .then((res) => res.data)
          .then(
            (result) => {
              //console.log("result555", result);
              setTypes(result);
              localStorage.setItem("typeGoodPlans", JSON.stringify(result));
              var typesF = [];
              typesF = result.find((category) => category.id.toString() === "1");
              setCategories(typesF.categoryGoodPlans);
  
             // setIsloaded(true);
            },
            (error) => {
            }
          );
      } else {
        setTypes(JSON.parse(localStorage.getItem("typeGoodPlans")));
        var typesF = [];
        typesF = JSON.parse(localStorage.getItem("typeGoodPlans")).find((category) => category.id.toString() === "1");
        setCategories(typesF.categoryGoodPlans);

       // setIsloaded(true);
 
      }

      if (!localStorage.getItem("countries")) {
        axios(`${API_BASE_URL}/countries/all`)
          .then((res) => res.data)
          .then(
            (result) => {
              // //console.log("countries", result);
              setCountries(result);
             // setIsLoaded2(true);
             if(location.state && location.state.all.cities) setCities(location.state.all.cities);
             else if (result && searchParams.get('country')) {
              var countriesF=[]
              countriesF = result.find((el) => el.id.toString() == searchParams.get('country'));
              setCities(countriesF.cities);
              //console.log('countriesF',countriesF);
            }
              localStorage.setItem("countries", JSON.stringify(result));
            },
            (error) => {}
          );
      } else {
        setCountries(JSON.parse(localStorage.getItem("countries")));
        if(location.state && location.state.all.cities) setCities(location.state.all.cities);
        else if (JSON.parse(localStorage.getItem("countries")) && searchParams.get('country')) {
         var countriesF=[]
         countriesF = JSON.parse(localStorage.getItem("countries")).find((el) => el.id.toString() == searchParams.get('country'));
        setCities(countriesF.cities);
         //console.log('countriesFfffffff',countriesF);
       }
      }

    // //console.log(location.state.result);
  }, []);


  // useEffect(() => {
  //   var results = all.filter(function (el) { 
  //     const nomc =  el.nom && el.nom !== undefined && el.nom.trim().length>0  && nom && nom.length > 0
  //     ? el.nom.includes(nom)
  //     : false;
  //     //const nomc = !nom || el.nom ? el.nom.includes(nom) : true;
  //     return  nomc ;
  //   });

  //   //console.log('rsddsdxxx ',results)
  //   setresults(results);
  //    }, [ isLoadedALL, nom ]);
  

  useEffect(() => {
    var results = all.filter(function (el) {
      const typec = !type || el.typeGoodPlans.id == type;  
      const categoryc = !category || el.categoryGoodPlans.id == category;  
      const countryc = !country || el.country.id == country;  
      const cityc = !city || el.city.id == city; 
      const delegatec = !delegate || el.delegate.id == delegate;  
      const minPricec = !minPrice || el.price >= minPrice;  
      const maxPricec = !maxPrice || el.price <= maxPrice; 
      const elDate = new Date(el.datef);   
      const datefromc = !datefrom || elDate >= datefrom;  
      const datetoc = !dateto || elDate <= dateto;  
      const nomc =     nom && nom.length > 0
      ? el.nom && el.nom.trim().length > 0 && el.nom.toLowerCase().includes(nom.toLowerCase())
      : true;
      //const nomc = !nom || el.nom ? el.nom.includes(nom) : true;
      return typec && categoryc && nomc && datefromc && datetoc  && countryc && cityc && delegatec && minPricec && maxPricec;
    });

    //console.log('rsddsdxxx ',results)
    setresults(results);
     }, [ isLoadedALL,type, category , nom , country , city , delegate , minPrice , maxPrice , datefrom , dateto]);
  
     const clearAll = () => {
      handleClearSelection('type');handleClearSelection('category');
      handleClearSelection('nom');
      handleClearSelection('datefrom');handleClearSelection('dateto');
      handleClearSelection('minPrice');handleClearSelection('maxPrice');
      handleClearSelection('country');handleClearSelection('city');handleClearSelection('delegate')
          }  
      
          const handleSortBy = (e) => {
            //console.log('sortBy:',e.target.value);
            setSortBy(e.target.value);
            const sortedData = [...results].sort((a, b) => {
         
              const titleA = a.nom !== undefined && a.nom !== null ? a.nom : ''; // Treat null or undefined as empty string
              const titleB = b.nom !== undefined && b.nom !== null ? b.nom : ''; // Treat null or undefined as empty string
          
              const getPrice = (item, isAscending) => {
                const convertToNumber = (value) => {
                  // Convert value to number if it's not null or undefined
                  return value !== null && value !== undefined ? parseFloat(value) || 0 : 0;
                };
                if (item.price !== undefined && item.price !== null && item.price !== 0) {
                  return convertToNumber(item.price); // If 'price' property exists and is valid, return it
                } else if (item.tickets && item.tickets.length > 0) {
                  const nonZeroPrices = item.tickets
                    .filter(ticket => ticket.price !== undefined && ticket.price !== null && ticket.price !== 0)
                    .map(ticket => convertToNumber(ticket.price));
          
                  if (nonZeroPrices.length > 0) {
                    return isAscending ? Math.min(...nonZeroPrices) : Math.max(...nonZeroPrices);
                  }
                }
          
                return isAscending ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER; // Place zero prices at the end in descending order
              };

              if (e.target.value === 'nomC') {
                if (!titleA && !titleB) return 0; // Both are null or empty, maintain current order
                if (!titleA) return 1; // 'a' comes after 'b'
                if (!titleB) return -1; // 'a' comes before 'b'
                return titleA.localeCompare(titleB); // Compare non-null titles
              } else if (e.target.value === 'nomD') {
                if (!titleA && !titleB) return 0; // Both are null or empty, maintain current order
                if (!titleA) return 1; // 'a' comes after 'b'
                if (!titleB) return -1; // 'a' comes before 'b'
                return titleB.localeCompare(titleA); // Compare non-null titles
              }
              
              else    if (e.target.value === 'dateC') {
                return a['createdAt'] > b['createdAt'] ? 1 : -1;
              }
              else    if (e.target.value === 'dateD') {
                return a['createdAt'] < b['createdAt'] ? 1 : -1;
              } 
              else     if (e.target.value === 'price_asc') {
                const priceA = getPrice(a, true);
                const priceB = getPrice(b, true);
                return priceA - priceB; // Ascending order by price
              } else if (e.target.value === 'price_desc') {
                const priceA = getPrice(a, false);
                const priceB = getPrice(b, false);
                return priceB - priceA; // Descending order by price
              }
          
            });
            setresults(sortedData);
           } 

     const updateUrlParams = (e) => {
      searchParams.set(e.target.name, e.target.value);
      const updatedURL = `${location.pathname}?${searchParams.toString()}`;
      history.replace(updatedURL);
    };
 
    const deleteUrlParams = (p) => {
       searchParams.delete(p);
       const updatedURL = `${location.pathname}?${searchParams.toString()}`;
       setFiltersCount(searchParams.size);
       history.replace(updatedURL);
     };
 
     const handleClearSelection = (type) => {
       if(searchParams.get(type))  deleteUrlParams(type) 
       const setStateFunction = `set${type.charAt(0).toUpperCase()}${type.slice(1)}`;
       eval(`${setStateFunction}('')`);
       if(type=='country') { deleteUrlParams('city'); deleteUrlParams('delegate') ; setCity('');setDelegate('') }
       if(type=='city') { deleteUrlParams('delegate') ; setDelegate('') }
     };

     const handleTypeChange = (e) => {
      setType(e.target.value);
  
      let id = e.target.value;
      var typesF = [];
      if (id !== "0") {
        typesF = types.find((category) => category.id.toString() === id);
        setCategories(typesF.categoryGoodPlans);
        setCategory("");
        handleClearSelection('category')
      } else {
        setCategories([]);
        setCategory("");
      }
    };

    const handleCategoryChange = (e) => {
      setCategory(e.target.value);
    };

    const handleCountryChange = (e) => {
      setCountry(e.target.value);
      let id = e.target.value;
      var countriesF = [];
      // //console.log("iddd", id);
      if (id !== "0") {
        countriesF = countries.find((city) => city.id.toString() === id);
        setCities(countriesF.cities);
        // //console.log("countriesF", countriesF);
      } else {
        setCity([]);
        //  setModel("");
      }
    };
  
    const handleCityChange = (e) => {
      setCity(e.target.value);
      let id = e.target.value;
      var citiesF = [];
      // //console.log("iddd", id);
      if (id !== "0") {
        citiesF = cities.find((delegate) => delegate.id.toString() === id);
        setDelegates(citiesF.delegations);
        setDelegate('');
        //  //console.log("citiesF", citiesF);
      } else {
        setDelegate('');
        //  setModel("");
      }
    };


  if (!isLoaded) {
    return (
    <Loading/>
    );
  } else {
    return (
      <div className="container">
      <Breadcrumb/>
        <main className="l-main">
          <div className="l-main__article l-main__content l-main__section l-article ">
            <div className="row">
          
              <CCol md="3">
              <span className="filtersMobButton" onClick={toggleVisibility} >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" id="filter"><path fill="#000" fill-rule="evenodd" d="M20 5h-1.17a3.001 3.001 0 0 0-5.66 0H4a1 1 0 0 0 0 2h9.17a3.001 3.001 0 0 0 5.66 0H20a1 1 0 1 0 0-2zm-4 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM3 12a1 1 0 0 1 1-1h1.17a3.001 3.001 0 0 1 5.66 0H20a1 1 0 1 1 0 2h-9.17a3.001 3.001 0 0 1-5.66 0H4a1 1 0 0 1-1-1zm5 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-4 4a1 1 0 1 0 0 2h9.17a3.001 3.001 0 0 0 5.66 0H20a1 1 0 1 0 0-2h-1.17a3.001 3.001 0 0 0-5.66 0H4zm13 1a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" clip-rule="evenodd"></path></svg> Filters
            </span>
            {filtersCount > 0 && <span className="filtersCountMob filtersCount">{ filtersCount+' appliqués'} <button
             onClick={clearAll} className="btn btn-primary ">
             Supprimer tout <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
<path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"></path>
</svg>
             </button>
              </span>}
              <div className={`bordered filter filtersSectionMob ${isVisible ? 'visibleFiltersmob' : ''}`} >
              <span onClick={toggleVisibility}
              className={`filtersMobButton filtersMobButtonx `} >&times;</span>
              <CLabel className='filtersS'> 
            Filters
            </CLabel>
            {filtersCount > 0 && <span className="filtersCount">{ filtersCount+' appliqués'} <button
             onClick={clearAll} className="btn btn-primary ">
             Supprimer tout <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
            <path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"></path>
            </svg>
             </button>
              </span>}

      <div>
      <span className="filter-title-field">Nom</span> 
      <div className="inputWithSuffix">
                <CInput
                disabled = {isLoadedALL? false : true} 
                  type="text"
                  name="nom"
                  value={nom}
                  onChange={(e) => {setNom(e.target.value);updateUrlParams(e)}}
                ></CInput>
               {nom && nom != "" && <button
         className="clearFilterInput"
          onClick={()=> handleClearSelection('nom')}
        >
          ×
        </button> } </div>
      </div>


      <div>
        <span className="filter-title-field">Type </span>
      </div>
      <div  className='filterOptions'>
      <CSelect name="type" value={type} 
      onChange={(e) => {handleTypeChange(e);
          updateUrlParams(e)
        }}
        style={{
          appearance: type == '0' || type == '' || !type ? 'auto' : 'none',
        }}
      >
        <option value="0"> Choisir le type</option>
        {types.map((type) => {
          return (
            <option key={type.id} value={type.id}>
              {type.label}
            </option>
          );
        })}
      </CSelect>
      {type && type != '0' && (
        <button
         className="clearFilterOption"
          onClick={()=> {handleClearSelection('type');  handleClearSelection('category') ; setCategory('');}}
        >
          ×
        </button>
      )}
      </div>

      <div>
        <span className="filter-title-field">Categorie</span>
      </div>
      <div  className='filterOptions'>
      <CSelect
        name="category"
        value={category}
        onChange={(e) => {handleCategoryChange(e);
          updateUrlParams(e)
        }}
        style={{
          appearance: category == '0' || category == '' || !category ? 'auto' : 'none',
        }}
      >
        <option value="0"> Choisir la categorie </option>
        {categories.map((category) => {
          return (
            <option key={category.id} value={category.id}>
              {category.label}
            </option>
          );
        })}
      </CSelect>
      {category && category != '0' && (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('category')}
        >
          ×
        </button>
      )}
       </div>
      
       {type == 1 &&
                        (category == 1 ||
                          category == 2 ||
                          category == 3 ||
                          category == 8) && (
                          <>
      <div>
      <span className="filter-title-field">Du</span> 
      <div className="inputWithSuffix">
      <DatePicker
         value={datefrom}
         disabled = {isLoadedALL? false : true} 
         //name="datefrom"
         onChange= {(e) => {setDatefrom();
        //  searchParams.set('datefrom', datefrom);
     // const updatedURL = `${location.pathname}?${searchParams.toString()}`;
     // history.replace(updatedURL);
         }}
      />
           <button
         className="clearFilterInput clearFilterInputDate"
          onClick={()=> handleClearSelection('datefrom')}
        >
          ×
        </button> </div>
      </div> 

      <div>
      <span className="filter-title-field">Au</span> 
      <div className="inputWithSuffix">
      <DatePicker
         value={dateto}
         onChange={setDateto}
         disabled = {isLoadedALL? false : true} 
      />
        <button
         className="clearFilterInput clearFilterInputDate"
          onClick={()=> handleClearSelection('dateto')}
        >
          ×
        </button> </div>
      </div> 
      </>
                        )}
      
      <div>
      <span className="filter-title-field">Prix de</span> 
      <div className="inputWithSuffix">
                <CInput
                disabled = {isLoadedALL? false : true} 
                  type="number"
                  name="minPrice"
                  min={1}
                 // max={maxpriceconst}
                  value={minPrice}
                 // placeholder={"Prix de "}
                  onChange={(e) => {setMinPrice(parseInt(e.target.value));updateUrlParams(e)}}
                ></CInput>
               {minPrice && minPrice != "" && <button
         className="clearFilterInput"
          onClick={()=> handleClearSelection('minPrice')}
        >
          ×
        </button> }  <span className="suf">TND</span> </div>
      </div>

      <div>
      <span className="filter-title-field">Prix à </span> 
      <div className="inputWithSuffix">
                <CInput
                disabled = {isLoadedALL? false : true} 
                  min={1}
                 // max={maxpriceconst}
                  type="number"
                  name="maxPrice"
                  //placeholder={"Prix à "}
                  value={maxPrice}
                  onChange={(e) => {setMaxPrice(parseInt(e.target.value));updateUrlParams(e)}}
                ></CInput>
                {maxPrice && maxPrice != "" && <button
         className="clearFilterInput"
          onClick={()=> handleClearSelection('maxPrice')}
        >
          ×
        </button> } <span className="suf">TND</span> </div>
      </div>

      <div>
      <span className="filter-title-field">Pays</span> 
      <div  className='filterOptions'>
      <CSelect
       style={{ appearance: country == '0' || country == '' || !country ? 'auto' : 'none',}}              
                  name="country"
                  value={country}
                  onChange={(e) => {handleCountryChange(e);updateUrlParams(e)}}
                >
                  <option value=""></option>
                  {countries.map((country) => {
                    return (
                      <option key={country.id} value={country.id}>
                        {country.label}
                      </option>
                    );
                  })}
                </CSelect> 
          {country == '0' || country == '' || !country ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('country')}
        >
          ×
        </button>
      )}</div>
      </div>

      <div>
      <span className="filter-title-field">Gouvernorat</span> 
      <div  className='filterOptions'>
      <CSelect
       style={{ appearance: city == '0' || city == '' || !city ? 'auto' : 'none',}}              
                  name="city"
                  value={city}
                  onChange={(e) => {handleCityChange(e);updateUrlParams(e)}}
                >
                  <option value=""></option>
                  {cities.map((city) => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.label}
                      </option>
                    );
                  })}
                </CSelect>
                {city == '0' || city == '' || !city ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('city')}
        >
          ×
        </button>
      )}</div>
      </div>

      <div>
      <span className="filter-title-field">Délégation</span> 
      <div  className='filterOptions'>
      <CSelect
       style={{ appearance: delegate == '0' || delegate == '' || !delegate ? 'auto' : 'none',}}              
                 name="delegate"
                  value={delegate}
                  onChange={(e) => {setDelegate(e.target.value);updateUrlParams(e)}}
                >
                  <option value=""></option>
                  {delegates.map((delegate) => {
                    return (
                      <option key={delegate.id} value={delegate.id}>
                        {delegate.label}
                      </option>
                    );
                  })}
                </CSelect>
                {delegate == '0' || delegate == '' || !delegate ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('delegate')}
        >
          ×
        </button>
      )}</div>
      </div>

      <span className="filtersMobButton filtersMobButtonG " onClick={toggleVisibility} >
              Chercher
            </span>
    
    </div>
              </CCol>

              <CCol>
                <div className="occasions-results layout-list">
                  <div className="articles">
                    Il y a <strong>{results.length} </strong> résultat
                    {results.length > 1 && "s"}
                    {results.length > 0 && 
                    <span className="filtersOrder">
                    <CSelect
      
                 name="sortBy"
                  value={sortBy}
                  onChange={(e) => {handleSortBy(e)}}
                >
                  <option value="dateD">Annonces les plus récentes d'abord</option>
                  <option value="dateC">Annonces les plus anciennes d'abord</option>
                  <option value="nomC">Titre ordre croissant</option>
                  <option value="nomD">Titre ordre décroissant</option>   
                  <option value="price_asc">Prix ordre croissant</option>
                  <option value="price_desc">Prix ordre décroissant</option>                  
                </CSelect>
                  </span>}
                    <CDataTable
                      className="vehicle-data-table"
                      items={results}
                      fields={fields}
                      header={false}
                      itemsPerPage={10}
                      pagination
                      scopedSlots={{
                        title: (bonPlans) => (
                          <td>
                            <ArticleItem
                            item={bonPlans}
                              id={bonPlans.id}
                              at={bonPlans.createdAt}
                              type={
                                bonPlans.nom && bonPlans.nom.length > 0
                                  ? bonPlans.nom
                                  : bonPlans.typeGoodPlans &&
                                    bonPlans.typeGoodPlans.label
                              }
                              category={
                                bonPlans.categoryGoodPlans &&
                                bonPlans.categoryGoodPlans.label
                              }
                              price={bonPlans.tickets 
                             ? bonPlans.tickets
                             : bonPlans.price 
                          }
                              moviemode={
                                bonPlans.nom && bonPlans.nom.length > 0 ? 1 : 0
                              }
                              url={`/bon-plans/details/${bonPlans.id}`}
                              image={
                                bonPlans.mainPicture
                                  ? `${IMAGES_BASE_URL}/public/uploads/images/goodPlans/${bonPlans.mainPicture.name}`
                                  : ""
                              }
                              city={bonPlans.city && bonPlans.city.label}
                              delegate={
                                bonPlans.delegate && bonPlans.delegate.label
                              }
                              locality={
                                bonPlans.locality && bonPlans.locality.label
                              }
                              itemType={itemType}
                              table='GoodPlans'
                            />
                          </td>
                        ),
                      }}
                    />
                  </div>
                </div>
              </CCol>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
