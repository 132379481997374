import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../../config";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Range } from "rc-slider";
//import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

import {
  CLabel,
  CFormGroup,
  CSelect,
  CInputRadio,
  CButton,
  CSpinner,
} from "@coreui/react";
export default function Filter(props) {
  const [makes, setMakes] = useState([]);
  const [make, setMake] = useState("");
  const [models, setModels] = useState([]);
  const [model, setModel] = useState("");
  const [status, setStatus] = useState("");
  const [year, setYear] = useState("");
  const [minMileage, setMinMileage] = useState("");
  const [maxMileage, setMaxMileage] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [fuel, setFuel] =useState("");
  const [transmission, setTransmission] = useState("");
  const [nbrSeat,setNbrSeat] = useState("");
  const [fiscalPower, setFiscalPower] = useState("");
  const [color, setColor] = useState("");
  const [boite,setBoite] = useState("");
  const [bodyStyle,setBodyStyle] = useState("");
  const [loading, setLoading] = useState(false);
  const [warranty, setWarranty] = useState("");
  const [doRequest, setDorequest] = useState(false);
  const [all, setAll] = useState([]);


  const handleMakeChange = (e) => {
    setMake(e.target.value);
    console.log("make hadle change : ", make);
    let id = e.target.value;
    console.log("make hadle change : ", id);
    var makesF = [];
    if (id.toString !== '0') {
      makesF = makes.find((model) => model.id.toString() === id);
      setModels(makesF.models);
    } else {
      setModels([]);
      setModel("");
    }
  };
  useEffect(() => {

    if (!localStorage.getItem("carBrands")) 
    { axios(`${API_BASE_URL}/vehicles/car-brands`)
       .then((res) => res.data)
       .then(
         (result) => {
          setMakes(result);
           //  console.log(result);
     localStorage.setItem("carBrands", JSON.stringify(result));
 
         },
         (error) => {
         }
       );
   } else {
    setMakes(JSON.parse(localStorage.getItem("carBrands")));
   }

  if(makes){
    console.log('filterrrrr',props);
    setAll(props.all.all)
    setStatus(props.all.status)
    setMake(props.all.make)
    setModels(props.all.models)
    setModel(props.all.model)
    setYear(props.all.year)
    setBodyStyle(props.all.bodyStyle)
    setFuel(props.all.fuel)
    setBoite(props.all.boite)
    setNbrSeat(props.all.nbrSeat)
    setWarranty(props.all.warranty)
    setMaxMileage(props.all.maxMileage)
    setMaxPrice(props.all.maxPrice)
    setMinMileage(props.all.minMileage)
    setMinPrice(props.all.minPrice)
    //setColor(props.all.color)

  }


  }, []);
  let history = useHistory();

  const handleSubmit = (e) =>  {
    console.log(make,model)
    e.preventDefault();
    var results = all.filter(function (el) {
      const makec =
        !make || el.brand.id == make;  
      const modelc = model > 0 ?  el.model.id == model : true;
      //userInputAge > 0 ? item.age === userInputAge : true;
      const yearc =  year > 0 ?  el.year == year : true;
    
      return makec && modelc && yearc;
    });

  }

  const handleSubmitOld = (e) => {
    setLoading(true)

    if(
      model
    ) {setDorequest(true);console.log('ddddd')} else console.log('nnnnn')

    e.preventDefault();
    const formData = new FormData();
    formData.append("brand", make);
    formData.append("state", status);
    formData.append("model", model);
    formData.append("year", year);
    formData.append("minMileage", minMileage);
    formData.append("maxMileage", maxMileage);
    formData.append("minPrice",minPrice);
    formData.append("maxPrice", maxPrice);
    formData.append('fuel', fuel);
    formData.append('nbrSeat', nbrSeat);
    formData.append('fiscalPower', fiscalPower);
    formData.append('color', color);
    formData.append('boite', boite);
    formData.append('bodyStyle', bodyStyle);
    axios
      .post(API_BASE_URL + "/annonce_car/search", formData)
      .then((res) => {
        setLoading(false)
        history.push({
          pathname: "/vehicles/cars/search-result",
          state: { result: res.data, keywords: { make, model } },
        });
      })
      .catch((error) => {
        setLoading(false)
        console.log(" errors : ", error);
      });
  };
  const getYear = () => {
    const years = [];
    for (let i = new Date().getFullYear(); i > 1950; i--) {
      years.push(
        <option value={i} key={i}>
          {" "}
          {i}
        </option>
      );
    }
    return years;
  };
  return (
    <div className="bordered filter">
         {/* {!loading && (<CButton onClick={handleSubmit} color="primary" block>
                Chercher
            </CButton>)}

            {loading && (<CButton color="primary" block disabled>
                <span> <CSpinner color="default" size="sm" /></span>

                <span> Chercher</span>

            </CButton>)} */}
            <CLabel variant="checkbox" htmlFor="neuf"> 
            Filters
            </CLabel>

      <div>
    <br />
              <CFormGroup variant="checkbox" inline>
                <CInputRadio
                  className="form-check-input"
                  id="1"
                  name="status"
                  value="1"
                  onChange={(e) => setStatus(e.target.value)}
                />
                <CLabel variant="checkbox" htmlFor="neuf">
                  Neuf
                </CLabel>
              </CFormGroup>
              <CFormGroup variant="checkbox" inline>
                <CInputRadio
                  className="form-check-input"
                  id="0"
                  name="status"
                  value="0"
                  onChange={(e) => setStatus(e.target.value)}
                />
                <CLabel variant="checkbox" htmlFor="occasion">
                  Occasion
                </CLabel>
              </CFormGroup>
      </div>
      <div>
        {" "}
        <span className="filter-title-field">Marques</span>
      </div>
      <CSelect
        name="make"
        value={make}
        onChange={(e) => {
          handleMakeChange(e);
          handleSubmit(e);
        }}
      >
        <option value="0"> Choisir la marque</option>
        {makes.map((make) => {
          return (
            <option key={make.id} value={make.id}>
              {make.label}
            </option>
          );
        })}
      </CSelect>

      <div>
        {" "}
        <span className="filter-title-field">Modèles</span>
      </div>
      <CSelect
        name="model"
        value={model}
        onChange={(e) => {
          setModel(e.target.value);
          handleSubmit(e);
        }}
      >
        <option value="0"> Modèle</option>
        {models.map((model) => {
          return (
            <option key={model.id} value={model.id}>
              {model.label}
            </option>
          );
        })}
      </CSelect>
      <div>
        {" "}
        <span className="filter-title-field">Année</span>
      </div>
      <CSelect
        name="year"
        value={year}
        onChange={(e) => setYear(e.target.value)}
      >
        <option value=""> 1ère immatriculation</option>
        {getYear()}
      </CSelect>
      <div>
        {" "}
        <span className="filter-title-field">Kilométrage</span>
        {minMileage !== "" && (
              <>
                min <span className="min-max">{minMileage} Km</span>
              </>
            )}
            {maxMileage !== "" && (
              <>
                Max <span className="min-max">{maxMileage} Km</span>{" "}
              </>
            )}
            <Range
              min={5000}
              max={300000}
              step={5000}
              onAfterChange={(values) => {
                setMinMileage(values[0]);
                setMaxMileage(values[1]);
              }}
              defaultValue={[5000, 300000]}
            />
      </div>
      <div>
        {" "}
        <span className="filter-title-field">Prix</span>
        {minPrice !== "" && (
              <>
                min <span className="min-max">{minPrice} TND</span>
              </>
            )}
            {maxPrice !== "" && (
              <>
                Max <span className="min-max">{maxPrice} TND</span>{" "}
              </>
            )}
            <Range
              min={5000}
              max={300000}
              step={5000}
              onAfterChange={(values) => {
                setMinPrice(values[0]);
                setMaxPrice(values[1]);
              }}
              defaultValue={[5000, 300000]}
            />
      </div>
      <div>
      <span className="filter-title-field">Carburant</span>
      <CSelect
              name="fuel"
              value={fuel}
              onChange={(e) => setFuel(e.target.value)}
            >
              <option value="">Carburant</option>
              <option value="Essence">Essence</option>
              <option value="Diesel">Diesel</option>
            </CSelect>
      </div>
      <div>
      <span className="filter-title-field">Transmission</span>
      <CSelect
              name="transmission"
              value={transmission}
              onChange={(e) => setTransmission(e.target.value)}
            >
            <option value=""></option>
              <option value="traction intégrale">Traction intégrale</option>
              <option value=" traction avant"> Traction avant</option>
              <option value="propulsion arrière">Propulsion arrière</option>
              <option value="à quatre roues motrices">À quatre roues motrices</option>
            </CSelect>
      </div>
      <div>
      <span className="filter-title-field">Nombre de sièges</span>
      <CSelect
                  name="nbrSeat"
                  value={nbrSeat}
                  onChange={(e) => setNbrSeat(e.target.value)}
                >
                  <option value=""></option>
                  <option value="5">5 sièges</option>
                  <option value="7">7 sièges</option>
                  <option value="9">9 sièges</option>
                </CSelect>
      </div>
      <div>
      <span className="filter-title-field">Puissance fiscale</span>
      <CSelect
                  name="fiscalPower"
                  value={fiscalPower}
                  onChange={(e) => setFiscalPower(e.target.value)}
                >
                  <option value=""></option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="autres">Autres</option>
                </CSelect>
      </div>
      <div>
      <span className="filter-title-field">Boîte</span>
      <CSelect
                  name="boite"
                  value={boite}
                  onChange={(e) => setBoite(e.target.value)}
                >
                  <option value=""></option>
                  <option value="Manuelle"> Manuelle</option>
                  <option value="Automatique">Automatique</option>
                </CSelect>
      </div>
      <div>
      <span className="filter-title-field">Type de carrosserie</span>
      <CSelect
                  name="bodyStyle"
                  value={bodyStyle}
                  onChange={(e) => setBodyStyle(e.target.value)}
                >
                  <option value=""></option>
                  <option value="Citadine">Citadine</option>
                  <option value="Compacte">Compacte</option>
                  <option value="Berline">Berline</option>
                  <option value="Coupé">Coupé</option>
                  <option value="Cabriolet">Cabriolet</option>
                  <option value="4x4">4x4</option>
                  <option value="Monospace">Monospace</option>
                  <option value="Utilitaire">Utilitaire</option>
                  <option value="Pick up">Pick Up</option>
                  <option value="Autres">Autres</option>
                </CSelect>
      </div>
      <div>
      <span className="filter-title-field">Couleur</span>
      <CSelect
                  name="color"
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                >
                  <option value=""></option>
                  <option value="Noir">Noir</option>
                  <option value="Gris argent">Gris argent</option>
                  <option value="Gris anthracite">Gris anthracite</option>
                  <option value="Gris Shark">Gris Shark</option>
                  <option value="Blanc">Blanc</option>
                  <option value="Rouge">Rouge</option>
                  <option value="Bleu">Bleu</option>
                  <option value="Beige">Beige</option>
                  <option value="Jaune">Jaune</option>
                  <option value="Orange">Orange</option>
                  <option value="Vert">Vert</option>
                  <option value="Aubergine">Aubergine</option>
                  <option value="Marron">Marron</option>
                  <option value="Autre">Autre</option>
                </CSelect>
      </div>
    </div>
  );
}
