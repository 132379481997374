import React, { useEffect, useState } from "react";
import { API_BASE_URL, IMAGES_BASE_URL } from "../../../config";
import axios from "axios";

import {
  CCol,
  /* CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,*/
} from "@coreui/react";
import VehicleItem from "./VehicleItem";
import { Link } from "react-router-dom";
import Filter from "./cars/Filter";

export default function Vehicles(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [cars, setCars] = useState([]);
  const { id } = props.match.params;
  useEffect(() => {
    axios(`${API_BASE_URL}/annonce_car/By_brand/${id}`)
      .then((res) => res.data)
      .then(
        (result) => {
          setIsLoaded(true);
          setCars(result);
          //console.log(result);
        },

        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [id, props.location.pathname]);

  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Chargement...</div>
          </div>
        </main>
      </div>
    );
  } else {
    return (
      <div className="container">
        <main className="l-main">
          <div className="">
            <div className="l-main__article l-main__content l-main__section l-article ">
              <div className="row">
                <CCol md="3">
                  <Filter/>
                </CCol>

                <CCol>
                  <div className="occasions-results layout-list">
                    <div className="articles">
                      {cars &&
                        cars.map((car) => {
                          return (
                            <Link to={`/vehicles/cars/details/${car.id}`} key={car.id}>
                              <VehicleItem
                               table='Vehicle'
                                make={car.brand && car.brand.label}
                                model={car.model && car.model.label}
                                price={car.price}
                                image={car.mainPicture &&`${IMAGES_BASE_URL}/public/uploads/images/cars/${car.mainPicture.name}`}
                                mileage={car.mileage}
                                city= {car.city}
                                boite= {car.boite}
                                fuel= {car.fuel}
                                year ={car.year}
                              />
                            </Link>
                          );
                        })}
                    </div>
                  </div>
                </CCol>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
