import { CButton, CCol, CInput, CLabel, CFormGroup } from '@coreui/react'
import React, { useState } from 'react'
import axios from "axios";
import { API_BASE_URL } from "../../../../config";
import { useHistory } from "react-router-dom";

//const rx_live = /^[0-9]*/;
export const SizeBike = (props) => {
  //  const [clicked, setClicked] = useState(false);
    const [error, setError] = useState(false)
    const [size, setSize] = useState('');
    const [result, setResult] = useState('');
  //  const [frameHeight,setFrameHeight] = useState("");

    const handleClick = () => {
       // setClicked(true);
        //console.log(size)

        if (!error) {
            setResult(parseInt(size) * 0.65)
        }
    }
    const handleChange = (e) => {
        setSize(e.target.value);
        if (isNaN(size)) {
            setError(true)
        } else {
            setError(false)

        }

    }
    let history = useHistory();

    const search = (e) => {

        setSize(e.target.value);
        if (isNaN(size)) {
            setError(true)
        } else {
            setError(false)
            //console.log(result)
            const formData = new FormData();
            formData.append("frameHeight", result);
            axios
            .post(API_BASE_URL + "/annonces_bikes/searchByFeameHeight", formData)
            .then((res) => {
              //console.log(res.data);
              //console.log('history',history)
              history.push({
                pathname: "/vehicles/bikes/search-result",
                state: { result: res.data },
              });
            })
            .catch((error) => {
              //console.log(" errors : ", error);
            });

        }

    }
    return (
        <div className="bike-size-container">
            <h3>Calculez la taille de votre vélo</h3>

            <CLabel>Indiquez votre longueur de jambe en cm</CLabel>
            <CFormGroup row>
                <CCol xs="6">
                    <CInput type="text" pattern="[0-9]*" name="size" value={size} onChange={handleChange}></CInput>
                    {error && (
                        <span className="invalid-feedback error">
                            Veuillez entrer la longueur de votre jambre en cm. <br />
                  La valeur doit se trouver entre 65 et 90 centimètres
                        </span>
                    )}
                </CCol>
                <CCol xs="6"> <CButton className="btn btn-primary" onClick={handleClick}> Calculer</CButton></CCol>

                </CFormGroup>
            <CFormGroup row>
                <CCol xs="6">
                    <div>
                        {" "}
                        <span className="filter-title-field">   {result}</span>
                    </div>
                </CCol>
                {result
              ?
                <CCol xs="6"> <CButton className="btn btn-primary" onClick={search}> Chercher</CButton></CCol>
                :
                null}
                </CFormGroup>



        </div>
    )
}
