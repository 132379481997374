import { ADD_FAVORITE, REMOVE_FAVORITE ,FETCH_FAVORITE} from "../types";
const INITIAL_STATE = {
  favorites: [],
  vehicle:[]
};
export default function favoritesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_FAVORITE:
      return [...state.favorites, action.payload];
    case REMOVE_FAVORITE:
      return state.favorites.filter((e) => {
        if (e.name !== action.payload.name) {
          return true;
        }
        return false;
      });
    case FETCH_FAVORITE:
      return  action.payload;
    default:
      return state;
  }
}
