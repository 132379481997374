
import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

//import { isLogin } from "../middleware/auth";
import {
CCol,

} from "@coreui/react";

export default function MemberCard(props) {


  return (
    <>
    <div className="vehicle-item memberCard">

      <Link 
      //to={props.url} 
      to = {{ pathname:props.url,
              state:props.item,}}
      className="itemCardLink">
        <div className="c-posts__media vehicle-media ">
          <img className=" similar-car" src={props.image} alt={props.title} />
        </div>
        <div className="row pt-4 pb-4 pl-4">
          <CCol md="12" className="itemCard1H2P">
            <h4 className="itemCardTitle">
              {props.title}
            </h4> 
           
           
          </CCol>

          <div className="mx-auto">
            {/* <CButton color="danger" className="btn-vehicle ">
              <img src="assets/icons/eye.svg" />
            </CButton> */}
            {props.at && <>
              <img
                    src={"assets/icons/icon_calandar.svg"}
                    alt=""
                    className="place-icon-itemCard"
                  /> Membre depuis le  <Moment format="DD-MM-YYYY">{props.at}</Moment></>}
          </div>
        </div>
      </Link>
    </div>


    </>
  );
}
