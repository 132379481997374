import React, { useEffect, useState  } from "react";
import { IMAGES_BASE_URL , API_BASE_URL , tokenU} from "src/config";
import { CCol, CRow , CButton , CSpinner ,  CModal, CBadge ,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CAlert,
    CLabel,
} from "@coreui/react";
import ImageGallery from "react-image-gallery";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import { getBadge ,  textBadge} from "src/actions";
import Moment from "react-moment";
import apiUser from "../apiUser";

export default function BonPlanDetails(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [item, setItem] = useState();
  const [pictures, setPictures] = useState([]);
  const { id } = props.match.params;
  const [iconSrc, setIconSrc] = useState("");
  const [mainImg, setMainImg] = useState("");
  const [itemType, setItemType] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [modal, setModal] = useState(false);
  const [msg, setMsg] = useState("");
  const [erroru, setErroru] = useState(null);
  const [success, setSuccess] = useState(false);
  const [movieMode, setMovieMode] = useState(false);
  const [pricess, setPricess] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [videoframe, setVideoframe] = useState("");
  const [pricefav, setpricefav] = useState("");
  const [seance23, setSeance23] = useState([]);
  const [menu, setMenu] = useState([]);
  const [menuLink, setMenuLink] = useState([]);

  const  table  = "GoodPlans";
  
  const toggle = () => {
    setModal(!modal);
    setSuccess(false);
    setErroru(false)
  };

  function setApproved() {

    //console.log(msg);
    const formData = new FormData();
    formData.append("itemId", id);

    formData.append("table", table);
setLoading(true)
setLoading1(true)

    apiUser
      .post(`/setStatusApproved`,formData)
      .then((res) => {
        //console.log("dddddddd");
        //console.log(res);
        if (res.data.status.toString() === "ok") {
          let temp = item;
          temp.status = 'approved';
          setItem(temp);
         setLoading(false)
         setLoading1(false)      
         setSuccess(true);
          setTimeout(() => {
            setSuccess(false); 
            setModal(false);
          }, 2000);
        } else {  setErroru(true)
            setLoading(false)
         setLoading1(false)
 } 
      })
      .catch((err) =>
      { //console.log(err);
        setLoading(false);
        setLoading1(false);
      }
        );
  }
  
  function setPending() {

    //console.log(msg);
    const formData = new FormData();
    formData.append("itemId", id);

    formData.append("table", table);
setLoading(true)
setLoading4(true)

    apiUser
      .post(`/setStatusPending`,formData)
      .then((res) => {
        //console.log("dddddddd");
        //console.log(res);
        if (res.data.status.toString() === "ok") {
          let temp = item;
          temp.status = 'pending';
          setItem(temp);
         setLoading(false)
         setLoading4(false)       
         setSuccess(true);
          setTimeout(() => {
            setSuccess(false); 
            setModal(false);
          }, 2000);
        } else {  setErroru(true)
            setLoading(false)
         setLoading4(false)  } 
      })
      .catch((err) =>
      { //console.log(err);
        setLoading(false);
        setLoading4(false)
      }
        );
  }

  function setEditing() {

    //console.log(msg);
    const formData = new FormData();
    formData.append("itemId", id);
  
    formData.append("table", table);
    formData.append("msg", msg)
setLoading(true)
setLoading2(true)

//console.log("msg get" , formData.get("msg"));

    apiUser
      .post(`/setStatusEditing`,formData)
      .then((res) => {
        //console.log("dddddddd");
        //console.log(res);
        if (res.data.status.toString() === "ok") {
          let temp = item;
          temp.status = 'editing';
          setItem(temp);
         setLoading2(false)       
         setSuccess(true);
          setTimeout(() => {
            setSuccess(false); 
            setModal(false);
            setLoading(false)
          }, 2000);
        } else {  setErroru(true)
            setLoading(false)
         setLoading2(false)  } 
      })
      .catch((err) =>
      { //console.log(err);
        setLoading(false);
        setLoading2(false)
      }
        );
  }

  function setRejected() {
    //console.log(msg , table);
    const formData = new FormData();
    formData.append("itemId", id);

    formData.append("table", table);
setLoading(true)
setLoading3(true)

    apiUser
      .post(`/setStatusRejected`,formData)
      .then((res) => {
        //console.log("dddddddd");
        //console.log(res);
        if (res.data.status.toString() === "ok") {
          let temp = item;
          temp.status = 'rejected';
          setItem(temp);
         setLoading(false)
         setLoading3(false)        
         setSuccess(true);
          setTimeout(() => {
            setSuccess(false); 
            setModal(false);
          }, 2000);
        } else {  setErroru(true)
            setLoading(false)
         setLoading3(false)  } 
      })
      .catch((err) =>
      { //console.log(err);
        setLoading(false);
        setLoading3(false)
      }
        );
  }

  useEffect(() => {

    //console.log('propsdsrdhf------',id);
    //console.log(props.location.state);

    setIsLoaded(false); 
      let tab = [];
      setPictures([]); 
      if(props.location.state) {
        //console.log('yessssss state');
    
            if (props.location.state.seance23 && props.location.state.seance23.length > 2) {
              setSeance23(props.location.state.seance23.split("++"));
            }
            let imglink = "";
   
  
            let videolink = props.location.state.video;
  
            if (videolink.includes("youtube")) {
              if (videolink.includes("?v=")) {
                let l = videolink.split("?v=")[1];
                let temp =
                  '<iframe width="560" height="315" src="https://www.youtube.com/embed/' +
                  l +
                  '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>';
                setVideoframe(temp);
              }
            } else setVideoframe(videolink);
    
            let tp = props.location.state.typeGoodPlans.id;
            //console.log("ttppppppppp", tp);
            switch (tp) {
              case 1:
                setIconSrc("assets/icons/loisir.svg");
                break;
              case 2:
                setIconSrc("assets/icons/soiree-dj.svg");
                break;
              case 3:
                setIconSrc("assets/icons/food-drink.svg");
                break;
              case 4:
                setIconSrc("assets/icons/hotels.svg");
                break;
              case 5:
                setIconSrc("assets/icons/bien-etre.svg");
                break;
              case 6:
                setIconSrc("assets/icons/shopping.svg");
                break;
              default:
                setIconSrc("");
            }
  
            setIsLoaded(true);
            setItem(props.location.state);
            props.location.state.imageGoodPlans.map((image) => {
              if (image.menu) {
                setMenu(image.name);
                setMenuLink(`${IMAGES_BASE_URL}/public/uploads/images/goodPlans/${image.name}`);
              }
              else if(image.principal) { imglink =
                IMAGES_BASE_URL +
                "/public/uploads/images/goodPlans/" +
                image.name;
            setMainImg(imglink);}
               else return tab.push({
                  original: `${IMAGES_BASE_URL}/public/uploads/images/goodPlans/${image.name}`,
                  thumbnail: `${IMAGES_BASE_URL}/public/uploads/images/goodPlans/${image.name}`,
                });
            });
           tab.unshift({
            original: imglink,
            thumbnail: imglink,
          })
            setPictures(tab);

  
      }

 
  }, [id]);

  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div className="loading_logo">
              <img
                src={"assets/icons/tunisia-living-loading-icon.gif"}
                alt="tunisia living loading icon"
              />
              <p>Chargement...</p>
            </div>
          </div>
        </main>
      </div>
    );
  } else {

    return (
      <div className="container dashBoardDetails">
        <main className="l-main">
          <div className="l-main__grid o-container">
            <div className="l-main__article2 l-main__article l-main__content l-main__section l-article ">
        
              <CRow className='dashBoardDetailsTop'>
              <CCol md="9">
             
              <div className="features-vehicle features2">
              <div className="sectionHeadingB">  <CLabel> Info de l'Annonce</CLabel> </div><br/>
              <CRow>              
                <CCol md="6">
                <h3>      {item.nom && item.nom.length > 0
                      ? item.nom
                      : item.typeGoodPlans &&
                      item.typeGoodPlans.label}</h3>
                </CCol>  
                <CCol md="6" >
                <h5>Numéro de série : {item.sn && item.sn} </h5>
                 </CCol>
               
              </CRow> <br/>

                <CRow>

                <CCol md="6" xs="6" >
                <svg  className="icon-option-vehicle" viewBox="0 0 20 20">
							<path d="M12.075,10.812c1.358-0.853,2.242-2.507,2.242-4.037c0-2.181-1.795-4.618-4.198-4.618S5.921,4.594,5.921,6.775c0,1.53,0.884,3.185,2.242,4.037c-3.222,0.865-5.6,3.807-5.6,7.298c0,0.23,0.189,0.42,0.42,0.42h14.273c0.23,0,0.42-0.189,0.42-0.42C17.676,14.619,15.297,11.677,12.075,10.812 M6.761,6.775c0-2.162,1.773-3.778,3.358-3.778s3.359,1.616,3.359,3.778c0,2.162-1.774,3.778-3.359,3.778S6.761,8.937,6.761,6.775 M3.415,17.69c0.218-3.51,3.142-6.297,6.704-6.297c3.562,0,6.486,2.787,6.705,6.297H3.415z"></path>
						</svg>
                    <p className="title-feature">Auteur</p>
                    <p className="title-feature1">
                    {item.seller && item.seller.length > 0 ? item.seller 
                  : item.createdBy && item.createdBy.accountType && item.createdBy.accountType.id==2? item.createdBy.nom
                  : item.createdBy&& item.createdBy.prenom ? 
                    item.createdBy.prenom+' '+ item.createdBy.nom.charAt(0): ''} 
                    </p>
                    <p></p>
                  </CCol>
                  <CCol md="6" xs="6" >                
                  <svg className="icon-option-vehicle" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24"><path d="M12,19a1,1,0,1,0-1-1A1,1,0,0,0,12,19Zm5,0a1,1,0,1,0-1-1A1,1,0,0,0,17,19Zm0-4a1,1,0,1,0-1-1A1,1,0,0,0,17,15Zm-5,0a1,1,0,1,0-1-1A1,1,0,0,0,12,15ZM19,3H18V2a1,1,0,0,0-2,0V3H8V2A1,1,0,0,0,6,2V3H5A3,3,0,0,0,2,6V20a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V6A3,3,0,0,0,19,3Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V11H20ZM20,9H4V6A1,1,0,0,1,5,5H6V6A1,1,0,0,0,8,6V5h8V6a1,1,0,0,0,2,0V5h1a1,1,0,0,1,1,1ZM7,15a1,1,0,1,0-1-1A1,1,0,0,0,7,15Zm0,4a1,1,0,1,0-1-1A1,1,0,0,0,7,19Z"/></svg>      
                    <p className="title-feature">Date d'ajout</p>
                    <p className="title-feature1">
                    <Moment format="DD/MM/YYYY">
                        {item.createdAt}
                      </Moment> 
                    </p>
                    <p></p>
                  </CCol>
                  </CRow>
                    <CRow> 
                    <br/> <div className="actionButCon actionButConA">
              <CBadge className="bigBadge" color={getBadge(item.status,item.edits)}>
               Statut de l'annonce : {textBadge(item.status, item.edits)}
                          </CBadge> 
              </div> <br/>
                    </CRow>
              </div>
           
              </CCol>
              <CCol md="3">
              <div className="features-vehicle features-vehicle2 features2">
          <div className="sectionHeadingB">  <CLabel>Actions</CLabel> </div> <br/>
              <div className="actionButCon">
                   <CButton
                    size="sm"
                    className="ml-1 actionButDet btn-primary"
                    disabled={loading || item.status=='approved'}
                    onClick={() => {
                        setApproved();
                    }}
                  >
                  {loading1 && <CSpinner size="sm"/>} Validé
                  </CButton>     
  
                <CButton
                  size="sm"
                  className="ml-1 actionButDet btn-primary"
                  disabled={loading || item.status=='editing' }
                  onClick={() => 
                  toggle()
                  }
                >
               Mettre à niveau
                </CButton>
                
                <CButton
                    size="sm"
                    className="ml-1 actionButDet btn-primary"
                    disabled={loading || item.status!='approved'}
                    onClick={() => {
                        setPending();
                    }}
                  >
                  {loading4 && <CSpinner size="sm"/>}  Annuler la diffusion
                  </CButton>

              <CButton
                size="sm"
                color="danger"
                className="ml-1 actionButDet"
                disabled={loading || item.status=='rejected'}
                onClick={() => setRejected()}
              >
              {loading3 && <CSpinner size="sm"/>}  Suppression
              </CButton>
       </div> </div>
              </CCol>
              </CRow>
             
           <br/><br/>
<div className="features-vehicle" style={{paddingTop:'0px',paddingLeft:'0px',paddingRight:'0px'}}>
 <div className="sectionHeadingB">  <CLabel> Annonce Preview</CLabel> </div> 
<br/><br/>
      <div className="annoncePreview ">
        
      <CRow>
                {pictures.length >= 1 && (
                  <ImageGallery items={pictures} thumbnailPosition="right" />
                )}
              </CRow>
              <CRow>
                <CCol md="8" className="mt-4">
                  <h3>
                    {item.nom && item.nom.length > 0
                      ? item.nom
                      : item.typeGoodPlans &&
                      item.typeGoodPlans.label}
                  </h3>
                  <div className="price">
                  
                  {item.tickets && item.tickets.length>1 
                           ? (Math.min(...item.tickets.map(item => parseFloat(item.price))).toLocaleString('en-US', {
  useGrouping: true,
  maximumFractionDigits: 0,
}) + '-' + Math.max(...item.tickets.map(item => parseFloat(item.price))).toLocaleString('en-US', {
  useGrouping: true,
  maximumFractionDigits: 0,
})
)
                           : item.tickets && item.tickets.length==1
                           ? item.tickets[0].price.toLocaleString('en-US', {
  useGrouping: true,
  maximumFractionDigits: 0,
})
                           : item.price 
                           ?item.price.toLocaleString('en-US', {
  useGrouping: true,
  maximumFractionDigits: 0,
}) 
                           :''
                        }
                  <span className="suffix"> TND</span>
                </div>
                </CCol>
                <CCol>
                  <div className="mt-4">
                  {item.createdBy && item.createdBy.id != 68 && <p className="title-feature appelez_le">
                  Le vendeur <strong> 
                  {item.seller && item.seller.length > 0 ? item.seller 
                  : item.createdBy && item.createdBy.accountType && item.createdBy.accountType.id==2? item.createdBy.nom
                  : item.createdBy&& item.createdBy.prenom ? 
                    item.createdBy.prenom+' '+ item.createdBy.nom.charAt(0): ''} </strong>
                  </p> 
                  }  
                    <a
                      href={`tel:${item.phoneNumber && item.phoneNumber.length > 0 ?
                        item.phoneNumber
                          :
                          item.createdBy && item.createdBy.phoneNumber ?
                          item.createdBy.phoneNumber
                            : ''
                        }`}
                      className="call-phone"
                    >
                      <span>
                    <svg style={{color: "white"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" fill="white"></path></svg> Appelez {item.phoneNumber && item.phoneNumber.length > 0 ?
                          item.phoneNumber
                          :
                          item.createdBy && item.createdBy.phoneNumber ?
                          item.createdBy.phoneNumber
                            : ''}
                      </span>
                    </a>
                  </div>
                </CCol>
              </CRow>

              <div className="features-vehicle">
                <CRow>
                  <CCol md="6" xs="6" className="filterWhiteIcon">
                    <img
                      src={iconSrc}
                      alt={
                        item.typeGoodPlans && item.typeGoodPlans.label
                      }
                      className="icon-option-vehicle"
                    />
                    <p className="title-feature">type </p>
                    <p className=" title-feature1">
                      {item.typeGoodPlans && item.typeGoodPlans.label}
                    </p>
                    <p></p>
                  </CCol>
                  <CCol md="6" xs="6">
                    <p className="title-feature">Categorie </p>
                    <p className="title-feature12">
                      {item.categoryGoodPlans &&
                        item.categoryGoodPlans.label}
                    </p>
                    <p></p>
                  </CCol>
                </CRow>

                <CRow className="placeInfoRow1">
                  <CCol md="6" xs="6">
                    <img
                      src="assets/icons/icon_map.svg"
                      alt=""
                      className="icon-option-vehicle"
                    />
                    <p className="title-feature">Pays </p>
                    <p className="title-feature1">
                      {item.country && item.country.label}
                    </p>
                  </CCol>
                  <CCol md="6" xs="6">
                    <p className="title-feature">Gouvernorat</p>
                    <p className="title-feature12">
                      {item.city && item.city.label}
                    </p>
                  </CCol>
                </CRow>
                <CRow className="placeInfoRow placeInfoRow1">
                  <CCol md="6" xs="6" className="placeInfoCol">
                    <p className="title-feature">Délégation</p>
                    <p className="title-feature12">
                      {item.delegate && item.delegate.label}
                    </p>
                  </CCol>
                  <CCol md="6" xs="6">
                    <p className="title-feature">Localité</p>
                    <p className="title-feature12">
                      {item.locality && item.locality.label}
                    </p>
                  </CCol>
                </CRow>
                {menu && menu.length > 0 &&
                  <CRow>
                    <CCol md="12" xs="6" className="filterWhiteIcon">
                      <a style={{ color: 'unset' }}
                        href={menuLink}
                      >  <svg className="icon-option-vehicle bi bi-file-text" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16"> <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" /> <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" /> </svg>

                        <p className="title-feature">menu</p>
                        <p className=" title-feature1">

                          {menu}

                        </p></a>
                      <p></p>
                    </CCol>
                  </CRow>}
                  {
                  <CRow className="placeInfoRow placeInfoRow1">
                    {item.adresse && <CCol md="6" xs="6" className="placeInfoCol addresInfoCol">
                      <p className="title-feature">Adresse </p>
                      <p className="title-feature12">
                        { item.adresse}
                      </p>
                    </CCol>}
                   { item.datef && <CCol md="6" xs="6" className="dateInfoCol">
                      <p className="title-feature">Date et heure </p>
                      <p
                        className="title-feature12"
                        style={{ textTransform: "inherit" }}
                      >
                        {item.datef.replace("T", " à ").split("+")[0]}
                      </p>
                    </CCol>}
                  </CRow>
                }
                { seance23[0] && seance23[0].length > 0 && (
                  <CRow className="placeInfoRow placeInfoRow1">
                    <CCol md="6" xs="6" className="placeInfoCol">
                      <p className="title-feature">2em seance à </p>
                      <p className="title-feature12">{seance23[0]}</p>
                    </CCol>
                    { seance23[1] && seance23[1].length > 0 && (
                      <CCol md="6" xs="6">
                        <p className="title-feature">3em seance à </p>
                        <p
                          className="title-feature12"
                          style={{ textTransform: "inherit" }}
                        >
                          {seance23[1]}
                        </p>
                      </CCol>
                    )}
                  </CRow>
                )}
                {item.tickets && item.tickets.length>0 &&(
                  <>
                    <p className="title-feature">Prix des tickets</p>
                    <CRow>
                      {item.tickets.map((el) => {
                        return (
                          <>
                            <CCol md="6" xs="6" className="ticketsprices">
                              <p className="title-feature12">
                                {el.type} - {el.price}
                              </p>
                            </CCol>
                          </>
                        );
                      })}
                    </CRow>
                  </>
                )}
                {item.placesnom && item.placesnom >0 &&(
                  <>
                    <CRow>
                      <CCol md="6" xs="6">
                        <p className="title-feature">
                          Nombre de place a vendre
                        </p>
                        <p className="title-feature12">
                          { item.placesnom}
                        </p>
                      </CCol>
                    </CRow>
                  </>
                )}
              </div>
              {(item.description || (videoframe && videoframe.length > 0 ))&&(  
                 <div className="features-vehicle">
                <div className="occasion-details">
                  <div className="wrapper">                 
                      <>
                        <h3>Autres information</h3>
                        <div>
                          {videoframe.length > 0 && (
                            <>
                              <br />
                              <h5>Promo </h5>
                              <br />
                            </>
                          ) &&
                            ReactHtmlParser(videoframe)}
                          {ReactHtmlParser(item.description)}
                        </div>
                      </>                  
                  </div>
                </div>
              </div>
               )}
              
       </div></div>
             
              <br/> <br/>
         
            </div>
          </div>
          <CModal show={modal} onClose={toggle}>
                                <CModalHeader closeButton>
                                Demander Ces modifications via Email
                                </CModalHeader>
                                <CModalBody>
                                <p>
                                Saisissez les modifications souhaitées séparées par ','                                   
                                <br/> (par exemple : changer l'image, fixer un prix raisonnable...)
                                </p>
                                 <textarea
                                    value={msg}
                                    onChange={(e)=>setMsg(e.target.value)}
                                 />
                                </CModalBody>
                                <CModalFooter>
                                  <CButton
                                    color="primary"
                                    onClick={() => {
                                       setEditing()
                                    }}
                                    disabled={loading}
                                  >
                                    {loading2 && <CSpinner size="sm"/>} submit
                                  </CButton>

                                  <CButton 
                                    disabled={loading}
                                  color="secondary" onClick={toggle}>
                                    Cancel
                                  </CButton>
                                  {success ? (
                    <CAlert
                      style={{
                        textAlign: "center",
                        width: "100%",
                        marginTop: "10px",
                      }}
                      color="success"
                      closeButton
                    >
                   Votre message a été modifié avec succès
                    </CAlert>
                  ) 
                  : erroru ? (<CAlert
                      style={{
                        textAlign: "center",
                        width: "100%",
                        marginTop: "10px",
                      }}
                      color="danger"
                      closeButton
                    >
                   Error !
                    </CAlert>)
                  :(
                    ""
                  )}
                                </CModalFooter>
                              </CModal>
        </main>
      </div>
    );
  }
}
