import React, { Component } from "react";
import axios from "axios";
import { API_BASE_URL , keyHeader , tokenCookie } from "../../../config";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { withRouter } from 'react-router-dom';
import {
  CBadge,
  CAlert,
  CButton,
  CCol,
  CFormGroup,
  CRow,
  CLabel,
  CInput,
  CSelect,
  CInputCheckbox,
  CSpinner
} from "@coreui/react";
import { getBadge } from "src/actions";
import { logoutLocal } from "src/components/middleware/auth";
import apiClient from "../apiClient";

export class JobForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading:false,
      //loadingCompany : false,
      //companyId : props.companyId,
      id : null ,
      title: "",
      description: "",
      sector: "",
      jobType: [],
      vacant : 1 ,
      remuneration: "",
      levelStudies: "",
      levelExp: "",
      language: "",
      sectors: [],
      success: "",
      // companyId: null,
      // name: "",
      // website: "",
      // email: "",
      // registrationNbr: "",
      // country: "",
      // city: "",
      // address: "",
      // image: null,
      // imagePreviewUrl: "",
      idMember:null,
      errors: {
        title: "",
        description: "",
        sector: "",
        jobType: "",
        remuneration: "",
        levelStudies: "",
        levelExp: "",
        language: "",
        // name: "",
        // website: "",
        // email: "",
        // registrationNbr: "",
        // country: "",
        // city: "",
        // address: "",
      },
    };
  } 
  componentDidMount() {
    if(this.props.location.state) {
      
        let result = this.props.location.state
        //console.log("job--", result);
      this.setState({
        id : result.id ,
        title: result.title,
        description: result.description,
        sector: result.sector.id,
        jobType: result.jobType.split(','),
        vacant : result.vacant ,
        remuneration: result.remuneration,
        levelStudies: result.levelStudies,
        levelExp: result.levelExp,
        //language: result.,
        //companyId: result.company.id,
        //name: result.company.name,
        //website: result.company.website,
        //email: result.company.email,
        //registrationNbr: result.company.registrationNumber,
        //country: result.company.country,
        //city: result.company.city,
        //address: result.company.address,
        //imagePreviewUrl: result.company.path,
        //idMember:localStorage.getItem('id'),
        })  ;
  //console.log("id", result.id);
    }

    if (!localStorage.getItem("sectors")) {
        axios(`${API_BASE_URL}/jobs/sectors`)
          .then((res) => res.data)
          .then(
            (result) => {
                this.setState({
                    sectors: result,
                  });
              localStorage.setItem("sectors", JSON.stringify(result));
            },
            (error) => {
            }
          );
      } else {
        this.setState({
            sectors: JSON.parse(localStorage.getItem("sectors")),
          });
      }

  }
  handleInputChange = (event) => {
    //console.log(this.state.jobType);
    let nCheckbox = this.state.jobType.slice(); // create a new copy of state value
    if (this.isValueExist(nCheckbox, event)) {
      // check if the same value is preexisted in the array
      const index = nCheckbox.indexOf(event.target.value);
      nCheckbox.splice(index, 1); // removing the preexciting value
    } else {
      nCheckbox.push(event.target.value); // inserting the value of checkbox in the array
    }
    this.setState({
      jobType: nCheckbox,
    });
    //console.log(this.state.jobType)
  };
  isValueExist(data, event) {
    if (data.length === 0) {
      return false;
    }

    for (let i = 0; i <= data.length; i++) {
      if (event.target.value === data[i]) {
        return true;
      }
    }
    return false;
  }

  handleChange = (event) =>
    this.setState({
      [event.target.name]: event.target.value,
    });

  handleValidation = () => {
    let formIsValid = true;
    let errors = {};
    if (!this.state.title) {
      formIsValid = false;
      errors.title = "Veuillez entrer le titre du poste";
    } else {
      errors.title = "";
    }
    if (!this.state.description) {
      formIsValid = false;
      errors.description = "Veuillez entrer le description du poste";
    } else {
      errors.description = "";
    }
    if (!this.state.sector) {
      formIsValid = false;
      errors.sector = "Veuillez choisir le secteur d'activité";
    } else {
      errors.sector = "";
    }
    if (!this.state.jobType) {
      formIsValid = false;
      errors.jobType = "Veuillez choisir le type du poste";
    } else {
      errors.jobType = "";
    }

    if (!this.state.levelStudies) {
      formIsValid = false;
      errors.levelStudies = "Veuillez choisir le niveau d'études ";
    } else {
      errors.levelStudies = "";
    }
    if (!this.state.levelExp) {
      formIsValid = false;
      errors.levelExp = "Veuillez choisir le niveau d'expérience ";
    } else {
      errors.levelExp = "";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) 
    {
      this.setState({loading:true});
      const formData = new FormData();

      formData.append("title", this.state.title);
      //formData.append("memberId", localStorage.getItem("id"));
      formData.append("description", this.state.description);
      formData.append("remuneration", this.state.remuneration);
      formData.append("levelStudies", this.state.levelStudies);
      formData.append("levelExp", this.state.levelExp);
      formData.append("sector", this.state.sector);
      formData.append("jobType", this.state.jobType);
      formData.append("title", this.state.title);
      formData.append("vacant", this.state.vacant);
      //formData.append("companyId", this.state.companyId);
      formData.append("jobId", this.state.id);
      
      apiClient
        .post("/job-offer/edit", formData )
        .then((res) => {
          if (res.data.status.toString() === "ok") {
            this.setState({
              success: "L'offre d'emploi a été ajouté avec succès",
            });
            //console.log(res.data);
            setTimeout(() => {
                this.props.history.push("/members/profile/jobs");
              }, 1500);
          }
          this.setState({loading:false});
        })
        .catch((error) => {
          //console.log(" errors : ", error);
          this.setState({loading:false});
          if (error.response && error.response.status === 401){
            logoutLocal();
            window.location.replace("/members/login");
          }
        });
    }
  };
 

  // handleChangeC = (event) =>
  //   this.setState({
  //     [event.target.name]: event.target.value,
  //   });
  // selectCountry(val) {
  //   this.setState({ country: val });
  // }

  // selectCity(val) {
  //   this.setState({ city: val });
  // }
  // handleFile = (event) => {
  //   let reader = new FileReader();
  //   let file = event.target.files[0];
  //   reader.onloadend = () => {
  //     this.setState({
  //       image: file,
  //       imagePreviewUrl: reader.result,
  //     });
  //   };
  //   reader.readAsDataURL(file);
  
  // };
  // handleValidationC = () => {
  //   let fieldName = this.state.name;
  //   let errors = {};
  //   let formIsValid = true;

  //   if (!fieldName) {
  //     formIsValid = false;
  //     errors.name = "Veuillez entrer le nom de l'entreprise";
  //   } else {
  //     errors.name = "";
  //   }
  //   if (!validator.isURL(this.state.website)) {
  //     formIsValid = false;
  //     errors.website = "Veuillez entrer un url valide";
  //   } else {
  //     errors.website = "";
  //   }
  //   if (!validator.isEmail(this.state.email)) {
  //     formIsValid = false;
  //     errors.email = "Veuillez entrer un e-mail valide";
  //   } else {
  //     errors.email = "";
  //   }
  //   if (!this.state.registrationNbr) {
  //     formIsValid = false;
  //     errors.registrationNbr = "Veuillez entrer le matricule fiscale";
  //   } else {
  //     errors.registrationNbr = "";
  //   }
  //   if (!this.state.country) {
  //     formIsValid = false;
  //     errors.country = "Veuillez Choisir un pays";
  //   } else {
  //     errors.country = "";
  //   }
  //   if (!this.state.city) {
  //     formIsValid = false;
  //     errors.city = "Veuillez Choisir la région";
  //   } else {
  //     errors.city = "";
  //   }
  //   if (!this.state.address) {
  //     formIsValid = false;
  //     errors.address = "Veuillez entrer l'adresse de l'entreprise";
  //   } else {
  //     errors.address = "";
  //   }
  //   this.setState({ errors: errors });
  //   return formIsValid;
  // };
  // handleSubmitC = (event) => {
  //   event.preventDefault();
  //   if (this.handleValidationC()) 
  //   {
  //     this.setState({loadingCompany:true});
  //     const formData = new FormData();
     
  //     formData.append("logo", this.state.image);
  //     formData.append("name", this.state.name);
  //     formData.append("website", this.state.website);
  //     formData.append("email", this.state.email);
  //     formData.append("registrationNumber", this.state.registrationNbr);
  //     formData.append("country", this.state.country);
  //     formData.append("city", this.state.city);
  //     formData.append("address", this.state.address);
  //     //formData.append("idMember", this.state.idMember);
  //     formData.append("companyId", this.state.companyId);
    
  //     axios
  //       .post(API_BASE_URL + "/edit-company", formData , keyHeader)
  //       .then((res) => {
  //         console.log('company : ',res)
  //         if (res.data.status.toString() === "ok") {
  //           setTimeout(() => {
  //               this.props.history.push("/members/profile/jobs");
  //             }, 1500);
  //           // this.setState({
  //           //   success: true,
  //           //  companyId: res.data.company.id
  //           // });
            
  //         }
  //        // this.setState({loadingCompany:false});
  //         // this.props.history.push("/5467854/index");
  //       })
  //       .catch((error) => {
  //         console.log(" errors : ", error);
  //         this.setState({loadingCompany:false});
  //       });
  //   }
  // };

  render() {
    let errors = this.state.errors;
    //const { country, city } = this.state;
    return (
      <div>
        <div className="information-title">
          <h2>Modifier Job</h2> <CBadge color={getBadge(this.props.location.state.status)}>
                          {this.props.location.state.status=='editing'?'needs editing':this.props.location.state.status}
                        </CBadge>
        </div>
        <p></p>
        <form onSubmit={this.handleSubmit} id="form-add" className="jobFormClass">
          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Titre du poste</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CInput
                type="text"
                name="title"
                value={this.state.title}
                onChange={this.handleChange}
              />
              {errors.title.length > 0 && (
                <span className="invalid-feedback error">{errors.title}</span>
              )}
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Secteur d'activité</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CSelect
                name="sector"
                value={this.state.sector}
                onChange={this.handleChange}
              >
                <option value=""> Choisissez un secteur d'activité</option>
                {this.state.sectors.map((sector) => {
                  return (
                    <option key={sector.id} id={sector.id} value={sector.id}>
                      {sector.label}
                    </option>
                  );
                })}
              </CSelect>
              {errors.sector.length > 0 && (
                <span className="invalid-feedback error">{errors.sector}</span>
              )}
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Description du poste</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CKEditor
                editor={ClassicEditor}
                data={this.props.location.state.description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  this.setState({
                    description: data,
                  });
                }}
              />
              {errors.description.length > 0 && (
                <span className="invalid-feedback error">
                  {errors.description}
                </span>
              )}
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Type de poste</CLabel>
            </CCol>
            <CCol xs="12" md="3">
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  id="1"
                  value="CDI"
                  onChange={this.handleInputChange}
                 checked = {this.state.jobType.includes("CDI")}
                />
                <CLabel variant="checkbox" className="form-check-label">
                  CDI
                </CLabel>
              </CFormGroup>
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  id="2"
                  value="CDD"
                  onChange={this.handleInputChange}
                 checked = {this.state.jobType.includes("CDD")}

                />
                <CLabel variant="checkbox" className="form-check-label">
                  CDD
                </CLabel>
              </CFormGroup>
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  id="3"
                  value="SIVP"
                  onChange={this.handleInputChange}
                 checked = {this.state.jobType.includes("SIVP")}
                />
                <CLabel variant="checkbox" className="form-check-label">
                  SIVP
                </CLabel>
              </CFormGroup>
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  id="4"
                  value="Functions publiques"
                  onChange={this.handleInputChange}
                 checked = {this.state.jobType.includes("Functions publiques")}

                />
                <CLabel variant="checkbox" className="form-check-label">
                  Functions publiques
                </CLabel>
              </CFormGroup>
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  id="5"
                  value="Indépendant/Freelance"
                  onChange={this.handleInputChange}
                 checked = {this.state.jobType.includes("Indépendant/Freelance")}

                />
                <CLabel variant="checkbox" className="form-check-label">
                  Indépendant/Freelance
                </CLabel>
              </CFormGroup>
              {errors.jobType.length > 0 && (
                <span className="invalid-feedback error">{errors.jobType}</span>
              )}
            </CCol>
            <CCol xs="12" md="3">
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  id="6"
                  value="Stage/PFE"
                  onChange={this.handleInputChange}
                 checked = {this.state.jobType.includes("Stage/PFE")}

                />
                <CLabel variant="checkbox" className="form-check-label">
                  Stage/PFE
                </CLabel>
              </CFormGroup>
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  id="7"
                  value="Stage"
                  onChange={this.handleInputChange}
                 checked = {this.state.jobType.includes("Stage")}

                />
                <CLabel variant="checkbox" className="form-check-label">
                  Stage
                </CLabel>
              </CFormGroup>
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  id="8"
                  value="Saisonnier"
                  onChange={this.handleInputChange}
                 checked = {this.state.jobType.includes("Saisonnier")}

                />
                <CLabel variant="checkbox" className="form-check-label">
                  Saisonnier
                </CLabel>
              </CFormGroup>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Niveau d'études</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CSelect
                name="levelStudies"
                value={this.state.levelStudies}
                onChange={this.handleChange}
              >
                <option value=""> --------- </option>
                <option value="Primaire">Primaire</option>
                <option value="Secondaire">Secondaire</option>
                <option value="Formations professionnelles">
                  Formations professionnelles
                </option>
                <option value="Bac">Bac</option>
                <option value="Bac + 1">Bac + 1</option>
                <option value="Bac + 2">Bac + 2</option>
                <option value="Bac + 3">Bac + 3</option>
                <option value="Bac + 4">Bac + 4</option>
                <option value="Bac + 5">Bac + 5</option>
              </CSelect>
              {errors.levelStudies.length > 0 && (
                <span className="invalid-feedback error">
                  {errors.levelStudies}
                </span>
              )}
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Niveau d'expériences</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CSelect
                name="levelExp"
                value={this.state.levelExp}
                onChange={this.handleChange}
              >
                <option value=""> --------- </option>
                <option value="Aucune expérience">Aucune expérience</option>
                <option value="Moins d'un an">Moins d'un an</option>
                <option value="Entre 1 et 2 ans">Entre 1 et 2 ans</option>
                <option value="Entre 2 et 5 ans">Entre 2 et 5 ans</option>
                <option value="Entre 5 et 10 ans">Entre 5 et 10 ans</option>
                <option value="Plus que 10 ans">Plus que 10 ans</option>
              </CSelect>
              {errors.levelExp.length > 0 && (
                <span className="invalid-feedback error">
                  {errors.levelExp}
                </span>
              )}
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Poste disponible</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CInput
                type="number"
                min={'1'}
                name="vacant"
                value={this.state.vacant}
                onChange={this.handleChange}
              />
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Salaire</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CInput
                type="text"
                name="remuneration"
                value={this.state.remuneration}
                onChange={this.handleChange}
              />
            </CCol>
          </CFormGroup>

          <CRow>
            <CCol md="3"></CCol>
            <CCol xs="12" sm="3">
              {!this.state.loading && (
                <CButton color="primary" block type="submit">
                  Valider
                </CButton>
              )}

              {this.state.loading && (
                <CButton color="primary" block disabled>
                  <span>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>

                  <span> Valider</span>
                </CButton>
              )}
            </CCol>
          </CRow>
          <CRow>
            <CCol md="3"></CCol>
            <CCol md="9">
              {" "}
              {this.state.success !== "" ? (
                <CAlert color="success" closeButton>
                  {this.state.success}
                </CAlert>
              ) : (
                ""
              )}
            </CCol>
          </CRow>
        </form>
        <br/>          
        {/* <br/>
        <div className="information-title" >
          <h2>Modifier Informations de l'entreprise</h2>
        </div>
        <span></span>
        <p></p>
        <form
          onSubmit={this.handleSubmitC}
          id="form-add"
          className="form-horizontal jobFormClass"
        >
          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Nom de l'entreprise</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CInput
                type="text"
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
              />
              {errors.name.length > 0 && (
                <span className="invalid-feedback error">{errors.name}</span>
              )}
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Site web</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CInput
                type="text"
                name="website"
                value={this.state.website}
                onChange={this.handleChange}
              />
              {errors.website.length > 0 && (
                <span className="invalid-feedback error">{errors.website}</span>
              )}
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">E-mail</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CInput
                type="text"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
              {errors.email.length > 0 && (
                <span className="invalid-feedback error">{errors.email}</span>
              )}
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Matricule fiscale</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CInput
                type="text"
                name="registrationNbr"
                value={this.state.registrationNbr}
                onChange={this.handleChange}
              />
              {errors.registrationNbr.length > 0 && (
                <span className="invalid-feedback error">
                  {errors.registrationNbr}
                </span>
              )}
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3"> <CLabel htmlFor="country">Pays</CLabel></CCol>
            <CCol xs="12" md="7">
              <CountryDropdown
                value={country}
                className="form-control"
                defaultOptionLabel="Choisissez un pays"
                onChange={(val) => this.selectCountry(val)}
                priorityOptions={["TN"]}
              />
              {errors.country.length > 0 && (
                <span className="invalid-feedback error">
                  {errors.country}
                </span>
              )}
            </CCol>
          </CFormGroup>
          <CFormGroup row>
          <CCol md="3"> <CLabel htmlFor="country">Région</CLabel></CCol>
            <CCol xs="12" md="7">
          <RegionDropdown
                country={country}
                value={city}
                className="form-control"
                onChange={(val) => this.selectCity(val)}
              />
               {errors.city.length > 0 && (
                <span className="invalid-feedback error">
                  {errors.city}
                </span>
              )}
              </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Adresse</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CInput
                type="text"
                name="address"
                value={this.state.address}
                onChange={this.handleChange}
              />
              {errors.address.length > 0 && (
                <span className="invalid-feedback error">
                  {errors.address}
                </span>
              )}
            </CCol>
          </CFormGroup>
          <CFormGroup row>
         
                    <CCol xs="3">
                     
                        <CLabel htmlFor="content">Image</CLabel>
                       
                    </CCol>
                    <CCol xs="7">
                    <CInput
                          type="file"
                          name="image"
                          onChange={this.handleFile}
                          accept=".jpg,.png,jpeg"
                        />
                        <img
          src={`${IMAGES_BASE_URL}/public/uploads/companies/${this.state.imagePreviewUrl}`} alt="preview"
          width="150"
          className="img-fluid img-thumbnail "
        />
                    </CCol>
                   
                 
          </CFormGroup>
          <CRow>
          <CCol md="3"></CCol>
            <CCol xs="12" sm="3">
              {!this.state.loadingCompany && (
                <CButton color="primary" block type="submit">
                  Valider
                </CButton>
              )}

              {this.state.loadingCompany && (
                <CButton color="primary" block disabled>
                  <span>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>

                  <span> Valider</span>
                </CButton>
              )}

            </CCol>
          </CRow>
        </form> */}
      </div>
    );
  }
}

export default withRouter (JobForm);
