import React, { Component } from 'react'
export class NavItem extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            isHovered:false,
             navLinkCss:'c-nav__link',
        }
       
    }

    handleSubMenu = ()=>{
        this.setState((prevState) => ({
            isHovered: !prevState.isHovered,     
        }
        ));
        if(!this.state.isHovered){
          this.setState({
            navLinkCss: "c-nav__link is toggled c-nav__link--active is disabled"
          })
        }
          else{
            this.setState({
                navLinkCss: "c-nav__link"
            })
          }
          
        
      }
    
    render() {
        return (
            <>
                
                        <button
                          onMouseEnter={this.handleSubMenu}
                          onMouseLeave={this.handleSubMenu}
                          className={this.state.navLinkCss}                         
                        >
                          <div className="c-nav__local" 
                          >
                            <span className='linkTitle'>{this.props.name}</span>
                            <span
                              className="c-nav__icon c-nav__icon--chevron"
                              focusable="false"
                            ></span>
                          </div>
                        </button>
                        

                    
                      
            </>
        )
    }
}

export default NavItem
