import React, { useEffect, useState , useContext } from "react";
import { keyHeader,API_BASE_URL ,IMAGES_BASE_URL } from "../../../config";
import { getBadge , textBadge } from "src/actions";
import axios from "axios";
import Moment from "react-moment";
import Loading from "src/components/myWidgets/loadingWidget";
import { Link } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CSpinner,
  CBadge , CDataTable
} from "@coreui/react";
import MemberContext from "./MemberContext";
import apiClient from "../apiClient";

function MyAnimals(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState(null);
  const [errorDel, setErrorDel] = useState("");
  const [isLoading, setIsLoading] = useState(false);
 const {animals,loadedAnimals , setAnimals} = useContext(MemberContext);
 
  const toggle = (id) => {
    setModal(!modal);
    setItem(id);
  };
  function deleteAnimal(id) {
    setIsLoading(true);

    apiClient
      .delete(`/animal/delete/${id}`)
      .then((res) => {
        toggle();
        //console.log(res);
        if (res.data.status.toString() === "ok") {
          setItems((items) => items.filter((x) => x.id !== id));
          setAnimals((items) => items.filter((x) => x.id !== id));
          setIsLoading(false);
        }
        if (
          res.data.status.toString() ===
          "ForeignKeyConstraintViolationException"
        ) {
          setErrorDel("Vous ne pouvez pas supprimer cet élément, il a des relations");
          alert("Vous ne pouvez pas supprimer cet élément, il a des relations");
          setIsLoading(false);
        }
      })
      .catch((err) =>{
        //console.log(err);
        setErrorDel("Vous ne pouvez pas supprimer cet bon plans maintenant");
      setIsLoading(false);} );
  }
  useEffect(() => {
    //console.log('props animalsm --- ', animals);   
if(loadedAnimals) 
{setItems(animals);
  setIsLoaded(true)
  //console.log('props animalsm 2 --- ', animals.length);   
}
// if(props.location.state) {
//        console.log('props.state --- ', props.location.state);   

//   setIsLoaded(true);
//         setItems(props.location.state);
// }else{
//     console.log('nooo props.state --- ');   

//     const formData = new FormData();
//   formData.append("key",tokenCookie );

//   axios.post(`${API_BASE_URL}/animals/By_member`, formData, keyHeader)
//       .then(response => {
//        // console.log('response --- ', response);   
//         setIsLoaded(true);
//         setItems(response.data.items);
       
//       })
//       .catch(error => {
//         setIsLoaded(true);
//         setError(error);
//           console.log('error --- ', error)

//       });
//     }
    // axios(`${API_BASE_URL}/animals/By_member/${localStorage.getItem("id")}`)
    //   .then((res) => res.data)
    //   .then(
    //     (result) => {
    //       setIsLoaded(true);
    //       setItems(result);
    //       console.log("animals by member", result);
    //     },
    //     (error) => {
    //       setIsLoaded(true);
    //       setError(error);
    //     }
    //   );
  }, [loadedAnimals , animals]);

  const fields = [
    {
      key: "thumb",
      label: "thumb",
    // _style: { width: "20%" },
    },
    {
      key: "Actions",
      label: "Actions",
    },
  ];

  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <Loading/>
    );
  }  else {
    return (
      <>
      {items && items.length == 0
      ?
      <div className="container">
      <main className="l-main">
        <div className="l-section l-section--archive">
          <div className="noItemsText">
          Il n'y a pas encore d'annonces ici <br/><br/>
          <Link to='/animals/add'>
          <button className="ml-1 btn btn-info" type="button">
          commencez à ajouter des annonces maintenant
          </button></Link>
          </div>
        </div>
      </main>
    </div>
    :  <>

        <CDataTable addTableClasses="table table-hover table-outline mb-0  d-sm-table"
                    items={items}
                    fields={fields}
                    itemsPerPageSelect
                    itemsPerPage={10}
                    hover
                    pagination
                    scopedSlots={{
                      thumb: (animal) => (
                        <td>
                    <div className="vehicle-thumbnail">
                      <img
                        src={
                          animal.mainPicture
                            ? `${IMAGES_BASE_URL}/public/uploads/images/animal/${animal.mainPicture.name}`
                            : ""
                        }
                        alt=""
                      />
                    </div>
                    <div className="profileAnnonceCardDetails">
                    <strong>
                    <div>
                    {animal.title && animal.title} - {animal.typeAnimal && animal.typeAnimal.label}     <CBadge color={getBadge(animal.status)}>
                            {textBadge(animal.status)}
                          </CBadge>
                    </div>
                   </strong>
                   <div>
                     <strong>Numéro de série :</strong> 
                     <span> {animal.sn}</span> 
                    </div>
                    <div>
                     <strong>Date d'ajout :{" "}</strong> 
                     <span><Moment format="DD/MM/YYYY">
                        {animal.createdAt}
                      </Moment></span> 
                    </div>
                    </div>
                  </td>
                      ),
                      Actions: (animal) => (
                        <td>
                  <Link to={{ pathname:`/members/profile/myItemDetails/animal`,
                         state:animal}}
                  >
                  <CButton>
              <img src="assets/icons/eye.svg" />
            </CButton></Link>
                    <Link to= {{ pathname:'/members/profile/animal-edit',
                         state:animal , source:'edit'}}                   
                    >
                      <CButton >
                      <CIcon name="cilPencil" /> 
                      </CButton>
                    </Link>
                    <CButton
                      onClick={(e) => toggle(animal.id)}
                    >
                      <CIcon name="cilTrash" />
                    </CButton>
                  </td>
                      )
                    }}
                  />

        <CModal show={modal} onClose={toggle}>
          <CModalHeader closeButton></CModalHeader>
          <CModalBody>Voulez-vous vraiment supprimer ce animal?</CModalBody>
          <CModalFooter>
            {/* <CButton
                                  color="primary"
                                  onClick={() => {
                                    deleteAnimal(item);
                                  }}
                                >
                                  Oui
                                </CButton> */}

            {!isLoading && (
              <CButton
                color="primary"
                onClick={() => {
                  deleteAnimal(item);
                }}
              >
                Oui
              </CButton>
            )}
            {isLoading && (
              <CButton color="primary" disabled>
                <span>
                  <CSpinner color="default" size="sm" />
                </span>

                <span> Oui</span>
              </CButton>
            )}

            <CButton color="secondary" onClick={toggle}>
              Cancel
            </CButton>
          </CModalFooter>
        </CModal>
      </>
      }
     
      </>
    );
  }
}

export default MyAnimals;
