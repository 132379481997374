import React, { useEffect, useState } from "react";
import { IMAGES_BASE_URL , API_BASE_URL , keyHeader , minmileconst , minpriceconst} from "../../../../config";
import axios from "axios";
import Loading from "src/components/myWidgets/loadingWidget";
import {
  CCol,
  CDataTable,
  CLabel,
  CFormGroup,
  CSelect,
  CInputRadio,
  CInput
  /* CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,*/
} from "@coreui/react";
import { useLocation , useHistory } from "react-router-dom";

import VehicleItem from "../VehicleItem";
import Filter from "./Filter";
//import { Range } from "rc-slider";

export default function ResultSearch() {
  const location = useLocation();
  const history = useHistory();

  const [isLoaded, setIsLoaded] = useState(false);
 // const [cars, setCars] = useState([]);
  const [all, setAll] = useState([]);
  const [errorAll,setErrorALL]= useState(false);
  const [results , setresults] = useState([]);

  const fields = [{ key: "title", _style: { width: "100%" } }];
  const itemType = "Vehicle";

  const [makes, setMakes] = useState([]);
  const [make, setMake] = useState("");
  const [models, setModels] = useState([]);
  const [model, setModel] = useState("");
  const [status, setStatus] = useState(null);
  const [year, setYear] = useState("");
  const [minMileage, setMinMileage] = useState("");
  const [maxMileage, setMaxMileage] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [fuel, setFuel] =useState("");
  const [transmission, setTransmission] = useState("");
  const [nbrSeat,setNbrSeat] = useState("");
  const [fiscalPower, setFiscalPower] = useState("");
  const [color, setColor] = useState("");
  const [boite,setBoite] = useState("");
  const [bodyStyle,setBodyStyle] = useState("");
  const [loading, setLoading] = useState(false);
  const [warranty, setWarranty] = useState("");
  const [isLoadedALL , setisLoadedALL] = useState(false);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [delegate, setDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  const [filtersCount, setFiltersCount] = useState(0);
  const [sortBy, setSortBy] = useState('dateD');
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    //console.log('rdyjgkhlkmgdeefglmdesghg');
    setIsVisible(prev => !prev);
  };
     const searchParams = new URLSearchParams(location.search);

  useEffect(() => {

    const make = searchParams.get('make');
    const model = searchParams.get('model');

    //console.log('paramMMM:', location.search );
    //console.log('paramMMMxxx:', searchParams );
    //console.log('Mark param:', make);
    //console.log('Model param:', model);

  //   if (location.state && location.state.all)
  //   {setStatus(location.state.all.status)
  //   setMake(location.state.all.make)
  //   setModels(location.state.all.models)
  //   setModel(location.state.all.model)
  //   setYear(location.state.all.year)
  //   setBodyStyle(location.state.all.bodyStyle)
  //   setFuel(location.state.all.fuel)
  //   setBoite(location.state.all.boite)
  //   setNbrSeat(location.state.all.nbrSeat)
  //   setWarranty(location.state.all.warranty)
  //   setMaxMileage(location.state.all.maxMileage)
  //   setMaxPrice(location.state.all.maxPrice)
  //   setMinMileage(location.state.all.minMileage)
  //   setMinPrice(location.state.all.minPrice)
  //   setCountry(location.state.all.country)
  //   setCities(location.state.all.cities)
  //   setCity(location.state.all.city)
  //   //setColor(location.state.all.color)
  // }

  if (location.search)
    {       
    setStatus(searchParams.get('status'));
    setMake(searchParams.get('make'));
    setModel(searchParams.get('model'));
    setYear(searchParams.get('year'));
    setBodyStyle(searchParams.get('bodyStyle'));
    setFuel(searchParams.get('fuel'));
    setBoite(searchParams.get('boite'));
    setNbrSeat(searchParams.get('nbrSeat'));
    setWarranty(searchParams.get('warranty'));
    setMaxMileage(searchParams.get('maxMileage'));
    setMaxPrice(searchParams.get('maxPrice'));
    setMinMileage(searchParams.get('minMileage'));
    setMinPrice(searchParams.get('minPrice'));
    setCountry(searchParams.get('country'));
    setCity(searchParams.get('city'));
    setDelegate(searchParams.get('delegate'));
    setColor(searchParams.get('color'));

    setFiltersCount(searchParams.size)
  }
  

  }, [location.search]);

  useEffect(() => {

    if (!localStorage.getItem("countries")) {
      axios(`${API_BASE_URL}/countries/all`)
        .then((res) => res.data)
        .then(
          (result) => {
            // console.log("countries", result);
            setCountries(result);
           // setIsLoaded2(true);
           if(location.state && location.state.all.cities) setCities(location.state.all.cities);
           else if (result && searchParams.get('country')) {
            var countriesF=[]
            countriesF = result.find((el) => el.id.toString() == searchParams.get('country'));
            setCities(countriesF.cities);
            //console.log('countriesF',countriesF);
          }
            localStorage.setItem("countries", JSON.stringify(result));
          },
          (error) => {}
        );
    } else {
      // console.log("countris2222222");
      //  console.log(localStorage.getItem("countries"));
     // setIsLoaded2(true);
      setCountries(JSON.parse(localStorage.getItem("countries")));
      if(location.state && location.state.all.cities) setCities(location.state.all.cities);
      else if (JSON.parse(localStorage.getItem("countries")) && searchParams.get('country')) {
       var countriesF=[]
       countriesF = JSON.parse(localStorage.getItem("countries")).find((el) => el.id.toString() == searchParams.get('country'));
      setCities(countriesF.cities);
       //console.log('countriesFfffffff',countriesF);
     }
    }

    if (!localStorage.getItem("carBrands")) 
    { axios(`${API_BASE_URL}/vehicles/car-brands`)
       .then((res) => res.data)
       .then(
         (result) => {
          setMakes(result);
            //console.log('brandddd : ',result);
     localStorage.setItem("carBrands", JSON.stringify(result));
     var makesF = [];
     if(location.state && location.state.all.models) setModels(location.state.all.models);
    else if (result && searchParams.get('make')) {
       makesF = result.find((el) => el.id.toString() == searchParams.get('make'));
      setModels(makesF.models);
       //console.log('makesF',makesF);
     }
 
         },
         (error) => {
         }
       );
   } else {
    setMakes(JSON.parse(localStorage.getItem("carBrands")));
    
    var makesF = [];
    if(location.state && location.state.all.models) setModels(location.state.all.models);
    else if (JSON.parse(localStorage.getItem("carBrands")) && searchParams.get('make')) {
      makesF = JSON.parse(localStorage.getItem("carBrands")).find((el) => el.id.toString() == searchParams.get('make'));
      setModels(makesF.models);
      //console.log('makesF',makesF);
    }
   }
 
   if (location.state) {   
    //console.log('rtrtrttkkkkkkk : ',location.state.result);
     //console.log('all : ',location.state);
    setresults(location.state.result);
    setAll(location.state.all.all);
   
    
    setisLoadedALL(true)
    setIsLoaded(true);
  } else {
    //console.log('no state : ',location.state);
    
    axios
    (API_BASE_URL + "/annonce_car/all",keyHeader)
    .then((res) => res.data)
    .then(
      (result) =>{ 
      //console.log('all result' , result);
      setAll(result);
      setresults(result)    
      setisLoadedALL(true)
      setIsLoaded(true);
      //setdisable('');
    },
    (error) => {
      //console.log(error);
      setErrorALL(true)
    }
    );
  }
  

  }, []);


  useEffect(() => {
    //  handleFilter(e);
    //console.log(make,model)
   // var results ;
   if(isLoadedALL) 
   {var results = all.filter(function (el) {
      const statusc = status === null || el.state == status;
      //!status || el.state == status; 
     // status!=null ?  el.state == status : true;

      const makec =
        !make || el.brand.id == make;  
      const modelc = model > 0 ?  el.model.id == model : true;
      const yearc =  year > 0 ?  el.year == year : true;
      const bodyStylec = !bodyStyle || el.bodyStyle == bodyStyle;  
      const fuelc = !fuel || el.fuel == fuel;  
      const boitec = !boite || el.boite == boite;  
      const nbrSeatc = !nbrSeat || el.nbrSeat == nbrSeat;  
      const warrantyc = !warranty || el.warranty == warranty;  
      const countryc = !country || el.country.id == country;  
      const cityc = !city || el.city.id == city;  
      const delegatec = !delegate || el.delegate.id == delegate;  
      const minPricec = !minPrice || el.price >= minPrice;  
      const maxPricec = !maxPrice || el.price <= maxPrice;  
      const minMileagec = !minMileage || el.mileage >= minMileage;  
      const maxMileagec = !maxMileage || el.mileage <= maxMileage;  
    
      return statusc && makec && modelc && bodyStylec && yearc && fuelc && boitec && nbrSeatc && warrantyc && countryc && cityc && delegatec && minPricec && maxPricec && minMileagec && maxMileagec;
    });
    //console.log('rsddsdxxx ',results)
    setresults(results);
  }
     }, [isLoadedALL , status ,make, model , bodyStyle , year , fuel , boite , nbrSeat , warranty , country , city , delegate , minPrice , maxPrice , minMileage , maxMileage]);
  
     const clearAll = () => {
      handleClearSelection('status'); handleClearSelection('year');
      handleClearSelection('make');handleClearSelection('model');
      handleClearSelection('minMileage');handleClearSelection('maxMileage')
      handleClearSelection('minPrice');handleClearSelection('maxPrice');
      handleClearSelection('fuel'); handleClearSelection('transmission');
      handleClearSelection('nbrSeat');handleClearSelection('fiscalPower');
      handleClearSelection('boite') ; handleClearSelection('bodyStyle');
      handleClearSelection('color');
      handleClearSelection('country');handleClearSelection('city');handleClearSelection('delegate')
          }  
      
          const handleSortBy = (e) => {
            //console.log('sortBy:',e.target.value);
            setSortBy(e.target.value);
            const sortedData = [...results].sort((a, b) => {
         
              if (e.target.value === 'dateC') {
                return a['createdAt'] > b['createdAt'] ? 1 : -1;
              }
              else    if (e.target.value === 'dateD') {
                return a['createdAt'] < b['createdAt'] ? 1 : -1;
              } 
               else    if (e.target.value === 'year_asc') {
                return a['year'] > b['year'] ? 1 : -1;
              }
              else    if (e.target.value === 'year_desc') {
                return a['year'] < b['year'] ? 1 : -1;
              } 
               else    if (e.target.value === 'price_asc') {
                return a['price'] > b['price'] ? 1 : -1;
              }
              else    if (e.target.value === 'price_desc') {
                return a['price'] < b['price'] ? 1 : -1;
              } 
               else    if (e.target.value === 'kilo_asc') {
                return a['mileage'] > b['mileage'] ? 1 : -1;
              }
              else    if (e.target.value === 'kilo_desc') {
                return a['mileage'] < b['mileage'] ? 1 : -1;
              }
            });
            setresults(sortedData);
           } 

     const updateUrlParams = (e) => {
     // console.log('nnnnnvvvvthfgjukhjk',e.target.name)
      // Set the new value for 'mark' parameter
      searchParams.set(e.target.name, e.target.value);
  
      // Create a new URL with the updated search parameters
      const updatedURL = `${location.pathname}?${searchParams.toString()}`;
  
      // Replace the current URL with the updated one
      history.replace(updatedURL);
    };

    const deleteUrlParams = (p) => {
      // console.log('nnnnnvvvvthfgjukhjk',e.target.name)
       // Set the new value for 'mark' parameter
       searchParams.delete(p);
      
       // Create a new URL with the updated search parameters
       const updatedURL = `${location.pathname}?${searchParams.toString()}`;
       setFiltersCount(searchParams.size);
       // Replace the current URL with the updated one
       history.replace(updatedURL);
     };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    let id = e.target.value;
    var countriesF = [];
    // console.log("iddd", id);
    if (id !== "0") {
      countriesF = countries.find((city) => city.id.toString() === id);
      setCities(countriesF.cities);
      setCity('');
      // console.log("countriesF", countriesF);
    } else {
      setCity('');
      //  setModel("");
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    let id = e.target.value;
    var citiesF = [];
    // console.log("iddd", id);
    if (id !== "0") {
      citiesF = cities.find((delegate) => delegate.id.toString() === id);
      setDelegates(citiesF.delegations);
      setDelegate('');
      //  console.log("citiesF", citiesF);
    } else {
      setDelegate('');
      //  setModel("");
    }
  };

  const handleClearSelection = (type) => {
    if(searchParams.get(type))  deleteUrlParams(type) 
    if (type=='status') setStatus(null)
     else {const setStateFunction = `set${type.charAt(0).toUpperCase()}${type.slice(1)}`;
    eval(`${setStateFunction}('')`);}
    if(type=='country') { deleteUrlParams('city'); deleteUrlParams('delegate') ; setCity('');setDelegate('') }
    if(type=='city') { deleteUrlParams('delegate') ; setDelegate('') }
  //  if(type =='make') {setMake(""); setModel("") ; if(searchParams.get('model')) deleteUrlParams('model') }
  //  else if(type =='model') { setModel("")  }
  //  else if(type=='status') {setStatus(null) }
  //  else if(type=='year') {setYear("") }
  //  else if(type=='minMileage') {setMinMileage("")}
  //  else if(type=='maxMileage') setMaxMileage("") 
  //  else if(type=='minPrice') setMinPrice("")  
  //  else if(type=='maxPrice') setMaxPrice("")
  //  else if(type=='fuel') setFuel("")
  //  else if(type=='transmission') setTransmission("")       
  //  else if(type=='nbrSeat') setNbrSeat("")
  //  else if(type=='boite') setBoite("")
  //  else if(type=='bodyStyle') setBodyStyle("")
  //  else if(type=='fiscalPower') setFiscalPower("")
  //  else if(type=='color') setColor("")
  //  else if(type=='city') setCity("")
  //  else if(type=='country') setCountry("")
  };

  const handleMakeChange = (e) => {
    setMake(e.target.value);
    //console.log("make hadle change : ", make);
    let id = e.target.value;
    //console.log("make hadle change : ", id);
    var makesF = [];
    if (id.toString !== '0') {
      makesF = makes.find((model) => model.id.toString() === id);
      setModels(makesF.models);
      setModel("");
      
    } else {
      setModels([]);
      setModel("");
    }
  };

  const handleSubmit = (e) =>  {
    //console.log(make,model)
    e.preventDefault();
    var results = all.filter(function (el) {
      const makec =
        !make || el.brand.id == make;  
      const modelc = model > 0 ?  el.model.id == model : true;
      //userInputAge > 0 ? item.age === userInputAge : true;
      const yearc =  year > 0 ?  el.year == year : true;
    
      return makec && modelc && yearc;
    });
    setresults(results);

  }

  const getYear = () => {
    const years = [];
    for (let i = new Date().getFullYear(); i > 1950; i--) {
      years.push(
        <option value={i} key={i}>
          {" "}
          {i}
        </option>
      );
    }
    return years;
  };





  if (!isLoaded) {
    return (
     <Loading/>
    );
  } else {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-main__article l-main__content l-main__section l-article searchPage ">
            <div className="row">
              <CCol md="3">
              <span className="filtersMobButton" onClick={toggleVisibility} >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" id="filter"><path fill="#000" fill-rule="evenodd" d="M20 5h-1.17a3.001 3.001 0 0 0-5.66 0H4a1 1 0 0 0 0 2h9.17a3.001 3.001 0 0 0 5.66 0H20a1 1 0 1 0 0-2zm-4 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM3 12a1 1 0 0 1 1-1h1.17a3.001 3.001 0 0 1 5.66 0H20a1 1 0 1 1 0 2h-9.17a3.001 3.001 0 0 1-5.66 0H4a1 1 0 0 1-1-1zm5 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-4 4a1 1 0 1 0 0 2h9.17a3.001 3.001 0 0 0 5.66 0H20a1 1 0 1 0 0-2h-1.17a3.001 3.001 0 0 0-5.66 0H4zm13 1a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" clip-rule="evenodd"></path></svg> Filters
            </span>
            {filtersCount > 0 && <span className="filtersCountMob filtersCount">{ filtersCount+' appliqués'} <button
             onClick={clearAll} className="btn btn-primary ">
             Supprimer tout <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
<path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"></path>
</svg>
             </button>
              </span>}
              <div className={`bordered filter filtersSectionMob ${isVisible ? 'visibleFiltersmob' : ''}`} >
              <span onClick={toggleVisibility}
              className={`filtersMobButton filtersMobButtonx `} >&times;</span>
         {/* {!loading && (<CButton onClick={handleSubmit} color="primary" block>
                Chercher
            </CButton>)}

            {loading && (<CButton color="primary" block disabled>
                <span> <CSpinner color="default" size="sm" /></span>

                <span> Chercher</span>

            </CButton>)} */}
            <CLabel className='filtersS'> 
            Filters
            </CLabel>
            {filtersCount > 0 && <span className="filtersCount">{ filtersCount+' appliqués'} <button
             onClick={clearAll} className="btn btn-primary ">
             Supprimer tout <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
<path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"></path>
</svg>
             </button>
              </span>}

      <div>
    <br />
    {/* {
              status || status==1 || status=='1' ?
                <>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="1"
                      name="status"
                      value="1"
                      onChange={() => setStatus(1)}
                      defaultChecked
                    />
                    <CLabel variant="checkbox" htmlFor="neuf">
                      Neuf
                    </CLabel>
                  </CFormGroup>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="0"
                      name="status"
                      value="0"
                      onChange={() => setStatus(0)}
                    />
                    <CLabel variant="checkbox" htmlFor="occasion">
                      Occasion
                    </CLabel>
                  </CFormGroup>
                </> :
                <>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="1"
                      name="status"
                      value="1"
                      onChange={() => setStatus(1)}
                      
                    />
                    <CLabel variant="checkbox" htmlFor="neuf">
                      Neuf
                    </CLabel>
                  </CFormGroup>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="0"
                      name="status"
                      value="0"
                      onChange={() => setStatus(0)}
                      defaultChecked
                    />
                    <CLabel variant="checkbox" htmlFor="occasion">
                      Occasion
                    </CLabel>

                  </CFormGroup>
                </>
                } */}

                <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="1"
                      name="status"
                      value="1"
                      onChange={(e) => {setStatus(1);updateUrlParams(e)}}
                      checked = {status==1 || status=='1' ? true : false}
                    />
                    <CLabel variant="checkbox" htmlFor="neuf">
                      Neuf
                    </CLabel>
                  </CFormGroup>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="0"
                      name="status"
                      value="0"
                      onChange={(e) => {setStatus(0);updateUrlParams(e)}}
                      checked = {status==0 || status=='0' ? true : false}
                    />
                    <CLabel variant="checkbox" htmlFor="occasion">
                      Occasion
                    </CLabel>
                  </CFormGroup>


                {
                  status!=null?  <button
         className="clearFilterOptionButton"
          onClick={()=> handleClearSelection('status')}
        >
          <span style={{verticalAlign:'text-top'}} > × </span>clear
        </button> : null
                }
      </div>
      <div>
        {" "}
        <span className="filter-title-field">Marques</span>
      </div>
      <div  className='filterOptions'>
      <CSelect
        name="make"
        value={make}
        onChange={(e) => {
          handleMakeChange(e);
          updateUrlParams(e)
         // handleSubmit(e);
         
        }}
        style={{
          appearance: make == '0' || make == '' || !make ? 'auto' : 'none',
        }}
      >
        <option value=""></option>
        {makes.map((make) => {
          return (
            <option key={make.id} value={make.id}>
              {make.label} 
            </option>
          );
        })}
      </CSelect>
      {make && make != '0' && (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('make')}
        >
          ×
        </button>
      )}
      </div>

      <div>
        {" "}
        <span className="filter-title-field">Modèles</span>
      </div>
      <div  className='filterOptions'>
      <CSelect
        name="model"
        value={model}
        onChange={(e) => {
          setModel(e.target.value);
          updateUrlParams(e)
        }}
        style={{
          appearance: model == '0' || model == '' || !model ? 'auto' : 'none',
        }}
      >
        <option value=""></option>
        {models.map((model) => {
          return (
            <option key={model.id} value={model.id}>
              {model.label}
            </option>
          );
        })}
      </CSelect>
      {model && model != '0' && (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('model')}
        >
          ×
        </button>
      )}
      </div>
      <div >
        <span className="filter-title-field">Année</span>
     </div>
     <div  className='filterOptions'>
      <CSelect
        name="year"
        value={year}
        onChange={(e) => {setYear(e.target.value); updateUrlParams(e)}}
        style={{
          appearance: year == '0' || year == '' || !year ? 'auto' : 'none',
        }}
      >
        <option value=""></option>
        {getYear()}
      </CSelect>
      {year == '0' || year == '' || !year ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('year')}
        >
          ×
        </button>
      )}
       </div>
      {/* <div>
        {" "}
        <span className="filter-title-field">Kilométrage</span>
        {minMileage !== "" && (
              <>
                min <span className="min-max">{minMileage} Km</span>
              </>
            )}
            {maxMileage !== "" && (
              <>
                Max <span className="min-max">{maxMileage} Km</span>{" "}
              </>
            )}
            <Range
              min={5000}
              max={300000}
              step={5000}
              onAfterChange={(values) => {
                setMinMileage(values[0]);
                setMaxMileage(values[1]);
              }}
              defaultValue={[5000, 300000]}
            />
      </div>
      <div>
        {" "}
        <span className="filter-title-field">Prix</span>
        {minPrice !== "" && (
              <>
                min <span className="min-max">{minPrice} TND</span>
              </>
            )}
            {maxPrice !== "" && (
              <>
                Max <span className="min-max">{maxPrice} TND</span>{" "}
              </>
            )}
            <Range
              min={5000}
              max={300000}
              step={5000}
              onAfterChange={(values) => {
                setMinPrice(values[0]);
                setMaxPrice(values[1]);
              }}
              defaultValue={[5000, 300000]}
            />
      </div> */}
      <div> <span className="filter-title-field">Kilométrage de </span> 
      <div className="inputWithSuffix">
                <CInput
                  type="number"
                  name="minMileage"
                  min={0}
                 // max={maxmileconst}
                  value={minMileage}
                 // placeholder={  "Kilométrage de "}
                  onChange={(e) => {
                    setMinMileage(parseInt(e.target.value));
                    updateUrlParams(e)
                  }}
                ></CInput>
             {minMileage && minMileage != "" && <button
         className="clearFilterInput"
          onClick={()=> handleClearSelection('minMileage')}
        >
          ×
        </button> } <span className="suf">km</span> </div>
      </div>

      <div>
      <span className="filter-title-field">Kilométrage à</span> 
      <div className="inputWithSuffix">
                <CInput
                 disabled = {isLoadedALL? false : true}  
                  min={minmileconst}
                 // max={maxmileconst}
                  type="number"
                  name="maxMileage"
                 // placeholder={"Kilométrage à " }
                  value={maxMileage}
                  onChange={(e) => {
                    setMaxMileage(parseInt(e.target.value));
                    updateUrlParams(e);
                  }}
                ></CInput>
                 {maxMileage && maxMileage != "" && <button
         className="clearFilterInput"
          onClick={()=> handleClearSelection('maxMileage')}
        >
          ×
        </button> }<span className="suf">km</span> </div>
      </div>

      <div>
      <span className="filter-title-field">Prix de</span> 
      <div className="inputWithSuffix">
                <CInput
                disabled = {isLoadedALL? false : true} 
                  type="number"
                  name="minPrice"
                  min={1}
                 // max={maxpriceconst}
                  value={minPrice}
                 // placeholder={"Prix de "}
                  onChange={(e) => {setMinPrice(parseInt(e.target.value));updateUrlParams(e)}}
                ></CInput>
               {minPrice && minPrice != "" && <button
         className="clearFilterInput"
          onClick={()=> handleClearSelection('minPrice')}
        >
          ×
        </button> }  <span className="suf">TND</span> </div>
      </div>

      <div>
      <span className="filter-title-field">Prix à </span> 
      <div className="inputWithSuffix">
                <CInput
                disabled = {isLoadedALL? false : true} 
                  min={1}
                 // max={maxpriceconst}
                  type="number"
                  name="maxPrice"
                  //placeholder={"Prix à "}
                  value={maxPrice}
                  onChange={(e) => {setMaxPrice(parseInt(e.target.value));updateUrlParams(e)}}
                ></CInput>
                {maxPrice && maxPrice != "" && <button
         className="clearFilterInput"
          onClick={()=> handleClearSelection('maxPrice')}
        >
          ×
        </button> } <span className="suf">TND</span> </div>
      </div>

      <div>
      <span className="filter-title-field">Carburant</span>
      <div  className='filterOptions'>
      <CSelect
              name="fuel"
              value={fuel}
              onChange={(e) => {setFuel(e.target.value);updateUrlParams(e)}}
              style={{
          appearance: fuel == '0' || fuel == '' || !fuel ? 'auto' : 'none',
        }}
            >
              <option value=""></option>
              <option value="Essence">Essence</option>
              <option value="Diesel">Diesel</option>
              <option value="Hybrid">Hybrid</option>
              <option value="électrique">électrique</option>
            </CSelect>
            {fuel == '0' || fuel == '' || !fuel ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('fuel')}
        >
          ×
        </button>
      )}
      </div>
      </div>
      <div>
      <span className="filter-title-field">Transmission</span>
      <div  className='filterOptions'>
      <CSelect
              name="transmission"
              value={transmission}
              onChange={(e) => {setTransmission(e.target.value);updateUrlParams(e);}}
              style={{
          appearance: transmission == '0' || transmission == '' || !transmission ? 'auto' : 'none',
        }}
            >
            <option value=""></option>
              <option value="traction intégrale">Traction intégrale</option>
              <option value=" traction avant"> Traction avant</option>
              <option value="propulsion arrière">Propulsion arrière</option>
              <option value="à quatre roues motrices">À quatre roues motrices</option>
            </CSelect>
            {transmission == '0' || transmission == '' || !transmission ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('transmission')}
        >
          ×
        </button>
      )}</div>
      </div>
      <div>
      <span className="filter-title-field">Nombre de sièges</span>
      <div  className='filterOptions'>

      <CSelect
                  name="nbrSeat"
                  value={nbrSeat}
                  onChange={(e) => {setNbrSeat(e.target.value);updateUrlParams(e)}}
       style={{ appearance: nbrSeat == '0' || nbrSeat == '' || !nbrSeat ? 'auto' : 'none',}}
                >
                  <option value=""></option>
                  <option value="5">5 sièges</option>
                  <option value="7">7 sièges</option>
                  <option value="9">9 sièges</option>
                </CSelect>
                {nbrSeat == '0' || nbrSeat == '' || !nbrSeat ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('nbrSeat')}
        >
          ×
        </button>
      )} </div>
      </div>
      <div>
      <span className="filter-title-field">Puissance fiscale</span>
      <div  className='filterOptions'>
      <CSelect
                  name="fiscalPower"
                  value={fiscalPower}
                  onChange={(e) => {setFiscalPower(e.target.value);updateUrlParams(e)}}
       style={{ appearance: fiscalPower == '0' || fiscalPower == '' || !fiscalPower ? 'auto' : 'none',}}          
                >
                  <option value=""></option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="autres">Autres</option>
                </CSelect>
                {fiscalPower == '0' || fiscalPower == '' || !fiscalPower ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('fiscalPower')}
        >
          ×
        </button>
      )} </div>
      </div>
      <div>
      <span className="filter-title-field">Boîte</span>
      <div  className='filterOptions'>
      <CSelect
       style={{ appearance: boite == '0' || boite == '' || !boite ? 'auto' : 'none',}}              
                  name="boite"
                  value={boite}
                  onChange={(e) => {setBoite(e.target.value);updateUrlParams(e)}}
                >
                  <option value=""></option>
                  <option value="Manuelle"> Manuelle</option>
                  <option value="Automatique">Automatique</option>
                </CSelect>
                {boite == '0' || boite == '' || !boite ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('boite')}
        >
          ×
        </button>
      )}</div>
      </div>
      <div>
      <span className="filter-title-field">Type de carrosserie</span>
      <div  className='filterOptions'>
      <CSelect
       style={{ appearance: bodyStyle == '0' || bodyStyle == '' || !bodyStyle ? 'auto' : 'none',}}              
                  name="bodyStyle"
                  value={bodyStyle}
                  onChange={(e) => {setBodyStyle(e.target.value);updateUrlParams(e)}}
                >
                  <option value=""></option>
                  <option value="Citadine">Citadine</option>
                  <option value="Compacte">Compacte</option>
                  <option value="Berline">Berline</option>
                  <option value="Coupé">Coupé</option>
                  <option value="Cabriolet">Cabriolet</option>
                  <option value="4x4">4x4</option>
                  <option value="Monospace">Monospace</option>
                  <option value="Utilitaire">Utilitaire</option>
                  <option value="Pick up">Pick Up</option>
                  <option value="SUV">SUV</option>
                  <option value="Autres">Autres</option>
                </CSelect>
                {bodyStyle == '0' || bodyStyle == '' || !bodyStyle ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('bodyStyle')}
        >
          ×
        </button>
      )}
      </div>
      </div>
      <div>
      <span className="filter-title-field">Couleur</span>
      <div  className='filterOptions'>
      <CSelect
       style={{ appearance: color == '0' || color == '' || !color ? 'auto' : 'none',}}              
                  name="color"
                  value={color}
                  onChange={(e) => {setColor(e.target.value);updateUrlParams(e)}}
                >
                  <option value=""></option>
                  <option value="Noir">Noir</option>
                  <option value="Gris argent">Gris argent</option>
                  <option value="Gris anthracite">Gris anthracite</option>
                  <option value="Gris Shark">Gris Shark</option>
                  <option value="Blanc">Blanc</option>
                  <option value="Rouge">Rouge</option>
                  <option value="Bleu">Bleu</option>
                  <option value="Beige">Beige</option>
                  <option value="Jaune">Jaune</option>
                  <option value="Orange">Orange</option>
                  <option value="Vert">Vert</option>
                  <option value="Aubergine">Aubergine</option>
                  <option value="Marron">Marron</option>
                  <option value="Autre">Autre</option>
                </CSelect>
                {color == '0' || color == '' || !color ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('color')}
        >
          ×
        </button>
      )}
      </div>
      </div>
    
      <div>
      <span className="filter-title-field">Pays</span> 
      <div  className='filterOptions'>
      <CSelect
       style={{ appearance: country == '0' || country == '' || !country ? 'auto' : 'none',}}              
                  name="country"
                  value={country}
                  onChange={(e) => {handleCountryChange(e);updateUrlParams(e)}}
                >
                  <option value=""></option>
                  {countries.map((country) => {
                    return (
                      <option key={country.id} value={country.id}>
                        {country.label}
                      </option>
                    );
                  })}
                </CSelect> 
          {country == '0' || country == '' || !country ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('country')}
        >
          ×
        </button>
      )}</div>
      </div>

      <div>
      <span className="filter-title-field">Gouvernorat</span> 
      <div  className='filterOptions'>
      <CSelect
       style={{ appearance: city == '0' || city == '' || !city ? 'auto' : 'none',}}              
                  name="city"
                  value={city}
                  onChange={(e) => {handleCityChange(e);updateUrlParams(e)}}
                >
                  <option value=""></option>
                  {cities.map((city) => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.label}
                      </option>
                    );
                  })}
                </CSelect>
                {city == '0' || city == '' || !city ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('city')}
        >
          ×
        </button>
      )}</div>
      </div>

      <div>
      <span className="filter-title-field">Délégation</span> 
      <div  className='filterOptions'>
      <CSelect
       style={{ appearance: delegate == '0' || delegate == '' || !delegate ? 'auto' : 'none',}}              
                 name="delegate"
                  value={delegate}
                  onChange={(e) => {setDelegate(e.target.value);updateUrlParams(e)}}
                >
                  <option value=""></option>
                  {delegates.map((delegate) => {
                    return (
                      <option key={delegate.id} value={delegate.id}>
                        {delegate.label}
                      </option>
                    );
                  })}
                </CSelect>
                {delegate == '0' || delegate == '' || !delegate ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('delegate')}
        >
          ×
        </button>
      )}</div>
      </div>
      <span className="filtersMobButton filtersMobButtonG " onClick={toggleVisibility} >
              Chercher
            </span>
    </div>
              </CCol>

              <CCol>
                <div className="occasions-results layout-list">
                  <div className="articles">
                 Il y a <strong>{results.length} </strong> résultat
                    {results.length > 1 && "s"}
                    {results.length > 0 && 
                    <span className="filtersOrder">
                    <CSelect
      
                 name="sortBy"
                  value={sortBy}
                  onChange={(e) => {handleSortBy(e)}}
                >
                  <option value="dateD">Annonces les plus récentes d'abord</option>
                  <option value="dateC">Annonces les plus anciennes d'abord</option>
                  <option value="year_asc">Année ordre croissant</option>
                  <option value="year_desc">Année ordre décroissant</option>  
                  <option value="price_asc">Prix ordre croissant</option>
                  <option value="price_desc">Prix ordre décroissant</option> 
                  <option value="kilo_asc">Kilométrage ordre croissant</option>
                  <option value="kilo_desc">Kilométrage ordre décroissant</option>              
                </CSelect>
                  </span>}
                    <CDataTable
                      className="vehicle-data-table"
                      items={results}
                      fields={fields}
                      header={false}
                      itemsPerPage={10}
                      pagination
                      scopedSlots={{
                        title: (car) => (
                          <td>
                            <VehicleItem
                             table='Vehicle'
                            item={car}
                            at={car.createdAt}
                            id={car.id}
                              make={car.brand && car.brand.label}
                              model={car.model && car.model.label}
                              price={car.price}
                              url={`/vehicles/cars/details/${car.id}`}
                              image={
                                car.mainPicture
                                  ? `${IMAGES_BASE_URL}/public/uploads/images/cars/${car.mainPicture.name}`
                                  : ""
                              }
                              mileage={car.mileage}
                              city={car.city && car.city.label}
                              boite={car.boite}
                              fuel={car.fuel}
                              year={car.year}
                              itemType={itemType}
                            />
                          </td>
                        ),
                      }} 
                    />
                  </div>
                </div>
              </CCol>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
