import React, { Component } from "react";
import { API_BASE_URL, IMAGES_BASE_URL } from "../../../../config";
import axios from "axios";
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CLabel,
  CCol,
  CContainer,
  CForm,
  CInput,
  CRow,
  CFormGroup,
  CSelect,
  CInputCheckbox,
} from "@coreui/react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export class EditEquipementRealEstate extends Component {
  constructor(props) {
    super(props);

    this.state = {
        label: "",
        type: "",
        type_annonce: [],
        type_annonce_label: "",
        type_annonce_value:"",
        loading:"",
        errors: {
            label: "",
            type: "",
            type_annonce: "",
            type_annonce_label: "",
            type_annonce_value:"",
           
          },
          success: "",

    }

  }
  componentDidMount() {
    axios(`${API_BASE_URL}/realEstate/equipement/type/all`)
    .then((res) => res.data)
    .then((result) => {
      this.setState({
        typeEquipementSelect: result,
        type_annonce: [{ id:1,label:"annonce de location et de vente"},{id:2,label:"annonce de location et de vacances" },{id:3,label:"annonce de location,de vente et de vacances" }],

      });

    });
    const { id } = this.props.match.params;
    axios(`${API_BASE_URL}/realEstate/equipement/show/${id}`)
      .then((res) => res.data)
      .then((result) => {
        console.log('resulttt', result)
        this.setState({
            equipement: result,
            label: result.label,
            type: result.type,
            type_annonce_value:result.type_annonce_value,
            type_annonce_label:result.type_annonce_label,

        });
      });
 
  }

  handleChange = (event) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    const name = event.target.name;
    this.setState({
      // [event.target.name]: event.target.value,
      [name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.handleValidation()) {
      const formData = new FormData();
      console.log("this.state.type.id",this.state.type_annonce_value)
      formData.append("type",this.state.type.id);
      formData.append("label", this.state.label);
      formData.append("typeAnnonceValue", this.state.type_annonce_value);
      formData.append("typeAnnonce", this.state.type_annonce[this.state.type_annonce_value-1].label);
      
      
      const { id } = this.props.match.params;
      axios
        .post(`${API_BASE_URL}/realEstate/equipement/edit/${id}`, formData)
        .then((res) => {
          if (res.data.status.toString() === "ok") {
            this.setState({
              success: "L'équipement a été modifiée avec succès",
              
            });
            window.scrollTo(0, 0)
          }
        })
        .catch((error) => {
          console.log(" errors : ", error);
        });
    }
  };

  handleValidation = () => {
    let fieldLabel = this.state.label;

    // let fieldCategory = this.state.category;
    let errors = {};
    let formIsValid = true;
    if (!fieldLabel) {
      formIsValid = false;
      errors.label = "Label de l'équipement ne peut pas être vide";
    } else {
      errors.title = "";
    }
   
    if (this.state.type === "") {
      formIsValid = false;
      errors.type = "Veuillez choisir le type d'équipement";
    } else {
      errors.type = "";
    }
    if (this.state.type_annonce === "") {
      formIsValid = false;
      errors.type_annonce = "Veuillez choisir le type de l'annonce";
    } else {
      errors.type_annonce = "";
    }
    /* if (this.state.image === null) {
      formIsValid = false;
      errors.image = "Veuillez ajouer une image";
    } else {
      errors.image = "";
    }*/
    this.setState({ errors: errors });
    return formIsValid;
  };


  render() {
    let errors = this.state.errors;
    return (
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="12" lg="12" xl="12">
            <CCard className="mx-4">
              <CCardBody className="p-4">
                {this.state.success !== "" ? (
                  <CAlert color="success" closeButton>
                    {this.state.success}
                  </CAlert>
                ) : (
                  ""
                )}
                <CForm onSubmit={this.handleSubmit} id="form-add">
                  <h1>Modifier un équipement</h1>
                  <CCol>
                    <p></p>
                  </CCol>

                  <CRow>
                    <CCol xs="6">
                      <CFormGroup>
                        <CLabel htmlFor="label">Label</CLabel>
                        <CInput
                          id="label"
                          placeholder="Entrer le label d'équipement"
                          name="label"
                          value={this.state.label}
                          onChange={this.handleChange}
                        />
                      </CFormGroup>
                    </CCol>
                  </CRow>
                  <CCol>
                    {errors.label && errors.label.length > 0 && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.label}
                      </p>
                    )}
                  </CCol>
                <CRow>
                    <CCol xs="8">
                      <CFormGroup>
                        <CLabel htmlFor="content">
                         Type équipement
                        </CLabel>
                        <CSelect
                          name="type"
                          value={this.state.type.id}
                          onChange={this.handleChange}
                        >
                          <option value="">--------------------</option>
                          {this.state.typeEquipementSelect && this.state.typeEquipementSelect.map((equipement) => {
                            return (
                              <option
                                key={equipement.id}
                                id={equipement.id}
                                value={equipement.id}
                              >
                                {equipement.label}
                              </option>
                            );
                          })}
                        </CSelect>
                      </CFormGroup>
                    </CCol>
                  </CRow> 
                   <CCol>
                    {errors.typeEquipementSelect && errors.typeEquipementSelect.length > 0 && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.typeEquipementSelect}
                      </p>
                    )}
                  </CCol>
                  <CRow>
                    <CCol xs="8">
                      <CFormGroup>
                        <CLabel htmlFor="content">
                         Type annonce
                        </CLabel>
                        <CSelect
                          name="type_annonce_value"
                          value={this.state.type_annonce_value}
                          onChange={this.handleChange}
                        >
                          <option value="">--------------------</option>
                          {this.state.type_annonce && this.state.type_annonce.map((annonce) => {
                            return (
                              <option
                                key={annonce.id}
                                id={annonce.id}
                                value={annonce.id}
                              >
                                {annonce.label}
                              </option>
                            );
                          })}
                        </CSelect>
                      </CFormGroup>
                    </CCol>
                  </CRow> 
                   <CCol>
                    {errors.type_annoce && errors.type_annoce.length > 0 && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.type_annoce}
                      </p>
                    )}
                  </CCol>
                {/*   <CRow>
                    <CCol xs="8">
                      <CFormGroup>
                        <CLabel htmlFor="content">Type de l'actualité</CLabel>
                        <CSelect
                          name="typeNews"
                          value={this.state.typeNews}
                          onChange={this.handleChange}
                        >
                          <option value="">--------------------</option>
                          {this.state.typeNewsSelect.map((typeNews) => {
                            return (
                              <option
                                key={typeNews.id}
                                id={typeNews.id}
                                value={typeNews.id}
                              >
                                {typeNews.label}
                              </option>
                            );
                          })}
                        </CSelect>
                      </CFormGroup>
                    </CCol>
                  </CRow>
                  <CCol>
                    {errors.typeNews.length > 0 && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.typeNews}
                      </p>
                    )}
                  </CCol>
                  <CRow>
                    <CCol xs="12">
                      <CFormGroup>
                        <CLabel htmlFor="content">Contenu</CLabel>
                        <CKEditor
                          editor={ClassicEditor}
                          data={this.state.content}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            this.setState({
                              content: data,
                            });
                          }}
                        />
                      </CFormGroup>
                    </CCol>
                  </CRow>
                  <CCol>
                    {errors.content.length > 0 && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.content}
                      </p>
                    )}
                  </CCol>
                  <CRow>
                    <CCol xs="8">
                      <CFormGroup>
                        <CLabel htmlFor="content">Lien youTube</CLabel>
                        <CInput
                          type="text"
                          name="youtubeLink"
                          onChange={this.handleChange}
                        />
                      </CFormGroup>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol xs="8">
                      <CFormGroup>
                        <CLabel htmlFor="content">Image</CLabel>
                        <CInput
                          type="file"
                          name="image"
                          onChange={this.handleFile}
                          accept=".jpg,.png,jpeg"
                        />
                      </CFormGroup>
                    </CCol>
                    <img
                      src={this.state.imageUrl}
                      width="100"
                      className="img-fluid img-thumbnail "
                      alt=""
                    />
                  </CRow>
                  <CCol>
                    {(errors.image && errors.image.length) > 0 && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.image}
                      </p>
                    )}
                  </CCol>
                  {localStorage.getItem("roles") === "ROLE_EDITOR_IN_CHIEF" ? (
                    <><CRow>
                      <CCol xs="8">
                        <CFormGroup variant="checkbox" className="checkbox">
                          <CInputCheckbox
                            checked={this.state.aLaUne}
                            onChange={this.handleChange}
                            value={this.state.aLaUne}
                            name="aLaUne"
                          />
                          <CLabel
                            variant="checkbox"
                            className="form-check-label"
                          >
                            Mettre à la une 
                          </CLabel>
                        </CFormGroup>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol xs="8">
                        <CFormGroup variant="checkbox" className="checkbox">
                          <CInputCheckbox
                            checked={this.state.status}
                            onChange={this.handleChange}
                            value={this.state.status}
                            name="status"
                          />
                          <CLabel
                            variant="checkbox"
                            className="form-check-label"
                          >
                            Publié 
                          </CLabel>
                        </CFormGroup>
                      </CCol>
                    </CRow>
                    </>
                  ) : (
                    ""
                  )} */}
                  <CRow>
                    <CCol xs="12" sm="3">
                      <CButton color="success" block type="submit">
                        Valider
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    );
  }
}

export default EditEquipementRealEstate;
