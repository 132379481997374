import React, { useEffect, useState } from "react";
import Loading from "src/components/myWidgets/loadingWidget";
import { useLocation , useHistory } from "react-router-dom";
import { IMAGES_BASE_URL , API_BASE_URL , keyHeader } from "../../../../config";
import axios from "axios";

import {
  CCol,
  CDataTable,
  CLabel,
  CFormGroup,
  CSelect,
  CInputRadio,
  CButton,
  CInput,
  CSpinner,

} from "@coreui/react";
import VehicleItem from "../VehicleItem";
import { FilterBike } from "./FilterBike";
import { WhatsappShareButton } from "react-share";

export default function ResultBike() {

  const location = useLocation();
  const history = useHistory();

  const [isLoaded, setIsLoaded] = useState(false);
  const [bikes, setBikes] = useState([]);
  const [all, setAll] = useState([]);
  const [errorAll,setErrorALL]= useState(false);
  const [results , setresults] = useState([]);

  const fields = [{ key: "title", _style: { width: "100%" } }];
  const itemType = "Vehicle";

  const [makes, setMakes] = useState([]);
  const [make, setMake] = useState("");
  const [status, setStatus] = useState(null);
  const [year, setYear] = useState("");
  const [isLoadedALL , setisLoadedALL] = useState(false);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [warranty, setWarranty] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [frameHeight, setFrameHeight] = useState("");
  const [frame, setFrame] = useState("");
  const [material, setMaterial] = useState("");
  const [wheelSize, setWheelSize] = useState("");
  const [delegate, setDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [filtersCount, setFiltersCount] = useState(0);
  const [sortBy, setSortBy] = useState('dateD');

  const toggleVisibility = () => {
    //console.log('rdyjgkhlkmgdeefglmdesghg');
    setIsVisible(prev => !prev);
  };
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {

    //console.log('paramMMM:', location.search );
    //console.log('paramMMMxxx:', searchParams );
    //console.log('Make param:', searchParams.get('make'));

  if (location.search)
    {       
    setStatus(searchParams.get('status'));
    setMake(searchParams.get('make'));
    setCategory(searchParams.get('category'));
    setYear(searchParams.get('year'));
    setFrameHeight(searchParams.get('frameHeight'));
    setWheelSize(searchParams.get('wheelSize')) 
    setFrame(searchParams.get('frame'))
    setMaterial(searchParams.get('material')) 
    setWarranty(searchParams.get('warranty'));
    setMaxPrice(searchParams.get('maxPrice'));
    setMinPrice(searchParams.get('minPrice'));
    setCountry(searchParams.get('country'));
    setCity(searchParams.get('city'));

    setFiltersCount(searchParams.size);
  }

  }, [location.search]);


  useEffect(() => {
    if (location.state) {   
      //console.log('rtrtrttkkkkkkk : ',location.state.result);
       //console.log('all : ',location.state);
      setresults(location.state.result);
      setAll(location.state.all.all);    
      setisLoadedALL(true)
      setIsLoaded(true);
    } else {
      //console.log('no state : ',location.state);
      
      axios
      (API_BASE_URL + "/annonce_bike/all",keyHeader)
      .then((res) => res.data)
      .then(
        (result) =>{ 
        //console.log('all result' , result);
        setAll(result);
        setresults(result)    
        setisLoadedALL(true)
        setIsLoaded(true);
        //setdisable('');
      },
      (error) => {
        //console.log(error);
        setErrorALL(true)
      }
      );
    }

    if (!localStorage.getItem("bikeBrands")){ 
      axios(`${API_BASE_URL}/bike/brands`)
          .then((res) => res.data)
          .then(
            (result) => {
              setMakes(result);
             // setIsLoaded2(true);
              // //console.log(result);
              localStorage.setItem("bikeBrands", JSON.stringify(result));         
            },
            (error) => {
              //setError(error);
             // setIsLoaded2(true);
            }
          );
          } else {
            setMakes(JSON.parse(localStorage.getItem("bikeBrands")));
            //setIsLoaded2(true);
          }
    
          if (!localStorage.getItem("bikeCategories")) {
            axios(`${API_BASE_URL}/bike/categories`)
            .then((res) => res.data)
            .then(
              (result) => {
                setCategories(result);
                localStorage.setItem("bikeCategories", JSON.stringify(result));
              },
              (error) => {
              }
            );
        } else {
          setCategories(JSON.parse(localStorage.getItem("bikeCategories")));
        }
    
        if (!localStorage.getItem("countries")) {
          axios(`${API_BASE_URL}/countries/all`)
            .then((res) => res.data)
            .then(
              (result) => {
                ////console.log("countries", result);
                setCountries(result);
               // setIsLoaded3(true);
               if(location.state && location.state.all.cities) setCities(location.state.all.cities);
               else if (result && searchParams.get('country')) {
                var countriesF=[]
                countriesF = result.find((el) => el.id.toString() == searchParams.get('country'));
                setCities(countriesF.cities);
                //console.log('countriesF',countriesF);
              }
                localStorage.setItem("countries", JSON.stringify(result));
                
              },
              (error) => {}
            );
        } else {
          // //console.log("countris2222222");
          // //console.log(localStorage.getItem("countries"));
        //  setIsLoaded3(true);
          setCountries(JSON.parse(localStorage.getItem("countries")));
          if(location.state && location.state.all.cities) setCities(location.state.all.cities);
          else if (JSON.parse(localStorage.getItem("countries")) && searchParams.get('country')) {
           var countriesF=[]
           countriesF = JSON.parse(localStorage.getItem("countries")).find((el) => el.id.toString() == searchParams.get('country'));
          setCities(countriesF.cities);
           //console.log('countriesFfffffff',countriesF);
         }
        }


  }, []);


  useEffect(() => {
    if(isLoadedALL)   {
      var results = all.filter(function (el) {
      const statusc = status === null || el.state == status; 
      const makec = !make || el.brand.id == make;  
      const categoryc = !category || el.category.id == category ;
      const yearc =  year > 0 ?  el.year == year : true;
      const frameHeightc = !frameHeight || el.frameHeight == frameHeight;   
      const warrantyc = !warranty || el.warranty == warranty;  
      const countryc = !country || el.country.id == country;  
      const cityc = !city || el.city.id == city;  
      const minPricec = !minPrice || el.price >= minPrice;  
      const maxPricec = !maxPrice || el.price <= maxPrice;  
      const framec = !frame || el.frame == frame; 
      const materialc = !material || el.material == material;  
      const wheelSizec = !wheelSize || el.wheelSize == wheelSize;  
    
      return statusc && makec && categoryc && framec && materialc && wheelSizec && frameHeightc && yearc && warrantyc  && countryc && cityc && minPricec && maxPricec;
    });
    //console.log('rsddsdxxx ',results)
    setresults(results);
  }
     }, [isLoadedALL,status ,make, category ,frame , material, wheelSize, frameHeight , year  , warranty , country , city , minPrice , maxPrice ]);
  
     const clearAll = () => {
      handleClearSelection('status'); handleClearSelection('year');
      handleClearSelection('make');handleClearSelection('category');
      handleClearSelection('minPrice');handleClearSelection('maxPrice');
      handleClearSelection('wheelSize') ; handleClearSelection('material');
      handleClearSelection('frame');handleClearSelection('frameHeight')
     
      //handleClearSelection('country');handleClearSelection('city');handleClearSelection('delegate')
          }  
      
          const handleSortBy = (e) => {
            //console.log('sortBy:',e.target.value);
            setSortBy(e.target.value);
            const sortedData = [...results].sort((a, b) => {
         
              if (e.target.value === 'dateC') {
                return a['createdAt'] > b['createdAt'] ? 1 : -1;
              }
              else    if (e.target.value === 'dateD') {
                return a['createdAt'] < b['createdAt'] ? 1 : -1;
              } 
               else    if (e.target.value === 'year_asc') {
                return a['year'] > b['year'] ? 1 : -1;
              }
              else    if (e.target.value === 'year_desc') {
                return a['year'] < b['year'] ? 1 : -1;
              } 
               else    if (e.target.value === 'price_asc') {
                return a['price'] > b['price'] ? 1 : -1;
              }
              else    if (e.target.value === 'price_desc') {
                return a['price'] < b['price'] ? 1 : -1;
              } 
               else    if (e.target.value === 'height_asc') {
                return a['frameHeight'] > b['frameHeight'] ? 1 : -1;
              }
              else    if (e.target.value === 'height_desc') {
                return a['frameHeight'] < b['frameHeight'] ? 1 : -1;
              }
            });
            setresults(sortedData);
           } 
     
  const updateUrlParams = (e) => {
     searchParams.set(e.target.name, e.target.value);
     const updatedURL = `${location.pathname}?${searchParams.toString()}`;
     history.replace(updatedURL);
   };

   const deleteUrlParams = (p) => {
      searchParams.delete(p);
      const updatedURL = `${location.pathname}?${searchParams.toString()}`;
      setFiltersCount(searchParams.size);
      history.replace(updatedURL);
    };

    const handleClearSelection = (type) => {
      if(searchParams.get(type))  deleteUrlParams(type) 
      if (type=='status') setStatus(null)
      else { const setStateFunction = `set${type.charAt(0).toUpperCase()}${type.slice(1)}`;
      eval(`${setStateFunction}('')`);}
      if(type=='country') { deleteUrlParams('city'); deleteUrlParams('delegate') ; setCity('');setDelegate('') }
      if(type=='city') { deleteUrlParams('delegate') ; setDelegate('') }

    };

    const getYear = () => {
      const years = [];
      for (let i = new Date().getFullYear(); i > 1950; i--) {
          years.push(
              <option value={i} key={i}>
                  {" "}
                  {i}
              </option>
          );
      }
      return years;
  };

  if (!isLoaded) {
    return (
   <Loading/>
    );
  } else {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-main__article l-main__content l-main__section l-article ">
            <div className="row">
              <CCol md="3">
               
              <span className="filtersMobButton" onClick={toggleVisibility} >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" id="filter"><path fill="#000" fill-rule="evenodd" d="M20 5h-1.17a3.001 3.001 0 0 0-5.66 0H4a1 1 0 0 0 0 2h9.17a3.001 3.001 0 0 0 5.66 0H20a1 1 0 1 0 0-2zm-4 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM3 12a1 1 0 0 1 1-1h1.17a3.001 3.001 0 0 1 5.66 0H20a1 1 0 1 1 0 2h-9.17a3.001 3.001 0 0 1-5.66 0H4a1 1 0 0 1-1-1zm5 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-4 4a1 1 0 1 0 0 2h9.17a3.001 3.001 0 0 0 5.66 0H20a1 1 0 1 0 0-2h-1.17a3.001 3.001 0 0 0-5.66 0H4zm13 1a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" clip-rule="evenodd"></path></svg> Filters
            </span>
            {filtersCount > 0 && <span className="filtersCountMob filtersCount">{ filtersCount+' appliqués'} <button
             onClick={clearAll} className="btn btn-primary ">
             Supprimer tout <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
<path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"></path>
</svg>
             </button>
              </span>}
              <div className={`bordered filter filtersSectionMob ${isVisible ? 'visibleFiltersmob' : ''}`} >
              <span onClick={toggleVisibility}
              className={`filtersMobButton filtersMobButtonx `} >&times;</span>
              <CLabel className='filtersS'> 
            Filters
            </CLabel>
            {filtersCount > 0 && <span className="filtersCount">{ filtersCount+' appliqués'} <button
             onClick={clearAll} className="btn btn-primary ">
             Supprimer tout <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
<path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"></path>
</svg>
             </button>
              </span>}
      <div>
          <br />
          <CFormGroup variant="checkbox" inline>
              <CInputRadio
                  className="form-check-input"
                  id="1"
                  name="status"
                  value="1"
                  onChange={(e) => {setStatus(e.target.value);updateUrlParams(e)}}
                  checked = {status==1 || status=='1' ? true : false}
              />
              <CLabel variant="checkbox" htmlFor="neuf">
                  Neuf
          </CLabel>
          </CFormGroup>
          <CFormGroup variant="checkbox" inline>
              <CInputRadio
                  className="form-check-input"
                  id="0"
                  name="status"
                  value="0"
                  onChange={(e) => {setStatus(e.target.value);updateUrlParams(e)}}
                  checked = {status==0 || status=='0' ? true : false}

              />
              <CLabel variant="checkbox" htmlFor="occasion">
                  Occasion
          </CLabel>
          </CFormGroup>
          {
                  status!=null?  <button
         className="clearFilterOptionButton"
          onClick={()=> handleClearSelection('status')}
        >
          <span style={{verticalAlign:'text-top'}} > × </span>clear
        </button> : null
                }
      </div>
      <div>
          {" "}
          <span className="filter-title-field">categories</span>
      </div>
      <div className='filterOptions'>
      <CSelect
          name="category"
          value={category}
          onChange={(e) => {
              setCategory(e.target.value);
              updateUrlParams(e)
        }}
        style={{
          appearance: category == '0' || category == '' || !category ? 'auto' : 'none',
        }}
      >
          <option value=""> Choisir la categorie</option>
          {categories.map((category) => {
              return (
                  <option key={category.id} value={category.id}>
                      {category.label}
                  </option>
              );
          })}
      </CSelect>
      {category && category != '0' && (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('category')}
        >
          ×
        </button>
      )}
      </div>

      <div>
          {" "}
          <span className="filter-title-field">Marques</span>
      </div>
      <div  className='filterOptions'>
      <CSelect
          name="make"
          value={make}
          onChange={(e) => {
              setMake(e.target.value);
              updateUrlParams(e)
        }}
        style={{
          appearance: make == '0' || make == '' || !make ? 'auto' : 'none',
        }}
      >
          <option value="0"> Choisir la marque</option>
          {makes.map((make) => {
              return (
                  <option key={make.id} value={make.id}>
                      {make.label}
                  </option>
              );
          })}
      </CSelect>
      {make && make != '0' && (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('make')}
        >
          ×
        </button>
      )}
      </div>


      <div>
          {" "}
          <span className="filter-title-field">Année</span>
      </div>
      <div  className='filterOptions'>
      <CSelect
          name="year"
          value={year}
          onChange={(e) => {setYear(e.target.value); updateUrlParams(e)}}
        style={{
          appearance: year == '0' || year == '' || !year ? 'auto' : 'none',
        }}
      >
          <option value=""> Année</option>
          {getYear()}
      </CSelect> 
       {year == '0' || year == '' || !year ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('year')}
        >
          ×
        </button>
      )}
       </div>

      <div>
      <span className="filter-title-field">Prix de</span> 
      <div className="inputWithSuffix">
                <CInput
                disabled = {isLoadedALL? false : true} 
                  type="number"
                  name="minPrice"
                  min={1}
                 // max={maxpriceconst}
                  value={minPrice}
                 // placeholder={"Prix de "}
                  onChange={(e) => {setMinPrice(parseInt(e.target.value));updateUrlParams(e)}}
                ></CInput>
               {minPrice && minPrice != "" && <button
         className="clearFilterInput"
          onClick={()=> handleClearSelection('minPrice')}
        >
          ×
        </button> }  <span className="suf">TND</span> </div>
      </div>

      <div>
      <span className="filter-title-field">Prix à </span> 
      <div className="inputWithSuffix">
                <CInput
                disabled = {isLoadedALL? false : true} 
                  min={1}
                 // max={maxpriceconst}
                  type="number"
                  name="maxPrice"
                  //placeholder={"Prix à "}
                  value={maxPrice}
                  onChange={(e) => {setMaxPrice(parseInt(e.target.value));updateUrlParams(e)}}
                ></CInput>
                {maxPrice && maxPrice != "" && <button
         className="clearFilterInput"
          onClick={()=> handleClearSelection('maxPrice')}
        >
          ×
        </button> } <span className="suf">TND</span> </div>
      </div>

      <div>
          <span className="filter-title-field">Taille du cadre</span>
      <div  className='filterOptions'>
          <CSelect
              name="frame"
              value={frame}
              onChange={(e) => {setFrame(e.target.value);updateUrlParams(e)}}
              style={{
          appearance: frame == '0' || frame == '' || !frame ? 'auto' : 'none',
        }}
          >
              <option value=""> Choisissez la taille</option>
              <option value="XS"> XS</option>
              <option value="S"> S</option>
              <option value="M"> M</option>
              <option value="L"> L</option>
              <option value="XL"> XL</option>

          </CSelect>
          {frame == '0' || frame == '' || !frame ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('frame')}
        >
          ×
        </button>
      )}
      </div>
      </div>
      <div>
          <span className="filter-title-field">Hauteur de cadre</span>
          <div  className='filterOptions'>
          <CInput
              name="frameHeight"
              value={frameHeight}
              onChange={(e) => {setFrameHeight(e.target.value);updateUrlParams(e)}}
          />
           {frameHeight == '0' || frameHeight == '' || !frameHeight ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('frameHeight')}
        >
          ×
        </button>
      )}
          </div>
      </div>

      <div>
          <span className="filter-title-field">Matériel du cadre</span>
          <div  className='filterOptions'>
          <CSelect
              name="material"
              value={material}
              onChange={(e) => {setMaterial(e.target.value);updateUrlParams(e)}}
              style={{
          appearance: material == '0' || material == '' || !material ? 'auto' : 'none',
        }}
          >
              <option value=""> </option>
              <option value="Acier"> Acier</option>
              <option value="Aluminium"> Aluminium</option>
              <option value="Titane"> Titane</option>
              <option value="Fibre de carbone"> Fibre de carbone</option>
          </CSelect>
          {material == '0' || material == '' || !material ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('material')}
        >
          ×
        </button>
      )}
          </div>
      </div>

      <div>
          <span className="filter-title-field">Taille des pneus</span>
          <div  className='filterOptions'>
              <CSelect
                  name="wheelSize"
                  value={wheelSize}
                  onChange={(e) => {setWheelSize(e.target.value);updateUrlParams(e)}}
                  style={{
          appearance: wheelSize == '0' || wheelSize == '' || !wheelSize ? 'auto' : 'none',
        }}
              >
                  <option value="">Choisissez la taille des pneus </option>
                  <option value="16 pouces"> 16 pouces</option>
                  <option value="20 pouces"> 20 pouces</option>
                  <option value="24 pouces"> 24 pouces</option>
                  <option value="26 pouces"> 26 pouces</option>
                  <option value="27.5 pouces"> 27.5 pouces</option>
                  <option value="27.5 plus"> 27.5 plus</option>
                  <option value="29 pouces"> 29 pouces</option>
                  <option value="650"> 650</option>
                  <option value="700">700</option>

              </CSelect>
              {wheelSize == '0' || wheelSize == '' || !wheelSize ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('wheelSize')}
        >
          ×
        </button>
      )}
              </div>
      </div>
      <span className="filtersMobButton filtersMobButtonG " onClick={toggleVisibility} >
              Chercher
            </span>
  </div>


              </CCol>

              <CCol>
                <div className="occasions-results layout-list">
                  <div className="articles">
                    Il y a <strong>{results.length} </strong> résultat
                    {results.length > 1 && "s"}
                    {results.length > 0 && 
                    <span className="filtersOrder">
                    <CSelect
      
                 name="sortBy"
                  value={sortBy}
                  onChange={(e) => {handleSortBy(e)}}
                >
                  <option value="dateD">Annonces les plus récentes d'abord</option>
                  <option value="dateC">Annonces les plus anciennes d'abord</option>
                  <option value="year_asc">Année ordre croissant</option>
                  <option value="year_desc">Année ordre décroissant</option>  
                  <option value="price_asc">Prix ordre croissant</option>
                  <option value="price_desc">Prix ordre décroissant</option> 
                  <option value="height_asc">Hauteur de cadre ordre croissant</option>
                  <option value="height_desc">Hauteur de cadre ordre décroissant</option>                 
                </CSelect>
                  </span>}
                    <CDataTable
                      className="vehicle-data-table"
                      items={results}
                      fields={fields}
                      header={false}
                      itemsPerPage={10}
                      pagination
                      scopedSlots={{
                        title: (bike) => (
                          <td>
                            {/** <Link
                                to={`/vehicules/cars/detail/${car.id}`}
                                key={car.id}
                              > */}
                            <VehicleItem
                             table='Vehicle'
                              item={bike}
                              at={bike.createdAt}
                              id={bike.id}
                              make={bike.brand && bike.brand.label}
                              model={bike.category && bike.category.label}
                              price={bike.price}
                              url={`/vehicles/bikes/details/${bike.id}`}
                              image={
                                bike.mainPicture
                                  ? `${IMAGES_BASE_URL}/public/uploads/images/bikes/${bike.mainPicture.name}`
                                  : ""
                              }
                              frame={bike.frame}
                              wheelsize={bike.wheelsize}
                              frameHeight={bike.frameHeight}
                              city={bike.city && bike.city.label}
                              year={bike.year}
                              itemType={itemType}
                            />
                          </td>
                        ),
                      }}
                    />
                  </div>
                </div>
              </CCol>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
