import React from 'react'
import { CFooter } from '@coreui/react'

const TheFooter = () => {
  const year = new Date().getFullYear();
  return (
    <CFooter fixed={false}>
      <div>
        <a href="https://www.tunisialiving.tn/" target="_blank" rel="noopener noreferrer">Tunisia Living</a>
        <span className="ml-1">&copy; 2020 - {year}</span>
      </div>
    </CFooter>
  )
}

export default React.memo(TheFooter)
