import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../config";
import {
  CAlert,
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CButton,
  CCollapse,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { Link } from "react-router-dom";
import axios from "axios";
import apiUser from "../apiUser";
const getBadge = (status) => {
  switch (status) {
    case "Active":
      return "success";
    case "Inactive":
      return "secondary";
    case "Pending":
      return "warning";
    case "Banned":
      return "danger";
    default:
      return "primary";
  }
};

export default function IndexTypeNews() {
  const [modal, setModal] = useState(false);
  const [error, setError] = useState("");
  const [errorDel, setErrorDel] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [details, setDetails] = useState([]);

  const toggle = () => {
    setModal(!modal);
  };
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [details, index];
    }
    setDetails(newDetails);
  };
  function deleteTypeNews(id) {
    //const { id } = this.props.match.params;
    toggle();
    apiUser
      .delete(`/type-news/delete/${id}`)
      .then((res) => {
        //console.log(res);
        if (res.data.status.toString() === "ok") {
          setItems((items) => items.filter((x) => x.id !== id));
        }
        if (res.data.status.toString() === "ForeignKeyConstraintViolationException") {
          setErrorDel("Vous ne pouvez pas supprimer ce type d'actualité");
          //console.log("object : " + errorDel);
        }
      })
      .catch((err) => console.log(err));
  }

  const fields = [
    {
      key: "label",
      label: "libellé",
    },
    {
      key: "show_details",
      label: "Actions",
      _style: { width: "1%" },
      sorter: false,
      filter: false,
    },
  ];
  // Remarque : le tableau vide de dépendances [] indique
  // que useEffect ne s’exécutera qu’une fois, un peu comme
  // componentDidMount()
  useEffect(() => {
    fetch(API_BASE_URL + "/type-news")
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          //console.log(result);
          setItems(result);
        },
        // Remarque : il faut gérer les erreurs ici plutôt que dans
        // un bloc catch() afin que nous n’avalions pas les exceptions
        // dues à de véritables bugs dans les composants.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  if (error) {
    return <div>Erreur : {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Chargement...</div>;
  } else {
    return (
      <div>
        <CRow>
          <CCol xl={12}>
            <Link
              className="btn btn-outline-primary btn-lg "
              to="/5467854/type-news/add"
            >
              {" "}
              <CIcon name="cilNotes" /> Ajouter un type d'actualité
            </Link>
            <p></p>
            <CCard>
              <CCardHeader>Type des actualités</CCardHeader>
              <CCardBody>
                {errorDel !== "" ? (
                  <CAlert color="danger" closeButton>
                    {errorDel}
                  </CAlert>
                ) : (
                  ""
                )}
                <CDataTable
                  items={items}
                  fields={fields}
                  columnFilter
                  tableFilter
                  footer
                  itemsPerPageSelect
                  itemsPerPage={10}
                  hover
                  sorter
                  pagination
                  scopedSlots={{
                    status: (item) => (
                      <td>
                        <CBadge color={getBadge(item.status)}>
                          {item.status}
                        </CBadge>
                      </td>
                    ),
                    show_details: (item, index) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(index);
                            }}
                          >
                            {details.includes(index) ? "Hide" : "Show"}
                          </CButton>
                        </td>
                      );
                    },
                    details: (item, index) => {
                      return (
                        <>
                          <CCollapse show={details.includes(index)}>
                            <CCardBody>
                              <h4>{item.label}</h4>
                              <Link to={`/5467854/type-news/edit/${item.id}`}>
                                <CButton size="sm" color="info">
                                  Modifier
                                </CButton>
                              </Link>
                              <CButton
                                size="sm"
                                color="danger"
                                className="ml-1"
                                onClick={toggle}
                              >
                                Supprimer
                              </CButton>
                              <CModal show={modal} onClose={toggle}>
                                <CModalHeader closeButton></CModalHeader>
                                <CModalBody>
                                  Voulez vous vraiment supprimer ce type
                                  d'actualité ?
                                </CModalBody>
                                <CModalFooter>
                                  <CButton
                                    color="primary"
                                    onClick={() => {
                                      deleteTypeNews(item.id);
                                      toggleDetails(index);
                                    }}
                                  >
                                    Oui
                                  </CButton>

                                  <CButton color="secondary" onClick={toggle}>
                                    Cancel
                                  </CButton>
                                </CModalFooter>
                              </CModal>
                            </CCardBody>
                          </CCollapse>
                        </>
                      );
                    },
                  }}
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
    );
  }
}
