import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../config";
import axios from "axios";
import { Link } from "react-router-dom";

import { CCol, CTabContent, CTabPane, CTabs } from "@coreui/react";

export default function Sectors() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {

    if (!localStorage.getItem("sectors")) {
      axios(`${API_BASE_URL}/jobs/sectors`)
        .then((res) => res.data)
        .then(
          (result) => {          
            setItems(result); 
            setIsLoaded(true);
            localStorage.setItem("sectors", JSON.stringify(result));
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    } else {
       
        setItems(JSON.parse(localStorage.getItem("sectors"))); 
        setIsLoaded(true);
    }

  }, []);

  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <CCol xs="9" md="9" className="justify-content-md-center offset-md-2">
        <CTabs>
          <CTabContent fade={false}>
            <CTabPane>
              <div className="container">
                <main className="l-main lmain2">
                  <div className="l-section l-section--archive">
                    <div className="loading_logo">
                      <img
                        src={"assets/icons/tunisia-living-loading-icon.gif"}
                        alt="tunisia living loading icon"
                      />
                      <p>Chargement...</p>
                    </div>
                  </div>
                </main>
              </div>{" "}
            </CTabPane>
          </CTabContent>
        </CTabs>
      </CCol>
    );
  } else {
    return (
      <>
        <div className="col-md-9 offset-md-2">
          <CTabs>
            <CTabContent fade={false}>
              <CTabPane>
                <div className="row">
                  {items.map((sector, index) => {
                    return (
                      <div key={sector.id} className="col-md-4">
                        <div className="row">
                          {" "}
                          <span className="sector-number">{index + 1} </span>
                          <Link
                            className="sector"
                            to={`/jobs/${sector.id}/${sector.slug}/`}
                          >
                            {sector.label}
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </CTabPane>
            </CTabContent>
          </CTabs>
        </div>
      </>
    );
  }
}
