import React, { Component } from "react";

import axios from "axios";
import { API_BASE_URL , tokenCookie , keyHeader, key } from "../../../config";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { withRouter } from 'react-router-dom';
import {
  CAlert,
  CButton,
  CCol,
  CFormGroup,
  CRow,
  CLabel,
  CInput,
  CSelect,
  CInputCheckbox,
  CSpinner
} from "@coreui/react";
import { logoutLocal } from "src/components/middleware/auth";
import apiClient from "../apiClient";


export class JobForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading:false,
      companyId : props.companyId,
      title: "",
      description: "",
      sector: "",
      jobType: [],
      remuneration: "",
      vacant : 1 ,
      levelStudies: "",
      levelExp: "",
      language: "",
      sectors: [],
      success: "",
      errors: {
        title: "",
        description: "",
        sector: "",
        jobType: "",
        remuneration: "",
        levelStudies: "",
        levelExp: "",
        language: "",
      },
    };
  }
  handleInputChange = (event) => {
    //console.log('companyId -- ',this.state.companyId);
    let nCheckbox = this.state.jobType.slice(); // create a new copy of state value
    if (this.isValueExist(nCheckbox, event)) {
      // check if the same value is preexisted in the array
      const index = nCheckbox.indexOf(event.target.value);
      nCheckbox.splice(index, 1); // removing the preexciting value
    } else {
      nCheckbox.push(event.target.value); // inserting the value of checkbox in the array
    }
    this.setState({
      jobType: nCheckbox,
    });
    //console.log(this.state.jobType)
  };
  isValueExist(data, event) {
    if (data.length === 0) {
      return false;
    }

    for (let i = 0; i <= data.length; i++) {
      if (event.target.value === data[i]) {
        return true;
      }
    }
    return false;
  }
  handleChange = (event) =>
    this.setState({
      [event.target.name]: event.target.value,
    });

  handleValidation = () => {
    let formIsValid = true;
    let errors = {};
    if (!this.state.title) {
      formIsValid = false;
      errors.title = "Veuillez entrer le titre du poste";
    } else {
      errors.title = "";
    }
    if (!this.state.description) {
      formIsValid = false;
      errors.description = "Veuillez entrer le description du poste";
    } else {
      errors.description = "";
    }
    if (!this.state.sector) {
      formIsValid = false;
      errors.sector = "Veuillez choisir le secteur d'activité";
    } else {
      errors.sector = "";
    }
    if (!this.state.jobType) {
      formIsValid = false;
      errors.jobType = "Veuillez choisir le type du poste";
    } else {
      errors.jobType = "";
    }

    if (!this.state.levelStudies) {
      formIsValid = false;
      errors.levelStudies = "Veuillez choisir le niveau d'études ";
    } else {
      errors.levelStudies = "";
    }
    if (!this.state.levelExp) {
      formIsValid = false;
      errors.levelExp = "Veuillez choisir le niveau d'expérience ";
    } else {
      errors.levelExp = "";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    //console.log('stt' , this.state);
    if (this.handleValidation()) {
      this.setState({loading:true});
      const formData = new FormData();

      formData.append("title", this.state.title);
      //formData.append("memberId", tokenCookie);
      formData.append("description", this.state.description);
      formData.append("remuneration", this.state.remuneration);
      formData.append("vacant", this.state.vacant);
      formData.append("levelStudies", this.state.levelStudies);
      formData.append("levelExp", this.state.levelExp);
      formData.append("sector", this.state.sector);
      formData.append("jobType", this.state.jobType);
      formData.append("title", this.state.title);
      if(this.state.companyId) formData.append("companyId", this.state.companyId);
      //console.log('jobType' , formData.get("jobType"));

      apiClient
        .post("/job-offer/add", formData )
        .then((res) => { 
          //console.log(res);
          if (res.data.status.toString() === "ok") {
            this.props.history.push( "/success/ok" , { from: 'add' } )       
        
          } 
          this.setState({loading:false});
        })
        .catch((error) => {
          //console.log(" errors : ", error);
          this.setState({loading:false});
          if (error.response && error.response.status === 401){
            logoutLocal();
            window.location.replace("/members/login");
          }
        });
    }
  };
  componentDidMount() {
    //console.log('this.props');
    //console.log(this.props);

    if (!localStorage.getItem("sectors")) {
      axios(`${API_BASE_URL}/jobs/sectors`)
        .then((res) => res.data)
        .then(
          (result) => {
              this.setState({
                  sectors: result,
                });
            localStorage.setItem("sectors", JSON.stringify(result));
          },
          (error) => {
          }
        );
    } else {
      this.setState({
          sectors: JSON.parse(localStorage.getItem("sectors")),
        });
    }


    // axios(`${API_BASE_URL}/jobs/sectors`)
    //   .then((res) => res.data)
    //   .then(
    //     (result) => {
    //       this.setState({
    //         sectors: result,
    //       });
    //       console.log(result);
    //     },
    //     (error) => {
    //       console.log(error);
    //     }
    //   );
  }
  render() {
    let errors = this.state.errors;
    return (
      <div>
        <div className="information-title">
          <h2>Créer une annonce</h2>
        </div>
        <p></p>
        <form onSubmit={this.handleSubmit} id="form-add" className="jobFormClass">
          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Titre du poste</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CInput
                type="text"
                name="title"
                value={this.state.title}
                onChange={this.handleChange}
              />
              {errors.title.length > 0 && (
                <span className="invalid-feedback error">{errors.title}</span>
              )}
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Secteur d'activité</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CSelect
                name="sector"
                value={this.state.sector}
                onChange={this.handleChange}
              >
                <option value=""> Choisissez un secteur d'activité</option>
                {this.state.sectors.map((sector) => {
                  return (
                    <option key={sector.id} id={sector.id} value={sector.id}>
                      {sector.label}
                    </option>
                  );
                })}
              </CSelect>
              {errors.sector.length > 0 && (
                <span className="invalid-feedback error">{errors.sector}</span>
              )}
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Description du poste</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CKEditor
                editor={ClassicEditor}
                data={this.state.description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  this.setState({
                    description: data,
                  });
                }}
              />
              {errors.description.length > 0 && (
                <span className="invalid-feedback error">
                  {errors.description}
                </span>
              )}
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Type de poste</CLabel>
            </CCol>
            <CCol xs="12" md="3">
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  id="1"
                  value="CDI"
                  onChange={this.handleInputChange}
                />
                <CLabel variant="checkbox" className="form-check-label">
                  CDI
                </CLabel>
              </CFormGroup>
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  id="2"
                  value="CDD"
                  onChange={this.handleInputChange}
                />
                <CLabel variant="checkbox" className="form-check-label">
                  CDD
                </CLabel>
              </CFormGroup>
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  id="3"
                  value="SIVP"
                  onChange={this.handleInputChange}
                />
                <CLabel variant="checkbox" className="form-check-label">
                  SIVP
                </CLabel>
              </CFormGroup>
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  id="4"
                  value="Functions publiques"
                  onChange={this.handleInputChange}
                />
                <CLabel variant="checkbox" className="form-check-label">
                  Functions publiques
                </CLabel>
              </CFormGroup>
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  id="5"
                  value="Indépendant/Freelance"
                  onChange={this.handleInputChange}
                />
                <CLabel variant="checkbox" className="form-check-label">
                  Indépendant/Freelance
                </CLabel>
              </CFormGroup>
              {errors.jobType.length > 0 && (
                <span className="invalid-feedback error">{errors.jobType}</span>
              )}
            </CCol>
            <CCol xs="12" md="3">
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  id="6"
                  value="Stage/PFE"
                  onChange={this.handleInputChange}
                />
                <CLabel variant="checkbox" className="form-check-label">
                  Stage/PFE
                </CLabel>
              </CFormGroup>
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  id="7"
                  value="Stage"
                  onChange={this.handleInputChange}
                />
                <CLabel variant="checkbox" className="form-check-label">
                  Stage
                </CLabel>
              </CFormGroup>
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  id="8"
                  value="Saisonnier"
                  onChange={this.handleInputChange}
                />
                <CLabel variant="checkbox" className="form-check-label">
                  Saisonnier
                </CLabel>
              </CFormGroup>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Niveau d'études</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CSelect
                name="levelStudies"
                value={this.state.levelStudies}
                onChange={this.handleChange}
              >
                <option value=""> --------- </option>
                <option value="Primaire">Primaire</option>
                <option value="Secondaire">Secondaire</option>
                <option value="Formations professionnelles">
                  Formations professionnelles
                </option>
                <option value="Bac">Bac</option>
                <option value="Bac + 1">Bac + 1</option>
                <option value="Bac + 2">Bac + 2</option>
                <option value="Bac + 3">Bac + 3</option>
                <option value="Bac + 4">Bac + 4</option>
                <option value="Bac + 5">Bac + 5</option>
              </CSelect>
              {errors.levelStudies.length > 0 && (
                <span className="invalid-feedback error">
                  {errors.levelStudies}
                </span>
              )}
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Niveau d'expériences</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CSelect
                name="levelExp"
                value={this.state.levelExp}
                onChange={this.handleChange}
              >
                <option value=""> --------- </option>
                <option value="Aucune expérience">Aucune expérience</option>
                <option value="Moins d'un an">Moins d'un an</option>
                <option value="Entre 1 et 2 ans">Entre 1 et 2 ans</option>
                <option value="Entre 2 et 5 ans">Entre 2 et 5 ans</option>
                <option value="Entre 5 et 10 ans">Entre 5 et 10 ans</option>
                <option value="Plus que 10 ans">Plus que 10 ans</option>
              </CSelect>
              {errors.levelExp.length > 0 && (
                <span className="invalid-feedback error">
                  {errors.levelExp}
                </span>
              )}
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Poste disponible</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CInput
                type="number"
                min={'1'}
                name="vacant"
                value={this.state.vacant}
                onChange={this.handleChange}
              />
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="name">Salaire</CLabel>
            </CCol>
            <CCol xs="12" md="7">
              <CInput
                type="text"
                name="remuneration"
                value={this.state.remuneration}
                onChange={this.handleChange}
              />
            </CCol>
          </CFormGroup>

          <CRow>
            <CCol md="3"></CCol>
            <CCol xs="12" sm="3">
              {!this.state.loading && (
                <CButton color="primary" block type="submit">
                  Valider
                </CButton>
              )}

              {this.state.loading && (
                <CButton color="primary" block disabled>
                  <span>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>

                  <span> Valider</span>
                </CButton>
              )}
            </CCol>
          </CRow>
          <CRow>
            <CCol md="3"></CCol>
            <CCol md="9">
              {" "}
              {this.state.success !== "" ? (
                <CAlert color="success" closeButton>
                  {this.state.success}
                </CAlert>
              ) : (
                ""
              )}
            </CCol>
          </CRow>
        </form>
      </div>
    );
  }
}

export default withRouter (JobForm);
