import React, { useState, useEffect , useContext } from 'react'
import {
  CBadge,
  CAlert,
  CRow,
  CCol,
  CFormGroup,
  CLabel,
  CInput,
  CSelect,
  CButton,
  CInputRadio,
  CSpinner,
  CModal,
  CModalBody,
  CModalFooter,

  CModalHeader,
  CInputCheckbox,
  CInputGroup,
  CInputGroupText,
  CInputGroupAppend,
} from "@coreui/react";
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { API_BASE_URL, IMAGES_BASE_URL , tokenCookie , keyHeader , sizeAlert} from "../../../config";
import axios from "axios";
//import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import CIcon from '@coreui/icons-react';
import './vehiclestyle.css';
import imageCompression from "browser-image-compression";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useHistory } from "react-router-dom";
import Loading from 'src/components/myWidgets/loadingWidget';
import { Basic , Scroll , Categori , Adresse , Contact , Extra , Imgs , Engine , Weight , Options , Equip} from "src/components/myWidgets/formTitles";
import { getBadge , textBadge } from 'src/actions';
import { addWatermark , addWatermarkToAlbum } from "src/actions";
import { logoutLocal } from 'src/components/middleware/auth';
import MemberContext from '../members/MemberContext';
import apiClient from '../apiClient';


const EditVehicle = (props) => {

  const [newPhone, setNewPhone] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [imgPrincipal, setImgPrincipal] = useState(null);
  const [pImageThumb, setpImageThumb] = useState(null);
  const [oldImgP, setoldImgp] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [annonce, setAnnonce] = useState('');
  
  const history = useHistory();
  /*****  annonce bike *******/
  const [error, setError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [categories, setCategories] = useState([]);
  const [brandsAll, setBrandsAll] = useState([]);
  const [brands, setBrands] = useState([]);
  const [category, setCategory] = useState("");
  //const [brand, setBrand] = useState("");
  //const [createdBy] = tokenCookie;
  const [frame, setFrame] = useState("");
  const [frameHeight, setFrameHeight] = useState("");
  const [material, setMaterial] = useState("");
  const [wheelSize, setWheelSize] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [breaking, setBreaking] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [album, setAlbum] = useState([]);
  const [otherImages, setOtherImages] = useState([]);
  const [success, setSuccess] = useState(false);
  const [year, setYear] = useState("");
  const [country, setCountry] = useState("");
    const [countries, setCountries] = useState([]);
    const [city, setCity] = useState("");
    const [cities, setCities] = useState([]);
    const [delegate, setDelegate] = useState("");
    const [delegates, setDelegates] = useState([]);
    const [locality, setLocality] = useState("");
    const [localities, setLocalities] = useState([]);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [result] = useState(null);
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState(null);
  const [setErrorDel] = useState("");
  const [setItems] = useState([]);
  const [typeImage, setTypeImage] = useState(false);

  /*****  annonce camion *******/
  const [makes, setMakes] = useState([]);
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [mileage, setMileage] = useState("");
  const [bodyStyle, setBodyStyle] = useState("");
  const [fuel, setFuel] = useState("");
  const [fiscalPower, setFiscalPower] = useState("");
  const [color, setColor] = useState("");
  const [transmission, setTransmission] = useState('');
  const [permGVW, setPermGVW] = useState("");
  const [admisblHW, setAdmisblHw] = useState("");
  const [payload, setPayload] = useState("");
  const [empWeight, setEmpWeight] = useState("");
  const [loadLength, setLoadLength] = useState("");
  const [loadHeight, setLoadHeight] = useState("");
  const [loadWidth, setLoadWidth] = useState("");
  const [loadVolume, setLoadVolume] = useState("");
  const [brake, setBrake] = useState("");
  const [suspension, setSuspension] = useState("");
  const [axleConfig, setAxleConfig] = useState("");
  const [wheelsDist, setWheelsDist] = useState("");
  const [tyreSize, setTyreSize] = useState("");
  const [emissionClass, setEmissionClass] = useState("");
  const [co2Emmision, setCo2Emmision] = useState("");
  const [fuelCons, setFuelCons] = useState("");
  const [urban, setUrban] = useState("");
  const [rural, setRural] = useState("");
  const [sootFilter, setSootFilter] = useState(null);
  const [cab, setCab] = useState("");
  const [sleepingCab, setSleepingCab] = useState(null);

  /*****  annonce car *******/
  const [boite, setBoite] = useState("");
  const [nbrSeat, setNbrSeat] = useState("");
  const [warranty, setWarranty] = useState("");
  const [extEquipments, setExtEquipments] = useState([]);
  const [intEquipments, setInEquipments] = useState([]);
  const [secureEquipments, setSecureEquipements] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [extEquipmentsVehicle, setExtEquipmentsVehicle] = useState([]);
  const [intEquipmentsVehicle, setIntEquipmentsVehicle] = useState([]);
  const [secEquipmentsVehicle, setSecEquipmentsVehicle] = useState([]);
  const [index, setIndex] = useState([]);

  /*****  annonce moto *******/
  const [speed, setSpeed] = useState('');
  const [paintType, setPaintType] = useState(null);
  const [gearingType, setGearingType] = useState('');
  const [equipmentsAnnonce, setEquipmentsAnnonce] = useState([]);
  const [models, setModels] = useState([]);
  const [equipmentsAnnonceVehicle, setEquipmentsAnnonceVehicle] = useState([]);
  const [otherEquipments, setOtherEquipments] = useState([]);
  const [otherEquipmentsVehicle, setOtherEquipmentsVehicle] = useState([]);

  const [accessoryEquipments, setAccessoryEquipments] = useState([]);
  const [accessoryEquipmentsVehicle, setAccessoryEquipmentsVehicle] = useState([]);

  const [doneuploadingfile, setDoneuploadingfile] = useState(true);
  const [id, setId] = useState(null);

  const [imgCount , setImgCount] = useState(0);
  const [imgCountA , setImgCountA] = useState(0);
  const [abortControllers, setAbortControllers] = useState({});
  const [removedfiles, setremovedfiles] = useState([]);

  const [hasChanged , sethasChanged] = useState(false);
  const {vehicles , setVehicles} = useContext(MemberContext);

  useEffect(() => {
    if(annonce && !hasChanged &&
      (
    (annonce.category && category!=annonce.category.id) 
    || (annonce.brand && make!=annonce.brand.id) 
    //|| (annonce.model && !annonce.model.id && model!=annonce.model) || (annonce.model && annonce.model.id && model!=annonce.model.id) 
    || (annonce.price && price!=annonce.price) || (annonce.material && material!=annonce.material)
    || (annonce.warranty && warranty!=annonce.warranty) || (annonce.year && year!=annonce.year) 
    || (annonce.braking && breaking!=annonce.braking) || (annonce.wheelSize && wheelSize!=annonce.wheelSize) 
    || (annonce.frame && year!=annonce.frame) || (annonce.frameHeight && frameHeight!=annonce.frameHeight)
    || (annonce.fuel && fuel !== annonce.fuel) ||
      (annonce.fiscalPower && fiscalPower !== annonce.fiscalPower) ||
      (annonce.color && color !== annonce.color) ||
      (annonce.transmission && transmission !== annonce.transmission) ||
      (annonce.permGVW && permGVW !== annonce.permGVW) ||
      (annonce.admisblHW && admisblHW !== annonce.admisblHW) ||
      (annonce.payload && payload !== annonce.payload) ||
      (annonce.empWeight && empWeight !== annonce.empWeight) ||
      (annonce.loadLength && loadLength !== annonce.loadLength) ||
      (annonce.loadHeight && loadHeight !== annonce.loadHeight) ||
      (annonce.loadWidth && loadWidth !== annonce.loadWidth) ||
      (annonce.loadVolume && loadVolume !== annonce.loadVolume) ||
      (annonce.brake && brake !== annonce.brake) ||
      (annonce.suspension && suspension !== annonce.suspension) ||
      (annonce.axleConfig && axleConfig !== annonce.axleConfig) ||
      (annonce.wheelsDist && wheelsDist !== annonce.wheelsDist) ||
      (annonce.tyreSize && tyreSize !== annonce.tyreSize) ||
      (annonce.emissionClass && emissionClass !== annonce.emissionClass) ||
      (annonce.co2Emmision && co2Emmision !== annonce.co2Emmision) ||
      (annonce.fuelCons && fuelCons !== annonce.fuelCons) ||
      (annonce.urban && urban !== annonce.urban) ||
      (annonce.rural && rural !== annonce.rural) ||
      //(annonce.sootFilter !== undefined && sootFilter !== annonce.sootFilter) ||
      (annonce.cab && cab !== annonce.cab) ||
    //  (annonce.sleepingCab !== undefined && sleepingCab !== annonce.sleepingCab) ||
      (annonce.mileage && mileage !== annonce.mileage) ||
      (annonce.bodyStyle && bodyStyle !== annonce.bodyStyle) ||
      (annonce.boite && boite !== annonce.boite) ||
      (annonce.nbrSeat && nbrSeat !== annonce.nbrSeat) ||
      (annonce.warranty && warranty !== annonce.warranty) ||
      (annonce.speed && speed !== annonce.speed) ||
     // (annonce.paintType !== undefined && paintType !== annonce.paintType) ||
      (annonce.gearingType && gearingType !== annonce.gearingType)
    || country != annonce.country.id || city != annonce.city.id 
    || delegate != annonce.delegate.id || locality != annonce.locality.id
    || description != annonce.description || (!newPhone && phoneNumber=='' ) || phoneNumber != annonce.phoneNumber
    || pImageThumb || imgCountA > 0  
    )
      )
    {
      sethasChanged(true);
      //console.log(' in use : ' , album.length)
    }

  },
 [category ,  make , price , warranty , year , frame , frameHeight , material , breaking , wheelSize,
  mileage,bodyStyle,fuel,fiscalPower, color,transmission,permGVW,
  admisblHW,
  payload,
  empWeight,
  loadLength,
  loadHeight,
  loadWidth,
  loadVolume,
  brake,
  suspension,
  axleConfig,
  wheelsDist,
  tyreSize,
  emissionClass,
  co2Emmision,
  fuelCons,
  urban, rural,sootFilter, cab, sleepingCab,

  boite,
  nbrSeat,
  warranty,
  speed,
  paintType,
  gearingType,

  imgCountA , imgPrincipal , description  ,phoneNumber , newPhone , locality , delegate , city , country]
 );

 useEffect(() => 
 {
   //console.log(props.location.state);
   if(props.location.state) {
     
     let result = props.location.state
     //console.log("data----", result);
     
setId(result.id);

 //console.log('resulttttttttttt', result)
 setAnnonce(result)


 if (result && result.type === 'bikes')
  {
   //console.log('bikkkk');
   if (!localStorage.getItem("bikeCategories")) 
   {axios(`${API_BASE_URL}/bike/categories`)
     .then((res) => res.data)
     .then(
       (result) => {
         //console.log('bike cat');
         setIsLoaded(true);
         setCategories(result);
 localStorage.setItem("bikeCategories", JSON.stringify(result));
       },
       (error) => {
         setIsLoaded(true);
         setError(error);
       }
     );} else {
  setCategories(JSON.parse(localStorage.getItem("bikeCategories")));setIsLoaded(true);
       
     }

if (!localStorage.getItem("bikeBrands")) 
   {
     axios(`${API_BASE_URL}/bike/brands`)
     .then((res) => res.data)
     .then(
       (result) => {
         setBrands(result);
         setBrandsAll(result);
   
        if(props.location.state.category && props.location.state.category.id == 12) {
         var brandsF = [];
         brandsF = result.filter((el) => el.category && el.category.id == 12);
         //console.log("brandsF in 12 <<<", brandsF);
         setBrands(brandsF);
       } 
       else {
         var brandsF = [];
         brandsF = result.filter((el) => !el.category );
         //console.log("brandsF <<<", brandsF);
         setBrands(brandsF);
       }
 localStorage.setItem("bikeBrands", JSON.stringify(result));

       },
       (error) => {
         setError(error);
       }
     );} 
     else {
       setBrands(JSON.parse(localStorage.getItem("bikeBrands")));
       setBrandsAll(JSON.parse(localStorage.getItem("bikeBrands")));
      
       if(props.location.state.category && props.location.state.category.id == 12) {
         var brandsF = [];
         brandsF = JSON.parse(localStorage.getItem("bikeBrands")).filter((el) => el.category && el.category.id == 12);
         //console.log("brandsF in 12 xxx<<<", brandsF);
         setBrands(brandsF);
       } 
       else {
         var brandsF = [];
         brandsF = JSON.parse(localStorage.getItem("bikeBrands")).filter((el) => !el.category );
         //console.log("brandsF yyy<<<", brandsF);
         setBrands(brandsF);
       }

     }
 }
 if (result && result.type === 'camions') {
   //console.log('cammmmmm');
   if (!localStorage.getItem("truckBrands")) {
axios(`${API_BASE_URL}/vehicles/truck-brands`)
     .then((res) => res.data)
     .then(
       (result) => {
         //console.log('cam cat');
         setIsLoaded(true);
         setMakes(result);
 localStorage.setItem("truckBrands", JSON.stringify(result));

       },
       (error) => {
         setIsLoaded(true);
         setError(error);
       }
     );
   } else {
     setMakes(JSON.parse(localStorage.getItem("truckBrands")));setIsLoaded(true);
     }
 
 }
 if (result && result.type === 'motos') {
   //console.log('motoooo');
   if (!localStorage.getItem("motoBrands")) 
   {axios(`${API_BASE_URL}/vehicles/moto-brands`)
     .then((res) => res.data)
     .then(
       (results) => {
         //console.log('mot cat');
         setIsLoaded(true);
         setMakes(results);
 localStorage.setItem("motoBrands", JSON.stringify(results));
 var tempArr = [];
 if (results.length>0 && result.brand.id.toString() != "0") {
   tempArr = results.find((model) => model.id.toString() === result.brand.id.toString());
   setModels(tempArr.models);
   //console.log("tempArrrrrrr : ",tempArr);
 }

       },
       (error) => {
         setIsLoaded(true);
         setError(error);
       }
     );} else {
     setMakes(JSON.parse(localStorage.getItem("motoBrands")));
     setIsLoaded(true);
     var tempArr = [];
     if (JSON.parse(localStorage.getItem("motoBrands")).length>0 && result.brand.id.toString() != "0") {
       tempArr = JSON.parse(localStorage.getItem("motoBrands")).find((model) => model.id.toString() === result.brand.id.toString());
       setModels(tempArr.models);
       //console.log("tempArrrrrrr : ",tempArr);
     }  
     }


 }
 if (result && result.type === 'cars') {
   //console.log('carrrrr');

   if (!localStorage.getItem("carBrands"))
   {axios(`${API_BASE_URL}/vehicles/car-brands`)
   .then((res) => res.data)
   .then(
     (results) => {
       //console.log('car cat');
       setIsLoaded(true);
       setMakes(results);
 localStorage.setItem("carBrands", JSON.stringify(results));
 var tempArr = [];
 if (results.length>0 && result.brand.id.toString() != "0") {
   tempArr = results.find((model) => model.id.toString() === result.brand.id.toString());
   setModels(tempArr.models);
   //console.log("tempArrrrrrr : ",tempArr);
 }
       //  console.log(result);
     },
     (error) => {
       setIsLoaded(true);
       setError(error);
       //console.log(error);
     }
   );
   } else {
     setMakes(JSON.parse(localStorage.getItem("carBrands")));
     setIsLoaded(true);
     var tempArr = [];
     if (JSON.parse(localStorage.getItem("carBrands")).length>0 && result.brand.id.toString() != "0") {
       tempArr = JSON.parse(localStorage.getItem("carBrands")).find((model) => model.id.toString() === result.brand.id.toString());
       setModels(tempArr.models);
       //console.log("tempArrrrrrr : ",tempArr);
     }
   }
 }
 

 if (result.type === 'bikes') {
  // usssssse
   setType(result.type)
   setStatus(result.state)
   
   if (result.category) {
     setCategory(result.category.id)
   }
   setMake(result.brand.id)
   setWarranty(result.warranty)
   setYear(result.year)
   setFrame(result.frame)
   setFrameHeight(result.frameHeight)
   setMaterial(result.material)
   setWheelSize(result.wheelSize)
   setPrice(result.price)
   setBreaking(result.braking)
   if(result.country){
   setCountry(result.country.id)
   setCities(result.country.cities)
   setCity(result.city.id)
   setDelegates(result.city.delegations)
   setDelegate(result.delegate.id)
   setLocalities(result.delegate.localities)
   setLocality(result.locality.id)
 }
   setDescription(result.description);
   if(result.phoneNumber) setPhoneNumber(result.phoneNumber);


   if (result.mainPicture) {
     setImgPrincipal(`${IMAGES_BASE_URL}/public/uploads/images/bikes/${result.mainPicture.name}`)
     setoldImgp(true); // heeeeere   
   }
   setOtherImages(result.images);
 }
 else if (result.type === 'camions') {
   setStatus(result.state)
   setType(result.type)
   setBodyStyle(result.bodyStyle)
   setMake(result.brand.id)
   setWarranty(result.warranty)

   setModel(result.model)
   setYear(result.year)
   setMileage(result.mileage)
   setPrice(result.price)
   setFiscalPower(result.power)
   if(result.country){

   setCountry(result.country.id)
   setCities(result.country.cities)
   setCity(result.city.id)
   setDelegates(result.city.delegations)
   setDelegate(result.delegate.id)
   setLocalities(result.delegate.localities)
   
   setLocality(result.locality.id)
   }
   setPermGVW(result.permGVW)
   setAdmisblHw(result.admisblHW)

   setPayload(result.payload)
   setEmpWeight(result.emptWeight)
   setLoadLength(result.loadLength)
   setLoadHeight(result.loadHeight)
   setLoadWidth(result.loadWidth)
   setLoadVolume(result.loadVolume)
   setTransmission(result.transmissionType)
   setBrake(result.brake)
   setSuspension(result.suspension)
   setAxleConfig(result.axleConfig)
   setWheelsDist(result.wheelsDist)
   setTyreSize(result.tyreSize)
   setFuel(result.fuel)
   setEmissionClass(result.emissionClass)
   setCo2Emmision(result.co2Emmision)
   setFuelCons(result.fuelConsCombined)
   setUrban(result.urban)
   setRural(result.rural)

   setSootFilter(result.sootFilter)
   setColor(result.color)
   setCab(result.cab)
   setSleepingCab(result.sleepingCab)
   setDescription(result.description)
   if(result.phoneNumber) setPhoneNumber(result.phoneNumber);
   setOtherEquipmentsVehicle(result.otherEquipement)
   setAccessoryEquipmentsVehicle(result.accessoryEquipement)

   
   if (!localStorage.getItem("otherEquipments")) {
     axios(`${API_BASE_URL}/equipements/other`)
     .then((res) => res.data)
     .then(
       (resultOther) => {
   localStorage.setItem("otherEquipments", JSON.stringify(resultOther));

         if(result.otherEquipement) {result.otherEquipement.forEach(element => {
           resultOther.forEach(elementtab => {
       if (element.id === elementtab.id) {
         elementtab.checked = true
       }
     });
   });
   setOtherEquipments(resultOther);}

 
       //console.log('other', resultOther);
       },
       (error) => {
         setError(error);
       }
     );
   } else {
  let resultOther = JSON.parse(localStorage.getItem("otherEquipments"));
  
  //console.log('resultOther',typeof resultOther , resultOther);
  if(result.otherEquipement)   {result.otherEquipement.forEach(element => {
         resultOther.forEach(elementtab => {
     if (element.id === elementtab.id) {
       elementtab.checked = true
     }
   });
 });
 setOtherEquipments(resultOther);
}
  

   }

   if (!localStorage.getItem("accessoryEquipments")) {
     axios(`${API_BASE_URL}/equipements/accessory`)
     .then((res) => res.data)
     .then(
       (resultAccesory) => {
   localStorage.setItem("accessoryEquipments", JSON.stringify(resultAccesory));

         if (result.accessoryEquipement) {
           result.accessoryEquipement.forEach(element => {
             resultAccesory.forEach(elementtab => {
               if (element.id === elementtab.id) {
                 elementtab.checked = true
               }
             });
           });
         }
         setAccessoryEquipments(resultAccesory);
          //console.log('accessory',resultAccesory);
       },
       (error) => {
         setError(error);
       }
     );
   } else 
   {
     let resultAccesory = JSON.parse(localStorage.getItem("accessoryEquipments"));
     
     if (result.accessoryEquipement) {
       result.accessoryEquipement.forEach(element => {
         resultAccesory.forEach(elementtab => {
           if (element.id === elementtab.id) {
             elementtab.checked = true
           }
         });
       });
     }
     setAccessoryEquipments(resultAccesory);
  
      }

   let nCheckbox = []
   if (result.otherEquipement) {
     result.otherEquipement.forEach(element => {
       nCheckbox.push(element.id + "");

     })
   }
   if (result.accessoryEquipement) {
     result.accessoryEquipement.forEach(element => {
       nCheckbox.push(element.id + "");

     })
   }
 
   setEquipments(nCheckbox);

   if (result.mainPicture) {
     setImgPrincipal(`${IMAGES_BASE_URL}/public/uploads/images/camions/${result.mainPicture.name}`)
     setoldImgp(true); // heeeeere   
   }
   if (result.images) {
     setOtherImages(result.images)
   }

 }
 else if (result.type === 'cars') {
   setStatus(result.state)
   setType(result.type)
   setMake(result.brand.id)
   //setModels(result.brand.models);



   setModel(result.model.id)
   setYear(result.year)
   setMileage(result.mileage)
   setPrice(result.price)
   setBodyStyle(result.bodyStyle)
   setFuel(result.fuel)
   setBoite(result.boite)
   setNbrSeat(result.nbrSeat)
   setWarranty(result.warranty)
   setFiscalPower(result.fiscalPower)
   setTransmission(result.transmissionType)
   setColor(result.color)
   if(result.country){

   setCountry(result.country.id)
   setCities(result.country.cities)
   setCity(result.city.id)
   setDelegates(result.city.delegations)
   setDelegate(result.delegate.id)
   setLocalities(result.delegate.localities)
   setLocality(result.locality.id)
   }
   setExtEquipmentsVehicle(result.extEquipement)
   setIntEquipmentsVehicle(result.intEquipement)
   setSecEquipmentsVehicle(result.securEquipement)
   
   if (!localStorage.getItem("extEquipments"))
   {axios(`${API_BASE_URL}/equipements/exterior`)
   .then((res) => res.data)
   .then(
     (resultExt) => {
       if (result.extEquipement) {
         result.extEquipement.forEach(element => {
           resultExt.forEach((elementtab, i) => {
             if (element.id === elementtab.id) {
               elementtab.checked = true

             }
           });
         });
       }
       setExtEquipments(resultExt)
    localStorage.setItem("extEquipments", JSON.stringify(result));

     },
     (error) => {
       setError(error);
     }
   );
 } else {
   let resultExt = JSON.parse(localStorage.getItem("extEquipments"));
   if (result.extEquipement) {
     result.extEquipement.forEach(element => {
       resultExt.forEach((elementtab, i) => {
         if (element.id === elementtab.id) {
           elementtab.checked = true

         }
       });
     });
   }
   setExtEquipments(resultExt)
 }

   if (!localStorage.getItem("secureEquipements")) 
 {axios(`${API_BASE_URL}/equipements/security`)
   .then((res) => res.data)
   .then(
     (resultSec) => {
       if (result.securEquipement) {
         result.securEquipement.forEach(element => {
           resultSec.forEach((elementtab, i) => {
             if (element.id === elementtab.id) {
               elementtab.checked = true


             }
           });
         });

       }
       setSecureEquipements(resultSec)
       localStorage.setItem("secureEquipements", JSON.stringify(result));

     },
     (error) => {
       setError(error);
     }
   );}
   else {
     let resultSec = JSON.parse(localStorage.getItem("secureEquipements"));
     if (result.securEquipement) {
       result.securEquipement.forEach(element => {
         resultSec.forEach((elementtab, i) => {
           if (element.id === elementtab.id) {
             elementtab.checked = true


           }
         });
       });

     }
     setSecureEquipements(resultSec)
   }

 if (!localStorage.getItem("inEquipments"))
{axios(`${API_BASE_URL}/equipements/interior`)
   .then((res) => res.data)
   .then(
     (resultInt) => {
       // setInEquipments(result);
       if (result.intEquipement) {

         result.intEquipement.forEach(element => {
           resultInt.forEach((elementtab, i) => {
             if (element.id === elementtab.id) {
               elementtab.checked = true
             }
           });
         });


       }
       setInEquipments(resultInt)
       localStorage.setItem("inEquipments", JSON.stringify(result));

     },
     (error) => {
       setError(error);
     }
   );} else {
     let resultInt = JSON.parse(localStorage.getItem("inEquipments"));
     if (result.intEquipement) {

       result.intEquipement.forEach(element => {
         resultInt.forEach((elementtab, i) => {
           if (element.id === elementtab.id) {
             elementtab.checked = true
           }
         });
       });


     }
     setInEquipments(resultInt)
   }


   setDescription(result.description)
   if(result.phoneNumber) setPhoneNumber(result.phoneNumber);
   let nCheckbox = []
   if (result.extEquipement) {
     result.extEquipement.forEach(element => {
       nCheckbox.push(element.id + "");

     })
   }
   if (result.intEquipement) {
     result.intEquipement.forEach(element => {
       nCheckbox.push(element.id + "");

     })
   }
   if (result.securEquipement) {

     result.securEquipement.forEach(element => {
       nCheckbox.push(element.id + "");

     })
   }
   setEquipments(nCheckbox);


   //equipments.push(result.extEquipement,result.intEquipement,result.securEquipement)
   if (result.mainPicture) {
     setImgPrincipal(`${IMAGES_BASE_URL}/public/uploads/images/cars/${result.mainPicture.name}`)
     setoldImgp(true); // heeeeere   
   }
   if (result.images) {
     setOtherImages(result.images)
   }
 }
 else if (result.type === 'motos') {
   setType(result.type)
   setStatus(result.state)
   setMake(result.brand.id)
  // setModels(result.brand.models);

   setModel(result.model.id)
   setYear(result.year);
   setSpeed(result.speed)
   setWarranty(result.warranty)
   setBodyStyle(result.body)
   setGearingType(result.gearingType)
   setColor(result.color)
   setFuel(result.fuel)
   setCo2Emmision(result.co2Emission)
   setPrice(result.price)
   if(result.country){

   setCountry(result.country.id)
   setCities(result.country.cities)
   setCity(result.city.id)
   setDelegates(result.city.delegations)
   setDelegate(result.delegate.id)
   setLocalities(result.delegate.localities)
   setLocality(result.locality.id)
   }
   setPaintType(result.paintType)
   setEquipmentsAnnonceVehicle(result.equipement)

   if (!localStorage.getItem("equipements4")) 
  { axios(`${API_BASE_URL}/equipements/by_vehicle/4`)
   .then((res) => res.data)
   .then(
     (resultEquip) => {
       //console.log('equipmentsAnnonceVehicle',result.equipement)
       if (result.equipement) {
         result.equipement.forEach(element => {
           resultEquip.forEach(elementtab => {
             if (element.id === elementtab.id) {
               elementtab.checked = true
             }
           });
         });
       }
       setEquipmentsAnnonce(resultEquip);
   localStorage.setItem("equipements4", JSON.stringify(result));
     },
     (error) => {
       setError(error);
     }
   );} else {
     let resultEquip = JSON.parse(localStorage.getItem("equipements4"));
     if (result.equipement) {
       result.equipement.forEach(element => {
         resultEquip.forEach(elementtab => {
           if (element.id === elementtab.id) {
             elementtab.checked = true
           }
         });
       });
     }
     setEquipmentsAnnonce(resultEquip);
   }


   setDescription(result.description)
   if(result.phoneNumber) setPhoneNumber(result.phoneNumber);
   let nCheckbox = []
   if (result.equipement) {
     result.equipement.forEach(element => {
       nCheckbox.push(element.id + "");

     })
   }
   //console.log('nCheckbox', nCheckbox)
   setEquipments(nCheckbox);
   if (result.mainPicture) {
     setImgPrincipal(`${IMAGES_BASE_URL}/public/uploads/images/motos/${result.mainPicture.name}`)
     setoldImgp(true); // heeeeere             
   } 
    if (result.images) {
       setOtherImages(result.images)
     }
 }
 //setIsLoaded(true);



}
},
[]
);


  const handleCategoryChange = (i) => {
    setCategory(i);
    if(i == 12) {
      var brandsF = [];
      brandsF = brandsAll.filter((el) => el.category && el.category.id == 12);
      //console.log("brandsF in 12 <<<", brandsF);
      setBrands(brandsF);
      setMake("");
    } 
    else {
      var brandsF = [];
      brandsF = brandsAll.filter((el) => !el.category );
      //console.log("brandsF <<<", brandsF);
      setBrands(brandsF);
      setMake("");
    }
  }

  const handleMakeChange = (e) => {
    setMake(e.target.value);
    let id = e.target.value;
    var makesF = [];
    if (id !== "0") {
      makesF = makes.find((model) => model.id.toString() === id);
      setModels(makesF.models);
      //console.log(makesF);
    } else {
      setModels([]);
      setModel("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (!doneuploadingfile) {
      alert("not done uploading files yet");
      setLoading(false);

      return false;
    } else {
    if (annonce.type === 'bikes') {
      //console.log('status',status)
      if (make && category && frame && price && delegate && locality &&    warranty && imgPrincipal &&
        (phoneNumber.length==0 || isValidPhoneNumber(phoneNumber)) ) {
        setLoading(true)
        const watermarkedAlbum = await addWatermarkToAlbum(album);
        const formData = new FormData();
        formData.append("state", status);
        formData.append("category", category);

        formData.append("brand", make);
        //formData.append("createdBy", createdBy);
        formData.append("year", year);
        formData.append("locality", locality);
        formData.append("price", price);
        formData.append("braking", breaking);
        formData.append("frame", frame);
        formData.append('description', description);
        formData.append('material', material);
        formData.append("warranty", warranty);
        formData.append('wheelSize', wheelSize);
        formData.append('frameHeight', frameHeight);
        if(newPhone)  formData.append("phone", phoneNumber);
        if (pImageThumb) {
          const watermarkedP = await addWatermark(imgPrincipal);
          formData.append("imgPrincipal", watermarkedP);
          formData.append("newimgPrincipal", 'yes');
      }
      else{
        formData.append("newimgPrincipal", 'no');
      }
      for (let index = 0; index < watermarkedAlbum.length; index++) {
        formData.append("album[]", watermarkedAlbum[index]);       
        } 

        apiClient
          .post("/annonce_bike/edit/" + id, formData )
          .then((res) => {
            setLoading(false)
            if (res.data.status.toString() === "ok") {
              setSuccess(true);
              if(res.data.annonce) { 
                const updatedAnnonces = [...vehicles];
                const elIndex = updatedAnnonces.findIndex(el => el.id === id);
                if (elIndex !== -1) {
                  updatedAnnonces[elIndex] = res.data.annonce;
                  setVehicles(updatedAnnonces);
                }
              }
              setTimeout(() => {
                history.push("/vehicles/bikes/details/" + id);
              }, 1500);
            }
          })
          .catch((error) => {
            setLoading(false)
            if (error.response && error.response.status === 401){
              logoutLocal();
              window.location.replace("/members/login");
            }
          });
      }
      else {
        window.scrollTo(0, 200);
      }
    }
    else if (annonce.type === 'camions') {
    
      if (make && model && bodyStyle &&  warranty && price > 1000 && mileage && fiscalPower && country && city && transmission && axleConfig  && fuel&& delegate && locality && imgPrincipal &&
      (phoneNumber.length==0 || isValidPhoneNumber(phoneNumber))) {
        setLoading(true)
        const watermarkedAlbum = await addWatermarkToAlbum(album);

        const formData = new FormData();
        
        formData.append("brand", make);
        formData.append("model", model);
        //formData.append("createdBy",tokenCookie);
        formData.append("bodyStyle", bodyStyle);
        formData.append("year", year);
        formData.append("fuel", fuel);
        formData.append("mileage", mileage);
        formData.append("price", price);
        formData.append("locality", locality);
        formData.append("fiscalPower", fiscalPower);
        formData.append("state", status);
        formData.append("color", color);
        formData.append('transmissionType', transmission);
        formData.append('perm_gvw', permGVW);
        formData.append('admisibl_hw', admisblHW);
        formData.append('payload', payload);
        formData.append('empty_weight', empWeight);
        formData.append('load_length', loadLength);
        formData.append('load_height', loadHeight);
        formData.append('load_width', loadWidth);
        formData.append('load_volume', loadVolume);
        formData.append('brake', brake);
        formData.append('suspension', suspension);
        formData.append('axle_config', axleConfig);
        formData.append('wheels_dist', wheelsDist);
        formData.append('tyre_size', tyreSize);
        formData.append('emission_class', emissionClass);
        formData.append('co2_emmision', co2Emmision);
        formData.append('fuel_cons_combined', fuelCons);
        formData.append('urban', urban);
        formData.append('rural', rural);
        formData.append('soot_filter', sootFilter);
        formData.append('cab', cab);
        formData.append('sleeping_cab', sleepingCab);
        formData.append('description', description);
        formData.append("equipement", equipments);
        formData.append("warranty", warranty);

        if(newPhone)  formData.append("phone", phoneNumber);
      if (pImageThumb) {
        const watermarkedP = await addWatermark(imgPrincipal);
        formData.append("imgPrincipal", watermarkedP);
        formData.append("newimgPrincipal", 'yes');
    }
    else{
      formData.append("newimgPrincipal", 'no');
    }
    for (let index = 0; index < watermarkedAlbum.length; index++) {
      formData.append("album[]", watermarkedAlbum[index]);       
      }

        apiClient
          .post("/annonce_camion/edit/" + id, formData)
          .then((res) => {
            if (res.data.status.toString() === "ok") {
              setSuccess(true);
              setLoading(false);
              if(res.data.annonce) { 
                const updatedAnnonces = [...vehicles];
                const elIndex = updatedAnnonces.findIndex(el => el.id === id);
                if (elIndex !== -1) {
                  updatedAnnonces[elIndex] = res.data.annonce;
                  setVehicles(updatedAnnonces);
                }
              }
              setTimeout(() => {
                history.push("/vehicles/camions/details/" + id);
              }, 1500);
            }
          })
          .catch((error) => {
            setLoading(false)
            if (error.response && error.response.status === 401){
              logoutLocal();
              window.location.replace("/members/login");
            }

          });
      }
      else {
        window.scrollTo(0, 200);
      }
    }
    else if (annonce.type === 'cars') {
      setSubmitted(true);
      if (
        make &&
        model &&
        year &&
        price > 1000 &&
        bodyStyle &&
        fuel &&
        boite &&
        nbrSeat &&
        warranty &&
        fiscalPower && transmission && color && country && city  && delegate && locality && imgPrincipal &&
        (phoneNumber.length==0 || isValidPhoneNumber(phoneNumber))
      ) {
        setLoading(true)
        const watermarkedAlbum = await addWatermarkToAlbum(album);
        const formData = new FormData();
    
        formData.append("brand", make);
        formData.append("model", model);
        //formData.append("createdBy", tokenCookie);
        formData.append("bodyStyle", bodyStyle);
        formData.append("year", year);
        formData.append("fuel", fuel);
        formData.append("mileage", mileage);
        formData.append("boite", boite);
        formData.append("price", price);
        formData.append("warranty", warranty);
        formData.append("locality", locality);
        formData.append("fiscalPower", fiscalPower);
        formData.append("equipement", equipments);
        formData.append("state", status);
        formData.append("color", color);
        formData.append("nbSeat", nbrSeat);
        formData.append("district", "district");
        formData.append("transmissionType", transmission);
        formData.append('description', description);
        //console.log('des', description);

        if(newPhone)  formData.append("phone", phoneNumber);
        if (pImageThumb) {
          const watermarkedP = await addWatermark(imgPrincipal);
          formData.append("imgPrincipal", watermarkedP);
          formData.append("newimgPrincipal", 'yes');
      }
      else{
        formData.append("newimgPrincipal", 'no');
      }
      for (let index = 0; index < watermarkedAlbum.length; index++) {
        formData.append("album[]", watermarkedAlbum[index]);       
        }

        apiClient
          .post("/annonce_car/edit/" + id, formData )
          .then((res) => {
            if (res.data.status.toString() === "ok") {
              setSuccess(true);
              setLoading(false);
              if(res.data.annonce) { 
                const updatedAnnonces = [...vehicles];
                const elIndex = updatedAnnonces.findIndex(el => el.id === id);
                if (elIndex !== -1) {
                  updatedAnnonces[elIndex] = res.data.annonce;
                  setVehicles(updatedAnnonces);
                }
              }
              setTimeout(() => {
                history.push("/vehicles/cars/details/" + id);
              }, 1500);
            }
            // this.props.history.push("/5467854/index");
          })
          .catch((error) => {
            //console.log(" errors : ", error);
            setLoading(false)
            if (error.response && error.response.status === 401){
              logoutLocal();
              window.location.replace("/members/login");
            }
          });
      } else {
        window.scrollTo(0, 200);
        setLoading(false)
      }

    }
    else if (annonce.type === 'motos') {
      //console.log('imgPrincipal', status)

      if (
        make &&
        model &&
        year &&
        price &&
        bodyStyle &&
        fuel &&
        warranty &&
        gearingType && color && country && city  && delegate && locality && imgPrincipal &&
        (phoneNumber.length==0 || isValidPhoneNumber(phoneNumber))
      ) {
        setLoading(true)
        const watermarkedAlbum = await addWatermarkToAlbum(album);
        const formData = new FormData();
        // formData.append("brand", make);

        formData.append("model", model);
        //formData.append("createdBy", tokenCookie);
        formData.append("body", bodyStyle);
        formData.append("year", year);
        formData.append("fuel", fuel);
        formData.append("price", price);
        formData.append("warranty", warranty);
        formData.append("locality", locality);

        formData.append("equipement", equipments);
        formData.append("state", status);
        formData.append("color", color);
        formData.append("paintType", paintType);
        formData.append("gearingType", gearingType);
        formData.append("co2Emission", co2Emmision);
        formData.append("speed", speed);
        formData.append('description', description);
  
        if(newPhone)  formData.append("phone", phoneNumber);
        if (pImageThumb) {
          const watermarkedP = await addWatermark(imgPrincipal);
          formData.append("imgPrincipal", watermarkedP);
          formData.append("newimgPrincipal", 'yes');
      }
      else{
        formData.append("newimgPrincipal", 'no');
      }
      for (let index = 0; index < watermarkedAlbum.length; index++) {
        formData.append("album[]", watermarkedAlbum[index]);       
        } 
  
        apiClient
          .post("/annonce_moto/edit/" + id, formData )
          .then((res) => {
            setLoading(false)

            //console.log(res);
            if (res.data.status.toString() === "ok") {
              setSuccess(true);
              if(res.data.annonce) { 
                const updatedAnnonces = [...vehicles];
                const elIndex = updatedAnnonces.findIndex(el => el.id === id);
                if (elIndex !== -1) {
                  updatedAnnonces[elIndex] = res.data.annonce;
                  setVehicles(updatedAnnonces);
                }
              }
              setTimeout(() => {
                history.push("/vehicles/motos/details/" + id);
              }, 1500);
            }
            // this.props.history.push("/5467854/index");
          })
          .catch((error) => {
            //console.log(" errors : ", error);
            setSuccess(false);
            setLoading(false)
            if (error.response && error.response.status === 401){
              logoutLocal();
              window.location.replace("/members/login");
            }

          });
      } else {
        window.scrollTo(0, 200);
      }
    }
  
  }}

  useEffect(() => {
    if (!localStorage.getItem("countries")) {
      axios(`${API_BASE_URL}/countries/all`)
        .then((res) => res.data)
        .then(
          (result) => {
            setCountries(result);
            localStorage.setItem("countries", JSON.stringify(result));
          },
          (error) => {
           // setError(error);
          }
        );
    } else {
      setCountries(JSON.parse(localStorage.getItem("countries")));
    }

}, []);

const handleCountryChange = (e) => {
    setCountry(e.target.value);
    let id = e.target.value;
    var countriesF = [];
    //console.log("iddd", id)
    if (id !== "0") {
      countriesF = countries.find((city) => city.id.toString() === id);
      setCities(countriesF.cities);
      //console.log('countriesF', countriesF);
    } else {
      setCity([]);
      //  setModel("");
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    let id = e.target.value;
    var citiesF = [];
    //console.log("iddd", id)
    if (id !== "0") {
      citiesF = cities.find((delegate) => delegate.id.toString() === id);
      setDelegates(citiesF.delegations);
      //console.log('citiesF', citiesF);
    } else {
      setDelegate([]);
      //  setModel("");
    }
  };

  const handleDelegateChange = (e) => {
    setDelegate(e.target.value);
    let id = e.target.value;
    var delegatesF = [];
    //console.log("iddd", id)
    if (id !== "0") {
      delegatesF = delegates.find((local) => local.id.toString() === id);
      setLocalities(delegatesF.localities);
      //console.log('delegatesF', delegatesF);
    } else {
      setLocality([]);
      //  setModel("");
    }
  };

  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };
  
  // called every time a file's `status` changes
  const handleChangeStatus = async ({ meta, file }, status) => {

    if (status === 'preparing' && meta.size > 5 * 1024 * 1024) {
      alert(sizeAlert);
      return;
  //file = null;
    }
    setDoneuploadingfile(false);
    let removedfilesx = removedfiles

    if (status === 'preparing' && !(meta.size > 5 * 1024 * 1024)) { setImgCountA(imgCountA+1)}     
    if (status.toString() === "done" && !(meta.size > 5 * 1024 * 1024)) {  
      let newalb = album;
      let options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
      };
     const abortController = new AbortController();
      const fileId = meta.id;
      const updatedAbortControllers = { ...abortControllers, [fileId]: abortController };
      setAbortControllers(updatedAbortControllers);
      options.signal = abortController.signal;

      try {
        file = await imageCompression(file, options);
      } catch (error) {
        //console.log('erro compress : ',error);
       // return;
      }
         if ((!removedfiles.includes(meta.id) && !removedfilesx.includes(meta.id)) && !(meta.size > 5 * 1024 * 1024)) 
         {
          newalb.push(file);
         // setAlbum(prevAlbum => [...prevAlbum, file]);
         setAlbum(newalb)
          setImgCount(prevCount => prevCount + 1);
    } else {
       //console.log("new album in done else");
    //console.log(newalb.length ,newalb);
   }
      setDoneuploadingfile(true);
    }
   if (status.toString() === "removed" && !(meta.size > 5 * 1024 * 1024)) {
    removedfilesx.push(meta.id);
    setremovedfiles(removedfiles)
  // setremovedfiles(prevA => [...prevA, meta.id]);
      const fileId = meta.id;
      if (abortControllers[fileId]) {
        abortControllers[fileId].abort();
        const updatedAbortControllers = { ...abortControllers };
        delete updatedAbortControllers[fileId];
        setAbortControllers(updatedAbortControllers);
      
      }

      let index = album.findIndex((obj) => obj.name === file.name);
      let newAlbum = album;
     { newAlbum.splice(index, 1);
     if(index>-1) {
     if(imgCount>0) setImgCount(newAlbum.length);}
      if(imgCountA>0) setImgCountA(imgCountA-1);
      setAlbum(newAlbum);
     }
      setDoneuploadingfile(true);

    } else  setDoneuploadingfile(true);
  };
      
  const handleFileSection = async ( event) => {
    setUploadingImage(true);
    
    let file = event.target.files[0];
    if (file.size > 5 * 1024 * 1024) {
      alert(sizeAlert);
      setUploadingImage(false);    
      event.target.value = '';
      return;
    }

    let options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
    };
    try {
      file = await imageCompression(file, options);
    } catch (error) {
      //console.log(error);
    }  
    setoldImgp(false) 
    setpImageThumb(URL.createObjectURL(file));
    setImgPrincipal(file);
    setUploadingImage(false);

  };


  function deleteImageVehicle(idIm, index) {
    //console.log("id", idIm, index);
    setDeleting(true); // heeeeere
    apiClient
      .delete(`/annonce/delete_image/` + idIm )
      .then((res) => {
        toggle();
        if (typeImage) {
          setImgPrincipal(false);
          setoldImgp(false); // heeeeere
        }
        otherImages.splice(index, 1);
        setOtherImages(otherImages);

        //console.log(res);
        if (res.data.status.toString() === "ok") {
          setOtherImages((items) => items.filter((x) => x.idIm !== idIm));
        }
        if (
          res.data.status.toString() ===
          "ForeignKeyConstraintViolationException"
        ) {
          setErrorDel(
            "Vous ne pouvez pas supprimer cette véhicule"
          );
        }
        setDeleting(false);
      })
      .catch((err) => {
        //console.log(err);
        setDeleting(false);})
  }

  const toggle = (id, type, i) => {
    setModal(!modal);
    setItem(id)
    setTypeImage(type)
    setIndex(i)
  };



  const getYear = () => {
    const years = [];
    for (let i = new Date().getFullYear(); i > 1950; i--) {
      years.push(
        <option value={i} key={i}>
          {" "}
          {i}
        </option>
      );
    }
    return years;
  };

  const onChangeExt = (event, equipement) => {
    //console.log(equipments)
    let nCheckbox = equipments.slice(); // create a new copy of state value
    if (isValueExist(nCheckbox, event)) {
      // check if the same value is preexisted in the array
      const index = nCheckbox.indexOf(event.target.value);
      nCheckbox.splice(index, 1); // removing the preexciting value
      equipement.checked = false
    } else {
      nCheckbox.push(event.target.value); // inserting the value of checkbox in the array
      equipement.checked = true

    }
    setEquipments(nCheckbox);
    sethasChanged(true)
  };
  const onChangeStatus = (event) => {
  //console.log('fhjgfgf')
    //console.log('event.target.value', event.target.value)
    setStatus(event.target.value)
  };
  function isValueExist(data, event) {
    if (data.length === 0) {
      return false;
    }

    for (let i = 0; i <= data.length; i++) {
      if (event.target.value === data[i]) {
        return true;
      }
    }
    return false;
  }
  const changePhoneNumber = (event) => {
    //console.log(event.target.checked);
    if(event.target.checked) {setNewPhone(false);setPhoneNumber("");}
    else {setNewPhone(true);
    }
  }

  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <Loading/>
    );
  } else {
    return (
      <div className="container addingPage editPage">
        <form onSubmit={handleSubmit} id="form-add">

        {type ==='bikes' ?
        <>
          <div className="information-title">
            <h2>modifier Vélos</h2> <CBadge color={getBadge(props.location.state.status)}>
            {textBadge(props.location.state.status)} 
                          </CBadge>
              <span className="snSpan snSpanE ">SN: {props.location.state.sn} </span> 
          </div>
          <div className="advInfoSzctionCard" >
        <Categori name='Vélos'/>
          <CRow>
            <CCol>
              {" "}
              <CLabel>Etat </CLabel> <br />
  
              {
              status || status==1 || status=='1' ?
                <>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="1"
                      name="status"
                      value="1"
                      onChange={() => setStatus(1)}
                      defaultChecked
                    />
                    <CLabel variant="checkbox" htmlFor="neuf">
                      Neuf1
                    </CLabel>
                  </CFormGroup>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="0"
                      name="status"
                      value="0"
                      onChange={() => setStatus(0)}
                    />
                    <CLabel variant="checkbox" htmlFor="occasion">
                      Occasion1
                    </CLabel>
                  </CFormGroup>
                </> :
                <>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="1"
                      name="status"
                      value="1"
                      onChange={() => setStatus(1)}
                      
                    />
                    <CLabel variant="checkbox" htmlFor="neuf">
                      Neuf
                    </CLabel>
                  </CFormGroup>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="0"
                      name="status"
                      value="0"
                      onChange={() => setStatus(0)}
                      defaultChecked
                    />
                    <CLabel variant="checkbox" htmlFor="occasion">
                      Occasion
                    </CLabel>

                  </CFormGroup>
                </>
                }

            </CCol>
          </CRow>
          <CRow >

            <CCol md="4">
              <CFormGroup>
                <CLabel htmlFor="category">Catégorie</CLabel>
                <CSelect
                  name="category"
                  value={category}
                  onChange={(e) => handleCategoryChange(e.target.value)}
                >
                  <option value=""> Choisir la catégorie</option>
                  {categories && categories.map((category) => {
                    return (
                      <option key={category.id} value={category.id}>
                        {category.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !category && (
                  <span className="invalid-feedback error">
                    Veuillez choisir la catégorie du vélo
                  </span>
                )}
              </CFormGroup>
            </CCol>
            <CCol md="4">
              <CFormGroup>
                <CLabel htmlFor="model">Marque</CLabel>
                <CSelect
                  name="make"
                  value={make}
                  onChange={(e) => setMake(e.target.value)}
                >
                  <option value="0"> Marque</option>
                  {brands && brands.map((brand) => {
                    return (
                      <option key={brand.id} value={brand.id}>
                        {brand.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !make && (
                  <span className="invalid-feedback error">
                    Veuillez choisir la marque du vélo
                  </span>
                )}
              </CFormGroup>
            </CCol>

            <CCol md="4">
              <CFormGroup>
                <CLabel htmlFor="year">Année</CLabel>
                <CSelect
                  value={year}
                  name="year"
                  onChange={(e) => setYear(e.target.value)}
                >
                  <option></option>
                  {getYear()}
                </CSelect>

                {submitted && !year && (
                  <span className="invalid-feedback error">
                    Veuillez entrer l'année de la voiture
                  </span>
                )}
              </CFormGroup>
            </CCol>


          </CRow>
          <Scroll/>
         </div>

       <div className="advInfoSzctionCard" >
        <Basic/>    
          <CRow>
            <CCol md="6">
              <CFormGroup>
                <CLabel htmlFor="model">Taille du cadre</CLabel>
                <CSelect
                  name="frame"
                  value={frame}
                  onChange={(e) => setFrame(e.target.value)}
                >
                  <option value=""> Choisissez la taille</option>
                  <option value="XS"> XS</option>
                  <option value="S"> S</option>
                  <option value="M"> M</option>
                  <option value="L"> L</option>
                  <option value="XL"> XL</option>

                </CSelect>
                {submitted && !frame && (
                  <span className="invalid-feedback error">
                    obligatoire
                  </span>
                )}
              </CFormGroup>
            </CCol>
            <CCol md="6">
              <CFormGroup>
                <CLabel htmlFor="model">Hauteur de cadre</CLabel>
                <CInput
                  name="frameHeight"
                  value={frameHeight}
                  onChange={(e) => setFrameHeight(e.target.value)}
                />
                {submitted && !frameHeight && (
                  <span className="invalid-feedback error">
                    obligatoire
                  </span>
                )}
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow>

            <CCol md="6">
              <CFormGroup>
                <CLabel htmlFor="model">Matériel du cadre</CLabel>
                <CSelect
                  name="material"
                  value={material}
                  onChange={(e) => setMaterial(e.target.value)}
                >
                  <option value=""> </option>
                  <option value="Acier"> Acier</option>
                  <option value="Aluminium"> Aluminium</option>
                  <option value="Titane"> Titane</option>
                  <option value="Fibre de carbone"> Fibre de carbone</option>
                </CSelect>
                {submitted && !material && (
                  <span className="invalid-feedback error">
                    obligatoire
                  </span>
                )}
              </CFormGroup>
            </CCol>
            <CCol md="6">

              <CFormGroup>
                <CLabel htmlFor="wheelSize">Taille des pneus</CLabel>
                <CSelect
                  name="wheelSize"
                  value={wheelSize}
                  onChange={(e) => setWheelSize(e.target.value)}
                >
                  <option value="">Choisissez la taille des pneus </option>
                  <option value="16 pouces"> 16 pouces</option>
                  <option value="20 pouces"> 20 pouces</option>
                  <option value="24 pouces"> 24 pouces</option>
                  <option value="26 pouces"> 26 pouces</option>
                  <option value="27.5 pouces"> 27.5 pouces</option>
                  <option value="27.5 plus"> 27.5 plus</option>
                  <option value="29 pouces"> 29 pouces</option>
                  <option value="650"> 650</option>
                  <option value="700">700</option>

                </CSelect>
                {submitted && !material && (
                  <span className="invalid-feedback error">
                    obligatoire
                  </span>
                )}
              </CFormGroup>

            </CCol>
          </CRow>

          <CRow>

            <CCol md="6">
              <CFormGroup>
                <CLabel htmlFor="price">Prix</CLabel>
                <CInput
                  name="price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
                {submitted && !price && (
                  <span className="invalid-feedback error">
                    obligatoire
                  </span>
                )}
              </CFormGroup>

            </CCol>
            <CCol md="6">
              <CFormGroup>
                <CLabel htmlFor="breaking">Freinage</CLabel>
                <CInput
                  name="breaking"
                  value={breaking}
                  onChange={(e) => setBreaking(e.target.value)}
                />
                {submitted && !breaking && (
                  <span className="invalid-feedback error">
                    obligatoire
                  </span>
                )}
              </CFormGroup>
            </CCol>
          </CRow>


          <CRow xs="12">
          <CCol xs="6">
       <CFormGroup>
         <CLabel>Garrantie </CLabel>
         <CSelect
           name="warranty"
           value={warranty}
           onChange={(e) => setWarranty(e.target.value)}
         >
           <option value=""></option>
           <option value="0">0</option>
           <option value="moins 1"> Moins d'une année</option>
           <option value="1">1 an</option>
           <option value="2">2 ans</option>
         </CSelect>
         {submitted && !warranty && (
           <span className="invalid-feedback error">Obligatoire</span>
         )}
       </CFormGroup>
     </CCol> 
     </CRow>
     <Scroll/>
         </div>
       
        </>       
       :null
       }
       
        {type ==='camions' ?
 <>
 <div className="information-title">
     <h2>modifier camion</h2> <CBadge color={getBadge(props.location.state.status)}>
     {textBadge(props.location.state.status)} 
                          </CBadge>
              <span className="snSpan snSpanE ">SN: {props.location.state.sn} </span> 
   </div>
   <div className="advInfoSzctionCard" >
       <Categori name='Camion' />
   <CRow>
            <CCol>
              {" "}
              <CLabel>Etat </CLabel> <br />
              {status ?
                <>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="1"
                      name="status"
                      value="1"
                      onChange={(e) => setStatus(e.target.value)}
                      defaultChecked
                    />
                    <CLabel variant="checkbox" htmlFor="neuf">
                      Neuf
                    </CLabel>
                  </CFormGroup>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="0"
                      name="status"
                      value="0"
                      onChange={(e) => setStatus(e.target.value)}
                    />
                    <CLabel variant="checkbox" htmlFor="occasion">
                      Occasion
                    </CLabel>
                  </CFormGroup>
                </> :
                <>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="1"
                      name="status"
                      value="1"
                      onChange={(e) => setStatus(e.target.value)}
                      defaultChecked
                    />
                    <CLabel variant="checkbox" htmlFor="neuf">
                      Neuf
                    </CLabel>
                  </CFormGroup>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="0"
                      name="status"
                      value="0"
                      onChange={(e) => setStatus(e.target.value)}
                      defaultChecked
                    />
                    <CLabel variant="checkbox" htmlFor="occasion">
                      Occasion
                    </CLabel>

                  </CFormGroup>
                </>}
             
                
            </CCol>
          </CRow>
   <CRow >
   <CCol md="6">
       <CFormGroup>
         <CLabel>Type de véhicule </CLabel>
         <CSelect
           name="bodyStyle"
           value={bodyStyle}
           onChange={(e) => setBodyStyle(e.target.value)}
         >
           <option value=""></option>
           <option value="Benne">Benne</option>
           <option value="Chassis">Châssis</option>
           <option value="Citerne">Citerne</option>
           <option value="Fourgon">Fourgon</option>
           <option value="Frigorifique">Frigorifique</option>
           <option value="Plateau">Plateau</option>
           <option value="Polybenne">Polybenne</option>
           <option value="Porte-conteneurs">Porte conteneurs</option>
           <option value="Porte-engins">Porte engins</option>
           <option value="Savoyarde">Savoyarde</option>
           <option value="Utilitaire">Utilitaire</option>
           <option value="Pick up">Pick Up</option>
           <option value="Autres">Autres</option>
         </CSelect>
         {submitted && !bodyStyle && (
           <span className="invalid-feedback error">
             Veuillez choisir la carrosserie
           </span>
         )}
       </CFormGroup>
     </CCol>
     <CCol md="6">
       <CFormGroup>
         <CLabel htmlFor="make">Marque</CLabel>
         <CSelect
           name="sector"
           value={make}
           onChange={(e) => handleMakeChange(e)}
         >
           <option value="0"> Choisir la marque</option>
           {makes && makes.map((make) => {
             return (
               <option key={make.id} value={make.id}>
                 {make.label}
               </option>
             );
           })}
         </CSelect>
         {submitted && !make && (
           <span className="invalid-feedback error">
             Veuillez choisir la marque de la voiture
           </span>
         )}
       </CFormGroup>
     </CCol>
     <CCol md="6">
       <CFormGroup>
         <CLabel htmlFor="model">Modèle</CLabel>
         <CInput
           name="model"
           value={model}
           onChange={(e) => {setModel(e.target.value);sethasChanged(true);}}
         >
          
         </CInput>
         {submitted && !model && (
           <span className="invalid-feedback error">
             Veuillez choisir le modèle de la voiture
           </span>
         )}
       </CFormGroup>
     </CCol>
     <CCol xs="6">
       <CFormGroup>
         <CLabel htmlFor="year">Année</CLabel>
         <CSelect
           value={year}
           name="year"
           onChange={(e) => setYear(e.target.value)}
         >
           <option></option>
           {getYear()}
         </CSelect>

         {submitted && !year && (
           <span className="invalid-feedback error">
             Veuillez entrer l'année de la voiture
           </span>
         )}
       </CFormGroup>
     </CCol>
   </CRow>
   <Scroll/>
          </div>

          <div className="advInfoSzctionCard" >
        <Basic/>
   <CRow xs="12">   
     <CCol xs="4">
       <CFormGroup>
         <CLabel>Kilométrage </CLabel>
         <CInput
           type="number"
           name="mileage"
           value={mileage}
           onChange={(e) => setMileage(e.target.value)}
         ></CInput>
         {submitted && !mileage && (
           <span className="invalid-feedback error">
             Veuillez entrer le kilométrage de la voiture
           </span>
         )}
       </CFormGroup>
     </CCol>
     <CCol xs="4">
       <CFormGroup>
         <CLabel htmlFor="price">Prix en TND</CLabel>
         <CInput
           type="number"
           name="price"
           value={price}
           onChange={(e) => setPrice(e.target.value)}
         ></CInput>
         {submitted && price < 1000 && (
           <span className="invalid-feedback error">
             Veuillez entrer le prix de la voiture
           </span>
         )}
       </CFormGroup>
     </CCol>
 
  <CCol xs="4">
       <CFormGroup>
         <CLabel htmlFor="fiscalPower">Puissance fiscale</CLabel>
         <CInputGroup>
         <CInput
           name="fiscalPower"
           value={fiscalPower}
           onChange={(e) => setFiscalPower(e.target.value)}
         />
          <CInputGroupAppend>
             <CInputGroupText>CH</CInputGroupText>
           </CInputGroupAppend>
         </CInputGroup>
         {submitted && !fiscalPower && (
           <span className="invalid-feedback error">obligatoire</span>
         )}
       </CFormGroup>
     </CCol>
          <CCol xs="4">
       <CFormGroup>
         <CLabel>Garrantie </CLabel>
         <CSelect
           name="warranty"
           value={warranty}
           onChange={(e) => setWarranty(e.target.value)}
         >
           <option value=""></option>
           <option value="0">0</option>
           <option value="moins 1"> Moins d'une année</option>
           <option value="1">1 an</option>
           <option value="2">2 ans</option>
         </CSelect>
         {submitted && !warranty && (
           <span className="invalid-feedback error">Obligatoire</span>
         )}
       </CFormGroup>
     </CCol> 
     <CCol md="4">
       <CFormGroup>
         <CLabel>Couleur </CLabel>
         <CSelect
           name="color"
           value={color}
           onChange={(e) => setColor(e.target.value)}
         >
           <option value=""></option>
           <option value="Noir">Noir</option>
           <option value="Gris argent">Gris argent</option>
           <option value="Gris anthracite">Gris anthracite</option>
           <option value="Gris Shark">Gris Shark</option>
           <option value="Blanc">Blanc</option>
           <option value="Rouge">Rouge</option>
           <option value="Bleu">Bleu</option>
           <option value="Beige">Beige</option>
           <option value="Jaune">Jaune</option>
           <option value="Orange">Orange</option>
           <option value="Vert">Vert</option>
           <option value="Aubergine">Aubergine</option>
           <option value="Marron">Marron</option>
           <option value="Autre">Autre</option>
         </CSelect>
       
       </CFormGroup>
     </CCol>
     <CCol md="4">
       <CFormGroup>
         <CLabel>Cabine du conducteur </CLabel>
         <CSelect
           name="cab"
           value={cab}
           onChange={(e) => setCab(e.target.value)}
         >
           <option value=""></option>
           <option value="Cabine couchette"> Cabine couchette</option>
           <option value="Cabine courte"> Cabine courte</option>
           <option value="Autre"> Autres</option> 
         </CSelect>
       </CFormGroup>
     </CCol>
   </CRow>
   <CRow>
     <CCol>
       {sleepingCab?
       <CFormGroup variant="checkbox" inline>
     <CInputCheckbox
       className="form-check-input"
       name="sleepingCab"
       value={sleepingCab}
       checked={true}
       onChange={(e) => setSleepingCab(e.target.checked)}
     />
     <CLabel variant="checkbox">
       Cabine couchette
     </CLabel>
   </CFormGroup>:  <CFormGroup variant="checkbox" inline>
     <CInputCheckbox
       className="form-check-input"
       name="sleepingCab"
       value={sleepingCab}
       checked={false}
       onChange={(e) => setSleepingCab(e.target.checked)}
     />
     <CLabel variant="checkbox">
       Cabine couchette
     </CLabel>
   </CFormGroup> }
     
     </CCol>
   </CRow>
   <Scroll/>
          </div>

          <div className="advInfoSzctionCard" >
        <Weight/>
   <CRow >
   <CCol md="3">
       <CFormGroup>
         <CLabel htmlFor="permGVW">Poids total admissible</CLabel>
         <CInputGroup>
         <CInput
           name="permGVW"
           value={permGVW}
           onChange={(e) => setPermGVW(e.target.value)}
         />
          <CInputGroupAppend>
             <CInputGroupText>Kg</CInputGroupText>
           </CInputGroupAppend>
         </CInputGroup>                   
       </CFormGroup>
     </CCol>
     <CCol md="3">
       <CFormGroup>
         <CLabel htmlFor="admisblHW">Poids du train admissible</CLabel>
         <CInputGroup>
         <CInput
           name="admisblHW"
           value={admisblHW}
           type="number"
           onChange={(e) => setAdmisblHw(e.target.value)}
         />
           <CInputGroupAppend>
             <CInputGroupText>Kg</CInputGroupText>
           </CInputGroupAppend>
         </CInputGroup>  
         
       </CFormGroup>
     </CCol>
     <CCol md="3">
       <CFormGroup>
         <CLabel htmlFor="payload">Charge utile</CLabel>
         <CInputGroup>
         <CInput
           name="paylod"
           value={payload}
           type="number"
           onChange={(e) => setPayload(e.target.value)}
         />
           <CInputGroupAppend>
             <CInputGroupText>Kg</CInputGroupText>
           </CInputGroupAppend>
         </CInputGroup>  
         
       </CFormGroup>
     </CCol>
     <CCol md="3">
       <CFormGroup>
         <CLabel htmlFor="empWeight">Poids à vide</CLabel>
         <CInputGroup>
         <CInput
           name="empWeight"
           value={empWeight}
           onChange={(e) => setEmpWeight(e.target.value)}
         />
           <CInputGroupAppend>
             <CInputGroupText>Kg</CInputGroupText>
           </CInputGroupAppend>
         </CInputGroup>  
       </CFormGroup>
     </CCol>
   </CRow>
   <CRow>
     <CCol md="3">
       <CFormGroup>
         <CLabel htmlFor="loadLength">Longueur de l'espace de chargement</CLabel>
         <CInputGroup>
         <CInput
           name="loadLength"
           value={loadLength}
           onChange={(e) => setLoadLength(e.target.value)}
         />
           <CInputGroupAppend>
             <CInputGroupText>mm</CInputGroupText>
           </CInputGroupAppend>
         </CInputGroup>  
       </CFormGroup>
     </CCol>
     <CCol md="3">
       <CFormGroup>
         <CLabel htmlFor="loadLength">Hauteur de chargement</CLabel>
         <CInputGroup>
         <CInput
           name="loadHeight"
           value={loadHeight}
           onChange={(e) => setLoadHeight(e.target.value)}
         />
           <CInputGroupAppend>
             <CInputGroupText>mm</CInputGroupText>
           </CInputGroupAppend>
         </CInputGroup>  
       </CFormGroup>
     </CCol>
     <CCol md="3">
       <CFormGroup>
         <CLabel htmlFor="loadWidth">Largeur de chargement</CLabel>
         <CInputGroup>
         <CInput
           name="loadWidth"
           value={loadWidth}
           onChange={(e) => setLoadWidth(e.target.value)}
         />
           <CInputGroupAppend>
             <CInputGroupText>mm</CInputGroupText>
           </CInputGroupAppend>
         </CInputGroup>  
       </CFormGroup>
     </CCol>
     
     <CCol md="3">
       <CFormGroup>
         <CLabel htmlFor="loadVolume">Capacité de chargement</CLabel>
         <CInputGroup>
         <CInput
           name="loadVolume"
           value={loadVolume}
           onChange={(e) => setLoadVolume(e.target.value)}
         />
           <CInputGroupAppend>
             <CInputGroupText>m3</CInputGroupText>
           </CInputGroupAppend>
         </CInputGroup>  
       </CFormGroup>
     </CCol>
   </CRow>
   <Scroll/>
          </div>

       <div className="advInfoSzctionCard" >
        <Engine/>
   <CRow>
   <CCol md="3">
       <CFormGroup>
         <CLabel htmlFor="transmission">Transmission</CLabel>
         <CSelect
       name="transmission"
       value={transmission}
       onChange={(e) => setTransmission(e.target.value)}
     >
     <option value=""></option>
       <option value="manuelle">Manuelle</option>
       <option value="semi-automatique"> Semi-automatique</option>
       <option value="automatique">Automatique</option>
       <option value="Autres">Autres</option>
     </CSelect>
         {submitted && !transmission && (
           <span className="invalid-feedback error">obligatoire</span>
         )}
       </CFormGroup>
     </CCol>
     <CCol md="3">
       <CFormGroup>
         <CLabel htmlFor="brake">Frein continu</CLabel>
         <CSelect
       name="brake"
       value={brake}
       onChange={(e) => setBrake(e.target.value)}
     >
     <option value="">Veuillez choisir</option>
       <option value="autres">Autres</option>
       <option value="frein moteur"> Frein moteur</option>
       <option value="intarder">Intarder</option>
       <option value="Ralentisseur">Ralentisseur</option>
       <option value="telma">Telma</option>
       <option value="VEB">VEB</option>
     </CSelect>
       </CFormGroup>
     </CCol>
     <CCol md="3">
       <CFormGroup>
         <CLabel htmlFor="suspension">Suspension</CLabel>
         <CSelect
       name="suspension"
       value={suspension}
       onChange={(e) => setSuspension(e.target.value)}
     >
     <option value="">Veuillez choisir</option>
       <option value="autres">Autres</option>
       <option value="Lames"> Lames</option>
       <option value="Lames/Air">Lames/air</option>
       <option value="Air/air">Air/air</option>
       <option value="Suspension à lames paraboliques">Suspension à lames paraboliques</option>
     </CSelect>
       </CFormGroup>
     </CCol>
     <CCol md="3">
     <CFormGroup>
         <CLabel htmlFor="transmission">Configuration essieux</CLabel>
         <CSelect
       name="axleConfig"
       value={axleConfig}
       onChange={(e) => setAxleConfig(e.target.value)}
     >
     <option value=""></option>
       <option value="4x2">4x2</option>
       <option value="4x4"> 4x4</option>
       <option value="6x2">6x2</option>
       <option value="6x4">6x4</option>
       <option value="6x6">6x6</option>
       <option value="8x2">8x2</option>
       <option value="8x4">8x4</option>
       <option value="8x6">8x6</option>
       <option value="8x8">8x8</option>
       <option value="10x4">10x4</option>
       <option value="10x8">10x8</option>
       <option value="12x6">12x6</option>
       <option value="2 essieux">2 essieux</option>
       <option value="3 essieux">3 essieux</option>
       <option value=">3 essieux"> {'>'}3 essieux</option>
     </CSelect>
        
       </CFormGroup>
       {submitted && !axleConfig && (
           <span className="invalid-feedback error">obligatoire</span>
         )}
     </CCol>
   </CRow>
   <CRow>
   
     <CCol md="3">
       <CFormGroup>
         <CLabel htmlFor="wheelsDist">Empattement</CLabel>
         <CInputGroup>
         <CInput
           name="wheelsDist"
           value={wheelsDist}
           onChange={(e) => setWheelsDist(e.target.value)}
         />
           <CInputGroupAppend>
             <CInputGroupText>mm</CInputGroupText>
           </CInputGroupAppend>
         </CInputGroup>  
   
       </CFormGroup>
     </CCol>
     <CCol md="3">
       <CFormGroup>
         <CLabel htmlFor="tyreSize">Taille des pneus</CLabel>
         <CInputGroup>
         <CInput
           name="tyreSize"
           value={tyreSize}
           onChange={(e) => setTyreSize(e.target.value)}
         />
           <CInputGroupAppend>
             <CInputGroupText>mm</CInputGroupText>
           </CInputGroupAppend>
         </CInputGroup>  
   
       </CFormGroup>
     </CCol>
     <CCol xs="3">
       <CFormGroup>
         <CLabel htmlFor="fuel">Carburant</CLabel>
         <CSelect
           name="fuel"
           value={fuel}
           onChange={(e) => setFuel(e.target.value)}
         >
           <option value=""></option>
           <option value="Essence">Essence</option>
           <option value="Diesel">Diesel</option>
           <option value="GAZ">GAZ</option>
          <option value="électrique">électrique</option>
         </CSelect>
         {submitted && !fuel && (
           <span className="invalid-feedback error">obligatoire</span>
         )}
       </CFormGroup>
     </CCol>
     <CCol xs="3">
       <CFormGroup>
         <CLabel htmlFor="emissionClass">Classe d'émission</CLabel>
         <CSelect
           name="emissionClass"
           value={emissionClass}
           onChange={(e) => setEmissionClass(e.target.value)}
         >
           <option value=""></option>
           <option value="Euro1">Euro1</option>
           <option value="Euro2">Euro2</option>
           <option value="Euro3">Euro3</option>
           <option value="Euro4">Euro4</option>
           <option value="Euro5">Euro5</option>
           <option value="Euro6">Euro6</option>
           <option value="Aucun">Aucun</option>
          
         </CSelect>
         
       </CFormGroup>
     </CCol>
   </CRow>
   <CRow>
   
     <CCol md="3">
       <CFormGroup>
         <CLabel htmlFor="co2Emmision">Émission de CO2 en cycle mixte</CLabel>
         <CInputGroup>
         <CInput
           name="co2Emmision"
           value={co2Emmision}
           onChange={(e) => setCo2Emmision(e.target.value)}
         />
           <CInputGroupAppend>
             <CInputGroupText>g/km</CInputGroupText>
           </CInputGroupAppend>
         </CInputGroup>  
   
       </CFormGroup>
     </CCol>
     <CCol md="3">
       <CFormGroup>
         <CLabel htmlFor="fuelCons">Consommation de carburant en cycle mixte</CLabel>
         <CInputGroup>
         <CInput
           name="fuelCons"
           value={fuelCons}
           onChange={(e) => setFuelCons(e.target.value)}
         />
           <CInputGroupAppend>
             <CInputGroupText>l/100km</CInputGroupText>
           </CInputGroupAppend>
         </CInputGroup>  
       </CFormGroup>
     </CCol>
     <CCol md="3">
       <CFormGroup>
         <CLabel htmlFor="urban">Consommation de carburant en ville</CLabel>
         <CInputGroup>
         <CInput
           name="urban"
           value={urban}
           onChange={(e) => setUrban(e.target.value)}
         />
           <CInputGroupAppend>
             <CInputGroupText>l/100km</CInputGroupText>
           </CInputGroupAppend>
         </CInputGroup>  
       </CFormGroup>
     </CCol>
     <CCol md="3">
       <CFormGroup>
         <CLabel htmlFor="rural">Consommation de carburant sur route</CLabel>
         <CInputGroup>
         <CInput
           name="rural"
           value={rural}
           onChange={(e) => setRural(e.target.value)}
         />
           <CInputGroupAppend>
             <CInputGroupText>l/100km</CInputGroupText>
           </CInputGroupAppend>
         </CInputGroup>  
       </CFormGroup>
     </CCol>
   </CRow>
   <CRow>
     <CCol>
      
     <CFormGroup variant="checkbox" inline>
     <CInputCheckbox
       className="form-check-input"
       name="sootFilter"
       value={sootFilter}
       checked={sootFilter}
       onChange={(e) => setSootFilter(e.target.checked)}
     />
     <CLabel variant="checkbox">
       Filtre à particule
     </CLabel>
   </CFormGroup>
     </CCol>
   </CRow>
   <Scroll/>
          </div> 

          <div className="advInfoSzctionCard" >
        <Options name='pièces /accessoires' />
          <CRow>
            {accessoryEquipments && accessoryEquipments.map((accessoryEquipment, index) => {
              return (
                <CCol xs="3" key={index}>
                  <CFormGroup variant="checkbox" className="checkbox">
                    <CInputCheckbox
                      id={`custom-checkbox-${index}`}
                      name={accessoryEquipment.id}
                      value={accessoryEquipment.id}
                      onChange={(e) => onChangeExt(e,accessoryEquipment)}
                      selected={accessoryEquipments.includes(accessoryEquipment.label)}
                      checked={accessoryEquipment.checked}

                    />
                    <label htmlFor={`custom-checkbox-${index}`}>
                      {accessoryEquipment.label}
                    </label>
                  </CFormGroup>
                </CCol>
              );
            })}
          </CRow>
          <Scroll/>
          </div>

          <div className="advInfoSzctionCard" >
        <Options name='Autres caractéristiques' /> 
          <CRow>
            {otherEquipments && otherEquipments.map((otherEquipment, index) => {
              return (
                <CCol xs="3" key={index}>
                  <CFormGroup variant="checkbox" className="checkbox">
                    <CInputCheckbox
                      id={`custom-checkbox-${index}`}
                      name={otherEquipment.id}
                      value={otherEquipment.id}
                      onChange={(e) => onChangeExt(e,otherEquipment)}
                      selected={otherEquipments.includes(otherEquipment.label)}
                      checked={otherEquipment.checked}

                    />
                    <label htmlFor={`custom-checkbox-${index}`}>
                      {otherEquipment.label}
                    </label>
                  </CFormGroup>
                </CCol>
              );
            })}
          </CRow>        
          {submitted && !equipments.length>0 && (
                  <span className="invalid-feedback error">Veuillez choisir au moins un équipement</span>
                )}  
                <Scroll/>
          </div>
 
 </>
          :null
          }
          
                  {type ==='cars' ?
   <>
   <div className="information-title">
     <h2>modifier Voiture</h2> <CBadge color={getBadge(props.location.state.status)}>
     {textBadge(props.location.state.status)} 
                          </CBadge>
              <span className="snSpan snSpanE ">SN: {props.location.state.sn} </span> 
   </div>
   <div className="advInfoSzctionCard" >
       <Categori name='Voiture' />
   <CRow>
            <CCol>
              {" "}
              <CLabel>Etat </CLabel>
              {status ?
                <>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="1"
                      name="status"
                      value="1"
                      onChange={(e) => {setStatus(e.target.value); sethasChanged(true)}}
                      defaultChecked
                    />
                    <CLabel variant="checkbox" htmlFor="neuf">
                      Neuf
                    </CLabel>
                  </CFormGroup>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="0"
                      name="status"
                      value="0"
                      onChange={(e) => {setStatus(e.target.value); sethasChanged(true)}}
                    />
                    <CLabel variant="checkbox" htmlFor="occasion">
                      Occasion
                    </CLabel>
                  </CFormGroup>
                </> :
                <>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="1"
                      name="status"
                      value="1"
                      onChange={(e) => setStatus(e.target.value)}
                      defaultChecked
                    />
                    <CLabel variant="checkbox" htmlFor="neuf">
                      Neuf
                    </CLabel>
                  </CFormGroup>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="0"
                      name="status"
                      value="0"
                      onChange={(e) => setStatus(e.target.value)}
                      defaultChecked
                    />
                    <CLabel variant="checkbox" htmlFor="occasion">
                      Occasion
                    </CLabel>

                  </CFormGroup>
                </>}
             
              {/* {submitted && !status && (
                <span className="invalid-feedback error">
                  Veuillez choisir l'état de la voiture
                </span>
              )} */}
            </CCol>
          </CRow> <br />
 
   <CRow xs="12">
     <CCol xs="4">
       <CFormGroup>
         <CLabel htmlFor="make">Marque</CLabel>
         <CSelect
           name="sector"
           value={make}
           onChange={(e) => handleMakeChange(e)}
         >
           <option value="0"> Choisir la marque</option>
           {makes && makes.map((make) => {
             return (
               <option key={make.id} value={make.id}>
                 {make.label}
               </option>
             );
           })}
         </CSelect>
         {submitted && !make && (
           <span className="invalid-feedback error">
             Veuillez choisir la marque de la voiture
           </span>
         )}
       </CFormGroup>
     </CCol>
     <CCol xs="4">
       <CFormGroup>
         <CLabel htmlFor="model">Modèle</CLabel>
         <CSelect
           name="model"
           value={model}
           onChange={(e) => {setModel(e.target.value);sethasChanged(true);}}
         >
           <option value="0"> Modèle</option>
           {models && models.map((model) => {
             return (
               <option key={model.id} value={model.id}>
                 {model.label}
               </option>
             );
           })}
         </CSelect>
         {submitted && !model && (
           <span className="invalid-feedback error">
             Veuillez choisir le modèle de la voiture
           </span>
         )}
       </CFormGroup>
     </CCol>
     <CCol xs="4">
       <CFormGroup>
         <CLabel htmlFor="year">Année</CLabel>
         <CSelect
           value={year}
           name="year"
           onChange={(e) => setYear(e.target.value)}
         >
           <option></option>
           {getYear()}
         </CSelect>

         {submitted && !year && (
           <span className="invalid-feedback error">
             Veuillez entrer l'année de la voiture
           </span>
         )}
       </CFormGroup>
     </CCol>
   </CRow>
   <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >
        <Basic/>
   <CRow xs="12">
     
     <CCol xs="6">
       <CFormGroup>
         <CLabel>Kilométrage </CLabel>
         <CInput
           type="number"
           name="mileage"
           value={mileage}
           onChange={(e) => setMileage(e.target.value)}
         ></CInput>
         {submitted && !mileage && (
           <span className="invalid-feedback error">
             Veuillez entrer le kilométrage de la voiture
           </span>
         )}
       </CFormGroup>
     </CCol>
     <CCol xs="6">
       <CFormGroup>
         <CLabel htmlFor="price">Prix en TND</CLabel>
         <CInput
           type="number"
           name="price"
           value={price}
           onChange={(e) => setPrice(e.target.value)}
         ></CInput>
         {submitted && price < 1000 && (
           <span className="invalid-feedback error">
             Veuillez entrer le prix de la voiture
           </span>
         )}
       </CFormGroup>
     </CCol>
     <CCol xs="6">
       <CFormGroup>
         <CLabel>Carrosserie </CLabel>
         <CSelect
           name="bodyStyle"
           value={bodyStyle}
           onChange={(e) => setBodyStyle(e.target.value)}
         >
           <option value=""></option>
           <option value="Citadine">Citadine</option>
           <option value="Compacte">Compacte</option>
           <option value="Berline">Berline</option>
           <option value="Coupé">Coupé</option>
           <option value="Cabriolet">Cabriolet</option>
           <option value="4x4">4x4</option>
           <option value="Monospace">Monospace</option>
           <option value="Utilitaire">Utilitaire</option>
           <option value="Pick up">Pick Up</option>
           <option value="SUV">SUV</option>
           <option value="Autres">Autres</option>
         </CSelect>
         {submitted && !bodyStyle && (
           <span className="invalid-feedback error">
             Veuillez choisir la carrosserie
           </span>
         )}
       </CFormGroup>
     </CCol>
     <CCol xs="6">
       <CFormGroup>
         <CLabel htmlFor="fuel">Carburant</CLabel>
         <CSelect
           name="fuel"
           value={fuel}
           onChange={(e) => setFuel(e.target.value)}
         >
           <option value=""></option>
           <option value="Essence">Essence</option>
           <option value="Diesel">Diesel</option>
           <option value="Hybrid">Hybrid</option>
           <option value="électrique">électrique</option>
         </CSelect>
         {submitted && !fuel && (
           <span className="invalid-feedback error">obligatoire</span>
         )}
       </CFormGroup>
     </CCol>
     <CCol xs="6">
       <CFormGroup>
         <CLabel>Boite </CLabel>
         <CSelect
           name="boite"
           value={boite}
           onChange={(e) => setBoite(e.target.value)}
         >
           <option value=""></option>
           <option value="Manuelle"> Manuelle</option>
           <option value="Automatique">Automatique</option>
         </CSelect>
         {submitted && !boite && (
           <span className="invalid-feedback error">Obligatoire</span>
         )}
       </CFormGroup>
     </CCol>
     <CCol xs="6">
       <CFormGroup>
         <CLabel htmlFor="nbrSeat">Nombre de sièges</CLabel>
         <CSelect
           name="nbrSeat"
           value={nbrSeat}
           onChange={(e) => setNbrSeat(e.target.value)}
         >
           <option value=""></option>
           <option value="5">5 sièges</option>
           <option value="7">7 sièges</option>
           <option value="9">9 sièges</option>
         </CSelect>
         {submitted && !nbrSeat && (
           <span className="invalid-feedback error">obligatoire</span>
         )}
       </CFormGroup>
     </CCol>
     <CCol xs="6">
       <CFormGroup>
         <CLabel>Garrantie </CLabel>
         <CSelect
           name="warranty"
           value={warranty}
           onChange={(e) => setWarranty(e.target.value)}
         >
           <option value=""></option>
           <option value="0">0</option>
           <option value="moins 1"> Moins d'une année</option>
           <option value="1">1 an</option>
           <option value="2">2 ans</option>
         </CSelect>
         {submitted && !warranty && (
           <span className="invalid-feedback error">Obligatoire</span>
         )}
       </CFormGroup>
     </CCol>
     <CCol xs="6">
       <CFormGroup>
         <CLabel htmlFor="fiscalPower">Puissance fiscale</CLabel>
         <CSelect
           name="fiscalPower"
           value={fiscalPower}
           onChange={(e) => setFiscalPower(e.target.value)}
         >
           <option value=""></option>
           <option value="4">4</option>
           <option value="5">5</option>
           <option value="6">6</option>
           <option value="7">7</option>
           <option value="8">8</option>
           <option value="9">9</option>
           <option value="10">10</option>
           <option value="11">11</option>
           <option value="12">12</option>
           <option value="13">13</option>
           <option value="14">14</option>
           <option value="15">15</option>
           <option value="16">16</option>
           <option value="17">17</option>
           <option value="18">18</option>
           <option value="19">19</option>
           <option value="20">20</option>
           <option value="21">21</option>
           <option value="autres">Autres</option>
         </CSelect>
         {submitted && !fiscalPower && (
           <span className="invalid-feedback error">obligatoire</span>
         )}
       </CFormGroup>
     </CCol>
     <CCol xs="6">
       <CFormGroup>
         <CLabel htmlFor="transmission">Transmission</CLabel>
         <CSelect
           name="transmission"
           value={transmission}
           onChange={(e) => setTransmission(e.target.value)}
         >
           <option value=""></option>
           <option value="traction intégrale">Traction intégrale</option>
           <option value="traction avant"> Traction avant</option>
           <option value="propulsion arrière">Propulsion arrière</option>
           <option value="à quatre roues motrices">
             À quatre roues motrices
           </option>
         </CSelect>
         {submitted && !transmission && (
           <span className="invalid-feedback error">obligatoire</span>
         )}
       </CFormGroup>
     </CCol>
     <CCol xs="6">
       <CFormGroup>
         <CLabel>Couleur </CLabel>
         <CSelect
           name="color"
           value={color}
           onChange={(e) => setColor(e.target.value)}
         >
           <option value=""></option>
           <option value="Noir">Noir</option>
           <option value="Gris argent">Gris argent</option>
           <option value="Gris anthracite">Gris anthracite</option>
           <option value="Gris Shark">Gris Shark</option>
           <option value="Blanc">Blanc</option>
           <option value="Rouge">Rouge</option>
           <option value="Bleu">Bleu</option>
           <option value="Beige">Beige</option>
           <option value="Jaune">Jaune</option>
           <option value="Orange">Orange</option>
           <option value="Vert">Vert</option>
           <option value="Aubergine">Aubergine</option>
           <option value="Marron">Marron</option>
           <option value="Autre">Autre</option>
         </CSelect>
         {submitted && !color && (
           <span className="invalid-feedback error">Obligatoire</span>
         )}
       </CFormGroup>
     </CCol>
   </CRow>
   <Scroll/>
         </div>
 
         <div className="advInfoSzctionCard" >
        <Equip name='extérieures' />
          <CRow>
            {extEquipments && extEquipments.map((extEquipment, index) => {
              return (
                <CCol xs="3" key={index}>
                  <CFormGroup variant="checkbox" className="checkbox">
                    <CInputCheckbox
                      id={`custom-checkbox-${index}`}
                      name={extEquipment.id}
                      value={extEquipment.id}
                      onChange={(e) => onChangeExt(e,extEquipment)}
                      selected={extEquipments.includes(extEquipment.label)}
                      checked={extEquipment.checked}
                    />
                    <label htmlFor={`custom-checkbox-${index}`}>
                      {extEquipment.label}
                    </label>
                  </CFormGroup>
                </CCol>
              );
            })}
          </CRow>
          <Scroll/>
         </div>
          
         <div className="advInfoSzctionCard" >
        <Equip name='intérieurs' />
          <CRow>
            {intEquipments && intEquipments.map((intEquipment, index) => {
              return (
                <CCol xs="3" key={index}>
                  <CFormGroup variant="checkbox" className="checkbox">
                    <CInputCheckbox
                      id={`custom-checkbox-${index}`}
                      name={intEquipment.id}
                      value={intEquipment.id}
                      onChange={(e) => onChangeExt(e,intEquipment)}
                      selected={extEquipments.includes(intEquipment.label)}
                      checked={intEquipment.checked}
                    />
                    <label htmlFor={`custom-checkbox-${index}`}>
                      {intEquipment.label}
                    </label>
                  </CFormGroup>
                </CCol>
              );
            })}
          </CRow>
          <Scroll/>
         </div>

          <div className="advInfoSzctionCard" >
        <Equip name='de sécurité' />
          <CRow>
            {secureEquipments && secureEquipments.map((secureEquipment, index) => {
              return (
                <CCol xs="3" key={index}>
                  <CFormGroup variant="checkbox" className="checkbox">
                    <CInputCheckbox
                      id={`custom-checkbox-${index}`}
                      name={secureEquipment.id}
                      value={secureEquipment.id}
                      onChange={(e) => onChangeExt(e,secureEquipment)}
                      selected={extEquipments.includes(secureEquipment.label)}
                      checked={secureEquipment.checked}
                    />
                    <label htmlFor={`custom-checkbox-${index}`}>
                      {secureEquipment.label}
                    </label>
                  </CFormGroup>
                </CCol>
              );
            })}
          </CRow>
          {submitted && !equipments.length>0 && (
                  <span className="invalid-feedback error">Veuillez choisir au moins un équipement</span>
                )}
                <Scroll/>
         </div>
        
 </>
:null
          }

           {type ==='motos' ?
              <>
              <div className="information-title">
                <h2>modifier Moto</h2> <CBadge color={getBadge(props.location.state.status)}>
                {textBadge(props.location.state.status)} 
                          </CBadge>
              <span className="snSpan snSpanE ">SN: {props.location.state.sn} </span> 
              </div>

              <div className="advInfoSzctionCard" >
        <Categori name='Moto' />
              <CRow>
            <CCol>
              {" "}
              <CLabel>Etat </CLabel> <br />
              {status ?
                <>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="1"
                      name="status"
                      value="1"
                      onChange={(e) => setStatus(e.target.value)}
                      defaultChecked
                    />
                   <CLabel variant="checkbox" htmlFor="neuf">
                      Neuf
                    </CLabel>
                  </CFormGroup>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="0"
                      name="status"
                      value="0"

                      onChange={(e) => setStatus(e.target.value)}
                    />
                    <CLabel variant="checkbox" htmlFor="occasion">
                      Occasion
                    </CLabel>
                  </CFormGroup>
                </> :
                <>
                   <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="1"
                      name="status"
                      value="1"
                      onChange={(e) => setStatus(e.target.value)} 

                    />
                    <CLabel variant="checkbox" htmlFor="neuf">
                      Neuf
                    </CLabel>
                  </CFormGroup>
                  <CFormGroup variant="checkbox" inline>
                    <CInputRadio
                      className="form-check-input"
                      id="0"
                      name="status"
                      value="0"
                      onChange={(e) => setStatus(e.target.value)}
                      defaultChecked  
                    />
                    <CLabel variant="checkbox" htmlFor="occasion">
                      Occasion
                    </CLabel>

                  </CFormGroup>
                </>}
             
           
            </CCol>
          </CRow>
              <CRow xs="12">
                <CCol xs="4">
                  <CFormGroup>
                    <CLabel htmlFor="make">Marque</CLabel>
                    <CSelect
                      name="sector"
                      value={make}
                      onChange={(e) => handleMakeChange(e)}
                    >
                      <option value="0"> Choisir la marque</option>
                      {makes && makes.map((make) => {
                        return (
                          <option key={make.id} value={make.id}>
                            {make.label}
                          </option>
                        );
                      })}
                    </CSelect>
                    {submitted && !make && (
                      <span className="invalid-feedback error">
                        Veuillez choisir la marque de la moto
                      </span>
                    )}
                  </CFormGroup>
                </CCol>
                <CCol xs="4">
                  <CFormGroup>
                    <CLabel htmlFor="model">Modèle</CLabel>
                    <CSelect
                      name="model"
                      value={model}
                      onChange={(e) => {setModel(e.target.value);sethasChanged(true);}}
                    >
                      <option value="0"> Modèle</option>
                      {models && models.map((model) => {
                        return (
                          <option key={model.id} value={model.id}>
                            {model.label}
                          </option>
                        );
                      })}
                    </CSelect>
                    {submitted && !model && (
                      <span className="invalid-feedback error">
                        Veuillez choisir le modèle de la moto
                      </span>
                    )}
                  </CFormGroup>
                </CCol> 
                <CCol xs="4">
                  <CFormGroup>
                    <CLabel htmlFor="year">1ére immatriculation</CLabel>
                    <CSelect
                      value={year}
                      name="year"
                      onChange={(e) => setYear(e.target.value)}
                    >
                      <option></option>
                      {getYear()}
                    </CSelect>
    
                    {submitted && !year && (
                      <span className="invalid-feedback error">
                        Veuillez entrer l'année de la moto
                      </span>
                    )}
                  </CFormGroup>
                </CCol>
              </CRow>
              <Scroll/>
          </div>

          <div className="advInfoSzctionCard" >
        <Basic />
              <CRow xs="12">  
               <CCol xs="6">
                  <CFormGroup>
                    <CLabel>Garrantie </CLabel>
                    <CSelect
                      name="warranty"
                      value={warranty}
                      onChange={(e) => setWarranty(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="0">0</option>
                      <option value="moins 1"> Moins d'une année</option>
                      <option value="1">1 an</option>
                      <option value="2">2 ans</option>
                    </CSelect>
                    {submitted && !warranty && (
                      <span className="invalid-feedback error">Obligatoire</span>
                    )}
                  </CFormGroup>
                </CCol>           
                <CCol xs="6">
                  <CFormGroup>
                    <CLabel>Vitesse </CLabel>
                    <CInput
                      type="number"
                      name="speed"
                      value={speed}
                      onChange={(e) => setSpeed(e.target.value)}
                    ></CInput>
                    {submitted && !speed && (
                      <span className="invalid-feedback error">
                        Veuillez entrer la vitesse de la moto
                      </span>
                    )}
                  </CFormGroup>
                </CCol>           
                <CCol xs="6">
                  <CFormGroup>
                    <CLabel htmlFor="bodyStyle">Type de châssis</CLabel>
                    <CSelect
                      name="bodyStyle"
                      value={bodyStyle}
                      onChange={(e) => setBodyStyle(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="Scooter">Scooter</option>
                      <option value="Roadster">Roadster</option>
                      <option value="Sportive">Sportive</option>
                      <option value="Custom">Custom</option>
                      <option value="Routière GT">Routière GT</option>
                      <option value="Quad">Quad</option>
                      <option value="Routière">Routière</option>
                      <option value="Trail">Trail</option>
                      <option value="Enduro">Enduro</option>
                      <option value="Sidecar">Sidecar</option>
                      <option value="Pocket Bike">Pocket Bike</option>
                      <option value="Vélomoteur">Vélomoteur</option>
                      <option value="Cross">Cross</option>
                      <option value="Collection">Collection</option>
                      <option value="Rallye">Rallye</option>
                      <option value="Compétition">Compétition</option>
                      <option value="Tuning">Tuning</option>
                      <option value="Supermotard">Supermotard</option>
                      <option value="Trial">Trial</option>
                      <option value="Trois Roues">Trois Roues</option>
                      <option value="autres">Autres</option>
                    </CSelect>
                    {submitted && !bodyStyle && (
                      <span className="invalid-feedback error">obligatoire</span>
                    )}
                  </CFormGroup>
                </CCol>
                <CCol xs="6">
                  <CFormGroup>
                    <CLabel htmlFor="gearingType">transmission</CLabel>
                    <CSelect
                      name="gearingType"
                      value={gearingType}
                      onChange={(e) => setGearingType(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="Semi-automatique">Semi-automatique</option>
                      <option value="Cylindrée">Cylindrée</option>
                      <option value="Boîte manuelle">Boîte manuelle</option>
                      
                      <option value="autres">Autres</option>
                    </CSelect>
                    {submitted && !gearingType && (
                      <span className="invalid-feedback error">obligatoire</span>
                    )}
                  </CFormGroup>
                </CCol>           
                <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="price">Prix en TND</CLabel>
                  <CInput
                    type="number"
                    name="price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  ></CInput>
                  {submitted  && !price  &&(
                    <span className="invalid-feedback error">
                      Veuillez entrer le prix de la moto
                    </span>
                  )}
                </CFormGroup>
              </CCol>
         
                <CCol xs="6">
                  <CFormGroup>
                    <CLabel>Carburant</CLabel>
                    <CSelect
                      name="fuel"
                      value={fuel}
                      onChange={(e) => setFuel(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="Essence">Essence</option>
                      <option value="Diesel">Diesel</option>
                      <option value="Electrique">Electrique</option>
                      <option value="GPL">GPL</option>
                      <option value="Electrique/Essence">Electrique/Essence</option>
                      <option value="Essence 2 temps">Essence 2 temps</option>
                     
                      <option value="Autres">Autres</option>
                    </CSelect>
                    {submitted && !fuel && (
                      <span className="invalid-feedback error">
                        Veuillez choisir le carburant
                      </span>
                    )}
                  </CFormGroup>
                </CCol>
                <CCol xs="6">
                     <CFormGroup>
                      <CLabel htmlFor="co2Emmision">Émission de CO2 </CLabel>
                      <CInputGroup>
                      <CInput
                        name="co2Emmision"
                        value={co2Emmision}
                        onChange={(e) => setCo2Emmision(e.target.value)}
                      />
                        <CInputGroupAppend>
                          <CInputGroupText>g/km</CInputGroupText>
                        </CInputGroupAppend>
                      </CInputGroup>  
                
                    </CFormGroup>
                </CCol>
              <CCol xs="6">
                  <CFormGroup>
                    <CLabel>Couleur </CLabel>
                    <CSelect
                      name="color"
                      value={color}
                      onChange={(e) => setColor(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="Argent">Argent</option>
                      <option value="Beige">Beige</option>
                      <option value="Blanc">Blanc</option>
                      <option value="Bleu">Bleu</option>
                      <option value="Bronze">Bronze</option>
                      <option value="Brun">Brun</option>
                      <option value="Gris">Gris</option>
                      <option value="Jaune">Jaune</option>
                      <option value="Mauve">Mauve</option>
                      <option value="Noir">Noir</option>
                      <option value="Or">Or</option>
                      <option value="Orange">Orange</option>
                      <option value="Rouge">Rouge</option>
                      <option value="Vert">Vert</option>
  
                      <option value="Autre">Autre</option>
                    </CSelect>
                    {submitted && !color && (
                      <span className="invalid-feedback error">Obligatoire</span>
                    )}
                  </CFormGroup>
                </CCol>
                <CCol xs="6=">
                <CLabel>  Peinture Métallisée </CLabel>

      <CFormGroup variant="checkbox" inline>
        {paintType  ?  
         <CInputCheckbox
        className="form-check-input"
        name="paintType"
        value={paintType}
        checked={true}
        onChange={(e) => setPaintType(e.target.checked)}
      />
     :
     <CInputCheckbox
     className="form-check-input"
     name="paintType"
     value={paintType}
     checked={false}
     onChange={(e) => setPaintType(e.target.checked)}
   />
   }
    
      
    </CFormGroup>
      </CCol>
   
              </CRow>
              <Scroll/>
          </div>

          <div className="advInfoSzctionCard" >
        <Equip />
              <CRow>
                {equipmentsAnnonce && equipmentsAnnonce.map((equipmentAnnonce, index) => {
                  return (
                    <CCol xs="3" key={index}>
                      <CFormGroup variant="checkbox" className="checkbox">
                        <CInputCheckbox
                          id={`custom-checkbox-${index}`}
                          name={equipmentAnnonce.id}
                          value={equipmentAnnonce.id}
                          onChange={(e) => onChangeExt(e,equipmentAnnonce)}
                          selected={equipmentsAnnonce.includes(equipmentAnnonce.label)}
                          checked={equipmentAnnonce.checked}

                        />
                        <label htmlFor={`custom-checkbox-${index}`}>
                          {equipmentAnnonce.label}
                        </label>
                      </CFormGroup>
                    </CCol>
                  );
                })}
              </CRow>
              {submitted && !equipments.length>0 && (
                  <span className="invalid-feedback error">Veuillez choisir au moins un équipement</span>
                )}
                <Scroll/>
          </div>

            </>         
           :null
          }


          <div className="advInfoSzctionCard" >
          <Adresse/>
<CRow xs="12">
  <CCol xs="6">
    <CFormGroup>
      <CLabel htmlFor="country">Pays</CLabel>
      <CSelect
        name="country"
        value={country}
        onChange={(e) => handleCountryChange(e)}
      >
        <option value="0"> Choisissez un pays</option>
        {countries && countries.map((country) => {
          return (
            <option key={country.id} value={country.id}>
              {country.label}
            </option>
          );
        })}
      </CSelect>
      {submitted && !country && (
        <span className="invalid-feedback error">
          Obligatoire
        </span>
      )}
    </CFormGroup>
  </CCol>
  <CCol xs="6">
    <CFormGroup>
      <CLabel htmlFor="Région">Gouvernorat</CLabel>
      <CSelect
        name="city"
        value={city}
        onChange={(e) => handleCityChange(e)}
      >
        <option value="0"> Gouvernorat</option>
        {cities && cities.map((city) => {
          return (
            <option key={city.id} value={city.id}>
              {city.label}
            </option>
          );
        })}
      </CSelect>
      {submitted && !city && (
        <span className="invalid-feedback error">
          Obligatoire
        </span>
      )}
    </CFormGroup>
  </CCol>
</CRow>
<CRow xs="12">
  <CCol xs="6">
    <CFormGroup>
      <CLabel htmlFor="delegate">Délégation</CLabel>
      <CSelect
        name="delegate"
        value={delegate}
        onChange={(e) => handleDelegateChange(e)}
      >
        <option value="0"> Choisissez une délégation</option>
        {delegates && delegates.map((delegate) => {
          return (
            <option key={delegate.id} value={delegate.id}>
              {delegate.label}
            </option>
          );
        })}
      </CSelect>
      {submitted && !delegate && (
        <span className="invalid-feedback error">
          Obligatoire
        </span>
      )}
    </CFormGroup>
  </CCol>
  <CCol xs="6">
    <CFormGroup>
      <CLabel htmlFor="loclité">Localité</CLabel>
      <CSelect
        name="locality"
        value={locality}
        onChange={(e) => setLocality(e.target.value)}
      >
        <option value="0"> Localité</option>
        {localities && localities.map((local) => {
          return (
            <option key={local.id} value={local.id}>
              {local.label}
            </option>
          );
        })}
      </CSelect>
      {submitted && !locality && (
        <span className="invalid-feedback error">
          Obligatoire
        </span>
      )}
    </CFormGroup>
  </CCol>
</CRow>
 <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >
       <Contact/>
<CRow xs="12">
            <CCol xs="6">
              {newPhone && <CFormGroup>
                <CLabel htmlFor="phoneNumber">Numéro téléphone</CLabel>
                <PhoneInput
                  type="text"
                  onChange={(value) => setPhoneNumber(value)}
                  placeholder="Entrez un numéro de téléphone"
                  defaultCountry="TN"
                  international
                  name="phoneNumber"
                  id="phoneNumber"
                  value={phoneNumber}
                />
                  {submitted && newPhone && phoneNumber.length>0 && !isValidPhoneNumber(phoneNumber) && (
                  <span className="invalid-feedback error">Ce numéro de téléphone n'est pas valide</span>
                )}
              </CFormGroup>}
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  onChange={changePhoneNumber}                 
                />
                <CLabel variant="checkbox" className="form-check-label">
                Utilisez le numéro téléphone de votre compte
                </CLabel>
              </CFormGroup> 
            </CCol>
            </CRow>
            <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >
     <Extra/>
<CRow>
  <CCol>
    <CFormGroup>
      <CKEditor
        editor={ClassicEditor}
        data={annonce.description}
        onChange={(event, editor) => {
          const data = editor.getData();
          setDescription(data)
        }}
      />


    </CFormGroup>
  </CCol>
</CRow>
   <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >

<Imgs/>

          <CRow>
            <CCol>
            <CFormGroup style={{display:"inline-block"}}>
                <CLabel htmlFor="pImage">Principal image</CLabel>
                <input 
                          type="file"
                          name="pImage"
                          onChange={handleFileSection}
                          accept="image/*"
                           ></input>
            </CFormGroup>
            {uploadingImage ?
            <span style={{height:"fit-content",marginLeft:"10px",lineHeight:"90px"}}>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>
       : !uploadingImage && oldImgP ? 
        <img style={{height: "85px", width: "auto"}}
          src={imgPrincipal}
          alt="preview"
          className="img-fluid img-thumbnail "
        />
       : !uploadingImage && pImageThumb ? 
        <img style={{height: "85px", width: "auto"}}
          src={pImageThumb}
          alt="preview"
          className="img-fluid img-thumbnail "
        /> : ''}
    
           {submitted && !imgPrincipal && (
                <span className="invalid-feedback error">
                  Veuillez uploader une image
                </span>
              )}
            </CCol>
          </CRow><br/>


          {otherImages.length > 0 && 
            <>
              <CLabel htmlFor="pImage">Autres images</CLabel>
                              {otherImages.map((other, i) => {

                                  return (

                                      <span key={i} className="img-thumbnail-others">
                                          {!other.principal ?
                                              <>

                                                  <img
                                                    src={`${IMAGES_BASE_URL}/public/uploads/images/service/${other.name}`}
                                                      width="100"
                                                      className="img-fluid img-thumbnail "
                                                      alt=""
                                                  />
                                                  <CButton onClick={(e) => toggle(other.id, false, i)}><CIcon name="cilTrash" /></CButton>

                                              </>

                                              : null}
                                      </span>

                                  );

                              })
                              }
                              </>
           }
      <br/><br/>
      {imgCountA>0 && <> <div className="imgCounter"> {imgCount} {imgCount > 1 ? ' images téléchargées sur ' :' image téléchargée sur ' }  {imgCountA} </div>
       <div className="loading-bar">
          <div className="loading-bar-fill" style={{ width: `${imgCountA==0?0 :(imgCount / imgCountA) * 100}%` }}></div>
        </div><br/></>}
          <CLabel >ajouter des images</CLabel> 
                      <CRow>
                          <CCol>
                              <Dropzone
                                 // getUploadParams={getUploadParams}
                                  onChangeStatus={handleChangeStatus}
                                  // onSubmit={handleSubmitF}
                                  accept="image/*"
                                  maxFiles={10 - otherImages.length}
                                  maxSizeBytes={5 * 1024 * 1024}
                              >
                                  <input />
                              </Dropzone>
                           </CCol>
                      </CRow>
                      <br />
                      </div>
                      {success && (
                          <CAlert
                              show={true}
                              // autohide="5000"
                              autohide={false}
                              placement="top-end"
                              color="success"
                          >
                              {`L'article a été modifiée avec succès !`}
                          </CAlert>
                      )}

                      <CRow>
                          <CCol md="3" className="offset-9">
                          {
       ((!(doneuploadingfile && imgCountA == album.length)) || uploadingImage)?
               (
              <CButton color="primary" block disabled>
                <span>
                  {" "}
                  <CSpinner color="default" size="sm" />
                </span>

                <span> chargement des images</span>
              </CButton>
            )

       :  loading ? (
              <CButton color="primary" block disabled>
                <span>
                  {" "}
                  <CSpinner color="default" size="sm" />
                </span>

                <span> Valider</span>
              </CButton>
            )  
            :
              <CButton color="primary" block type="submit"
               disabled={!hasChanged}
               >
                Valider
              </CButton>
            
}

                          </CCol>
                      </CRow>
<br/>



</form>
        <CModal show={modal} onClose={toggle}>
                              <CModalHeader closeButton></CModalHeader>
                              <CModalBody>
                              voulez vous vraiment supprimer cette image?
                               
                              </CModalBody>
                              <CModalFooter>
                                <CButton
                                  color="primary"
                                  onClick={() => {
                                    deleteImageVehicle(item,index);
                                  }}
                                >
                                  Oui
                                </CButton>

                                <CButton color="secondary" onClick={toggle}>
                                  Cancel
                                </CButton>
                              </CModalFooter>
                            </CModal>

      </div>
    );
  }
}

export default EditVehicle
