import React, { Component } from "react";
import axios from "axios";
import { API_BASE_URL, IMAGES_BASE_URL } from "../../../config";

import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";

export class NewsByCat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      news: [],
      category: "",
      isloaded: false,
    };
    

  }
  convertDate(isoDate) {
    let date = new Date(isoDate);

    return date.toLocaleDateString("en-GB");
  }
  componentDidMount() {
    axios(`${API_BASE_URL}/news-by-category/`, {
      params: { categoryId: this.props.match.params.id },
    })
      .then((res) => res.data)
      .then((result) => {
        //console.log("klkm", result, this.props.match.params.id);
        this.setState({
          news: result.news,
          category: result.category,
          isloaded: true,
          //categoryId:result.category.id
        });
      });
  }
  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.match.params;

    if (id !== prevProps.match.params.id) {
      axios(`${API_BASE_URL}/news-by-category/`, {
        params: { categoryId: this.props.match.params.id },
      })
        .then((res) => res.data)
        .then((result) => {
          //console.log("jfnkfnf", result);
          this.setState({
            news: result.news,
            category: result.category,
            isloaded: true,
            //categoryId:result.category.id
          });
        });
    }
  }
  render() {
    const category = this.state.category;
    const news = this.state.news;
    const arabic = /[\u0600-\u06FF]/;
    const styleArabic={textAlign:"right",direction:"rtl"};

    if (!this.state.isloaded) {
      return (
        <div className="container">
          <main className="l-main">
            <div className="l-section l-section--archive">
              <div className="loading_logo">
                <img
                  src={"assets/icons/tunisia-living-loading-icon.gif"}
                  alt="tunisia living loading icon"
                />
                <p>Chargement...</p>
              </div>
            </div>
          </main>
        </div>
      );
    } else
      return (
        <div className="container">
          <main className="l-main newsByCatPage">
            <section className="l-section l-section--pageHeader ">
              <div className="o-container">
                <div className="l-section__heading c-headingLink">
                  <h1 className="c-heading c-heading--page c-headingLink__heading">
                    {category.label}
                  </h1>
                </div>
              </div>
            </section>
            <section className="l-section l-section--archive">
              <div className="l-section__grid l-section__grid--wideSidebar o-container">
                <div className="l-section__main">
                  <div className="l-section_widget">
                    <ul className="c-posts c-posts--column  c-posts--loadMore c-posts--anchored">
                      {news.map((news) => {
                        return (
                          <li
                            key={news.id}
                            className="c-posts__item c-posts__loadmore"
                          
                          >
                            <Link
                              // to={
                              //   news.category &&
                              //   `/news/${news.id}-${news.category.id}/${news.category.slug}/${news.slug}`
                              // }
                              to = {{ pathname:`/news/${news.id}-${news.category.id}/${news.category.slug}/${news.slug}`,
                                  state:news,}}
                              title={news.title}
                              className="c-posts__inner"
                            >
                              <div className={ `c-posts__details ${arabic.test(news.title) && 'arabicFontClass'}` }  >
                                <div className="c-posts__headline ">
                                  <span className="c-posts__headlineText">
                                    {news.title}
                                  </span>
                                </div>
                                <div className="c-posts__excerpt">
                                  <div className="u-lineClamp">
                                    {
          (news.sections && news.sections.length>0 && news.sections[0].position && news.sections[0].position<0) ||  news.id<71                                    
                                      ?ReactHtmlParser(news.content)
                                      :ReactHtmlParser(decodeURIComponent(news.content))
                                      }
                                  </div>
                                </div>
                                <div className="c-posts__about c-posts__about--multiple">
                                  <div className="c-posts__info c-posts__info--right c-posts__info--highlight"></div>
                                  <div className="c-posts__info">
                                    {this.convertDate(news.createdAt)}
                                  </div>
                                </div>
                              </div>
                              <div className="c-posts__media ">
                                <img
                                  className="c-posts__thumbnail"
                                  alt={news.title}
                                  src={`${IMAGES_BASE_URL}/public/uploads/actualites/${news.path}`}
                                />
                              </div>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      );
  }
}

export default NewsByCat;
