import React, { useEffect, useState , useContext} from "react";
import MemberContext from "./MemberContext";
import apiClient from "../apiClient";
import { tokenCookie , keyHeader ,API_BASE_URL ,IMAGES_BASE_URL } from "../../../config";
import { getBadge , textBadge} from "src/actions";
import axios from "axios";
import Moment from "react-moment";
import Loading from "src/components/myWidgets/loadingWidget";
import { Link } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CSpinner,
  CBadge , CDataTable
} from "@coreui/react";
function MyArticles(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState(null);
  const [errorDel, setErrorDel] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {articles,loadedArticles , setArticles} = useContext(MemberContext);

  const toggle = (id) => {
    setModal(!modal);
    setItem(id);
  };
  function deleteArticle(id) {
    setIsLoading(true);
    apiClient
      .delete(`/article/delete/${id}`)
      .then((res) => {
        toggle();
        //console.log(res);
        if (res.data.status.toString() === "ok") {
          setItems((items) => items.filter((x) => x.id !== id));
          setArticles((items) => items.filter((x) => x.id !== id));
          setIsLoading(false);
        }
        if (
          res.data.status.toString() ===
          "ForeignKeyConstraintViolationException"
        ) {
          setErrorDel("Vous ne pouvez pas supprimer cet élément, il a des relations");
          alert("Vous ne pouvez pas supprimer cet élément, il a des relations");
          setIsLoading(false);
        }
      })
      .catch((err) =>{
        //console.log(err);
        setErrorDel("Vous ne pouvez pas supprimer cet bon plans maintenant");
      setIsLoading(false);} );
  }
  useEffect(() => {

    if(loadedArticles) 
    {setItems(articles);
      setIsLoaded(true)
      //console.log('props articles 2 <<<<<< ', articles.length);   
    }

// if (props.location.state) {
//   setIsLoaded(true);
//   setItems(props.location.state);
// }
//   else {
//     const formData = new FormData();
//   formData.append("key",tokenCookie );
//   axios.post(`${API_BASE_URL}/articles/By_member`, formData, keyHeader)
//       .then(response => {
//         //console.log('response --- ', response);
//         setIsLoaded(true);
//         setItems(response.data.items);
       
//       })
//       .catch(error => {
//         setIsLoaded(true);
//         setError(error);
//           console.log('error --- ', error)

//       });
//     }

    // axios(`${API_BASE_URL}/articles/By_member/${localStorage.getItem("id")}`)
    //   .then((res) => res.data)
    //   .then(
    //     (result) => {
    //       console.log("articles by member", result);
    //       setIsLoaded(true);
    //       setItems(result.slice(0,10));
          
    //     },
    //     (error) => {
    //       setIsLoaded(true);
    //       setError(error);
    //     }
    //   );
  }, [articles,loadedArticles]);
  const fields = [
    {
      key: "thumb",
      label: "thumb",
    // _style: { width: "20%" },
    },
    {
      key: "Actions",
      label: "Actions",
    },
  ];

  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <Loading/>
    );
  } else if (!items.length) {
    return (
      <div className="container">
      <main className="l-main">
        <div className="l-section l-section--archive">
          <div className="noItemsText">
          Il n'y a pas encore d'annonces ici <br/><br/>
          <Link to='/articles/add'>
          <button className="ml-1 btn btn-info" type="button">
          commencez à ajouter des annonces maintenant
          </button></Link>
          </div>
        </div>
      </main>
    </div>

    )} else {
    return (
      <>
        <CDataTable addTableClasses="table table-hover table-outline mb-0  d-sm-table"
                    items={items}
                    fields={fields}
                    itemsPerPageSelect
                    itemsPerPage={10}
                    hover
                    pagination
                    scopedSlots={{
                      thumb: (article) => (
                        <td>
                    <div className="vehicle-thumbnail">
                      {article.mainPicture  ? (
                        <img
                          src={
                            article.typeArticle && `${IMAGES_BASE_URL}/public/uploads/images/${article.typeArticle.section}/${article.mainPicture.name}`                             
                          }
                          alt=""
                        />
                      ) : (
                        ""
                      )}

                     
                    </div>
                    <div className="profileAnnonceCardDetails">
                    <strong>
                     <div>
                      {article.nom}  <CBadge color={getBadge(article.status)}>
                      {textBadge(article.status)}
                          </CBadge>
                    </div>
                    <div>
                      {article.typeArticle && article.typeArticle.label} - {" "}
                      {article.categoryArticle && article.categoryArticle.label}
                    </div>

                    </strong>  
                        <div>{article.price>0 && article.price} TND</div>
                    <div>
                     <strong>Numéro de série :</strong> 
                     <span> {article.sn}</span> 
                    </div>
            
                    <div>
                     <strong>Date d'ajout :{" "}</strong> 
                     <span><Moment format="DD-MM-YYYY">{article.createdAt}</Moment></span> 
                    </div>
                    </div>
                  </td>
                      ),
                      Actions: (article) => (
                        <td>
                  <Link to={{ pathname:`/members/profile/myItemDetails/article`,
                         state:article}}            
                  >
                  <CButton>
              <img src="assets/icons/eye.svg" />
            </CButton></Link>
                    <Link to={{ pathname:'/members/profile/article-edit',
                         state:article}}
                    >
                    <CButton>
                      <CIcon name="cilPencil" />                     
                      </CButton>
                    </Link>
                    <CButton
                      onClick={(e) => toggle(article.id)}
                    >
                      <CIcon name="cilTrash" />
                    </CButton>
                  </td>
                      )
                    }}
                  />

        <CModal show={modal} onClose={toggle}>
          <CModalHeader closeButton></CModalHeader>
          <CModalBody>Voulez-vous vraiment supprimer cet annonce ?</CModalBody>
          <CModalFooter>

            {!isLoading && (
              <CButton
                color="primary"
                onClick={() => {
                  deleteArticle(item);
                }}
              >
                Oui
              </CButton>
            )}
            {isLoading && (
              <CButton color="primary" disabled>
                <span>
                  <CSpinner color="default" size="sm" />
                </span>

                <span> Oui</span>
              </CButton>
            )}

            <CButton color="secondary" onClick={toggle}>
              Cancel
            </CButton>
          </CModalFooter>
        </CModal>
      </>
    );
  }
}

export default MyArticles;
