import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react'

import CIcon from '@coreui/icons-react'

// sidebar nav config
//import navigation from './_nav'

const TheSidebar = () => {
  const dispatch = useDispatch()
  const show = useSelector(state => state.sidebarShow)
  let role=localStorage.getItem('roles');
  let   navigation=[];
  if ( role=='ROLE_SUPER_ADMIN'){
   navigation =[
    {
      _tag: 'CSidebarNavTitle',
      _children: ['Users']
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Gestion des utilisateurs',
      to: '/5467854/users',
      icon: 'cilUser',
      
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Gestion des membres',
      to: '/5467854/members',
      icon: 'cilUser',
      
    },
    {
      _tag: 'CSidebarNavTitle',
      _children: ['News']
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Information bar',
      to: '/5467854/info-bar',
      icon: 'cilNotes',
    },
  
    {
      _tag: 'CSidebarNavDropdown',
      name: 'Gestion des news',
      route: '/base',
      icon: 'cilNotes',
      _children: [
        {
          _tag: 'CSidebarNavItem',
          name: 'Gestion des actualités',
          to: '/5467854/news',
        },
        {
          _tag: 'CSidebarNavItem',
          name: 'Catégories des actualités',
          to: '/5467854/category-news',
        },
        {
          _tag: 'CSidebarNavItem',
          name: 'Types des actualités',
          to: '/5467854/type-news',
        },
      ],
    },
    {
      _tag: 'CSidebarNavTitle',
      _children: ['Annonces']
    },
 
    {
      _tag: 'CSidebarNavDropdown',
      name: 'Paramétrage',
      route: '/base',
      icon: 'cilSettings',
      _children: [
        {
          _tag: 'CSidebarNavItem',
          name: 'Gestion des équipements',
          to: '/5467854/setting/equipement',
        },
        {
          _tag: 'CSidebarNavItem',
          name: 'Gestion des équipements immobiliers',
          to: '/5467854/setting/equipement/immobiliers',
        },
    
      ],
    },
 
    {
      _tag: 'CSidebarNavItem',
      name: 'Offres d\'emploi',
      to: '/5467854/jobs',
      icon: 'cil-user-follow',
    },
    {
      _tag: 'CSidebarNavDropdown',
      name: 'Gestion des Annonces',
      route: '/buttons',
      icon: 'cil-tags',
      _children: [
        {
          _tag: 'CSidebarNavItem',
          name: 'Véhicules',
          to: '/5467854/vehicles',
          icon: 'cil-tags',
        },
        {
          _tag: 'CSidebarNavItem',
          name: 'Immoblier',
          to: '/5467854/realEstates',
          icon: 'cil-tags',
        },
        {
          _tag: 'CSidebarNavItem',
          name: 'Bon Plans',
          to: '/5467854/bonPlans',
          icon: 'cil-tags',
        },
        {
          _tag: 'CSidebarNavItem',
          name: 'Animaux',
          to: '/5467854/animals',
          icon: 'cil-tags',
        },
        {
          _tag: 'CSidebarNavItem',
          name: 'Articles',
          to: '/5467854/articles',
          icon: 'cil-tags',
        },
        {
          _tag: 'CSidebarNavItem',
          name: 'Services',
          to: '/5467854/services',
          icon: 'cil-tags',
        },    

      ],
    },
    
  ]
}
 else if ( role=='ROLE_EDITOR_IN_CHIEF') {
  navigation =[

    {
      _tag: 'CSidebarNavTitle',
      _children: ['News']
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Information bar',
      to: '/5467854/info-bar',
      icon: 'cilNotes',
    },
  
    {
      _tag: 'CSidebarNavDropdown',
      name: 'Gestion des news',
      route: '/base',
      icon: 'cilNotes',
      _children: [
        {
          _tag: 'CSidebarNavItem',
          name: 'Gestion des actualités',
          to: '/5467854/news',
        },
        {
          _tag: 'CSidebarNavItem',
          name: 'Catégories des actualités',
          to: '/5467854/category-news',
        },
        {
          _tag: 'CSidebarNavItem',
          name: 'Types des actualités',
          to: '/5467854/type-news',
        },
      ],
    },

  ]
 }
 else if ( role=='ROLE_MANAGER') {
  navigation =[

    {
      _tag: 'CSidebarNavItem',
      name: 'Offres d\'emploi',
      to: '/5467854/jobs',
      icon: 'cil-user-follow',
    },
    {
      _tag: 'CSidebarNavDropdown',
      name: 'Gestion des Annonces',
      route: '/buttons',
      icon: 'cil-tags',
      _children: [
        {
          _tag: 'CSidebarNavItem',
          name: 'Véhicules',
          to: '/5467854/vehicles',
        },
        {
          _tag: 'CSidebarNavItem',
          name: 'Immoblier',
          to: '/5467854/realEstates',
        },
        {
          _tag: 'CSidebarNavItem',
          name: 'Bon Plans',
          to: '/5467854/bonPlans',
        },
        {
          _tag: 'CSidebarNavItem',
          name: 'Animaux',
          to: '/5467854/animals',
        },
        {
          _tag: 'CSidebarNavItem',
          name: 'Articles',
          to: '/5467854/articles',
        },
        {
          _tag: 'CSidebarNavItem',
          name: 'Services',
          to: '/5467854/services',
        },    

      ],
    },
    
  ]
 }
if( role=='ROLE_JOURNALIST'){
  navigation=[
    {
      _tag: 'CSidebarNavTitle',
      _children: ['News']
    },
    {
      _tag: 'CSidebarNavDropdown',
      name: 'Gestion des news',
      route: '/base',
      icon: 'cilNotes',
      _children: [
        {
          _tag: 'CSidebarNavItem',
          name: 'Gestion des actualités',
          to: '/5467854/news',
        },
      ],
    }
    
  ]
}
  return (
    <CSidebar
      show={show}
      onShowChange={(val) => dispatch({type: 'set', sidebarShow: val })}
    >
      <CSidebarBrand className="d-md-down-none" to="/">
      <img style={{height:"70px"}} src="assets/images/logos/logo-tunisia-living-light.svg" alt="tunisia living logo"/>
        {/* <CIcon
          className="c-sidebar-brand-full"
          name="logo-negative"
          height={35}
        /> */}
        {/* <CIcon
          className="c-sidebar-brand-minimized"
          name="sygnet"
          height={35}
        /> */}
      </CSidebarBrand>
      <CSidebarNav>

        <CCreateElement
          items={navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none"/>
    </CSidebar>
  )
}

export default React.memo(TheSidebar)
