import React from "react";
import { IMAGES_BASE_URL } from "../../config";
//import NewsItem from "./news/NewsItem";

import ItemCard from "./itemCard";
import MemberCard from "./memberCard";

import { CCol } from "@coreui/react";
//import HtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
//import { element } from "prop-types";

function AllSearchResult  (props) { 
    //console.log('propsqsqsqsq : ');
    //console.log(props.location.state);
    //console.log('propsqsqsqsq term : ');
    //console.log(props.location.term);
    const arabic = /[\u0600-\u06FF]/;
    const styleArabic={textAlign:"right",direction:"rtl"};
    let annonceClass='';
    let newsClass='';
    if((props.location.state.goodPlans.length || props.location.state.members.length || props.location.state.articles.length
      || props.location.state.vehicles.length || props.location.state.realEstate.length
      || props.location.state.animals.length || props.location.state.services.length
      || props.location.state.jobs.length
      ) && props.location.state.news.length)
    {annonceClass= 'twoPartsResultPage1' ; newsClass= 'twoPartsResultPage2';}
    return (<>
          <div className="container">
        <main className="l-main searchResultPage">
<br/>
        <h6 className="c-heading c-heading--component c-headingLink__heading"> Recherche du '{props.location.term}'  {props.location.state&&' - '}
         {props.location.state?
           props.location.state.goodPlans.length + props.location.state.members.length + props.location.state.articles.length 
           + props.location.state.vehicles.length + props.location.state.realEstate.length
           + props.location.state.animals.length + props.location.state.services.length
           + props.location.state.jobs.length
           + props.location.state.news.length
          : 0
          } Résultats : </h6>
<br/>
        
        {(props.location.state && (props.location.state.goodPlans.length || props.location.state.members.length || props.location.state.articles.length 
          || props.location.state.vehicles.length || props.location.state.realEstate.length
          || props.location.state.animals.length || props.location.state.services.length
          || props.location.state.jobs.length
        ))?
         <section className={"annonceSection l-section--compact "+ annonceClass}>
         { props.location.state.members && props.location.state.members.length>0 && <>
         <h4 className="c-heading c-heading--component c-headingLink__heading">
              Vitrines - {props.location.state.members.length } Résultats
            </h4>
            <div className="row itemsRow membersRow">
            {props.location.state.members &&
                props.location.state.members.map((element) => {
                  return (
                    <CCol md="3" xs="6" key={element.id}>
                      {
                        <MemberCard
                        id = {element.id}
                          title={
                            element.prenom && element.prenom.length>0 &&
                             element.nom && element.nom.length>0 ? element.prenom + ' ' + element.nom    
                           : element.nom 
                          }
                          image={
                            element.path
                          ? `${IMAGES_BASE_URL}/public/uploads/members/${element.path}`
                          : `${IMAGES_BASE_URL}/public/uploads/members/default_avatar.jpg`
                      }
                          url={`/memberAnnonces/${element.prenom?element.prenom+'-'+element.nom+'-'+element.id :element.nom+'-'+element.id}`}
                          at={element.createdAt}
                        />
                      }
                    </CCol>
                  );
                })}
            </div> 
          
          </>}

         {props.location.state.goodPlans.length + props.location.state.articles.length 
           + props.location.state.vehicles.length + props.location.state.realEstate.length
           + props.location.state.animals.length + props.location.state.services.length
           + props.location.state.jobs.length >0 &&
          <>
          { props.location.state.goodPlans.length + props.location.state.articles.length 
           + props.location.state.vehicles.length + props.location.state.realEstate.length
           + props.location.state.animals.length + props.location.state.services.length
           + props.location.state.jobs.length >0 &&  props.location.state.members.length>0 &&
            <div className="separatorLine" ></div>}
            
        <h4 className="c-heading c-heading--component c-headingLink__heading">
              ANNONCES - {props.location.state.goodPlans.length + props.location.state.articles.length 
           + props.location.state.vehicles.length + props.location.state.realEstate.length
           + props.location.state.animals.length + props.location.state.services.length
           + props.location.state.jobs.length} Résultats
            </h4>
         <div className="row itemsRow ">  

           {props.location.state.jobs&& 
                props.location.state.jobs.map((jobOffer, index) => {
                return (
                  <CCol xs="6" md="3" key={index}>
                  <ItemCard
                  item={jobOffer}
                        id = {jobOffer.id}
                         table='JobOffer'
                          title= {jobOffer.title}
                          subtitle={
                          jobOffer.createdBy.accountType.id==2 ? jobOffer.createdBy.nom : 
                          jobOffer.company?jobOffer.company.name:
                          jobOffer.createdBy.company ?
                          jobOffer.createdBy.company.name
                          :'job company'
                        }
                          image={
                              jobOffer.createdBy.accountType.id==2 ? `${IMAGES_BASE_URL}/public/uploads/members/${jobOffer.createdBy.path}`                  
                                  : jobOffer.company? `${IMAGES_BASE_URL}/public/uploads/companies/${jobOffer.company.path}`
                                    : jobOffer.createdBy.company.path
                                    ? `${IMAGES_BASE_URL}/public/uploads/companies/${jobOffer.createdBy.company.path}`
                                    : "https://www.keejob.com/static/recruiter/images/default-logo.jpg"
                                }
                                alt={
                          jobOffer.createdBy.accountType.id==2 ?   jobOffer.createdBy.nom :     
                          jobOffer.company?jobOffer.company.name:
                          jobOffer.createdBy.company ?
                          jobOffer.createdBy.company.name
                          :'job company'
                        }     
                          url={`/jobs/job-details/${jobOffer.id}/${jobOffer.sector.slug}/${jobOffer.slug}`}
                   
                        />
                  

              </CCol>
                );
              })}

              {props.location.state.vehicles &&
                  props.location.state.vehicles.map((element) => {
                    return (
                    <CCol md="3" xs="6" key={element.id}>
                    <ItemCard
                        item={element}
                        id = {element.id}
                         table='Vehicle'
                          title={element.brand &&  element.model && element.model.label ? 
                            element.brand.label +' '+ element.model.label 
                            : element.brand &&  element.model ? 
                            element.brand.label +' '+ element.model
                            :element.brand &&  element.category ? 
                            element.brand.label +' '+ element.category.label 
                            :''
                          }
                          price={element.price}
                          city= {element.city && element.city.label}
                          image={
                            element.mainPicture
                              ? `${IMAGES_BASE_URL}/public/uploads/images/${element.type}/${element.mainPicture.name}`
                              : `assets/icons/${element.type}.svg`
                          }
                          url={`/vehicles/${element.type}/details/${element.id}`}
                          at={element.createdAt}
                        />
                    </CCol>
                  );
                })}

                {props.location.state.realEstate &&
                  props.location.state.realEstate.map((element) => {
                  return (
                    <CCol md="3" xs="6" key={element.id}>
                    <ItemCard
                    item={element}
                       id = {element.id}
                         table='RealEstate'
                          title={element.title}
                          price={element.price}
                          city= {element.city && element.city.label}
                          image={
                            element.mainPicture
                              ? `${IMAGES_BASE_URL}/public/uploads/images/${element.type}/${element.mainPicture.name}`
                              : `assets/icons/${element.type}.svg`
                          }
                          url={`/real-estates/details/${element.id}`}
                          at={element.createdAt}
                        />
                    </CCol>
                  );
                })}

              {props.location.state.goodPlans &&
                props.location.state.goodPlans.map((element) => {
                  return (
                    <CCol md="3" xs="6" key={element.id}>
                      {
                        <ItemCard
                        item={element}
                        id = {element.id}
                         table='GoodPlans'
                          title={
                            element.nom && element.nom.length > 0
                              ? element.nom
                              : element.typeGoodPlans.label
                          }
                          price={element.tickets 
                             ? element.tickets
                             : element.price 
                          }
                          subtitle={element.categoryGoodPlans.label}
                          city= {element.city && element.city.label}
                          image={
                            element.mainPicture
                              ? `${IMAGES_BASE_URL}/public/uploads/images/goodPlans/${element.mainPicture.name}`
                              : "assets/icons/loisir.svg"
                          }
                          url={`/bon-plans/details/${element.id}`}
                          at={element.createdAt}
                        />
                      }
                    </CCol>
                  );
                })}

                {props.location.state.animals &&
                  props.location.state.animals.map((element) => {
                  return (
                    <CCol md="3" xs="6" key={element.id}>
                      {
                        <ItemCard
                        item={element}
                        id = {element.id}
                         table='Animal'
                          title={element.title}
                          subtitle={element.typeAnimal && element.typeAnimal.label}
                          city= {element.city && element.city.label}
                          price={element.price ? element.price : ""}
                          image={
                            element.mainPicture
                              ? `${IMAGES_BASE_URL}/public/uploads/images/animal/${element.mainPicture.name}`
                              : "assets/icons/chien-blanc.svg"
                          }
                          url={`/animals/details/${element.id}`}
                          at={element.createdAt}
                        />
                      }
                    </CCol>
                  );
                })}

                {props.location.state.articles &&
                  props.location.state.articles.map((element) => {
                  return (
                    <CCol md="3" xs="6" key={element.id}>
                      {
                        <ItemCard
                        item={element}
                        id = {element.id}
                         table='Articles'
                          title={
                            element.nom && element.nom.length>0 ? element.nom
                              : element.categoryArticle ? element.categoryArticle.label
                              : ""
                          }
                          price={element.price}
                          subtitle={
                            element.typeArticle
                              ? element.typeArticle.label
                              : ""
                          }
                          city= {element.city && element.city.label}
                          image={
                            element.mainPicture && element.typeArticle
                              ? `${IMAGES_BASE_URL}/public/uploads/images/${element.typeArticle.section}/${element.mainPicture.name}`
                              : "assets/icons/icone-electromenager.svg"
                          }
                          url={`/articles/details/${element.id}`}
                          at={element.createdAt}
                        />
                      }
                    </CCol>
                  );
                })}

                {props.location.state.services &&
                  props.location.state.services.map((element) => {
                  return (
                    <CCol md="3" xs="6" key={element.id}>
                      {
                        <ItemCard
                        item={element}
                        id = {element.id}
                         table='Service'
                          title={element.title}
                          price={element.price}
                          subtitle={element.typeService.label}
                          city= {element.city && element.city.label}
                          image={
                            element.mainPicture
                              ? `${IMAGES_BASE_URL}/public/uploads/images/service/${element.mainPicture.name}`
                              : "assets/icons/house.svg"
                          }
                          url={`/services/details/${element.id}`}
                          at={element.createdAt}
                        />
                      }
                    </CCol>
                  );
                })}

             

            </div> 
        </>}
       </section> : '' } 

       {props.location.state && props.location.state.news.length ?
         <section className={"annonceSection l-section--compact "+ newsClass}>
        <h4 className="c-heading c-heading--component c-headingLink__heading">
              NEWS - {props.location.state.news.length} Résultats
            </h4>
         <div className="itemsRow ">  
        
         <ul
                    className="newsListSearchPage"
                    data-ad-empty="true"
                  >
                    {props.location.state.news &&
                      props.location.state.news.map((news) => {
                      return (
                        <li className="c-posts__item" key={news.id} >
                        
                          <Link
                          to = {{ pathname:`/news/${news.id}-${news.category.id}/${news.category.slug}/${news.slug}`,
              state:news,}}
                            // to={
                            //   news.category &&
                            //   `/news/${news.id}-${news.category.id}/${news.category.slug}/${news.slug}`
                            // }
                            title={news.title}
                            className="c-posts__inner"
                          >
                            <div className="c-posts__rank"></div>
                            <div className="c-posts__media c-imageContainer c-imageContainer--fitWidth">
                              <img
                                src={`${IMAGES_BASE_URL}/public/uploads/actualites/${news.path}`}
                                data-ratio="0.67"
                                className="c-posts__thumbnail c-imageContainer__image"
                                alt=""
                              />
                            </div>
                            <div className="c-posts__details">
                              <div className="c-posts__headline "  style={arabic.test(news.title)?styleArabic:{}}>
                                <span
                                  className="c-posts__headlineText"
                                  data-title=""
                                >
                                  {news.title}
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                
            </div>         
       </section> :'' } 
       
       {!(props.location.state) && (
                <div className="loadingGif">
                aucun résultat trouvé !
                </div>
              )}
       </main></div>
       </>
    );
}

export default AllSearchResult;
