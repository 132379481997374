import React from "react";
import { Link } from "react-router-dom";

function JobItem(props) {
  return (
    <div className="col-sm-12 col-md-12">
     
       
        <div className="card-body similar-job">
          <div className="row">
          
            <div className="col-sm-3"><img src={props.logo} alt=""/></div>
          <div className="col-sm-9">
          <h5><Link to={props.url}  className="text-blue-primary">{props.title}</Link></h5>
          <h5  className="text-blue-secondary">{props.company}</h5>
        
           
            
          </div>
          </div>
          
        
      </div>
    </div>
  );
}

export default JobItem;
