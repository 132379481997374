/*export const API_BASE_URL = 'http://127.0.0.1:8000';
//export const IMAGES_BASE_URL= 'http://127.0.0.1/tunisia_living/tunisia_living_back'
export const IMAGES_BASE_URL= 'http://127.0.0.1:8000'

export const BASE_URL ='http://localhost:3000';*/

// export const API_BASE_URL = "http://localhost:8000";
// export const IMAGES_BASE_URL = "";
// export const BASE_URL = "http://127.0.0.1:8000";
export const temp_sub_url = "";

//export const API_BASE_URL = "https://tunisia-living.com/453678832";
//export const IMAGES_BASE_URL = "https://tunisia-living.com";
//export const BASE_URL = "https://tunisia-living.com/453678832";

export const API_BASE_URL = "https://tunisialiving.tn/453678832";
export const IMAGES_BASE_URL = "https://tunisialiving.tn";
export const BASE_URL = "https://tunisialiving.tn/453678832";

// export const API_BASE_URL = "http://localhost/tunisia_living/tunisia_living_back";
// export const IMAGES_BASE_URL = "https://tunisia-living.com";
// export const BASE_URL = "http://localhost/tunisia_living/tunisia_living_back";

//export const temp_sub_url = "/20222508";

export const cat_news = [
  { "id": "1", "label": "économie", "slug": "economie", "type_news_id": "1" },
  { "id": "2", "label": "Sport", "slug": "sport", "type_news_id": "1" },
  { "id": "3", "label": "Santé", "slug": "sante", "type_news_id": "1" },
  { "id": "4", "label": "Social", "slug": "social", "type_news_id": "1" },
  { "id": "5", "label": "Agroalimentaire", "slug": "agroalimentaire", "type_news_id": "2" },
  { "id": "6", "label": "Consommation", "slug": "consommation", "type_news_id": "2" },
  { "id": "7", "label": "Environment", "slug": "environment", "type_news_id": "2" },
  { "id": "8", "label": "Culture", "slug": "culture", "type_news_id": "2" },
  { "id": "9", "label": "Logement et transport", "slug": "logement-et-transport", "type_news_id": "2" },
  { "id": "10", "label": "Fake news", "slug": "fake-news", "type_news_id": "2" },
  { "id": "12", "label": "space", "slug": "space", "type_news_id": "1" },
  { "id": "13", "label": "Technolgie", "slug": "technolgie", "type_news_id": "1" },
  { "id": "14", "label": "Education", "slug": "education", "type_news_id": "2" }
]

export const articleSection = [
  { id: 1, label: "Animalerie" },
  { id: 2, label: "Informatique" },
  { id: 3, label: "Smart Home" },
  { id: 4, label: "Maison et déco" },
  { id: 5, label: "Sport et Fitness" },
  { id: 6, label: "Auto et Accessoires" },
  { id: 7, label: "Vêtements" },
  { id: 8, label: "AudioVisuel" },
  { id: 9, label: "Divers" }
];

export const divers = [
  { id: 1, label: "Jouets" },
  { id: 2, label: "Bois" },
  { id: 3, label: "Acier" },
  { id: 4, label: "Cuivre" },
  { id: 5, label: "Plastique" },
  { id: 6, label: "Verreries" },
  { id: 7, label: "Antiquités" },
  { id: 8, label: "Collection" },
  { id: 9, label: "Plantes" },
  { id: 10, label: "Liquide" },
  { id: 11, label: "Autres" },
];

export const types = [
  { id: 7, label: "Camera" },
  { id: 1, label: "Appareils photo" },
  { id: 2, label: "Éclairage" },
  { id: 3, label: "Objectifs" },
  { id: 4, label: "Accessoires vidéo" },
  { id: 5, label: "Accessoires photo" },
  { id: 6, label: "Machinerie" },
];

export const realEstateEquipementsTypes = [
  { id: 1, label: "Internet et bureau" },
  { id: 2, label: "Linge" },
  { id: 3, label: "Chambre" },
  { id: 4, label: "Cuisine" },
  { id: 5, label: "Extérieur" },
  { id: 6, label: "Installation" },
];

export const realEstateEquipementsAll = [
  { "id": "1", "type_id": "1", "label": "Wifi", "type_annonce_value": "2", "type_annonce": "annonce de location et de vacances" },
  { "id": "2", "type_id": "1", "label": "TV", "type_annonce_value": "2", "type_annonce": "annonce de location et de vacances" },
  { "id": "3", "type_id": "2", "label": "Lave-linge", "type_annonce_value": "2", "type_annonce": "annonce de location et de vacances" },
  { "id": "4", "type_id": "2", "label": "Placard", "type_annonce_value": "2", "type_annonce": "annonce de location et de vacances" },
  { "id": "5", "type_id": "2", "label": "Étendoir à linge", "type_annonce_value": "2", "type_annonce": "annonce de location et de vacances" },
  { "id": "6", "type_id": "3", "label": "Placard", "type_annonce_value": "2", "type_annonce": "annonce de location et de vacances" },
  { "id": "7", "type_id": "3", "label": "Cintres", "type_annonce_value": "2", "type_annonce": "annonce de location et de vacances" },
  { "id": "8", "type_id": "3", "label": "Draps\/couvertures supplémentaires", "type_annonce_value": "2", "type_annonce": "annonce de location et de vacances" },
  { "id": "9", "type_id": "3", "label": "Oreillers", "type_annonce_value": "2", "type_annonce": "annonce de location et de vacances" },
  { "id": "10", "type_id": "4", "label": "Réfrigérateur", "type_annonce_value": "2", "type_annonce": "annonce de location et de vacances" },
  { "id": "11", "type_id": "4", "label": "Équipements de cuisine de base", "type_annonce_value": "2", "type_annonce": "annonce de location et de vacances" },
  { "id": "12", "type_id": "4", "label": "Vaisselle et couverts", "type_annonce_value": "2", "type_annonce": "annonce de location et de vacances" },
  { "id": "13", "type_id": "4", "label": "Congélateur", "type_annonce_value": "2", "type_annonce": "annonce de location et de vacances" },
  { "id": "14", "type_id": "4", "label": "Four", "type_annonce_value": "2", "type_annonce": "annonce de location et de vacances" },
  { "id": "15", "type_id": "4", "label": "Cafetière", "type_annonce_value": "2", "type_annonce": "annonce de location et de vacances" },
  { "id": "16", "type_id": "4", "label": "Blender", "type_annonce_value": "2", "type_annonce": "annonce de location et de vacances" },
  { "id": "17", "type_id": "4", "label": "Table à manger", "type_annonce_value": "2", "type_annonce": "annonce de location et de vacances" },
  { "id": "18", "type_id": "5", "label": "Mobilier d'extérieur", "type_annonce_value": "2", "type_annonce": "annonce de location et de vacances" },
  { "id": "19", "type_id": "5", "label": "Barbecue", "type_annonce_value": "2", "type_annonce": "annonce de location et de vacances" },
  { "id": "20", "type_id": "6", "label": "Chauffage", "type_annonce_value": "3", "type_annonce": "annonce de location et de vente" },
  { "id": "21", "type_id": "6", "label": "Climatisation", "type_annonce_value": "3", "type_annonce": "annonce de location et de vente" },
  { "id": "22", "type_id": "6", "label": "Détecteur de fumée", "type_annonce_value": "3", "type_annonce": "annonce de location et de vente" },
  { "id": "23", "type_id": "6", "label": "Détecteur de monoxyde de carbone", "type_annonce_value": "3", "type_annonce": "annonce de location et de vente" }
]

export const houseTypes = [
  { value: 'villa', label: 'Villa' },
  { value: 'etage_de_villa', label: 'Étage de villa' },
  { value: 'bungalow', label: 'bungalow' },
  { value: 'maison', label: 'maison' },
  { value: 'studio_de_maison', label: 'studio_de_maison' },
];

export const apartmentTypes = [
  { value: 'appartement', label: 'Appartement' },
  { value: 'chambre', label: 'chambre' },
  { value: 'studio', label: 'studio' },
  { value: 'duplex', label: 'duplex' },
];

export const new_real_estateTypes = [
  { value: 'lotissement', label: 'lotissement' },
  { value: 'résidence', label: 'résidence' },
];

export const localTypes = [
  { value: 'magasin', label: 'magasin' },
  { value: 'restaurant', label: 'restaurant' },
  { value: 'hotel', label: 'hotel' },
  { value: 'zone de stockage', label: 'zone de stockage' },
  { value: 'autres', label: 'autres' },
];

export const groundTypes = [
  { value: 'terrain à bâtir', label: 'terrain à bâtir' },
  { value: 'terrain agricole', label: 'terrain agricole' },
];

export const minsurfaceconst = 5;
export const maxsurfaceconst = 50000;
export const minpriceconst = 1;
export const maxpriceconst = 300000;
export const minmileconst = 10;
export const maxmileconst = 300000;

const expirationDate = new Date();
expirationDate.setTime(expirationDate.getTime() + (73 * 60 * 60 * 1000)); // 70 hours in milliseconds

export const formattedExpiration = expirationDate.toUTCString();

//export const tokenCookie = document.cookie.match(new RegExp('(^| )' + 'token' + '=([^;]+)'))[2];

export const tokenCookie = (() => {
  const match = document.cookie.match(new RegExp('(^| )' + 'token' + '=([^;]+)'));
  return match ? match[2] : null;
})();

export const tokenU = (() => {
  const match = document.cookie.match(new RegExp('(^| )' + 'tokenU' + '=([^;]+)'));
  return match ? match[2] : null;
})();

export const key = 'ysuehi698663gdsvh9s60b64647jygkshes654hvjvs44vhhdd753hxdjd8hhddjs687';

export const keyHeader = {
  headers: {
    Authorization: `Bearer ${key}`,
  }
};
export const sizeAlert = 'La taille du fichier ne doit pas dépasser 5 MB'

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function isTunisliveDomain() {
  return window.location.hostname === 'tunisialiving.tn';
}