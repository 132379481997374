import React, { useState ,useEffect , useContext} from "react";
import { CCol, CRow, CListGroupItem, CListGroup , CSpinner} from "@coreui/react";
import { IMAGES_BASE_URL} from "../../../config";
import {
  Route,
  Switch,
  BrowserRouter,
  Link,
  HashRouter,
  NavLink
} from "react-router-dom";
import MemberProvider from "./MemberProvider";
import MyJobs from "./MyJobs";
import MyApps from "./MyApps";
import MyProfile from "./MyProfile";
import Favorite from "./Favorite";
import MyVehicles from "./MyVehicles";
import Candidats from "./Candidats";
import Candidature from "./Candidature";
import EditVehicle from "../vehicles/editVehicle";
import MyRealEstate from "./MyRealEstate";
import EditRealEstate from "../realEstate/editRealEstate";
import MyArticles from "./MyArticles";
import EditArticle from "../OtherAnnonce/articles/editArticles";
import MyAnimals from "./MyAnimals";
import MyServices from "./MyServices";
import EditAnimal from "../OtherAnnonce/animals/editAnimal";
import EditService from "../OtherAnnonce/services/editService";
import MyGoodPlans from "./MyBonPlans";
import EditBonPlans from "../bonPlans/editBonPlans";
import EditJob from "../jobs/editJob";
import MyItemDetails from "./MyItemDetails";
import { logout } from "src/components/middleware/auth";
import MemberContext from "./MemberContext";
import MyInterest from "./MyInterest";
import Support from "../Support";
import MyProfileMain from "./MyProfileMain";

function Profile(props) {
  const [showSub, setshowSub] = useState(false);
  const [showMenu, setshowMenu] = useState(false);

  const {loadedAnimals, loadedArticles, loadedEstates,loadedPlans, loadedServices,loadedVehicles,loadedJobs,
     animals , articles , services , estates , vehicles , plans ,jobs , failed} = useContext(MemberContext)


     useEffect(() =>   {

      if(failed)    { 
        window.location.href = "/members/login";
      }
    }  , [failed]);

    useEffect(() =>   {

// console.log('pppp' , props.location.pathname)
// console.log(window ,window.innerWidth);
const section = document.querySelector( '#profileSectionContentID' );
if(section) 
{
 if( window.innerWidth < 768 ) {
  const offset = section.getBoundingClientRect().top - 30;
  window.scrollBy({ top: offset, behavior: 'smooth' });
 // console.log(section.getBoundingClientRect().top , 'in 1');
} 
// else {
//   const offset = section.getBoundingClientRect().top ;
//   window.scrollBy({ top: offset, behavior: 'smooth' });
//   console.log(section.getBoundingClientRect().top , 'in 2');
// }

}

    }  , [props.location.pathname]);

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="container">
      <main className="l-main">
        <div className="l-section l-section--archive">
          {/* <HashRouter> */}
          {/* <BrowserRouter> */}
            <React.Suspense>  
                
              <CRow className="profilePageAll">
                <CCol sm="3" className="profileSideBar">
                  <div className="c-avatar profile-avatar ">
                    <img
                      className="c-avatar-img"
                      src={
                        localStorage.getItem("avatar") !== "undefined"
                          ? `${IMAGES_BASE_URL}/public/uploads/members/${localStorage.getItem(
                              "avatar"
                            )}`
                          : `${IMAGES_BASE_URL}/public/uploads/members/default_avatar.jpg`
                      }
                      alt="avatar"
                    />
                  </div>
        
                <p className="profileSideBarName">    {localStorage.getItem("prenom")&&localStorage.getItem("name") ? localStorage.getItem("prenom")+' ' +localStorage.getItem("name") :
        localStorage.getItem("name") ? localStorage.getItem("name")
        :''}</p>
                  <CListGroup >
        
                      <NavLink to="/members/profile/my-info" className="mainTabWithSub" activeClassName="profileSideBarSelected" 
                      onClick={() =>{setshowSub(false); setshowMenu(false)}}>Mon profil</NavLink>      
                      
                       {localStorage.getItem("typeCompte") == 2  && <NavLink  
                       onClick={() =>{setshowSub(false); setshowMenu(false)}} to="/members/profile/jobs" className="mainTabWithSub" activeClassName="profileSideBarSelected">
                          Offres d'emploi {!loadedJobs ? 
                  <span className="annoncesCount">   <CSpinner color="default" size="sm" /></span>                
                  :<span className="annoncesCount">{jobs.length}</span>  }
                        </NavLink>}
                  
                        {localStorage.getItem("typeCompte") == 1 && <NavLink  
                        onClick={() =>{setshowSub(false); setshowMenu(false)}} to="/members/profile/my-applications" className="mainTabWithSub" activeClassName="profileSideBarSelected">
                        Mes candidatures
                        </NavLink>}

                    <CListGroupItem>
                      <p
                        onClick={() => setshowSub(!showSub)}
                        className="mainTabWithSub"
                      >
                        Historique des annonces <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          width="10px"
                        >
                          <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
                        </svg>
                      </p>
                    </CListGroupItem>
                    {showSub && (
                      <>
                          <NavLink  onClick={() => {setshowMenu(false);}} 
                          to={{ pathname:"/members/profile/vehicules",
                         //state:vehicles
                         }}
                          className="subTab" activeClassName="profileSideBarSelected">
                          Véhicules {!loadedVehicles ? 
                  <span className="annoncesCount">   <CSpinner color="default" size="sm" /></span>                
                  :<span className="annoncesCount">{vehicles.length}</span>  } </NavLink>
                      
                          <NavLink  onClick={() => {setshowMenu(false);}} 
                          to={{ pathname:"/members/profile/immobilier" ,
                        // state:estates
                         }}
                          className="subTab" activeClassName="profileSideBarSelected">
                            Immobilier {!loadedEstates ? 
                              <span className="annoncesCount">   <CSpinner color="default" size="sm" /></span>                
          
                  :<span className="annoncesCount">{estates.length}</span>  }
                          </NavLink>

                          <NavLink  onClick={() => {setshowMenu(false);}}
                           to={{ pathname:"/members/profile/articles",
                         //state:articles
                         }}
                           className="subTab" activeClassName="profileSideBarSelected">
                          Articles {!loadedArticles ? 
                            <span className="annoncesCount">   <CSpinner color="default" size="sm" /></span>                

                  :<span className="annoncesCount">{articles.length}</span>  }</NavLink>

                          <NavLink  onClick={() => {setshowMenu(false);}} 
                          to={{ pathname:`/members/profile/animaux`,
                        // state:animals
                         }}
                           className="subTab" activeClassName="profileSideBarSelected">
                          Animaux {!loadedAnimals ? 
                            <span className="annoncesCount">   <CSpinner color="default" size="sm" /></span>                

                  :<span className="annoncesCount">{animals.length}</span>  }
                  </NavLink>
                       
                          <NavLink  onClick={() => {setshowMenu(false);}} 
                          to={{ pathname:"/members/profile/services" ,
                         //state:services
                         }}
                          className="subTab" activeClassName="profileSideBarSelected">
                          Services {!loadedServices ? 
                            <span className="annoncesCount">   <CSpinner color="default" size="sm" /></span>                

                  :<span className="annoncesCount">{services.length}</span>  }</NavLink>

                          <NavLink  onClick={() => {setshowMenu(false);}}
                          to={{ pathname:"/members/profile/bon-plans",
                         //state:plans
                         }}
                          className="subTab" activeClassName="profileSideBarSelected">
                          Bon plans {!loadedPlans ? 
                            <span className="annoncesCount">   <CSpinner color="default" size="sm" /></span>                

                  :<span className="annoncesCount">{plans.length}</span>  }</NavLink>
                 
                      </>
                    )}
                 <div className="mobProfileSetting" onClick={() => setshowMenu(!showMenu)}>Suite <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg></div>
               <div className={showMenu?'showProfileDropDown':'hideProfileDropDown'}>
                                        
                    <NavLink  onClick={() => {setshowSub(false);}} to="/members/profile/interest" className="mainTabWithSub" activeClassName="profileSideBarSelected">Centre d'intérêt</NavLink>
                    <NavLink  onClick={() => {setshowSub(false);}} to="/members/profile/support" className="mainTabWithSub" activeClassName="profileSideBarSelected">Support</NavLink>
                    <NavLink  onClick={() => {setshowSub(false);}} to="/members/profile/mes-favoris" className="mainTabWithSub" activeClassName="profileSideBarSelected">Mes Favoris</NavLink>
                  
                    <CListGroupItem className='profileSideBarLogout'>
                      <Link onClick={() => handleLogout()}
                      to="/" >
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg> Déconnexion</Link>
                    </CListGroupItem>
                    </div>
                  </CListGroup>
                </CCol>
                <CCol sm="9" style={{paddingRight:'5px'}} id="profileSectionContentID">
         
                  <Switch>
                    <Route path="/members/profile/jobs" component={MyJobs} />
                    <Route path="/members/profile/my-applications" component={MyApps} />
                    <Route
                      path="/members/profile/my-info"
                      component={MyProfile}
                    />
                    <Route path="/members/profile/interest" 
                    component={MyInterest}                       
                    />  
                    <Route path="/members/profile/support" 
                    component={Support}                       
                    />  
                    <Route path="/members/profile/mes-favoris" 
                    component={Favorite}                       
                    />
                    <Route
                      path="/members/profile/vehicules"
                      component={MyVehicles}
                    />
                      <Route
                      path="/members/profile/myItemDetails/:type"
                      component={MyItemDetails}
                    />
                    {/**<Route path="/members/profile/immobilier" component={MyProperties} /> */}
                    <Route
                      path="/members/profile/jobs-candidats/:id"
                      component={Candidats}
                    />
                    <Route
                      path="/members/profile/detail-candidature/jobs/:id"
                      component={Candidature}
                    />

                    <Route
                      path="/members/profile/vehicules-edit"
                      component={EditVehicle}
                    />
                    <Route
                      path="/members/profile/immobilier-edit"
                      component={EditRealEstate}
                    />
                    <Route
                      path="/members/profile/article-edit"
                      component={EditArticle}
                    />

                    <Route
                      path="/members/profile/immobilier"
                      component={MyRealEstate}
                    />
                    <Route
                      path="/members/profile/articles"
                      component={MyArticles}
                    />
                    <Route
                      path="/members/profile/animaux"
                      component={MyAnimals}
                    />
                    <Route
                      path="/members/profile/animal-edit"
                      component={EditAnimal}
                    />

                    <Route
                      path="/members/profile/services"
                      component={MyServices}
                    />
                    <Route
                      path="/members/profile/service-edit"
                      component={EditService}
                    />

                    <Route
                      path="/members/profile/bon-plans"
                      component={MyGoodPlans}
                    />
                    <Route
                      path="/members/profile/bon-plans-edit"
                      component={EditBonPlans}
                    /> 
                    <Route
                      path="/members/profile/jobs-edit"
                      component={EditJob}
                    />
                  <Route component={MyProfileMain} />

                  </Switch>
          
                </CCol>
              </CRow>   
             
            </React.Suspense>
          {/* </BrowserRouter> */}
          {/* </HashRouter> */}
        </div>
      </main>
    </div>
  );
}

export default Profile;
