import React from 'react'
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

const TheHeaderDropdownMssg = () => {
  const itemsCount = 4
  return (
    <></>
    // <CDropdown
    //   inNav
    //   className="c-header-nav-item mx-2"
    //   direction="down"
    // >
    //   <CDropdownToggle className="c-header-nav-link" caret={false}>
    //     <CIcon name="cil-envelope-open" /><CBadge shape="pill" color="info">{itemsCount}</CBadge>
    //   </CDropdownToggle>
    //   <CDropdownMenu className="pt-0" placement="bottom-end">
    //     <CDropdownItem
    //       header
    //       tag="div"
    //       color="light"
    //     >
    //       <strong>You have {itemsCount} messages</strong>
    //     </CDropdownItem>
    //     <CDropdownItem href="#">
    //       <div className="message">
    //         <div className="pt-3 mr-3 float-left">
    //           <div className="c-avatar">
    //             <CImg
    //               src={'avatars/7.jpg'}
    //               className="c-avatar-img"
    //               alt="admin@bootstrapmaster.com"
    //             />
    //             <span className="c-avatar-status bg-success"></span>
    //           </div>
    //         </div>
    //         <div>
    //           <small className="text-muted">John Doe</small>
    //           <small className="text-muted float-right mt-1">Just now</small>
    //         </div>
    //         <div className="text-truncate font-weight-bold">
    //           <span className="fa fa-exclamation text-danger"></span> Important message
    //         </div>
    //         <div className="small text-muted text-truncate">
    //           Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...
    //         </div>
    //       </div>
    //     </CDropdownItem>

    //     <CDropdownItem href="#">
    //       <div className="message">
    //         <div className="pt-3 mr-3 float-left">
    //           <div className="c-avatar">
    //             <CImg
    //               src={'avatars/6.jpg'}
    //               className="c-avatar-img"
    //               alt="admin@bootstrapmaster.com"
    //             />
    //             <span className="c-avatar-status bg-warning"></span>
    //           </div>
    //         </div>
    //         <div>
    //           <small className="text-muted">Jane Dovve</small>
    //           <small className="text-muted float-right mt-1">5 minutes ago</small>
    //         </div>
    //         <div className="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
    //         <div className="small text-muted text-truncate">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...
    //         </div>
    //       </div>
    //     </CDropdownItem>

    //     <CDropdownItem href="#">
    //       <div className="message">
    //         <div className="pt-3 mr-3 float-left">
    //           <div className="c-avatar">
    //             <CImg
    //               src={'avatars/5.jpg'}
    //               className="c-avatar-img"
    //               alt="admin@bootstrapmaster.com"
    //             />
    //             <span className="c-avatar-status bg-danger"></span>
    //           </div>
    //         </div>
    //         <div>
    //           <small className="text-muted">Janet Doe</small>
    //           <small className="text-muted float-right mt-1">1:52 PM</small>
    //         </div>
    //         <div className="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
    //         <div className="small text-muted text-truncate">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...
    //         </div>
    //       </div>
    //     </CDropdownItem>

    //     <CDropdownItem href="#">
    //       <div className="message">
    //         <div className="pt-3 mr-3 float-left">
    //           <div className="c-avatar">
    //             <CImg
    //               src={'avatars/4.jpg'}
    //               className="c-avatar-img"
    //               alt="admin@bootstrapmaster.com"
    //             />
    //             <span className="c-avatar-status bg-info"></span>
    //           </div>
    //         </div>
    //         <div>
    //           <small className="text-muted">Joe Doe</small>
    //           <small className="text-muted float-right mt-1">4:03 AM</small>
    //         </div>
    //         <div className="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
    //         <div className="small text-muted text-truncate">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...
    //         </div>
    //       </div>
    //     </CDropdownItem>
    //     <CDropdownItem href="#" className="text-center border-top"><strong>View all messages</strong></CDropdownItem>
    //   </CDropdownMenu>
    // </CDropdown>
  )
}

export default TheHeaderDropdownMssg