import React from "react";
export default function Loading() {
    return (
        <div className="container">
          <main className="l-main lmain2">
            <div className="l-section l-section--archive">
              <div className="loading_logo">
                <img
                  src={"assets/icons/tunisia-living-loading-icon.gif"}
                  alt="tunisia living loading icon"
                />
                <p>Chargement...</p>
              </div>
            </div>
          </main>
        </div>
      );
}