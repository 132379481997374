import React, { useEffect, useState } from "react";
import {IMAGES_BASE_URL , API_BASE_URL , keyHeader , houseTypes , apartmentTypes , new_real_estateTypes ,localTypes , groundTypes} from "../../../config";
import axios from "axios";
import Loading from "src/components/myWidgets/loadingWidget";
import {
  CCol,
  CDataTable,
  CLabel,
  CSelect,
  CInput
} from "@coreui/react";
import { useLocation , useHistory } from "react-router-dom";
import Breadcrumb from "../Breadcrumb";
import RealEstateItem from "./RealEstateItem";

export default function ResultRealEstate() {
  const location = useLocation();
  const history = useHistory();
  
  const [isLoaded, setIsLoaded] = useState(false);
  const [section, setSection] = useState('');
  const [types, setTypes] = useState([]);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [typeAnnonce, setTypeAnnonce] = useState("");
  const [type, setType] = useState("");
  const [minSurface, setMinSurface] = useState("");
  const [maxSurface, setMaxSurface] = useState("");
  const [delegate, setDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  const [locality, setLocality] = useState("");
  const [localities, setLocalities] = useState([]);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [isLoadedALL , setisLoadedALL] = useState(false);
  const [all, setAll] = useState([]);
  const [errorAll,setErrorALL]= useState(false);
  const [results , setresults] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [filtersCount, setFiltersCount] = useState(0);
  const [sortBy, setSortBy] = useState('dateD');

  const toggleVisibility = () => {
    //console.log('rdyjgkhlkmgdeefglmdesghg');
    setIsVisible(prev => !prev);
  };

  const searchParams = new URLSearchParams(location.search);
  const fields = [{ key: "title", _style: { width: "100%" } }];
  const sections = [
  {value:'house',label:'Maison'},
  {value:'apartment',label:'appartement'},
  {value:'new_real_estate',label:'immoblier'},
  {value:'garage',label:'garage'},
  {value:'office',label:'office'},
  {value:'commercial_space',label:'local'},
  {value:'ground',label:'Terrain'},
]

  useEffect(() => {

    //console.log('paramMMM:', location.search );
    //console.log('paramMMMxxx:', searchParams );

  if (location.search)
    {       
    setType(searchParams.get('type'));
    setTypeAnnonce(searchParams.get('typeAnnonce'));
    setSection(searchParams.get('section'));
    setMaxPrice(searchParams.get('maxPrice'));
    setMinPrice(searchParams.get('minPrice'));
    setMinSurface(searchParams.get('minSurface'));
    setMaxSurface(searchParams.get('maxSurface'));
    setCountry(searchParams.get('country'));
    setCity(searchParams.get('city'));
    setDelegate(searchParams.get('delegate'));

    setFiltersCount(searchParams.size);
    
  }  

  }, [location.search]);

  useEffect(() => {

        if (!localStorage.getItem("countries")) {
          axios(`${API_BASE_URL}/countries/all`)
            .then((res) => res.data)
            .then(
              (result) => {
                // console.log("countries", result);
                setCountries(result);
               // setIsLoaded2(true);
               //if(location.state && location.state.all.cities) setCities(location.state.all.cities);
               //else 
               if (result && searchParams.get('country')) {
                var countriesF=[]
                countriesF = result.find((el) => el.id.toString() == searchParams.get('country'));
                setCities(countriesF.cities);
                //console.log('countriesF',countriesF);
              }
                localStorage.setItem("countries", JSON.stringify(result));
              },
              (error) => {}
            );
        } else {
          setCountries(JSON.parse(localStorage.getItem("countries")));
          //if(location.state && location.state.all.cities) setCities(location.state.all.cities);
          //else 
          if (JSON.parse(localStorage.getItem("countries")) && searchParams.get('country')) {
           var countriesF=[]
           countriesF = JSON.parse(localStorage.getItem("countries")).find((el) => el.id.toString() == searchParams.get('country'));
          setCities(countriesF.cities);
          // console.log('countriesFfffffff',countriesF);
         }
        }
        
        if (location.state) {   
          //console.log('rtrtrttkkkkkkk : ',location.state.result);
           //console.log('all : ',location.state);
          setresults(location.state.result);
          setAll(location.state.all);  
          setisLoadedALL(true)
          setIsLoaded(true);
        } else {
          //console.log('no state : ',location.state);
          
          axios
          (API_BASE_URL + "/real_estate/all",keyHeader)
          .then((res) => res.data)
          .then(
            (result) =>{ 
            //console.log('all result' , result);
            setAll(result);
            setresults(result)    
            setisLoadedALL(true)
            setIsLoaded(true);
            //setdisable('');
          },
          (error) => {
            //console.log(error);
            setErrorALL(true)
          }
          );
        }   
        

    }, []);

    useEffect(() => {
      //typeApartment , typeHouse , typeNewRealEstate , typeGround , typeCommercialSpace
          if(isLoadedALL)   {
            var results = all.filter(function (el) {
      
            const sectionc = !section || (section  && el.type  && el.type == section);
         // const typec = !type || (type  && el.typeArticle && el.typeArticle.id && el.typeArticle.id == type);
         const typeC = !type ||
         (el.typeHouse && el.typeHouse == type) ||
         (el.typeApartment && el.typeApartment == type) ||
         (el.typeNewRealEstate && el.typeNewRealEstate == type) ||
         (el.typeGround && el.typeGround == type) ||
         (el.typeCommercialSpace && el.typeCommercialSpace == type);
      
        const typeAnnoncec = !typeAnnonce || (typeAnnonce  && el.typeAnnonce &&  el.typeAnnonce== typeAnnonce);
      const minSurfacec = !minSurface || el.surface >= minSurface;  
      const maxSurfacec = !maxSurface || el.surface <= maxSurface;   
        const minPricec = !minPrice || el.price >= minPrice;  
        const maxPricec = !maxPrice || el.price <= maxPrice; 
         //const countryc = !country || el.country.id == country;  
        const cityc = !city || el.city.id == city;  
        const delegatec = !delegate || el.delegate.id == delegate;  
    
              return sectionc && typeC && typeAnnoncec  && cityc && delegatec && minPricec && maxPricec && minSurfacec && maxSurfacec;
            });
        
            //console.log('rsddsdxxx ',results)
            setresults(results);
          }  
           
          }, [ isLoadedALL, section ,type, typeAnnonce  , city , delegate , minPrice, maxPrice , minSurface , maxSurface]);

          const clearAll = () => {
            handleClearSelection('type'); handleClearSelection('typeAnnonce');
            handleClearSelection('section');
            handleClearSelection('minPrice');handleClearSelection('maxPrice');
            handleClearSelection('country');handleClearSelection('city');handleClearSelection('delegate')
                }  
            
                const handleSortBy = (e) => {
                  //console.log('sortBy:',e.target.value);
                  setSortBy(e.target.value);
                  const sortedData = [...results].sort((a, b) => {
               
                    const titleA = a.title !== undefined && a.title !== null ? a.title : ''; // Treat null or undefined as empty string
                    const titleB = b.title !== undefined && b.title !== null ? b.title : ''; // Treat null or undefined as empty string
                
                    const convertToNumber = (value) => {
                      // Convert value to number if it's not null or undefined
                      return value !== null && value !== undefined ? parseFloat(value) || 0 : 0;
                    };
                
                    const priceA = convertToNumber(a.price);
                    const priceB = convertToNumber(b.price);
                
                    if ((priceA === null || priceA === 0) && (priceB === null || priceB === 0)) {
                      return 0; // Maintain original order if both prices are null or zero
                    }

                    if (e.target.value === 'nomC') {
                      if (!titleA && !titleB) return 0; // Both are null or empty, maintain current order
                      if (!titleA) return 1; // 'a' comes after 'b'
                      if (!titleB) return -1; // 'a' comes before 'b'
                      return titleA.localeCompare(titleB); // Compare non-null titles
                    } else if (e.target.value === 'nomD') {
                      if (!titleA && !titleB) return 0; // Both are null or empty, maintain current order
                      if (!titleA) return 1; // 'a' comes after 'b'
                      if (!titleB) return -1; // 'a' comes before 'b'
                      return titleB.localeCompare(titleA); // Compare non-null titles
                    }
                    
                    else    if (e.target.value === 'dateC') {
                      return a['createdAt'] > b['createdAt'] ? 1 : -1;
                    }
                    else    if (e.target.value === 'dateD') {
                      return a['createdAt'] < b['createdAt'] ? 1 : -1;
                    } else    if (e.target.value === 'surface_asc') {
                      return a['surface'] > b['surface'] ? 1 : -1;
                    }
                    else    if (e.target.value === 'surface_desc') {
                      return a['surface'] < b['surface'] ? 1 : -1;
                    }
                     else     if (e.target.value === 'price_asc') {
                      return priceA - priceB; // Ascending order by price
                    } else if (e.target.value === 'price_desc') {
                      return priceB - priceA; // Descending order by price
                    }
                  });
                  setresults(sortedData);
                 }   
          
  const handleSectionChange = (s) =>  {
            //console.log(s);
            setSection(s);
            switch (s) {
            case 'house':
                setTypes(houseTypes);
                break;
             case 'apartment':
                setTypes(apartmentTypes);
                break;
             case 'new_real_estate':
                  setTypes(new_real_estateTypes);
               break;
            case 'ground':
                  setTypes(groundTypes);
                break;
            case 'commercial_space':
                  setTypes(localTypes);
                break;    
            default:
                 setTypes([]); break;
              }
              setType(''); setTypeAnnonce('') ; 
              handleClearSelection('type'); handleClearSelection('typeAnnonce')
              //setMinPrice('') ; setMaxPrice('');setMinSurface(''); setMaxSurface(''); setCities([]) ; setCity(''); setCountry('');
            }
            
    
      const updateUrlParams = (e) => {
        // console.log('nnnnnvvvvthfgjukhjk',e.target.name)
         // Set the new value for 'mark' parameter
         searchParams.set(e.target.name, e.target.value);
     
         // Create a new URL with the updated search parameters
         const updatedURL = `${location.pathname}?${searchParams.toString()}`;
     
         // Replace the current URL with the updated one
         history.replace(updatedURL);
       };
   
       const deleteUrlParams = (p) => {
         // console.log('nnnnnvvvvthfgjukhjk',e.target.name)
          // Set the new value for 'mark' parameter
          searchParams.delete(p);
      
          // Create a new URL with the updated search parameters
          const updatedURL = `${location.pathname}?${searchParams.toString()}`;
          setFiltersCount(searchParams.size)
          // Replace the current URL with the updated one
          history.replace(updatedURL);
        };
   
     const handleCountryChange = (e) => {
       setCountry(e.target.value);
       let id = e.target.value;
       var countriesF = [];
       // console.log("iddd", id);
       if (id !== "0") {
         countriesF = countries.find((city) => city.id.toString() === id);
         setCities(countriesF.cities);
         setCity('');
         // console.log("countriesF", countriesF);
       } else {
         setCity('');
         //  setModel("");
       }
     };
   
     const handleCityChange = (e) => {
       setCity(e.target.value);
       let id = e.target.value;
       var citiesF = [];
       // console.log("iddd", id);
       if (id !== "0") {
         citiesF = cities.find((delegate) => delegate.id.toString() === id);
         setDelegates(citiesF.delegations);
         setDelegate('');
         //  console.log("citiesF", citiesF);
       } else {
         setDelegate('');
         //  setModel("");
       }
     };
   
     const handleClearSelection = (type) => {
       if(searchParams.get(type))  deleteUrlParams(type) 
       const setStateFunction = `set${type.charAt(0).toUpperCase()}${type.slice(1)}`;
       eval(`${setStateFunction}('')`);
       if(type=='country') { deleteUrlParams('city'); deleteUrlParams('delegate') ; 
         setCity('');setDelegate('') }
       if(type=='city') { deleteUrlParams('delegate') ; setDelegate('') }
       if(type=='section') { deleteUrlParams('type') ;deleteUrlParams('typeAnnonce'); setType(''); setTypeAnnonce('');
       }
     };
   

  if (!isLoaded) {
    return (
  <Loading/>
    );
  } else {
    return (
      <div className="container">
      <Breadcrumb/>
        <main className="l-main">
          <div className="l-main__article l-main__content l-main__section l-article ">
            <div className="row">
              <CCol md="3">             
                {/* { from == 'house' ? <FilterHouse /> 
                : from == 'apartment' ? <FilterApartment /> 
                : from == 'commercial_space' ? <FilterSpace /> 
                : from == 'garage' ? <FilterGarage /> 
                : from == 'ground' ? <FilterGround /> 
                : from == 'new_real_estate' ? <FilterNewRealEstate /> 
                : from == 'office' ? <FilterOffice />                
                : null } */}
                <span className="filtersMobButton" onClick={toggleVisibility} >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" id="filter"><path fill="#000" fill-rule="evenodd" d="M20 5h-1.17a3.001 3.001 0 0 0-5.66 0H4a1 1 0 0 0 0 2h9.17a3.001 3.001 0 0 0 5.66 0H20a1 1 0 1 0 0-2zm-4 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM3 12a1 1 0 0 1 1-1h1.17a3.001 3.001 0 0 1 5.66 0H20a1 1 0 1 1 0 2h-9.17a3.001 3.001 0 0 1-5.66 0H4a1 1 0 0 1-1-1zm5 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-4 4a1 1 0 1 0 0 2h9.17a3.001 3.001 0 0 0 5.66 0H20a1 1 0 1 0 0-2h-1.17a3.001 3.001 0 0 0-5.66 0H4zm13 1a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" clip-rule="evenodd"></path></svg> Filters
            </span>
            {filtersCount > 0 && <span className="filtersCountMob filtersCount">{ filtersCount+' appliqués'} <button
             onClick={clearAll} className="btn btn-primary ">
             Supprimer tout <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
<path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"></path>
</svg>
             </button>
              </span>}
              <div className={`bordered filter filtersSectionMob ${isVisible ? 'visibleFiltersmob' : ''}`} >
              <span onClick={toggleVisibility}
              className={`filtersMobButton filtersMobButtonx `} >&times;</span>      
              <CLabel className='filtersS'> 
            Filters
            </CLabel>
            {filtersCount > 0 && <span className="filtersCount">{ filtersCount+' appliqués'} <button
             onClick={clearAll} className="btn btn-primary ">
             Supprimer tout <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
<path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"></path>
</svg>
             </button>
              </span>}
            <div>
      <span className="filter-title-field">Catégorie Immobilière</span> 
      <div  className='filterOptions'>
      <CSelect
       style={{ appearance: section == '0' || section == '' || !section ? 'auto' : 'none',}}              
                  name="section"
                  value={section}
                  onChange={(e) => {handleSectionChange(e.target.value);setType(e.target.value);updateUrlParams(e)}}
                >
                  <option value=""></option>
                  {sections.map((el,index) => {
                    return (
                      <option key={index} value={el.value}>
                        {el.label}
                      </option>
                    );
                  })}
                </CSelect> 
          {section == '0' || section == '' || !section ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('section')}
        >
          ×
        </button>
      )}
      </div>
      </div>
    
    {types && types.length>0  && <div>
      <span className="filter-title-field">Type de l'immobilier</span> 
      <div  className='filterOptions'>
      <CSelect
       style={{ appearance: type == '0' || type == '' || !type ? 'auto' : 'none',}}              
                  name="type"
                  value={type}
                  onChange={(e) => {setType(e.target.value);updateUrlParams(e)}}
                >
                  <option value=""></option>
                  {types.map((el,index) => {
                    return (
                      <option key={index} value={el.value}>
                        {el.label}
                      </option>
                    );
                  })}
                </CSelect> 
          {type == '0' || type == '' || !type ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('type')}
        >
          ×
        </button>
      )}</div>
      </div> }

      <div>
      <span className="filter-title-field">Type d'annonce</span> 
      <div  className='filterOptions'>
      <CSelect
       style={{ appearance: typeAnnonce == '0' || typeAnnonce == '' || !typeAnnonce ? 'auto' : 'none',}}              
                  name="typeAnnonce"
                  value={typeAnnonce}
                  onChange={(e) => {setTypeAnnonce(e.target.value);updateUrlParams(e)}}
                >
              <option value=""></option>
               <option value="annonce_location">Annonce de location</option>
              <option value="annonce_vente">Annonce de vente </option>
              { section && (section =='house' || section =='apartment') &&<option value="annonce_vacance">Annonce de vacances</option>}
            
                </CSelect> 
          {typeAnnonce == '0' || typeAnnonce == '' || !typeAnnonce ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('typeAnnonce')}
        >
          ×
        </button>
      )}
      </div>
      </div>

      <div>
      <span className="filter-title-field">Prix de</span> 
      <div className="inputWithSuffix">
                <CInput
                disabled = {isLoadedALL? false : true} 
                  type="number"
                  name="minPrice"
                  min={1}
                 // max={maxpriceconst}
                  value={minPrice}
                 // placeholder={"Prix de "}
                  onChange={(e) => {setMinPrice(parseInt(e.target.value));updateUrlParams(e)}}
                ></CInput>
               {minPrice && minPrice != "" && <button
         className="clearFilterInput"
          onClick={()=> handleClearSelection('minPrice')}
        >
          ×
        </button> }  <span className="suf">TND</span> </div>
      </div>

      <div>
      <span className="filter-title-field">Prix à </span> 
      <div className="inputWithSuffix">
                <CInput
                disabled = {isLoadedALL? false : true} 
                  min={1}
                 // max={maxpriceconst}
                  type="number"
                  name="maxPrice"
                  //placeholder={"Prix à "}
                  value={maxPrice}
                  onChange={(e) => {setMaxPrice(parseInt(e.target.value));updateUrlParams(e)}}
                ></CInput>
                {maxPrice && maxPrice != "" && <button
         className="clearFilterInput"
          onClick={()=> handleClearSelection('maxPrice')}
        >
          ×
        </button> } <span className="suf">TND</span> </div>
      </div>
      
      
       <div>
      <span className="filter-title-field">Pays</span> 
      <div  className='filterOptions'>
      <CSelect
       style={{ appearance: country == '0' || country == '' || !country ? 'auto' : 'none',}}              
                  name="country"
                  value={country}
                  onChange={(e) => {handleCountryChange(e);updateUrlParams(e)}}
                >
                  <option value=""></option>
                  {countries.map((country) => {
                    return (
                      <option key={country.id} value={country.id}>
                        {country.label}
                      </option>
                    );
                  })}
                </CSelect> 
          {country == '0' || country == '' || !country ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('country')}
        >
          ×
        </button>
      )}</div>
      </div>

      <div>
      <span className="filter-title-field">Gouvernorat</span> 
      <div  className='filterOptions'>
      <CSelect
       style={{ appearance: city == '0' || city == '' || !city ? 'auto' : 'none',}}              
                  name="city"
                  value={city}
                  onChange={(e) => {handleCityChange(e);updateUrlParams(e)}}
                >
                  <option value=""></option>
                  {cities.map((city) => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.label}
                      </option>
                    );
                  })}
                </CSelect>
                {city == '0' || city == '' || !city ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('city')}
        >
          ×
        </button>
      )}</div>
      </div>

      <div>
      <span className="filter-title-field">Délégation</span> 
      <div  className='filterOptions'>
      <CSelect
       style={{ appearance: delegate == '0' || delegate == '' || !delegate ? 'auto' : 'none',}}              
                 name="delegate"
                  value={delegate}
                  onChange={(e) => {setDelegate(e.target.value);updateUrlParams(e)}}
                >
                  <option value=""></option>
                  {delegates.map((delegate) => {
                    return (
                      <option key={delegate.id} value={delegate.id}>
                        {delegate.label}
                      </option>
                    );
                  })}
                </CSelect>
                {delegate == '0' || delegate == '' || !delegate ? '' : (
        <button
         className="clearFilterOption"
          onClick={()=> handleClearSelection('delegate')}
        >
          ×
        </button>
      )}</div>
      </div>
           
      <span className="filtersMobButton filtersMobButtonG " onClick={toggleVisibility} >
              Chercher
            </span> 
            </div>
              </CCol>

              <CCol>
                <div className="occasions-results layout-list">
                  <div className="articles">
                    Il y a <strong>{results.length} </strong> résultat
                    {results.length > 1 && "s"}
                    {results.length > 0 && 
                    <span className="filtersOrder">
                    <CSelect
      
                 name="sortBy"
                  value={sortBy}
                  onChange={(e) => {handleSortBy(e)}}
                >
                  <option value="dateD">Annonces les plus récentes d'abord</option>
                  <option value="dateC">Annonces les plus anciennes d'abord</option>
                  <option value="nomC">Titre ordre croissant</option>
                  <option value="nomD">Titre ordre décroissant</option>  
                  <option value="price_asc">Prix ordre croissant</option>
                  <option value="price_desc">Prix ordre décroissant</option>  
                  <option value="surface_asc">Surface  ordre croissant</option>
                  <option value="surface_desc">Surface  ordre décroissant</option>                  
                </CSelect>
                  </span>}
                    <CDataTable
                      className="vehicle-data-table"
                      items={results}
                      fields={fields}
                      header={false}
                      itemsPerPage={10}
                      pagination
                      scopedSlots={{
                        title: (item) => (
                          <td>
                        
                        <RealEstateItem
                        item={item}
                                id={item.id}
                                at={item.createdAt}
                                surface={item.surface}
                                price={item.price}
                                title={item.title}
                                url={`/real-estates/details/${item.id}`}
                                image={
                                  item.mainPicture
                                    ? `${IMAGES_BASE_URL}/public/uploads/images/${item.type}/${item.mainPicture.name}`
                                    : ""
                                }
                                city={item.city?item.city.label:''}
                                typeRealEstate={item.typeHouse?item.typeHouse
                                : item.typeApartment ? item.typeApartment
                                : item.typeCommercialSpace ? item.typeCommercialSpace 
                                : item.typeNewRealEstate ? item.typeNewRealEstate
                                : item.typeGround ? item.typeGround
                                :''
                                }
                                table='RealEstate'
                              />

                          </td>
                        ),
                      }}
                    />
                  </div>
                </div>
              </CCol>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
