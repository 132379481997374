import React from "react";
import { Link } from "react-router-dom";

export const NewsItem = (props) => {
 
  const arabic = /[\u0600-\u06FF]/;

  var classArabic = ''
  if( arabic.test(props.title)) classArabic = 'arabicFontClass';

  return (
    <li className={`c-posts__item ${classArabic}`}  >
      <Link
        className="c-posts__inner"
         to = {{ pathname:props.url,
              state:props.item,}}
        title={props.title}
       // onClick={() => history.push(props.url)}
      >
        <div className="c-posts__media ">
          <img
            src={props.imageUrl}
            className="c-posts__thumbnail"
            loading="lazy"
            alt={props.title}
          />
        </div>
        <div className="c-posts__details">
          <div className="c-posts__headline ">
            <span className="c-posts__headlineText">{props.title}</span>
          </div>
          <div className="c-posts__about c-posts__about--multiple">
            <div className="c-posts__info c-posts__info--highlight">
              {props.category}
            </div>
            <div className="c-posts__info">{props.date}</div>
          </div>
        </div>
      </Link>
    </li>
  );
};

export default NewsItem;
