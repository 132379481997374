import React, { Component } from 'react'
import { API_BASE_URL } from '../../../config'
import axios from 'axios';

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CInputGroup,
  CRow
} from '@coreui/react'

export class ForgetBack extends Component {
    constructor(props) {
        super(props)
        this.state = {
             isloading:'0',
             email:'',
             loginErrors:"",
             errors:'',
             token:'0',
             vcode:'',
             vcode2:'',
             password:'',
             passwordConfirmation:'',
             success:''
        }
        this.returnedtime=0;
    }

    handleChange=(event)=>{
        this.setState({
            [event.target.name] :event.target.value
        })
        //console.log(this.state);
    }
    handleSubmit=(event)=>{
      this.setState({ errors:""});

      if(this.state.email.length<2){
        this.setState({ errors:"Veuillez entrer votre adresse e-mail associée !"})
      } else {
        this.setState({
          isloading: '1'
        });
        //console.log(API_BASE_URL+'/users/forget',this.state);
      axios.post(API_BASE_URL+'/users/forget',this.state)
      .then(res => {
        //console.log(res)
        if(res.data.status=='ok'){
          this.returnedtime=res.data.times;
          this.setState({ isloading: '0',vcode:res.data.code,token:'1'});

        }else if (res.data.status=='fail'){
          this.setState({ isloading: '0',errors:"Cet e-mail n'est pas associé à un compte existant !"});

        }
        
      })
      .catch(error =>{
        //console.log("login errors : ", error);
         setTimeout(() => {
      this.setState({isloading: '0'})
    }, 500);
    })
   
  }
      event.preventDefault();
     
  }
    handleSubmit2=(event)=>{
    this.setState({ errors:""});
   //console.log(this.state);
   let timebetween=(Date.now()/1000)-this.returnedtime;
   //console.log(Date.now()/1000);
   //console.log(this.returnedtime);
   //console.log(timebetween);
    if(this.state.vcode2==this.state.vcode){
      if(timebetween<7200){
        this.setState({ token:'2'});
      }else{
        this.setState({ errors:"Ce code a expiré !"});
          }
      
    } else {   
      this.setState({ errors:"Mauvais code !"});
    }
  
    event.preventDefault();  
}
handleSubmit3=(event)=>{
  event.preventDefault();
  if(this.state.password.length<8){
    this.setState({ errors:"Le mot de passe doit contenir au moins 8 caractères!"});  
  } else if (this.state.passwordConfirmation !== this.state.password) {
    this.setState({ errors:"La confirmation ne correspond pas au mot de passe!"});  
  } else {
    this.setState({isloading: '1'});
    this.setState({ errors:""});  
    const formData = new FormData();
    formData.append("password", this.state.passwordConfirmation);
    formData.append("passwordConfirmation", this.state.passwordConfirmation);
    //console.log('formdata');
    //console.log(formData);
    axios
    .post(API_BASE_URL + "/users/updatepass", this.state)
    .then((res) => {
      //console.log(res.data);
      if(res.data.status=='ok'){
        setTimeout(() => {
          this.setState({isloading: '0',success:'Votre mot de passe a été réinitialisé avec succès. Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.'})
        }, 200);
      }else  setTimeout(() => {
        this.setState({isloading: '0',errors:'Une erreur est survenue, veuillez réessayer ultérieurement'})
      }, 200);
      
    })
        
    .catch((error) => {
      //console.log("Register errors : ", error);
      setTimeout(() => {
        this.setState({isloading: '0',errors:'Une erreur est survenue, veuillez réessayer ultérieurement'})
      }, 200);
    });
  
  }

   
}
LoginRedirect=()=>{
  //console.log('loggggggggg');
  window.location.href = '/5467854/login';
}
  
    render() {
        return (
            <div className="c-app c-default-layout flex-row align-items-center">
           {this.state.token==='0' && <CContainer>
              <CRow className="justify-content-center">
                <CCol md="6">
                  <CCardGroup>
                    <CCard className="p-4">
                      <CCardBody>
                        <form onSubmit={this.handleSubmit}>
                          <h1>Vous avez oublié votre mot de passe ?</h1>
                          <p className="text-muted">Veuillez entrer votre adresse e-mail associée ci-dessous:</p>
                          <CInputGroup className="mb-3">
                          
                            <input type="text" placeholder="Email"  name="email" value={this.state.email} onChange={this.handleChange}/>
                          </CInputGroup>
                          <CRow>
                            <CCol xs="6">
                              <CButton color="primary" className="px-4" type="submit">Envoyez-moi un code de vérification</CButton>
                            </CCol>
                          </CRow>
                        </form>
                        {this.state.errors.length > 1 && <p style={{color:'red'}}>{this.state.errors}</p>}
                        {this.state.isloading==='1' && <p className="loadingnow" style={{fontSize:"18px",color:"green",fontWeight:"bold",marginLeft:"4px",marginTop:"10px"}}>Chargement...</p>}
                      </CCardBody>
                    </CCard>
                    
                  </CCardGroup>
                </CCol>
              </CRow>
            </CContainer>}
            {this.state.token==='1' && <CContainer>
              <CRow className="justify-content-center">
                <CCol md="6">
                  <CCardGroup>
                    <CCard className="p-4">
                      <CCardBody>
                        <form onSubmit={this.handleSubmit2}>
                          <h1>Vous avez oublié votre mot de passe ?</h1>
                          <p className="text-muted">Nous avons envoyé un code de vérification à votre adresse e-mail. Veuillez l'entrer ci-dessous :</p>
                          <CInputGroup className="mb-3">
                          
                            <input type="text" placeholder="Verification code"  name="vcode2" value={this.state.vcode2} onChange={this.handleChange}/>
                          </CInputGroup>
                          <CRow>
                            <CCol xs="6">
                              <CButton color="primary" className="px-4" type="submit">Envoyer le code</CButton>
                            </CCol>
                          </CRow>
                        </form>
                        {this.state.errors.length > 1 && <p style={{color:'red'}}>{this.state.errors}</p>}
                        {this.state.isloading==='1' && <p className="loadingnow" style={{fontSize:"18px",color:"green",fontWeight:"bold",marginLeft:"4px",marginTop:"10px"}}>Chargement...</p>}
                      </CCardBody>
                    </CCard>
                    
                  </CCardGroup>
                </CCol>
              </CRow>
            </CContainer>}
            {this.state.token==='2' && <CContainer>
              <CRow className="justify-content-center">
                <CCol md="6">
                  <CCardGroup>
                    <CCard className="p-4">
                      <CCardBody>
                        <form onSubmit={this.handleSubmit3}>
                          <h1>Vous avez oublié votre mot de passe ?</h1>
                          <p className="text-muted">Veuillez saisir votre nouveau mot de passe:</p>
                          <CInputGroup className="mb-3" style={{display:"block"}}>
                          <div className="mb-3">
                        <label htmlFor="password">Mot de passe*</label>
                        <input
                          type="password"
                          name="password"
                          value={this.state.password}
                          onChange={this.handleChange}
                        />
                      </div>
                        </CInputGroup>
                         <CInputGroup className="mb-3" style={{display:"block"}}>
                      <div className="mb-3">
                        <label htmlFor="passwordConfirmation">
                          Confirmer le mot de passe *
                        </label>
                        <input
                          type="password"
                          name="passwordConfirmation"
                          value={this.state.passwordConfirmation}
                          onChange={this.handleChange}
                        />
                      </div>
                        </CInputGroup>
                          <CRow>
                            <CCol xs="6">
                              <CButton color="primary" className="px-4" type="submit">soumettre le mot de passe</CButton>
                            </CCol>
                          </CRow>
                        </form>
                        {this.state.errors.length > 1 && <p style={{color:'red'}}>{this.state.errors}</p>}
                        {this.state.isloading==='1' && <p className="loadingnow" style={{fontSize:"18px",color:"green",fontWeight:"bold",marginLeft:"4px",marginTop:"10px"}}>Chargement...</p>}
                        {this.state.success.length >1 && <div><p className="loadingnow" style={{fontSize:"18px",color:"green",fontWeight:"bold",marginLeft:"4px",marginTop:"10px"}}>{this.state.success}</p>
                        <br/>
                        <CButton color="primary" className="px-4" onClick={this.LoginRedirect}>Connexion</CButton>
                        </div>
                        }
                      </CCardBody>
                    </CCard>
                    
                  </CCardGroup>
                </CCol>
              </CRow>
            </CContainer>}
                  </div>
        )
    }
}

export default ForgetBack
