import React, { Component } from 'react'


export class IndexAdmin extends Component {
    render() {
        return (
            <div>
                <h1>Admin index</h1>
                <p> </p>
            </div>
           
        )
    }
}

export default IndexAdmin
