import React, { useEffect, useState } from "react";
import { API_BASE_URL, IMAGES_BASE_URL } from "../../../config";
import axios from "axios";

import JobItem from "./JobItem";

function JobsByCompany(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const { id } = props.match.params;
  useEffect(() => {
    
    axios(`${API_BASE_URL}/jobs-by-company/${id}`)
      .then((res) => res.data)
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
          //console.log(result)
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [id]);
  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Chargement...</div>
          </div>
        </main>
      </div>
    );
  } else {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-main__grid o-container">
            <div className="l-main__article l-main__content l-main__section l-article">
              {items.map((jobOffer, index) => {
                return (
               <JobItem
                    key={index}
                    title={jobOffer.title}
                    company={jobOffer.createdBy.company && jobOffer.createdBy.company.name} 
                    urlCompany={`/jobs/jobs-by-company/${jobOffer.createdBy.company.id}`}
                    url={`/jobs/job-details/${jobOffer.id}/${jobOffer.sector.slug}/${jobOffer.slug}`}
                    logo ={jobOffer.createdBy.company.path  ?`${IMAGES_BASE_URL}/public/uploads/companies/${jobOffer.createdBy.company.path}`:"https://www.keejob.com/static/recruiter/images/default-logo.jpg"}
                 />
                  
                
                );
              })}
             
            </div>
           
          </div>
        </main>
      </div>
    );
  }
}

export default JobsByCompany;
