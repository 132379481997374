import React, { useEffect, useState } from "react";
import { API_BASE_URL , keyHeader} from "../../../../config";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
//import 'rc-slider/assets/index.css';
import { minpriceconst, maxpriceconst } from "../../../../config";
import {
  CCol,
  CSelect,
  CFormGroup,
  CInputRadio,
  CLabel,
  CButton,
  CInput,
  CSpinner,
} from "@coreui/react";
import Loading from "src/components/myWidgets/loadingWidget";

export default function SearchBike(props) {
  const [brandsAll, setBrandsAll] = useState([]);
  const [makes, setMakes] = useState([]);
  const [make, setMake] = useState("");
  const [status, setStatus] = useState(null);
  const [year, setYear] = useState("");

  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");

  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [frameHeight, setFrameHeight] = useState("");
  const [loading, setLoading] = useState(false);
  const [delegate, setDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  const [locality, setLocality] = useState("");
  const [localities, setLocalities] = useState([]);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [isLoaded1, setIsLoaded1] = useState(false);
  const [isLoaded2, setIsLoaded2] = useState(false);
  const [isLoaded3, setIsLoaded3] = useState(false);
  const [warranty, setWarranty] = useState("");
  const [isLoadedALL , setisLoadedALL] = useState(false);
  const [all, setAll] = useState([]);
  const [errorAll,setErrorALL]= useState(false);
  const [results , setresults] = useState([]);
  const [trys , setTrys] = useState(0);

  let history = useHistory(); 
  
  useEffect(() => {

    if (!localStorage.getItem("bikeCategories")) {
      axios(`${API_BASE_URL}/bike/categories`)
      .then((res) => res.data)
      .then(
        (result) => {
          setCategories(result);
          setIsLoaded1(true);
          //  //console.log(result);
          localStorage.setItem("bikeCategories", JSON.stringify(result));
        },
        (error) => {
          setIsLoaded1(true);
          //setError(error);
        }
      );
  } else {
    setCategories(JSON.parse(localStorage.getItem("bikeCategories")));
    setIsLoaded1(true);
  }

  if (!localStorage.getItem("bikeBrands")){ 
  axios(`${API_BASE_URL}/bike/brands`)
      .then((res) => res.data)
      .then(
        (result) => {
          setMakes(result);
          setBrandsAll(result);
          setIsLoaded2(true);
          // console.log(result);
          localStorage.setItem("bikeBrands", JSON.stringify(result));         
        },
        (error) => {
          //setError(error);
          setIsLoaded2(true);
        }
      );
      } else {
        setMakes(JSON.parse(localStorage.getItem("bikeBrands")));
        setBrandsAll(JSON.parse(localStorage.getItem("bikeBrands")));
        setIsLoaded2(true);
      }


    if (!localStorage.getItem("countries")) {
      axios(`${API_BASE_URL}/countries/all`)
        .then((res) => res.data)
        .then(
          (result) => {
            //console.log("countries", result);
            setCountries(result);
            setIsLoaded3(true);
            localStorage.setItem("countries", JSON.stringify(result));
          },
          (error) => {}
        );
    } else {
      // console.log("countris2222222");
      // console.log(localStorage.getItem("countries"));
      setIsLoaded3(true);
      setCountries(JSON.parse(localStorage.getItem("countries")));
    }

    axios
    (API_BASE_URL + "/annonce_bike/all",keyHeader)
    .then((res) => res.data)
    .then(
      (result) =>{ 
      //console.log('all result bike' , result);
      setAll(result);
      setresults(result)
      setisLoadedALL(true)
      //setdisable('');
    },
    (error) => {
      //console.log(error);
      setErrorALL(true)
    }
    );

  }, []);
 
  
  useEffect(() => {
    var results = all.filter(function (el) {
      const statusc = status === null || el.state == status; 
      const makec = !make || el.brand.id == make;  
      const categoryc = category > 0 ?  el.category.id == category : true;
      const yearc =  year > 0 ?  el.year == year : true;
      const frameHeightc = !frameHeight || el.frameHeight == frameHeight;   
      const warrantyc = !warranty || el.warranty == warranty;  
      const countryc = !country || el.country.id == country;  
      const cityc = !city || el.city.id == city;  
      const minPricec = !minPrice || el.price >= minPrice;  
      const maxPricec = !maxPrice || el.price <= maxPrice;  
    
      return statusc && makec && categoryc && frameHeightc && yearc && warrantyc  && countryc && cityc && minPricec && maxPricec;
    });
    //console.log('rsddsdxxx ',results)
    setresults(results);
     }, [status ,make, category , frameHeight , year  , warranty , country , city , minPrice , maxPrice ]);
  
     const handleCategoryChange = (i) => {
      setCategory(i);
      if(i == 12) {
        var brandsF = [];
        brandsF = brandsAll.filter((el) => el.category && el.category.id == 12);
        //console.log("brandsF in 12 <<<", brandsF);
        setMakes(brandsF);
        setMake("");
      } 
      else {
        var brandsF = [];
        brandsF = brandsAll.filter((el) => !el.category );
        //console.log("brandsF <<<", brandsF);
        setMakes(brandsF);
        setMake("");
      }
    }

  const getYear = () => {
    const years = [];
    for (let i = new Date().getFullYear(); i > 1950; i--) {
      years.push(
        <option value={i} key={i}>
          {" "}
          {i}
        </option>
      );
    }
    return years;
  };
  // handle change event of the country dropdown

  const handleSubmit = (e) =>  {
    e.preventDefault();
    let path =``;
if(make) path+=`make=${make}&`
if(category) path+=`category=${category}&`
if(year) path+=`year=${year}&`
if(frameHeight) path+=`frameHeight=${frameHeight}&`
if(minPrice) path+=`minPrice=${minPrice}&`
if(maxPrice) path+=`maxPrice=${maxPrice}&`
if(warranty) path+=`warranty=${warranty}&`
if(country) path+=`country=${country}&`
if(city) path+=`city=${city}&`
if(status) path+=`status=${status}&`

//console.log(path.charAt(path.length - 1))
if(path.charAt(path.length - 1)=='&') path = path.substring(0, path.length - 1)
//console.log(path)

 history.push({
      pathname:  `/vehicles/bikes/search-result`,
      search:`?${path}`,
      state: { result: results  ,
        all:{all:all , cities:cities
      }
        ,      
             },
    });
  }
  const handleSubmitOld = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("category", category);

    formData.append("brand", make);
    formData.append("state", status);

    formData.append("year", year);

    formData.append("minPrice", minPrice);
    formData.append("maxPrice", maxPrice);

    formData.append("country", country);
    formData.append("city", city);
    formData.append("frameHeight", frameHeight);
    //console.log("okk");
    axios
      .post(API_BASE_URL + "/annonces_bikes/search", formData)
      .then((res) => {
        // //console.log(res.data);
        history.push({
          pathname: "/vehicles/bikes/search-result",
          state: { result: res.data },
        });
        setLoading(false);

        // //console.log(props);
      })
      .catch((error) => {
        ////console.log(" errors : ", error);
        setLoading(false);
      });
  };
  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    let id = e.target.value;
    var countriesF = [];
    //  //console.log("iddd", id);
    if (id !== "0") {
      countriesF = countries.find((city) => city.id.toString() === id);
      setCities(countriesF.cities);
      // //console.log("countriesF", countriesF);
      setCity('');
    } else {
      setCity('');
      //  setModel("");
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    let id = e.target.value;
    var citiesF = [];
    // //console.log("iddd", id);
    if (id !== "0") {
      citiesF = cities.find((delegate) => delegate.id.toString() === id);
      setDelegates(citiesF.delegations);
      // //console.log("citiesF", citiesF);
    } else {
      setDelegate([]);
      //  setModel("");
    }
  };
  const handleDelegateChange = (e) => {
    setDelegate(e.target.value);
    let id = e.target.value;
    var delegatesF = [];
    // //console.log("iddd", id);
    if (id !== "0") {
      delegatesF = delegates.find((local) => local.id.toString() === id);
      setLocalities(delegatesF.localities);
      //  //console.log("delegatesF", delegatesF);
    } else {
      setLocality([]);
      //  setModel("");
    }
  };
  if (isLoaded1 && isLoaded2 && isLoaded3) {
    return (
      <>
        <form onSubmit={handleSubmit} className="searchPage">
          <CFormGroup row>
            <CCol xs="6" md="6">
              <CFormGroup variant="custom-checkbox" inline>
                <CFormGroup variant="checkbox" inline>
                  <CInputRadio
                    className="form-check-input"
                    id="1"
                    name="status"
                    value="1"
                    onChange={(e) => setStatus(e.target.value)}
                    disabled = {isLoadedALL? false : true} 
                  />
                  <CLabel variant="checkbox" htmlFor="neuf">
                    Neuf
                  </CLabel>
                </CFormGroup>
                <CFormGroup variant="checkbox" inline>
                  <CInputRadio
                    className="form-check-input"
                    id="0"
                    name="status"
                    value="0"
                    onChange={(e) => setStatus(e.target.value)}
                    disabled = {isLoadedALL? false : true} 
                  />
                  <CLabel variant="checkbox" htmlFor="occasion">
                    Occasion
                  </CLabel>
                </CFormGroup>
              </CFormGroup>
            </CCol>
            <CCol xs="6" md="6"></CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs="6" md="6">
              <CFormGroup>
                <CSelect
                  name="category"
                  value={category}
                  onChange={(e) => handleCategoryChange(e.target.value)}
                  disabled = {isLoadedALL? false : true} 
                >
                  <option value="0"> Choisir la catégorie</option>
                  {categories.map((category) => {
                    return (
                      <option key={category.id} value={category.id}>
                        {category.label}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>

            <CCol xs="6" md="6">
              <CSelect
                name="make"
                value={make}
                onChange={(e) => setMake(e.target.value)}
                disabled = {isLoadedALL? false : true} 
              >
                <option value="0"> Choisir la marque</option>
                {makes.map((make) => {
                  return (
                    <option key={make.id} value={make.id}>
                      {make.label}
                    </option>
                  );
                })}
              </CSelect>
            </CCol>

            <CCol xs="6" md="6">
              <CSelect
                name="year"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                disabled = {isLoadedALL? false : true} 
              >
                <option value=""> Année</option>
                {getYear()}
              </CSelect>
            </CCol>
            <CCol xs="6" md="6">
              <CFormGroup>
                <CInput
                  name="frameHeight"
                  value={frameHeight}
                  placeholder="La taille du vélo"
                  onChange={(e) => setFrameHeight(e.target.value)}
                  disabled = {isLoadedALL? false : true} 
                />
              </CFormGroup>
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol xs="6" md="6" className="min-max2">
              <CFormGroup>
              <div className="inputWithSuffix">
                <CInput
                  type="number"
                  name="minPrice"
                  min={1}
                 // max={maxpriceconst}
                  value={minPrice}
                  placeholder={"Prix de "}
                  onChange={(e) => setMinPrice(parseInt(e.target.value))}
                  disabled = {isLoadedALL? false : true} 
                ></CInput>
                <span className="suf">TND</span> </div>
              </CFormGroup>
            </CCol>
            <CCol xs="6" md="6" className="min-max2">
              <CFormGroup>
              <div className="inputWithSuffix">
                <CInput
                  min={1}
                 //max={maxpriceconst}
                  type="number"
                  name="maxPrice"
                  placeholder={"Prix à "}
                  value={maxPrice}
                  onChange={(e) => setMaxPrice(parseInt(e.target.value))}
                  disabled = {isLoadedALL? false : true} 
                ></CInput>
                <span className="suf">TND</span> </div>
              </CFormGroup>
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol xs="6" md="6">
              <CFormGroup>
                <CSelect
                  name="country"
                  value={country}
                  onChange={(e) => handleCountryChange(e)}
                  disabled = {isLoadedALL? false : true} 
                >
                  <option value="0"> Choisissez un pays</option>
                  {countries.map((country) => {
                    return (
                      <option key={country.id} value={country.id}>
                        {country.label}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
            <CCol xs="6" md="6">
              <CFormGroup>
                <CSelect
                  name="city"
                  value={city}
                  onChange={(e) => handleCityChange(e)}
                  disabled = {isLoadedALL? false : true} 
                >
                  <option value="0"> Gouvernorat</option>
                  {cities.map((city) => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.label}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol xs="6" md="6">
              <CFormGroup>
                <CSelect
                  name="delegate"
                  value={delegate}
                  onChange={(e) => handleDelegateChange(e)}
                  disabled = {isLoadedALL? false : true} 
                >
                  <option value="0"> Choisissez une délégation</option>
                  {delegates.map((delegate) => {
                    return (
                      <option key={delegate.id} value={delegate.id}>
                        {delegate.label}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
            <CCol xs="6" md="6">
              <CFormGroup>
                <CSelect
                  name="locality"
                  value={locality}
                  onChange={(e) => setLocality(e.target.value)}
                  disabled = {isLoadedALL? false : true} 
                >
                  <option value="0"> Localité</option>
                  {localities.map((local) => {
                    return (
                      <option key={local.id} value={local.id}>
                        {local.label}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol xs="6" md="6"></CCol>
            <CCol xs="6" md="6">
            { errorAll?
                <CButton className=" btn-search" disabled>
                  <span> Error Chercher ! 😟 </span>
                </CButton>
                : !loading && isLoadedALL ? (
                <CButton className=" btn-search" type="submit">
                  <span> {results.length} résultats</span>
                </CButton>
              )
              :  <CButton className=" btn-search" disabled>
                  <span>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>

                  <span> Chercher </span>
                </CButton>
              }

         
            </CCol>
          </CFormGroup>
        </form>
      </>
    );
  } else
    return (
     <Loading/>
    );
}
