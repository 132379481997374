import { CCol } from "@coreui/react";
import React from "react";


function Comment(props) {
  return (
    <CCol className="mt-3">
      <div className="row">
        <div className="c-avatar ">
          <img
            className="c-avatar-img"
            src={props.avatar}
            alt={props.name}
          />
        </div>
        <div className="col-md-10">
          <div className="small text-muted">
            <span>{props.owner}</span> | {props.date}
          </div>
          {props.commentary}
        </div>
      </div>
    </CCol>
  );
}

export default Comment;
