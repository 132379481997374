import React, { useEffect, useState } from "react";
import { API_BASE_URL , keyHeader } from "../../../../config";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
//import 'rc-slider/assets/index.css';
import { minpriceconst, maxpriceconst } from "../../../../config";
import {
  CCol,
  CSelect,
  CFormGroup,
  CInputRadio,
  CLabel,
  CButton,
  CSpinner,
  CInput,
} from "@coreui/react";

export default function SearchMoto(props) {
  const [makes, setMakes] = useState([]);
  const [make, setMake] = useState("");
  const [models, setModels] = useState([]);
  const [model, setModel] = useState("");
  const [status, setStatus] = useState(null);
  const [bodyStyle, setBodyStyle] = useState("");
  const [year, setYear] = useState("");
  const [fuel, setFuel] = useState("");
  const [warranty, setWarranty] = useState("");
  const [loading, setLoading] = useState(false);
  const [gearingType, setGearingType] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [delegate, setDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  const [locality, setLocality] = useState("");
  const [localities, setLocalities] = useState([]);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [isLoaded1, setIsLoaded1] = useState(false);
  const [isLoaded2, setIsLoaded2] = useState(false);
  const [isLoadedALL , setisLoadedALL] = useState(false);
  const [all, setAll] = useState([]);
  const [errorAll,setErrorALL]= useState(false);
  const [results , setresults] = useState([]);
  const [trys , setTrys] = useState(0);

  const handleMakeChange = (e) => {
    setMake(e.target.value);
    let id = e.target.value;
    var makesF = [];
    if (id !== "0") {
      makesF = makes.find((model) => model.id.toString() === id);
      setModels(makesF.models);
      setModel("");
      //console.log(makesF);
    } else {
      setModels([]);
      setModel("");
    }
  };
  const getYear = () => {
    const years = [];
    for (let i = new Date().getFullYear(); i > 1950; i--) {
      years.push(
        <option value={i} key={i}>
          {" "}
          {i}
        </option>
      );
    }
    return years;
  };
  // handle change event of the country dropdown

  useEffect(() => {
//console.log('qcttt')
      if (!localStorage.getItem("motoBrands")) 
      {
          axios(`${API_BASE_URL}/vehicles/moto-brands`)
            .then((res) => res.data)
            .then(
              (result) => {
                setMakes(result);
                setIsLoaded1(true);                
        localStorage.setItem("motoBrands", JSON.stringify(result));           
                
              },
              (error) => {
              }
            );
        } else {
          setMakes(JSON.parse(localStorage.getItem("motoBrands")));
          setIsLoaded1(true);

        }

    if (!localStorage.getItem("countries")) {
      axios(`${API_BASE_URL}/countries/all`)
        .then((res) => res.data)
        .then(
          (result) => {
            //  console.log("countries", result);
            setCountries(result);
            setIsLoaded2(true);
            localStorage.setItem("countries", JSON.stringify(result));
          },
          (error) => {}
        );
    } else {
      // console.log("countris2222222");
      //  console.log(localStorage.getItem("countries"));
      setIsLoaded2(true);
      setCountries(JSON.parse(localStorage.getItem("countries")));
    }

    axios
    (API_BASE_URL + "/annonce_moto/all",keyHeader)
    .then((res) => res.data)
    .then(
      (result) =>{ 
      //console.log('all result moto' , result);
      setAll(result);
      setresults(result)
      setisLoadedALL(true)
      //setdisable('');
    },
    (error) => {
      //console.log(error);
      setErrorALL(true)
    }
    );

  }, []);
  let history = useHistory();

  useEffect(() => {
    //  handleFilter(e);
    //console.log(make,model)
    var results = all.filter(function (el) {
      const statusc = status === null || el.state == status; 
      const makec = !make || el.brand.id == make;  
      const modelc = model > 0 ?  el.model.id == model : true;
      const yearc =  year > 0 ?  el.year == year : true;
      const bodyStylec = !bodyStyle || el.body == bodyStyle;  
      const fuelc = !fuel || el.fuel == fuel;  
      const gearingTypec = !gearingType || el.gearingType == gearingType;  
      const warrantyc = !warranty || el.warranty == warranty;  
      const countryc = !country || el.country.id == country;  
      const cityc = !city || el.city.id == city;  
      const minPricec = !minPrice || el.price >= minPrice;  
      const maxPricec = !maxPrice || el.price <= maxPrice;   
    
      return statusc && makec && modelc && bodyStylec && yearc && fuelc && gearingTypec  && warrantyc && countryc && cityc && minPricec && maxPricec;
    });
    //console.log('rsddsdxxx ',results)
    setresults(results);
     }, [status ,make, model , bodyStyle , year , fuel , gearingType , warranty , country , city , minPrice , maxPrice ]);
  

     const handleSubmit = (e) =>  {
      e.preventDefault();
      let path =``;
  if(make) path+=`make=${make}&`
  if(model) path+=`model=${model}&`
  if(year) path+=`year=${year}&`
  if(bodyStyle) path+=`bodyStyle=${bodyStyle}&`
  if(fuel) path+=`fuel=${fuel}&`
  if(gearingType) path+=`boite=${gearingType}&`
  if(minPrice) path+=`minPrice=${minPrice}&`
  if(maxPrice) path+=`maxPrice=${maxPrice}&`
  if(warranty) path+=`warranty=${warranty}&`
  if(country) path+=`country=${country}&`
  if(city) path+=`city=${city}&`
  if(status) path+=`status=${status}&`
  
  //console.log(path.charAt(path.length - 1))
  if(path.charAt(path.length - 1)=='&') path = path.substring(0, path.length - 1)
  //console.log(path)
  
   history.push({
        pathname:  `/vehicles/motos/search-result`,
        search:`?${path}`,
        state: { result: results  ,
          all:{all:all , cities:cities,models:models
        }
          ,      
               },
      });
    }

  const handleSubmitOld = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("state", status);
    formData.append("brand", make);
    formData.append("model", model);
    formData.append("body", bodyStyle);
    formData.append("year", year);
    formData.append("fuel", fuel);
    formData.append("gearingType", gearingType);
    formData.append("warranty", warranty);
    formData.append("country", country);
    formData.append("city", city);

    formData.append("minPrice", minPrice);
    formData.append("maxPrice", maxPrice);
    axios
      .post(API_BASE_URL + "/annonce_moto/search", formData)
      .then((res) => {
        history.push({
          pathname: "/vehicles/motos/search-result",
          state: { result: res.data },
        });
        setLoading(false);
      })
      .catch((error) => {
        //  console.log(" errors : ", error);
        setLoading(false);
      });
  };
  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    let id = e.target.value;
    var countriesF = [];
    // console.log("iddd", id);
    if (id !== "0") {
      countriesF = countries.find((city) => city.id.toString() === id);
      setCities(countriesF.cities);
      setCity('');
      // console.log("countriesF", countriesF);
    } else {
      setCity('');
      //  setModel("");
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    let id = e.target.value;
    var citiesF = [];
    // console.log("iddd", id);
    if (id !== "0") {
      citiesF = cities.find((delegate) => delegate.id.toString() === id);
      setDelegates(citiesF.delegations);
      // console.log("citiesF", citiesF);
    } else {
      setDelegate([]);
      //  setModel("");
    }
  };
  const handleDelegateChange = (e) => {
    setDelegate(e.target.value);
    let id = e.target.value;
    var delegatesF = [];
    // console.log("iddd", id);
    if (id !== "0") {
      delegatesF = delegates.find((local) => local.id.toString() === id);
      setLocalities(delegatesF.localities);
      // console.log("delegatesF", delegatesF);
    } else {
      setLocality([]);
      //  setModel("");
    }
  };
  if (isLoaded1 && isLoaded2) {
    return (
      <>
        <form onSubmit={handleSubmit} className="searchPage">
          <CFormGroup row>
            <CCol xs="6" md="6">
              <CFormGroup variant="custom-checkbox" inline>
                <CFormGroup variant="checkbox" inline>
                  <CInputRadio
                    className="form-check-input"
                    id="1"
                    name="status"
                    value="1"
                    onChange={(e) => setStatus(e.target.value)}
                    disabled = {isLoadedALL? false : true} 
                  />
                  <CLabel variant="checkbox" htmlFor="neuf">
                    Neuf
                  </CLabel>
                </CFormGroup>
                <CFormGroup variant="checkbox" inline>
                  <CInputRadio
                    className="form-check-input"
                    id="0"
                    name="status"
                    value="0"
                    onChange={(e) => setStatus(e.target.value)}
                    disabled = {isLoadedALL? false : true} 
                  />
                  <CLabel variant="checkbox" htmlFor="occasion">
                    Occasion
                  </CLabel>
                </CFormGroup>
              </CFormGroup>
            </CCol>
            <CCol xs="6" md="6"></CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs="6" md="6">
              <CSelect
                name="sector"
                value={make}
                onChange={(e) => handleMakeChange(e)}
                disabled = {isLoadedALL? false : true} 
              >
                <option value="0"> Choisir la marque</option>
                <optgroup label="Top Marques">
        {makes.slice(0, 10).map((make) => {
                    return (
                      <option key={make.id} value={make.id}>
                        {make.label}
                      </option>
                    );
                  })}
      </optgroup>
      <optgroup label="Autres Marques">
        {makes.slice(10).map((make) =>
          {
                    return (
                      <option key={make.id} value={make.id}>
                        {make.label}
                      </option>
                    );
                  }
        )}
      </optgroup>
              </CSelect>
            </CCol>
            <CCol xs="6" md="6">
              <CSelect
                name="model"
                value={model}
                onChange={(e) => setModel(e.target.value)}
                disabled = {isLoadedALL? false : true} 
              >
                <option value="0"> Modèle</option>
                {models.map((model) => {
                  return (
                    <option key={model.id} value={model.id}>
                      {model.label}
                    </option>
                  );
                })}
              </CSelect>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs="6" md="6">
              <CSelect
                name="bodyStyle"
                value={bodyStyle}
                onChange={(e) => setBodyStyle(e.target.value)}
                disabled = {isLoadedALL? false : true} 
              >
                <option value="">Type de châssis</option>
                <option value="Scooter">Scooter</option>
                <option value="Roadster">Roadster</option>
                <option value="Sportive">Sportive</option>
                <option value="Custom">Custom</option>
                <option value="Routière GT">Routière GT</option>
                <option value="Quad">Quad</option>
                <option value="Routière">Routière</option>
                <option value="Trail">Trail</option>
                <option value="Enduro">Enduro</option>
                <option value="Sidecar">Sidecar</option>
                <option value="Pocket Bike">Pocket Bike</option>
                <option value="Vélomoteur">Vélomoteur</option>
                <option value="Cross">Cross</option>
                <option value="Collection">Collection</option>
                <option value="Rallye">Rallye</option>
                <option value="Compétition">Compétition</option>
                <option value="Tuning">Tuning</option>
                <option value="Supermotard">Supermotard</option>
                <option value="Trial">Trial</option>
                <option value="Trois Roues">Trois Roues</option>
                <option value="autres">Autres</option>
              </CSelect>
            </CCol>
            <CCol xs="6" md="6">
              <CSelect
                name="year"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                disabled = {isLoadedALL? false : true} 
              >
                <option value=""> 1ère immatriculation</option>
                {getYear()}
              </CSelect>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs="6" md="6">
              <CSelect
                name="fuel"
                value={fuel}
                onChange={(e) => setFuel(e.target.value)}
                disabled = {isLoadedALL? false : true} 
              >
                <option value="">Carburant</option>
                <option value="Essence">Essence</option>
                <option value="Diesel">Diesel</option>
                <option value="Electrique">Electrique</option>
                <option value="GPL">GPL</option>
                <option value="Electrique/Essence">Electrique/Essence</option>
                <option value="Essence 2 temps">Essence 2 temps</option>

                <option value="Autres">Autres</option>
              </CSelect>
            </CCol>
            <CCol xs="6" md="6">
              <CSelect
                name="gearingType"
                value={gearingType}
                onChange={(e) => setGearingType(e.target.value)}
                disabled = {isLoadedALL? false : true} 
              >
                <option value="">Transmission</option>
                <option value="Semi-automatique">Semi-automatique</option>
                <option value="Cylindrée">Cylindrée</option>
                <option value="Boîte manuelle">Boîte manuelle</option>

                <option value="autres">Autres</option>
              </CSelect>
            </CCol>
          </CFormGroup>
          <CFormGroup row></CFormGroup>



          <CFormGroup row>
            <CCol xs="6" md="6" className="min-max2">
              <CFormGroup>
              <div className="inputWithSuffix">
                <CInput
                  type="number"
                  name="minPrice"
                  min={1}
                 // max={maxpriceconst}
                  value={minPrice}
                  placeholder={"Prix de " }
                  onChange={(e) => setMinPrice(parseInt(e.target.value))}
                  disabled = {isLoadedALL? false : true} 
                ></CInput>
                <span className="suf">TND</span> </div>
              </CFormGroup>
            </CCol>
            <CCol xs="6" md="6" className="min-max2">
              <CFormGroup>
              <div className="inputWithSuffix">
                <CInput
                  min={1}
                 // max={maxpriceconst}
                  type="number"
                  name="maxPrice"
                  placeholder={"Prix à "}
                  value={maxPrice}
                  onChange={(e) => setMaxPrice(parseInt(e.target.value))}
                  disabled = {isLoadedALL? false : true} 
                ></CInput>
                <span className="suf">TND</span> </div>
              </CFormGroup>
            </CCol>
          </CFormGroup>

                   <CFormGroup row>
            <CCol xs="6" md="6">
              <CSelect
                name="warranty"
                value={warranty}
                onChange={(e) => setWarranty(e.target.value)}
                disabled = {isLoadedALL? false : true} 
              >
                <option value="">Garantie</option>
                <option value="0">0</option>
                <option value="moins 1"> Moins d'une année</option>
                <option value="">1 an</option>
                <option value="Automatique">2 ans</option>
              </CSelect>
            </CCol>
          </CFormGroup> 

          <CFormGroup row>
            <CCol xs="6" md="6">
              <CFormGroup>
                <CSelect
                  name="country"
                  value={country}
                  onChange={(e) => handleCountryChange(e)}
                  disabled = {isLoadedALL? false : true} 
                >
                  <option value="0"> Choisissez un pays</option>
                  {countries.map((country) => {
                    return (
                      <option key={country.id} value={country.id}>
                        {country.label}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
            <CCol xs="6" md="6">
              <CFormGroup>
                <CSelect
                  name="city"
                  value={city}
                  onChange={(e) => handleCityChange(e)}
                  disabled = {isLoadedALL? false : true} 
                >
                  <option value="0"> Gouvernorat</option>
                  {cities.map((city) => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.label}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
          </CFormGroup>

          {/* <CFormGroup row>
            <CCol xs="6" md="6">
              <CFormGroup>
              
                <CSelect
                  name="delegate"
                  value={delegate}
                  onChange={(e) => handleDelegateChange(e)}
                  disabled = {isLoadedALL? false : true} 
                >
                  <option value="0"> Choisissez une délégation</option>
                  {delegates.map((delegate) => {
                    return (
                      <option key={delegate.id} value={delegate.id}>
                        {delegate.label}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
            <CCol xs="6" md="6">
              <CFormGroup>
                <CSelect
                  name="locality"
                  value={locality}
                  onChange={(e) => setLocality(e.target.value)}
                  disabled = {isLoadedALL? false : true} 
                >
                  <option value="0"> Localité</option>
                  {localities.map((local) => {
                    return (
                      <option key={local.id} value={local.id}>
                        {local.label}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
          </CFormGroup> */}

          <CFormGroup row>
            <CCol xs="6" md="6"></CCol>
            <CCol xs="6" md="6">
            { errorAll?
                <CButton className=" btn-search" disabled>
                  <span> Error Chercher ! 😟 </span>
                </CButton>
                : !loading && isLoadedALL ? (
                <CButton className=" btn-search" type="submit">
                  <span> {results.length} résultats</span>
                </CButton>
              )
              :  <CButton className=" btn-search" disabled>
                  <span>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>

                  <span> Chercher </span>
                </CButton>
              }
            </CCol>
          </CFormGroup>
        </form>
      </>
    );
  } else
    return (
      <div className="container">
        <main className="l-main lmain2">
          <div className="l-section l-section--archive">
            <div className="loading_logo">
              <img
                src={"assets/icons/tunisia-living-loading-icon.gif"}
                alt="tunisia living loading icon"
              />
              <p>Chargement...</p>
            </div>
          </div>
        </main>
      </div>
    );
}
