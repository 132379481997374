// actions.js

export const addWatermarkX = async (imageFile) => {
  // Implement watermarking logic here
  // This example demonstrates using a canvas to add a logo watermark with dynamic width

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const image = await new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = URL.createObjectURL(imageFile);
  });

  canvas.width = image.width;
  canvas.height = image.height;

  // Draw the image onto the canvas
  ctx.drawImage(image, 0, 0);

  // Load your logo image
  const logoImage = await new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = "assets/images/logos/tunisia-living-logo-white-2.png"; // Replace with your logo path
  });

  // Calculate logo width based on image width
  let logoWidth;
  if (canvas.width > 360) {
    logoWidth = 350; // Set fixed width for large images
  } else {
    logoWidth = canvas.width - 30; // Otherwise, use original width minus 30px
  }

  // Calculate watermark height based on aspect ratio
  const watermarkHeight = logoWidth * (logoImage.height / logoImage.width);

  // Position the watermark in the center horizontally
  const watermarkX = (canvas.width - logoWidth) / 2;

  // Calculate the Y position to maintain vertical centering within the image (adjust if needed)
  const watermarkY = (canvas.height - watermarkHeight) / 2;

  // Adjust logo opacity (optional)
  ctx.globalAlpha = 0.1; // Set watermark opacity (0-1)

  // Draw the logo onto the canvas with adjusted width and centered position
  ctx.drawImage(logoImage, watermarkX, watermarkY, logoWidth, watermarkHeight);

  // Return a new Blob containing the watermarked image data
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => resolve(blob), 'image/jpeg', 0.8); // Adjust quality if needed
  });
};

export const addWatermark = async (imageFile) => {
  // Implement watermarking logic here
  // This example demonstrates using a canvas to add a logo watermark with dynamic width

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const image = await new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = URL.createObjectURL(imageFile);
  });

  canvas.width = image.width;
  canvas.height = image.height;

  // Draw the image onto the canvas
  ctx.drawImage(image, 0, 0);

  // Load your logo image
  const logoImage = await new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = "assets/images/logos/tunisia-living-logo-white-watermark.png"; // Replace with your logo path
  });

  // Calculate logo width based on image width
  let logoWidth = canvas.width - (canvas.width/2);
  // if (canvas.width > 360) {
  //   logoWidth = 350; // Set fixed width for large images
  // } else {
  //   logoWidth = canvas.width - 30; // Otherwise, use original width minus 30px
  // }

  // Calculate watermark height based on aspect ratio
  const watermarkHeight = logoWidth * (logoImage.height / logoImage.width);

  // Position the watermark in the center horizontally
  const watermarkX = (canvas.width - logoWidth) / 2;

  // Calculate the Y position to maintain vertical centering within the image (adjust if needed)
  const watermarkY = (canvas.height - watermarkHeight) / 2;

  // Adjust logo opacity (optional)
  ctx.globalAlpha = 0.09; // Set watermark opacity (0-1)

  // Draw the logo onto the canvas with adjusted width and centered position
  ctx.drawImage(logoImage, watermarkX, watermarkY, logoWidth, watermarkHeight);

  // Return a new Blob containing the watermarked image data
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      resolve(new File([blob], imageFile.name, { type: imageFile.type }));
    }, imageFile.type, 0.8); // Adjust quality if needed
  });
};


export const addWatermarkToAlbum = async (album) => {
  const watermarkedAlbum = [];
  for (let index = 0; index < album.length; index++) {
      const watermarkedImage = await addWatermark(album[index]);
      watermarkedAlbum.push(watermarkedImage);
  }
  return watermarkedAlbum;
};


export const addToFavorites = (item) => ({
    type: 'ADD_TO_FAVORITES',
    payload: item,
  });
  
  export const removeFromFavorites = (item) => ({
    type: 'REMOVE_FROM_FAVORITES',
    payload: item,
  });
 export const getBadge = (status , i=1) => {
    switch (status) {
      case 'approved':
        return "success";
      case 'pending':
        return "secondary";
      case 'editing':
        if (i==0)  return  "halfWarning";
        else return "warning" ;
      case 'rejected':
          return "danger";  
  
      default:
        return "primary";
    }
  };

  export const textBadge = (status , i=0) => {
    switch (status) {
      case 'approved':
        return "validé";
      case 'pending':
        return "en attente";
      case 'editing':
          if( i==0 )return "en validation";
          else return (     <span>
            en validation <span className='editsBadge'>(modifié)</span>
          </span> );
      case 'rejected':
          return "suspendu";  
  
      default:
        return "";
    }
  };