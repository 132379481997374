import React from "react";
import {
  CTabContent,
  CTabPane,
  CTabs,

} from "@coreui/react";

import LoginMember from "../../members/LoginMember";
import { isLogin } from "src/components/middleware/auth";
import ServiceForm from "./serviceForm";
import Breadcrumb from "../../Breadcrumb";


export default function AddServices(props) {
  
  localStorage.setItem("prevUrl", props.location.pathname);
  
  return localStorage.getItem("roles") === "ROLE_MEMBER" && isLogin() ? (
      <>
        <div className="container addingPage">
        <Breadcrumb/>
          <main className="l-main">
            <div className="l-section l-section--archive">
              <div className="information-title">
                <h2>Créer une annonce</h2>
              </div>
              <p></p>
              <CTabs>
      

              <CTabContent fade={false} className="vehicule-form-tabContent">
         
             
                <CTabPane>
                  <ServiceForm/>
                </CTabPane>
             
          
              </CTabContent>
            </CTabs>
             
            </div>
          </main>
        </div>
      </>
    ):
    (<LoginMember/>)
    ;

  
}
