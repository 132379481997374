import {
  CAlert,
  CRow,
  CCol,
  CFormGroup,
  CLabel,
  CInput,
  CSelect,
  CInputCheckbox,
  CInputGroup,
  CInputGroupText,
  CInputGroupAppend,
  CButton,
  CInputRadio,
  CSpinner,
} from "@coreui/react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import React, { useEffect, useState } from "react";
import { API_BASE_URL , tokenCookie , keyHeader, sizeAlert } from "../../../../config";
import axios from "axios";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import imageCompression from "browser-image-compression";
import { useHistory } from "react-router-dom";
import { Basic , Scroll , Categori , Adresse , Contact , Extra , Imgs , Engine , Weight , Options} from "src/components/myWidgets/formTitles";
import Loading from "src/components/myWidgets/loadingWidget";
import { addWatermark , addWatermarkToAlbum } from "src/actions";
import { logoutLocal } from "src/components/middleware/auth";
import apiClient from "../../apiClient";

export default function TruckForm() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [makes, setMakes] = useState([]);
  const [status, setStatus] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState("");
  const [mileage, setMileage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [price, setPrice] = useState("");
  const [bodyStyle, setBodyStyle] = useState("");
  const [fuel, setFuel] = useState("");

  const [fiscalPower, setFiscalPower] = useState("");
  const [color, setColor] = useState("");
  const [transmission, setTransmission] = useState("");
  const [album, setAlbum] = useState([]);
  const [imgPrincipal, setImgPrincipal] = useState(null);
  const [permGVW, setPermGVW] = useState("");
  const [admisiblHw, setAdmisblHw] = useState("");
  const [payload, setPayload] = useState("");
  const [empWeight, setEmpWeight] = useState("");
  const [loadLength, setLoadLength] = useState("");
  const [loadHeight, setLoadHeight] = useState("");
  const [loadWidth, setLoadWidth] = useState("");
  const [loadVolume, setLoadVolume] = useState("");
  const [brake, setBrake] = useState("");
  const [suspension, setSuspension] = useState("");
  const [axleConfig, setAxleConfig] = useState("");
  const [wheelsDist, setWheelsDist] = useState("");
  const [tyreSize, setTyreSize] = useState("");
  const [emissionClass, setEmissionClass] = useState("");
  const [co2Emmision, setCo2Emmision] = useState("");
  const [fuelCons, setFuelCons] = useState("");
  const [urban, setUrban] = useState("");
  const [rural, setRural] = useState("");
  const [sootFilter, setSootFilter] = useState(false);
  const [cab, setCab] = useState("");
  const [sleepingCab, setSleepingCab] = useState(false);
  const [description, setDescription] = useState("");
  const [success, setSuccess] = useState(false);

  const [equipments, setEquipments] = useState([]);
  const [otherEquipments, setOtherEquipments] = useState([]);
  const [accessoryEquipments, setAccessoryEquipments] = useState([]);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [delegate, setDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  const [locality, setLocality] = useState("");
  const [localities, setLocalities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [doneuploadingfile, setDoneuploadingfile] = useState(true);
  const [newPhone, setNewPhone] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pImageThumb, setpImageThumb] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false); 
  const [warranty, setWarranty] = useState("");
  
  const [imgCount , setImgCount] = useState(0);
  const [imgCountA , setImgCountA] = useState(0);
  const [abortControllers, setAbortControllers] = useState({});
  const [removedfiles, setremovedfiles] = useState([]);

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

      if (!doneuploadingfile) {
        alert("not done uploading files yet");
        setLoading(false);
        return false;
      } else {
        setSubmitted(true);
        //console.log("eqqqqq-", equipments);
        //console.log("album0000", album);
        if (
          status &&
          make &&
          model &&
          bodyStyle &&
          price > 1000 &&
          mileage &&
          fiscalPower &&
          country &&
          city &&
          transmission &&
          axleConfig &&
          fuel &&
          warranty &&
          delegate &&
          locality &&
          description
          && imgPrincipal &&            
          (phoneNumber.length==0 || isValidPhoneNumber(phoneNumber)))  {

            const watermarkedAlbum = await addWatermarkToAlbum(album);
            const watermarkedP = await addWatermark(imgPrincipal);

          const formData = new FormData();

          formData.append("brand", make);
          formData.append("model", model);
          formData.append("bodyStyle", bodyStyle);
          formData.append("year", year);
          formData.append("fuel", fuel);
          formData.append("mileage", mileage);
          formData.append("price", price);
          formData.append("locality", locality);
          formData.append("fiscalPower", fiscalPower);
          formData.append("state", status);
          formData.append("color", color);
          formData.append("transmissionType", transmission);
          formData.append("perm_gvw", permGVW);
          formData.append("admisibl_hw", admisiblHw);
          formData.append("payload", payload);
          formData.append("empty_weight", empWeight);
          formData.append("load_length", loadLength);
          formData.append("load_height", loadHeight);
          formData.append("load_width", loadWidth);
          formData.append("load_volume", loadVolume);
          formData.append("brake", brake);
          formData.append("suspension", suspension);
          formData.append("axle_config", axleConfig);
          formData.append("wheels_dist", wheelsDist);
          formData.append("tyre_size", tyreSize);
          formData.append("emission_class", emissionClass);
          formData.append("co2_emmision", co2Emmision);
          formData.append("fuel_cons_combined", fuelCons);
          formData.append("urban", urban);
          formData.append("rural", rural);
          formData.append("soot_filter", sootFilter);
          formData.append("cab", cab);
          formData.append("warranty", warranty);
          formData.append("sleeping_cab", sleepingCab);
          formData.append("description", description);
          formData.append("equipement", equipments);
          if(newPhone)  formData.append("phone", phoneNumber);
          formData.append("imgPrincipal", watermarkedP);
          for (let index = 0; index < watermarkedP.length; index++) {
            formData.append("album[]", watermarkedP[index]);
          }

          apiClient
            .post("/annonce_camion/add", formData )
            .then((res) => {
              //console.log(res);
              if (res.data.status.toString() === "ok") {
                setSuccess(true);

                history.push( "/success/ok" , { from: 'add' } );
              }
              setLoading(false);
            })
            .catch((error) => {
              //console.log(" errors : ", error);
              setLoading(false);
              if (error.response && error.response.status === 401){
                logoutLocal();
                window.location.replace("/members/login");
              }
            });
        } else {
          window.scrollTo(0, 200);
          setLoading(false);
        }
      }
    
  };

  const handleMakeChange = (e) => {
    setMake(e.target.value);
  };
  const onChangeExt = (event) => {
    let nCheckbox = equipments.slice(); // create a new copy of state value
    if (isValueExist(nCheckbox, event)) {
      // check if the same value is preexisted in the array
      const index = nCheckbox.indexOf(event.target.value);
      nCheckbox.splice(index, 1); // removing the preexciting value
    } else {
      nCheckbox.push(event.target.value); // inserting the value of checkbox in the array
    }
    setEquipments(nCheckbox);
  };

  const onSelectAll = (event, i) => {
    //console.log(event.target.checked);
    //console.log(equipments);
    //console.log(accessoryEquipments);
    //console.log(otherEquipments);
    let nCheckbox = equipments.slice();

    if (i == 1) {
      if (event.target.checked) {
        accessoryEquipments.forEach((element) => {
          if (nCheckbox.includes(element.id.toString())) {
          } else nCheckbox.push(element.id.toString());
        });
      } else {
        accessoryEquipments.forEach((element) => {
          if (nCheckbox.includes(element.id.toString())) {
            const index = nCheckbox.indexOf(element.id.toString());
            nCheckbox.splice(index, 1);
          } else {
          }
        });
      }
    }
    if (i == 2) {
      if (event.target.checked) {
        otherEquipments.forEach((element) => {
          if (nCheckbox.includes(element.id.toString())) {
          } else nCheckbox.push(element.id.toString());
        });
      } else {
        otherEquipments.forEach((element) => {
          if (nCheckbox.includes(element.id.toString())) {
            const index = nCheckbox.indexOf(element.id.toString());
            nCheckbox.splice(index, 1);
          } else {
          }
        });
      }
    }

    setTimeout(() => {
      setEquipments(nCheckbox);
      //console.log("nch-" + nCheckbox);
      //console.log("eq-" + equipments);
    }, 100);
  };

  useEffect(() => {

    if (!localStorage.getItem("truckBrands")) 
    { axios(`${API_BASE_URL}/vehicles/truck-brands`)
       .then((res) => res.data)
       .then(
         (result) => {
          setIsLoaded(true);
          setMakes(result);
     localStorage.setItem("truckBrands", JSON.stringify(result));
         },
         (error) => {
           // //console.log(error);
           setIsLoaded(true);
         }
       );
   } else {
    setMakes(JSON.parse(localStorage.getItem("truckBrands")));  
    setIsLoaded(true);
   }
      
      if (!localStorage.getItem("otherEquipments")) {
        axios(`${API_BASE_URL}/equipements/other`)
        .then((res) => res.data)
        .then(
          (result) => {
            setOtherEquipments(result);
            // //console.log(result);
    localStorage.setItem("otherEquipments", JSON.stringify(result));

          },
          (error) => {
            setError(error);
          }
        );
      } else {
     setOtherEquipments(JSON.parse(localStorage.getItem("otherEquipments")));
      }
  

if (!localStorage.getItem("accessoryEquipments"))
  {  axios(`${API_BASE_URL}/equipements/accessory`)
      .then((res) => res.data)
      .then(
        (result) => {
          setAccessoryEquipments(result);
          // //console.log(result);
    localStorage.setItem("accessoryEquipments", JSON.stringify(result));
        },
        (error) => {
          setError(error);
        }
      );
      } else {
    setAccessoryEquipments(JSON.parse(localStorage.getItem("accessoryEquipments")));
      }

    if (!localStorage.getItem("countries")) {
      axios(`${API_BASE_URL}/countries/all`)
        .then((res) => res.data)
        .then(
          (result) => {
            // //console.log("countries", result);
            setCountries(result);
            localStorage.setItem("countries", JSON.stringify(result));
          },
          (error) => {
            setError(error);
          }
        );
    } else {
      // //console.log("countris2222222");
      //  //console.log(localStorage.getItem("countries"));
      setCountries(JSON.parse(localStorage.getItem("countries")));
    }
  }, [countries.id]);

  const getYear = () => {
    const years = [];
    for (let i = new Date().getFullYear(); i > 1950; i--) {
      years.push(
        <option value={i} key={i}>
          {" "}
          {i}
        </option>
      );
    }
    return years;
  };

  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = async ({ meta, file }, status) => {

    if (status === 'preparing' && meta.size > 5 * 1024 * 1024) {
      alert(sizeAlert);
      return;
  //file = null;
    }
    setDoneuploadingfile(false);
    let removedfilesx = removedfiles

    if (status === 'preparing' && !(meta.size > 5 * 1024 * 1024)) { setImgCountA(imgCountA+1)}     
    if (status.toString() === "done" && !(meta.size > 5 * 1024 * 1024)) {  
      let newalb = album;
      let options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
      };
     const abortController = new AbortController();
      const fileId = meta.id;
      const updatedAbortControllers = { ...abortControllers, [fileId]: abortController };
      setAbortControllers(updatedAbortControllers);
      options.signal = abortController.signal;

      try {
        file = await imageCompression(file, options);
      } catch (error) {
        //console.log('erro compress : ',error);
       // return;
      }
         if ((!removedfiles.includes(meta.id) && !removedfilesx.includes(meta.id)) && !(meta.size > 5 * 1024 * 1024)) 
         {
          newalb.push(file);
         // setAlbum(prevAlbum => [...prevAlbum, file]);
         setAlbum(newalb)
          setImgCount(prevCount => prevCount + 1);
    } else {
       //console.log("new album in done else");
    //console.log(newalb.length ,newalb);
   }
      setDoneuploadingfile(true);
    }
   if (status.toString() === "removed" && !(meta.size > 5 * 1024 * 1024)) {
    removedfilesx.push(meta.id);
    setremovedfiles(removedfiles)
  // setremovedfiles(prevA => [...prevA, meta.id]);
      const fileId = meta.id;
      if (abortControllers[fileId]) {
        abortControllers[fileId].abort();
        const updatedAbortControllers = { ...abortControllers };
        delete updatedAbortControllers[fileId];
        setAbortControllers(updatedAbortControllers);
      
      }

      let index = album.findIndex((obj) => obj.name === file.name);
      let newAlbum = album;
     { newAlbum.splice(index, 1);
     if(index>-1) {
     if(imgCount>0) setImgCount(newAlbum.length);}
      if(imgCountA>0) setImgCountA(imgCountA-1);
      setAlbum(newAlbum);
     }
      setDoneuploadingfile(true);

    } else  setDoneuploadingfile(true);
  };

  const handleFileSection = async ( event) => {
    setUploadingImage(true);
    let file = event.target.files[0];
    if (file.size > 5 * 1024 * 1024) {
      alert(sizeAlert);
      setpImageThumb(null);
      setImgPrincipal(null);
      setUploadingImage(false);    
      event.target.value = '';
      return;
    }

    let options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
    };
    try {
      file = await imageCompression(file, options);

    } catch (error) {
      //console.log(error);
    }  
    setpImageThumb(URL.createObjectURL(file));
    setImgPrincipal(file);
    setUploadingImage(false);   
  }; 

  function isValueExist(data, event) {
    if (data.length === 0) {
      return false;
    }

    for (let i = 0; i <= data.length; i++) {
      if (event.target.value === data[i]) {
        return true;
      }
    }
    return false;
  }
  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    let id = e.target.value;
    var countriesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      countriesF = countries.find((city) => city.id.toString() === id);
      setCities(countriesF.cities);
      //console.log("countriesF", countriesF);
    } else {
      setCity([]);
      //  setModel("");
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    let id = e.target.value;
    var citiesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      citiesF = cities.find((delegate) => delegate.id.toString() === id);
      setDelegates(citiesF.delegations);
      //console.log("citiesF", citiesF);
    } else {
      setDelegate([]);
      //  setModel("");
    }
  };
  const handleDelegateChange = (e) => {
    setDelegate(e.target.value);
    let id = e.target.value;
    var delegatesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      delegatesF = delegates.find((local) => local.id.toString() === id);
      setLocalities(delegatesF.localities);
      //console.log("delegatesF", delegatesF);
    } else {
      setLocality([]);
      //  setModel("");
    }
  };

  const changePhoneNumber = (event) => {
    //console.log(event.target.checked);
    if(event.target.checked) {setNewPhone(false);setPhoneNumber("");}
    else {setNewPhone(true);
    }
  }

  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
    <Loading/>
    );
  } else {
    return (
      <>
        <form id="form-add" onSubmit={handleSubmit} className='TruckFormClass'>
          {/* <div className="information-title">
            <h2>Informations du camion</h2>
          </div> */}

          <div className="advInfoSzctionCard" >
       <Categori name='Camion' />
       <CRow className='stateCheckBoxForm'>
            <CCol>
              {" "}
              <CLabel>Etat </CLabel>
              <CFormGroup variant="checkbox" inline>
                <CInputRadio
                  className="form-check-input"
                  id="1"
                  name="status"
                  value="1"
                  onChange={(e) => setStatus(e.target.value)}
                />
                <CLabel variant="checkbox" htmlFor="neuf">
                  Neuf
                </CLabel>
              </CFormGroup>
              <CFormGroup variant="checkbox" inline>
                <CInputRadio
                  className="form-check-input"
                  id="0"
                  name="status"
                  value="0"
                  onChange={(e) => setStatus(e.target.value)}
                />
                <CLabel variant="checkbox" htmlFor="occasion">
                  Occasion
                </CLabel>
              </CFormGroup>
              {submitted && !status && (
                <span className="invalid-feedback error">
                  Veuillez choisir l'état de la voiture
                </span>
              )}
            </CCol>
          </CRow> <br />
          <CRow>
            <CCol md="6">
              <CFormGroup>
                <CLabel>Type de véhicule </CLabel>
                <CSelect
                  name="bodyStyle"
                  value={bodyStyle}
                  onChange={(e) => setBodyStyle(e.target.value)}
                >
                  <option value=""></option>
                  <option value="Benne">Benne</option>
                  <option value="Chassis">Châssis</option>
                  <option value="Citerne">Citerne</option>
                  <option value="Fourgon">Fourgon</option>
                  <option value="Frigorifique">Frigorifique</option>
                  <option value="Plateau">Plateau</option>
                  <option value="Polybenne">Polybenne</option>
                  <option value="Porte-conteneurs">Porte conteneurs</option>
                  <option value="Porte-engins">Porte engins</option>
                  <option value="Savoyarde">Savoyarde</option>
                  <option value="Utilitaire">Utilitaire</option>
                  <option value="Pick up">Pick Up</option>
                  <option value="Autres">Autres</option>
                </CSelect>
                {submitted && !bodyStyle && (
                  <span className="invalid-feedback error">
                    Veuillez choisir la carrosserie
                  </span>
                )}
              </CFormGroup>
            </CCol>
            <CCol md="6">
              <CFormGroup>
                <CLabel htmlFor="make">Marque</CLabel>
                <CSelect
                  name="sector"
                  value={make}
                  onChange={(e) => handleMakeChange(e)}
                >
                  <option value="0"> Choisir la marque</option>
                  {makes.map((make) => {
                    return (
                      <option key={make.id} value={make.id}>
                        {make.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !make && (
                  <span className="invalid-feedback error">
                    Veuillez choisir la marque de la voiture
                  </span>
                )}
              </CFormGroup>
            </CCol>
            <CCol md="6">
              <CFormGroup>
                <CLabel htmlFor="model">Modèle</CLabel>
                <CInput
                  name="model"
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                ></CInput>
                {submitted && !model && (
                  <span className="invalid-feedback error">
                    Veuillez choisir le modèle de la voiture
                  </span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="year">Année</CLabel>
                <CSelect
                  value={year}
                  name="year"
                  onChange={(e) => setYear(e.target.value)}
                >
                  <option></option>
                  {getYear()}
                </CSelect>

                {submitted && !year && (
                  <span className="invalid-feedback error">
                    Veuillez entrer l'année de la voiture
                  </span>
                )}
              </CFormGroup>
            </CCol>
          </CRow>
          <Scroll/>
          </div>

          <div className="advInfoSzctionCard" >
        <Basic/>
          <CRow xs="12">          
            <CCol xs="4">
              <CFormGroup>
                <CLabel>Kilométrage </CLabel>
                <CInput
                  type="number"
                  name="mileage"
                  value={mileage}
                  onChange={(e) => setMileage(e.target.value)}
                ></CInput>
                {submitted && !mileage && (
                  <span className="invalid-feedback error">
                    Veuillez entrer le kilométrage de la voiture
                  </span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="4">
              <CFormGroup>
                <CLabel htmlFor="price">Prix en TND</CLabel>
                <CInput
                  type="number"
                  name="price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                ></CInput>
                {submitted && price < 1000 && (
                  <span className="invalid-feedback error">
                    Veuillez entrer le prix de la voiture
                  </span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="4">
              <CFormGroup>
                <CLabel htmlFor="fiscalPower">Puissance fiscale</CLabel>
                <CInputGroup>
                  <CInput
                    name="fiscalPower"
                    value={fiscalPower}
                    onChange={(e) => setFiscalPower(e.target.value)}
                  />
                  <CInputGroupAppend>
                    <CInputGroupText>CH</CInputGroupText>
                  </CInputGroupAppend>
                </CInputGroup>
                {submitted && !fiscalPower && (
                  <span className="invalid-feedback error">obligatoire</span>
                )}
              </CFormGroup>
            </CCol>

            <CCol xs="4">
              <CFormGroup>
              <CLabel>Garrantie </CLabel>
                <CSelect
                  name="warranty"
                  value={warranty}
                  onChange={(e) => setWarranty(e.target.value)}
                >
                  <option value=""></option>
                  <option value="0">0</option>
                  <option value="moins 1"> Moins d'une année</option>
                  <option value="1">1 an</option>
                  <option value="2">2 ans</option>
                </CSelect>
                {submitted && !warranty && (
                  <span className="invalid-feedback error">Obligatoire</span>
                )}
              </CFormGroup>
            </CCol>

            <CCol md="4">
              <CFormGroup>
                <CLabel>Couleur </CLabel>
                <CSelect
                  name="color"
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                >
                  <option value=""></option>
                  <option value="Noir">Noir</option>
                  <option value="Gris argent">Gris argent</option>
                  <option value="Gris anthracite">Gris anthracite</option>
                  <option value="Gris Shark">Gris Shark</option>
                  <option value="Blanc">Blanc</option>
                  <option value="Rouge">Rouge</option>
                  <option value="Bleu">Bleu</option>
                  <option value="Beige">Beige</option>
                  <option value="Jaune">Jaune</option>
                  <option value="Orange">Orange</option>
                  <option value="Vert">Vert</option>
                  <option value="Aubergine">Aubergine</option>
                  <option value="Marron">Marron</option>
                  <option value="Autre">Autre</option>
                </CSelect>
              </CFormGroup>
            </CCol>
            <CCol md="4">
              <CFormGroup>
                <CLabel>Cabine du conducteur </CLabel>
                <CSelect
                  name="cab"
                  value={cab}
                  onChange={(e) => setCab(e.target.value)}
                >
                  <option value=""></option>
                  <option value="Cabine couchette"> Cabine couchette</option>
                  <option value="Cabine courte"> Cabine courte</option>
                  <option value="Autre"> Autres</option>
                </CSelect>
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CFormGroup variant="checkbox" inline>
                <CInputCheckbox
                  className="form-check-input"
                  name="sleepingCab"
                  value={sleepingCab}
                  onChange={(e) => setSleepingCab(e.target.checked)}
                />
                <CLabel variant="checkbox">Cabine couchette</CLabel>
              </CFormGroup>
            </CCol>
          </CRow>
          <Scroll/>
          </div>

          <div className="advInfoSzctionCard" >
        <Adresse/>
         <CRow xs="12"> 
           <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="country">Pays</CLabel>
                <CSelect
                  name="country"
                  value={country}
                  onChange={(e) => handleCountryChange(e)}
                >
                  <option value="0"> Choisissez un pays</option>
                  {countries.map((country) => {
                    return (
                      <option key={country.id} value={country.id}>
                        {country.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !country && (
                  <span className="invalid-feedback error">Obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="Région">Gouvernorat</CLabel>
                <CSelect
                  name="city"
                  value={city}
                  onChange={(e) => handleCityChange(e)}
                >
                  <option value="0"> Gouvernorat</option>
                  {cities.map((city) => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !city && (
                  <span className="invalid-feedback error">Obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow xs="12">
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="delegate">Délégation</CLabel>
                <CSelect
                  name="delegate"
                  value={delegate}
                  onChange={(e) => handleDelegateChange(e)}
                >
                  <option value="0"> Choisissez une délégation</option>
                  {delegates.map((delegate) => {
                    return (
                      <option key={delegate.id} value={delegate.id}>
                        {delegate.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !delegate && (
                  <span className="invalid-feedback error">Obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="loclité">Localité</CLabel>
                <CSelect
                  name="locality"
                  value={locality}
                  onChange={(e) => setLocality(e.target.value)}
                >
                  <option value="0"> Localité</option>
                  {localities.map((local) => {
                    return (
                      <option key={local.id} value={local.id}>
                        {local.label}
                      </option>
                    );
                  })}
                </CSelect>
                {submitted && !locality && (
                  <span className="invalid-feedback error">Obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
          </CRow>
          <Scroll/>
          </div>

          {/* <div className="information-title">
            <h2>Poids & masse</h2>
          </div> */}
          <div className="advInfoSzctionCard" >
        <Weight/>
          <CRow>
            <CCol md="3">
              <CFormGroup>
                <CLabel htmlFor="permGVW">Poids total admissible</CLabel>
                <CInputGroup>
                  <CInput
                    name="permGVW"
                    value={permGVW}
                    onChange={(e) => setPermGVW(e.target.value)}
                  />
                  <CInputGroupAppend>
                    <CInputGroupText>Kg</CInputGroupText>
                  </CInputGroupAppend>
                </CInputGroup>
              </CFormGroup>
            </CCol>
            <CCol md="3">
              <CFormGroup>
                <CLabel htmlFor="admisiblHw">Poids du train admissible</CLabel>
                <CInputGroup>
                  <CInput
                    name="admisiblHw"
                    value={admisiblHw}
                    type="number"
                    onChange={(e) => setAdmisblHw(e.target.value)}
                  />
                  <CInputGroupAppend>
                    <CInputGroupText>Kg</CInputGroupText>
                  </CInputGroupAppend>
                </CInputGroup>
              </CFormGroup>
            </CCol>
            <CCol md="3">
              <CFormGroup>
                <CLabel htmlFor="payload">Charge utile</CLabel>
                <CInputGroup>
                  <CInput
                    name="paylod"
                    value={payload}
                    type="number"
                    onChange={(e) => setPayload(e.target.value)}
                  />
                  <CInputGroupAppend>
                    <CInputGroupText>Kg</CInputGroupText>
                  </CInputGroupAppend>
                </CInputGroup>
              </CFormGroup>
            </CCol>
            <CCol md="3">
              <CFormGroup>
                <CLabel htmlFor="empWeight">Poids à vide</CLabel>
                <CInputGroup>
                  <CInput
                    name="empWeight"
                    value={empWeight}
                    onChange={(e) => setEmpWeight(e.target.value)}
                  />
                  <CInputGroupAppend>
                    <CInputGroupText>Kg</CInputGroupText>
                  </CInputGroupAppend>
                </CInputGroup>
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="3">
              <CFormGroup>
                <CLabel htmlFor="loadLength">
                  Longueur de l'espace de chargement
                </CLabel>
                <CInputGroup>
                  <CInput
                    name="loadLength"
                    value={loadLength}
                    onChange={(e) => setLoadLength(e.target.value)}
                  />
                  <CInputGroupAppend>
                    <CInputGroupText>mm</CInputGroupText>
                  </CInputGroupAppend>
                </CInputGroup>
              </CFormGroup>
            </CCol>
            <CCol md="3">
              <CFormGroup>
                <CLabel htmlFor="loadLength">Hauteur de chargement</CLabel>
                <CInputGroup>
                  <CInput
                    name="loadHeight"
                    value={loadHeight}
                    onChange={(e) => setLoadHeight(e.target.value)}
                  />
                  <CInputGroupAppend>
                    <CInputGroupText>mm</CInputGroupText>
                  </CInputGroupAppend>
                </CInputGroup>
              </CFormGroup>
            </CCol>
            <CCol md="3">
              <CFormGroup>
                <CLabel htmlFor="loadWidth">Largeur de chargement</CLabel>
                <CInputGroup>
                  <CInput
                    name="loadWidth"
                    value={loadWidth}
                    onChange={(e) => setLoadWidth(e.target.value)}
                  />
                  <CInputGroupAppend>
                    <CInputGroupText>mm</CInputGroupText>
                  </CInputGroupAppend>
                </CInputGroup>
              </CFormGroup>
            </CCol>

            <CCol md="3">
              <CFormGroup>
                <CLabel htmlFor="loadVolume">Capacité de chargement</CLabel>
                <CInputGroup>
                  <CInput
                    name="loadVolume"
                    value={loadVolume}
                    onChange={(e) => setLoadVolume(e.target.value)}
                  />
                  <CInputGroupAppend>
                    <CInputGroupText>m3</CInputGroupText>
                  </CInputGroupAppend>
                </CInputGroup>
              </CFormGroup>
            </CCol>
          </CRow>
          <Scroll/>
          </div>

          {/* <div className="information-title">
            <h2>Moteur & environnement</h2>
          </div> */}
          <div className="advInfoSzctionCard" >
        <Engine/>
          <CRow>
            <CCol md="3">
              <CFormGroup>
                <CLabel htmlFor="transmission">Transmission</CLabel>
                <CSelect
                  name="transmission"
                  value={transmission}
                  onChange={(e) => setTransmission(e.target.value)}
                >
                  <option value=""></option>
                  <option value="manuelle">Manuelle</option>
                  <option value="semi-automatique"> Semi-automatique</option>
                  <option value="automatique">Automatique</option>
                  <option value="Autres">Autres</option>
                </CSelect>
                {submitted && !transmission && (
                  <span className="invalid-feedback error">obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol md="3">
              <CFormGroup>
                <CLabel htmlFor="brake">Frein continu</CLabel>
                <CSelect
                  name="brake"
                  value={brake}
                  onChange={(e) => setBrake(e.target.value)}
                >
                  <option value="">Veuillez choisir</option>
                  <option value="autres">Autres</option>
                  <option value="frein moteur"> Frein moteur</option>
                  <option value="intarder">Intarder</option>
                  <option value="Ralentisseur">Ralentisseur</option>
                  <option value="telma">Telma</option>
                  <option value="VEB">VEB</option>
                </CSelect>
              </CFormGroup>
            </CCol>
            <CCol md="3">
              <CFormGroup>
                <CLabel htmlFor="suspension">Suspension</CLabel>
                <CSelect
                  name="suspension"
                  value={suspension}
                  onChange={(e) => setSuspension(e.target.value)}
                >
                  <option value="">Veuillez choisir</option>
                  <option value="autres">Autres</option>
                  <option value="Lames"> Lames</option>
                  <option value="Lames/Air">Lames/air</option>
                  <option value="Air/air">Air/air</option>
                  <option value="Suspension à lames paraboliques">
                    Suspension à lames paraboliques
                  </option>
                </CSelect>
              </CFormGroup>
            </CCol>
            <CCol md="3">
              <CFormGroup>
                <CLabel htmlFor="transmission">Configuration essieux</CLabel>
                <CSelect
                  name="axleConfig"
                  value={axleConfig}
                  onChange={(e) => setAxleConfig(e.target.value)}
                >
                  <option value=""></option>
                  <option value="4x2">4x2</option>
                  <option value="4x4"> 4x4</option>
                  <option value="6x2">6x2</option>
                  <option value="6x4">6x4</option>
                  <option value="6x6">6x6</option>
                  <option value="8x2">8x2</option>
                  <option value="8x4">8x4</option>
                  <option value="8x6">8x6</option>
                  <option value="8x8">8x8</option>
                  <option value="10x4">10x4</option>
                  <option value="10x8">10x8</option>
                  <option value="12x6">12x6</option>
                  <option value="2 essieux">2 essieux</option>
                  <option value="3 essieux">3 essieux</option>
                  <option value=">3 essieux"> {">"}3 essieux</option>
                </CSelect>
              </CFormGroup>
              {submitted && !axleConfig && (
                <span className="invalid-feedback error">obligatoire</span>
              )}
            </CCol>
          </CRow>
          <CRow>
            <CCol md="3">
              <CFormGroup>
                <CLabel htmlFor="wheelsDist">Empattement</CLabel>
                <CInputGroup>
                  <CInput
                    name="wheelsDist"
                    value={wheelsDist}
                    onChange={(e) => setWheelsDist(e.target.value)}
                  />
                  <CInputGroupAppend>
                    <CInputGroupText>mm</CInputGroupText>
                  </CInputGroupAppend>
                </CInputGroup>
              </CFormGroup>
            </CCol>
            <CCol md="3">
              <CFormGroup>
                <CLabel htmlFor="tyreSize">Taille des pneus</CLabel>
                <CInputGroup>
                  <CInput
                    name="tyreSize"
                    value={tyreSize}
                    onChange={(e) => setTyreSize(e.target.value)}
                  />
                  <CInputGroupAppend>
                    <CInputGroupText>mm</CInputGroupText>
                  </CInputGroupAppend>
                </CInputGroup>
              </CFormGroup>
            </CCol>
            <CCol xs="3">
              <CFormGroup>
                <CLabel htmlFor="fuel">Carburant</CLabel>
                <CSelect
                  name="fuel"
                  value={fuel}
                  onChange={(e) => setFuel(e.target.value)}
                >
                  <option value=""></option>
                  <option value="Essence">Essence</option>
                  <option value="Diesel">Diesel</option>
                  <option value="GAZ">GAZ</option>
                </CSelect>
                {submitted && !fuel && (
                  <span className="invalid-feedback error">obligatoire</span>
                )}
              </CFormGroup>
            </CCol>
            <CCol xs="3">
              <CFormGroup>
                <CLabel htmlFor="emissionClass">Classe d'émission</CLabel>
                <CSelect
                  name="emissionClass"
                  value={emissionClass}
                  onChange={(e) => setEmissionClass(e.target.value)}
                >
                  <option value=""></option>
                  <option value="Euro1">Euro1</option>
                  <option value="Euro2">Euro2</option>
                  <option value="Euro3">Euro3</option>
                  <option value="Euro4">Euro4</option>
                  <option value="Euro5">Euro5</option>
                  <option value="Euro6">Euro6</option>
                  <option value="Aucun">Aucun</option>
                </CSelect>
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="3">
              <CFormGroup>
                <CLabel htmlFor="co2Emmision">
                  Émission de CO2 en cycle mixte
                </CLabel>
                <CInputGroup>
                  <CInput
                    name="co2Emmision"
                    value={co2Emmision}
                    onChange={(e) => setCo2Emmision(e.target.value)}
                  />
                  <CInputGroupAppend>
                    <CInputGroupText>g/km</CInputGroupText>
                  </CInputGroupAppend>
                </CInputGroup>
              </CFormGroup>
            </CCol>
            <CCol md="3">
              <CFormGroup>
                <CLabel htmlFor="fuelCons">
                  Consommation de carburant en cycle mixte
                </CLabel>
                <CInputGroup>
                  <CInput
                    name="fuelCons"
                    value={fuelCons}
                    onChange={(e) => setFuelCons(e.target.value)}
                  />
                  <CInputGroupAppend>
                    <CInputGroupText>l/100km</CInputGroupText>
                  </CInputGroupAppend>
                </CInputGroup>
              </CFormGroup>
            </CCol>
            <CCol md="3">
              <CFormGroup>
                <CLabel htmlFor="urban">
                  Consommation de carburant en ville
                </CLabel>
                <CInputGroup>
                  <CInput
                    name="urban"
                    value={urban}
                    onChange={(e) => setUrban(e.target.value)}
                  />
                  <CInputGroupAppend>
                    <CInputGroupText>l/100km</CInputGroupText>
                  </CInputGroupAppend>
                </CInputGroup>
              </CFormGroup>
            </CCol>
            <CCol md="3">
              <CFormGroup>
                <CLabel htmlFor="rural">
                  Consommation de carburant sur route
                </CLabel>
                <CInputGroup>
                  <CInput
                    name="rural"
                    value={rural}
                    onChange={(e) => setRural(e.target.value)}
                  />
                  <CInputGroupAppend>
                    <CInputGroupText>l/100km</CInputGroupText>
                  </CInputGroupAppend>
                </CInputGroup>
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CFormGroup variant="checkbox" inline>
                <CInputCheckbox
                  className="form-check-input"
                  name="sootFilter"
                  value={sootFilter}
                  onChange={(e) => setSootFilter(e.target.checked)}
                />
                <CLabel variant="checkbox">Filtre à particule</CLabel>
              </CFormGroup>
            </CCol>
          </CRow>
          <Scroll/>
          </div>

          {/* <div className="information-title">
            <h2>Options</h2>
          </div>
      


          <div className="information-title">
            <h2>pièces /accessoires</h2>
          </div> */}
          <div className="advInfoSzctionCard" >
        <Options name='pièces /accessoires' />
          <CRow className="toutesOptions">
            <CCol xs="12">
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  id="0"
                  name="0"
                  value="0"
                  onChange={(e) => onSelectAll(e, 1)}
                />
                <label htmlFor="0">Toutes options</label>
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow>
            {accessoryEquipments.map((accessoryEquipment, index) => {
              return (
                <CCol xs="3" key={index}>
                  <CFormGroup variant="checkbox" className="checkbox">
                    <CInputCheckbox
                      checked={equipments.includes(
                        accessoryEquipment.id.toString()
                      )}
                      id={`custom-checkbox-${index}`}
                      name={accessoryEquipment.id}
                      value={accessoryEquipment.id}
                      onChange={(e) => onChangeExt(e)}
                      selected={accessoryEquipments.includes(
                        accessoryEquipment.label
                      )}
                    />
                    <label htmlFor={`custom-checkbox-${index}`}>
                      {accessoryEquipment.label}
                    </label>
                  </CFormGroup>
                </CCol>
              );
            })}
          </CRow>
          <Scroll/>
         </div>

          {/* <div className="information-title">
            <h2>Autres caractéristiques</h2>
          </div> */}
          <div className="advInfoSzctionCard" >
        <Options name='Autres caractéristiques' /> 
          <CRow className="toutesOptions">
            <CCol xs="12">
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  id="0"
                  name="0"
                  value="0"
                  onChange={(e) => onSelectAll(e, 2)}
                />
                <label htmlFor="0">Toutes options</label>
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow>
            {otherEquipments.map((otherEquipment, index) => {
              return (
                <CCol xs="3" key={index}>
                  <CFormGroup variant="checkbox" className="checkbox">
                    <CInputCheckbox
                      checked={equipments.includes(
                        otherEquipment.id.toString()
                      )}
                      id={`custom-checkbox-${index}`}
                      name={otherEquipment.id}
                      value={otherEquipment.id}
                      onChange={(e) => onChangeExt(e)}
                      selected={otherEquipments.includes(otherEquipment.label)}
                    />
                    <label htmlFor={`custom-checkbox-${index}`}>
                      {otherEquipment.label}
                    </label>
                  </CFormGroup>
                </CCol>
              );
            })}
          </CRow>

          {submitted && !equipments.length > 0 && (
            <span className="invalid-feedback error">
              Veuillez choisir au moins un équipement
            </span>
          )}
          <br/>
          <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >
       <Contact/>
        <CRow xs="12">
          <CCol xs="6">
              {newPhone && <CFormGroup className='formPhoneNumber'>
                <CLabel htmlFor="phoneNumber">Numéro téléphone</CLabel>
                <PhoneInput
                  type="text"
                  onChange={(value) => setPhoneNumber(value)}
                  placeholder="Entrez un numéro de téléphone"
                  defaultCountry="TN"
                  international
                  name="phoneNumber"
                  id="phoneNumber"
                  value={phoneNumber}
                />
                 {submitted && newPhone && phoneNumber.length>0 && !isValidPhoneNumber(phoneNumber) && (
                  <span className="invalid-feedback error">Ce numéro de téléphone n'est pas valide</span>
                )}
              </CFormGroup>}
              <CFormGroup variant="checkbox" className="checkbox">
                <CInputCheckbox
                  onChange={changePhoneNumber}                 
                />
                <CLabel variant="checkbox" className="form-check-label">
                Utilisez le numéro téléphone de votre compte
                </CLabel>
              </CFormGroup> 
            </CCol>
          </CRow>
          <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >
     <Extra/>
     <CRow>
            <CCol>
              <CKEditor
                editor={ClassicEditor}
                data={description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDescription(data);
                }}
              />

              {submitted && !description > 0 && (
                <span className="invalid-feedback error">Obligatoire</span>
              )}
            </CCol>
          </CRow>
          <Scroll/>
         </div>

         <div className="advInfoSzctionCard" >
          <Imgs/>
          <CRow>
            <CCol>
            <CFormGroup style={{display:"inline-block"}}>
                <CLabel htmlFor="pImage">Principal image</CLabel>
                <input 
                          type="file"
                          name="pImage"
                          onChange={handleFileSection}
                          accept="image/*"
                           ></input>
            </CFormGroup>
            {uploadingImage &&<span style={{height:"fit-content",marginLeft:"10px",lineHeight:"90px"}}>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span>}
                               {!uploadingImage && pImageThumb &&  <img style={{height: "85px", width: "auto"}}
          src={pImageThumb}
          alt="preview"
          className="img-fluid img-thumbnail "
        />} 
           {submitted && !imgPrincipal && (
                <span className="invalid-feedback error">
                  Veuillez uploader une image
                </span>
              )}
            </CCol>
          </CRow><br/>
          {imgCountA>0 && <> <div className="imgCounter"> {imgCount} {imgCount > 1 ? ' images téléchargées sur ' :' image téléchargée sur ' }  {imgCountA} </div>
       <div className="loading-bar">
          <div className="loading-bar-fill" style={{ width: `${imgCountA==0?0 :(imgCount / imgCountA) * 100}%` }}></div>
        </div><br/></>}

          <CRow>
            <CCol>
            <CLabel >Autres images</CLabel> 
            <Dropzone
              //getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              // onSubmit={handleSubmitF}
              accept="image/*"
              maxFiles="9"
              maxSizeBytes={5 * 1024 * 1024}
            ></Dropzone>
            </CCol>
          </CRow>
          <br />
          </div>
          {success && (
            <CAlert show={true} placement="top-end" color="success">
              {`Le véhicule a été ajouté avec succès !`}
            </CAlert>
          )}
          <br />
          <CRow>
          <CCol md="3" className="offset-9">
            {
  ((!(doneuploadingfile && imgCountA == album.length)) || uploadingImage)? 
  (
              <CButton color="primary" block disabled>
                <span>
                  {" "}
                  <CSpinner color="default" size="sm" />
                </span>

                <span> chargement des images</span>
              </CButton>
            )

       :  loading ? (
              <CButton color="primary" block disabled>
                <span>
                  {" "}
                  <CSpinner color="default" size="sm" />
                </span>

                <span> Valider</span>
              </CButton>
            )  
            :
              <CButton color="primary" block type="submit">
                Valider
              </CButton>
            
}
            </CCol>
          </CRow>
        </form>
      </>
    );
  }
}
