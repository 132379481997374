import React, { Component } from "react";

export class ErrorPage extends Component {
  render() {
    return (
      <>
        <div className="l-errorPage l-errorPage--404">
          <main
            id="content"
            className="l-errorPage__container l-errorPage__container--narrow"
          >
            <div className="l-errorPage__inner">
              <h1 className="l-errorPage__title">
                <span className="l-errorPage__errorCode">404</span> Page Not
                Found
              </h1>
              <p className="l-errorPage__text">
                We're sorry, but the page you requested can't be found. You can
                try searching our archives or browsing our latest headlines.
              </p>
            </div>
          </main>
        </div>
      </>
    );
  }
}

export default ErrorPage;
