import React, { Component } from "react";

//import axios from "axios";
//import { API_BASE_URL , tokenCookie , keyHeader, key } from "../../../config";
import apiClient from "../apiClient";
import LoginMember from "../members/LoginMember";
import AddCompany from "./AddCompany";
import JobForm from "./JobForm";
import Loading from "src/components/myWidgets/loadingWidget";
import { isLogin } from "src/components/middleware/auth";
export class AddJob extends Component {
  constructor(props) {
    super(props);

    this.state = {
      member: "",
      error: "",
      company:false,
      loaded: false,
      admin : false ,
    };
  }

  componentDidMount() {
    //console.log("ggggggggggggggg");
    //console.log(localStorage);
    localStorage.setItem("prevUrl", this.props.location.pathname);

    const formData = new FormData();
    //formData.append("id",  tokenCookie);
    apiClient
      .post(`/get_member`  )
      .then((result) => {
        console.log('rss mm:',result);
        if(result.data.accountType.id==2) this.setState({company: true,});
        this.setState({
          member: result.data,
          loaded: true,
        });
    })
    .catch((error) => {
        //console.log("err jj ow", error);

    });

  }
  render() {
    const loaded = this.state.loaded;
if( !(localStorage.getItem("roles") === "ROLE_MEMBER" && isLogin()) )
{
 return (
    <LoginMember />
  );
}

  else  if (!loaded) {
      return (
        <>
        <Loading/>
        </>
      );
    } else {
      return (
        <>
          <div className="container addingPage">
            <main className="l-main">
              <div className="l-section l-section--archive">

            {
              this.state.admin?
              (
                  <>
                  <AddCompany data={this.state}></AddCompany>                  
                  </> 
                )

            : this.state.company ?
            (
                  <>
                     <JobForm></JobForm>
                  </>
                ) 
                :
                (
                  <>
                  <div className="container">
          <main className="l-main lmain2">
            <div className="l-section l-section--archive">
              <div className="loading_logo">
                {/* <img
                  src={"assets/icons/tunisia-living-loading-icon.gif"}
                  alt="tunisia living loading icon"
                /> */}
                <p>   you have to login with a company account in order to have access to add job form !
</p>
              </div>
            </div>
          </main>
        </div>               
                  </> 
                )
            }


                {/* {
                !this.state.member.company  || localStorage.getItem("id")== 65 || localStorage.getItem("id")== 68 
                 ? (<>
                  <AddCompany data={this.state}></AddCompany>                  
                  </>
                ) : (
                  <>
                     <JobForm></JobForm>
                  </>
                )} */}
              </div>
            </main>
          </div>
        </>
      ) 
    }
  }
}

export default AddJob;
