import {
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CButton,
  CAlert,
  CTextarea,
  CSpinner,
} from "@coreui/react";
import React, { Component} from "react";
import { API_BASE_URL , keyHeader, tokenCookie} from "../../../config";
import axios from "axios";
import { logoutLocal } from "src/components/middleware/auth";
import apiClient from "../apiClient";

export class SendApplication extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      cv: null,
      success: "",
      existcv: false,
      isloaded: false,
      cvname: "",
      submitted : false,
      errors: {
        message: "",
        cv: "",
      },
      jobOffer: "",
      loading: false,
    };
  }

  componentDidMount() {
    //const { id } = localStorage.getItem("id");
    
    apiClient(`/checkcv`)
      .then((res) => res.data)
      .then(
        (result) => {
          console.log('checkCV',result);
          if (result.status === 1 || result.status === "1")
            this.setState({
              existcv: true,
            });
           
          this.setState({
            isloaded: true,
          });
   
        },
        (error) => {
          //console.log(error);
          if (error.response && error.response.status === 401){
            logoutLocal();
            window.location.replace("/members/login");
          }
        }
      );
  }

  handleFile = (event) => {
    let file = event.target.files[0];
    //console.log(file.name);

    this.setState({
      cv: file,
      cvname: file.name,
    });
  };
  handleChange = (event) =>
    this.setState({
      [event.target.name]: event.target.value,
    });
  handleValidation = () => {
    let formIsValid = true;
    let errors = {};
    if (this.state.cv === null) {
      formIsValid = false;
      errors.cv = "Veuillez ajouter votre cv en format PDF";
    } else {
      errors.cv = "";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  handleSubmit = (event) => {
    let idMember = tokenCookie;
    //console.log("idMember", idMember);
    event.preventDefault();
    if (1) {
      this.setState({
        loading: true,
      });
      const { id } = this.props.idJob;
      let idMember = tokenCookie;
      //console.log(idMember);
      //console.log("this.props.idJob", this.props.idJob);
      const formData = new FormData();

      formData.append("message", this.state.message);
      formData.append("cv", this.state.cv);
      formData.append("cvname", this.state.cvname);
      formData.append("jobOffer", this.props.idJob);
      formData.append("idMember", idMember);
      apiClient
        .post(`/jobs/send-job-application`, formData )
        .then((res) => {
          if (res.data.status.toString() === "ok") {
            this.setState({
              loading: false,
            });
            this.setState({
              success: "Votre canditature a été envoyé avec succes",
              label: "",
              submitted: true,
            });
            window.location.reload();
          } 
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          //console.log(" errors : ", error);
          if (error.response && error.response.status === 401){
            logoutLocal();
            window.location.replace("/members/login");
          }
        });
    }
  };

  render() {
    let errors = this.state.errors;
    // let jobOffer = this.state.jobOffer;

    return (
      <>
        {this.state.isloaded && (
          <>
            <div className="l-section l-section--archive">
              <form onSubmit={this.handleSubmit} id="form-add">
                <h3>{this.props.title}</h3>
                <p></p>
                <CFormGroup row>
                  <CCol md="3">
                    <CLabel htmlFor="name">Votre message</CLabel>
                  </CCol>
                  <CCol xs="12" md="7">
                    <CTextarea
                      name="message"
                      value={this.state.message}
                      onChange={this.handleChange}
                    />
                  </CCol>
                </CFormGroup>
                {!this.state.existcv && (
                  <CFormGroup row>
                    <CCol xs="3">
                      <CLabel htmlFor="content">Ajouter votre cv</CLabel>
                    </CCol>
                    <CCol xs="7">
                      <CInput
                        type="file"
                        name="image"
                        onChange={this.handleFile}
                        accept=".pdf"
                      />
                      {errors.cv.length > 0 && (
                        <span className="invalid-feedback error">
                          {errors.cv}
                        </span>
                      )}
                    </CCol>
                  </CFormGroup>
                )}
                {this.state.existcv && (
                  <div>
                    <span className="applymessage">
                      Votre candidature sera soumise avec vos documents
                    </span>
                    <span className="applymessage">
                      Vous pouvez modifier vos documents depuis votre{" "}
                      <a href={ "/members/profile/my-info"}>
                        page de profil
                      </a>{" "}
                    </span>
                  </div>
                )}
                <CRow>
                  <CCol
                    xs="12"
                    sm="3"
                    style={{ margin: "auto", marginTop: "15px" }}
                  >
                    {
                      this.state.submitted?
                    <CButton color="primary" block disabled>

                        <span> Valider</span>
                      </CButton>
                     : !this.state.loading ? (
                      <CButton color="primary" block type="submit">
                        Valider
                      </CButton>
                    )
                    :this.state.loading ? (
                      <CButton color="primary" block disabled>
                        <span>
                          {" "}
                          <CSpinner color="default" size="sm" />
                        </span>

                        <span> Valider</span>
                      </CButton>
                    )
                  
                    
                    : null
                    }
                    {/**  <CButton color="primary" block type="submit">
                      Valider
                    </CButton> */}

                    {/* {this.state.loading && (
                      <CButton color="primary" block disabled>
                        <span>
                          {" "}
                          <CSpinner color="default" size="sm" />
                        </span>

                        <span> Valider</span>
                      </CButton>
                    )} */}
                  </CCol>
                </CRow>
                <CRow>
                  {this.state.success !== "" ? (
                    <CAlert
                      style={{
                        textAlign: "center",
                        width: "100%",
                        marginTop: "10px",
                      }}
                      color="success"
                      closeButton
                    >
                      {this.state.success}
                    </CAlert>
                  ) : (
                    ""
                  )}
                </CRow>
              </form>
            </div>
          </>
        )}
        {!this.state.isloaded && (
          <div className="l-section l-section--archive">
            <p className="loadingnow">Chargement...</p>
          </div>
        )}
      </>
    );
  }
}

export default SendApplication;
