import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Breadcrumb = (props) => {
  const location = useLocation();
  const pathParts = location.pathname.split('/'); // Split path into parts
//console.log('pathParts',pathParts)
  if (pathParts.length === 1) return null; // No breadcrumbs for root path

  return (
    <nav aria-label="Breadcrumb">
      <ol className="breadcrumb">
        {pathParts.map((crumb, index) => {
          if (index === 0) { // Handle home link
            return (
              <li className="breadcrumb-item" key={index}>
                <Link to="/">Accueil</Link>
              </li>
            );
          }

          // Create breadcrumbs for other levels
          const crumbLink = pathParts.slice(0, index + 1).join('/');
          return (
             crumb=='details' || crumb=='cars' || crumb=='bikes' || crumb=='camions' || crumb=='motos'  ? (
              ''
              ) 
              :
            <li className="breadcrumb-item" key={index}>
              {index === pathParts.length - 1 && props.name ?  (props.name)
                :index === pathParts.length - 1 ? (
                crumb == 'add'? 'ajouter'
                : crumb=='animals'?'Animaux'
                :crumb=='vehicles'?'Véhicules'
                :crumb == 'real-estates'?'Immoblier'
                : crumb.replace('-',' ')
            
              ) : (
               
                <Link to={crumbLink}>{
                  crumb=='animals'?'Animaux'
                  :crumb=='vehicles'?'Véhicules'
                  :crumb=='real-estates'?'Immoblier'
                  :crumb.replace('-',' ')
                  }</Link>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
